/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../style';

export function TermsContentPT() {
  return (
    <Container>
      <h2>TERMOS DE SERVIÇO - COPYCUT AI</h2>

      <p>(Um produto da SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP)</p>
      <p>Última Atualização: 12 de março de 2025</p>

      <p>POR FAVOR, LEIA ESTES TERMOS E CONDIÇÕES CUIDADOSAMENTE ANTES DE UTILIZAR NOSSOS SERVIÇOS. AO ACESSAR OU UTILIZAR O COPYCUT AI, VOCÊ CONCORDA EM ESTAR VINCULADO A ESTES TERMOS. SE VOCÊ NÃO CONCORDAR COM QUALQUER PARTE DESTES TERMOS, NÃO DEVE UTILIZAR NOSSOS SERVIÇOS.
        EMBORA NOS ESFORCEMOS PARA FORNECER CONTEÚDO GERADO POR IA PRECISO E CONFIÁVEL, MODELOS DE LINGUAGEM PODEM COMETER ERROS. OS USUÁRIOS SÃO EXCLUSIVAMENTE RESPONSÁVEIS POR REVISAR TODO O CONTEÚDO GERADO QUANTO À PRECISÃO, ADEQUAÇÃO E ADEQUAÇÃO E CONFORMIDADE LEGAL <span>E DE AVALIAR EVENTUAIS VIOLAÇÕES DE LEIS DE DIREITOS DE AUTOR</span> ANTES DO USO. O COPYCUT AI NÃO ASSUME NENHUMA RESPONSABILIDADE POR DECISÕES TOMADAS OU AÇÕES REALIZADAS COM BASE NO CONTEÚDO GERADO POR IA.
      </p>

      <h3>1. DEFINIÇÕES</h3>
      <p>"Acordo" significa estes Termos de Serviço.
        "Empresa", "nós", "nos" ou "nosso" refere-se à SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP, registrada sob o CNPJ/MF: 15.563.090/0001-04, com sede na Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 em São Paulo, Brasil, que opera o CopyCut AI, uma plataforma de geração de conteúdo de marketing baseada em IA. Nossos serviços estão disponíveis através do nosso website em https://app.shifters.ai ou https://www.shifters.ai ou https://shifters.ai, ou através de quaisquer de nossas aplicações, plataformas ou serviços relacionados (coletivamente, os "Serviços").
        "Conteúdo" significa qualquer informação, dados, texto, software, música, som, fotografias, gráficos, vídeos, mensagens ou outros materiais.
        "Conteúdo Gerado" significa todo o conteúdo criado pelos Serviços com base no Conteúdo do Usuário ou instruções.
        "Serviços" significa a plataforma CopyCut AI e todos os serviços, recursos, aplicativos e conteúdos relacionados.
        "Assinatura" significa o plano de pagamento recorrente para acesso aos Serviços.
        "Usuário", "você" ou "seu" refere-se a qualquer indivíduo ou entidade que acessa ou utiliza os Serviços.
        "Conteúdo do Usuário" significa qualquer Conteúdo que um Usuário carrega, submete ou disponibiliza aos Serviços.
      </p>

      <h3>2. DESCRIÇÃO DO SERVIÇO</h3>
      <p>O CopyCut AI fornece serviços de geração de conteúdo de marketing baseados em IA, incluindo, mas não se limitando a:
        <ul>
          <li>Geração de VSL (Video Sales Letter)</li>
          <li>Criação de Campanhas Publicitárias</li>
          <li>Geração de Ofertas Irresistíveis</li>
          <li>Geração de Sequências de Email</li>
          <li>Construção de Mecanismos de Marketing</li>
          <li>Otimização Multilíngue</li>
          <li>Desenvolvimento de LLM Personalizado (Empresarial)</li>
          <li>Otimização e Aprimoramento de Conteúdo</li>
          <li>Personalização de Voz da Marca</li>
          <li>Recomendações de Estratégia de Marketing</li>
          <li>Os Serviços são fornecidos "no estado em que se encontram" e "conforme disponíveis". Podemos atualizar, modificar ou aprimorar os Serviços a qualquer momento sem aviso prévio.</li>
        </ul>
      </p>

      <h3>3. ELEGIBILIDADE E REGISTRO</h3>
      <p>
        <h4>3.1 Requisito de Idade</h4>
        <p>Você deve ter pelo menos 18 anos de idade para utilizar os Serviços. Ao utilizar os Serviços, você declara e garante que tem pelo menos 18 anos de idade.</p>

        <h4>3.2 Informações de Registro</h4>
        <p>Para acessar e utilizar os Serviços, você deve se registrar e criar uma conta. Você concorda em fornecer informações precisas, atuais e completas durante o processo de registro e em atualizar essas informações para mantê-las precisas, atuais e completas.</p>

        <h4>3.3 Verificação de Conta</h4>
        <p>Reservamo-nos o direito de verificar sua identidade e elegibilidade a qualquer momento. Podemos solicitar informações ou documentação adicional para confirmar sua identidade ou autoridade para utilizar os Serviços.</p>

        <h4>3.4 Usuários Corporativos</h4>
        <p>Se você se registrar em nome de uma empresa ou entidade legal, você declara e garante que tem autoridade para vincular essa entidade a este Acordo, e que essa entidade aceita este Acordo.</p>
      </p>

      <h3>4. NÍVEIS DE SERVIÇO E PREÇOS</h3>
      <p>
        <h4>4.1 Níveis de Serviço</h4>
        <p>O CopyCut AI oferece os seguintes níveis de serviço:
          <ul>
            <li>Start: \$497/mês</li>
            <li>Scale: \$997/mês</li>
            <li>Enterprise: \$4.997/mês</li>
          </ul>
        </p>

        <h4>4.2 Recursos dos Níveis</h4>
        <p>Cada nível inclui recursos específicos, limites de uso e níveis de suporte conforme descrito em nossa página de preços. Reservamo-nos o direito de modificar os recursos dos níveis a qualquer momento mediante aviso aos usuários.</p>

        <h4>4.3 Soluções Personalizadas</h4>
        <p>Clientes empresariais podem solicitar soluções personalizadas com preços determinados caso a caso.</p>

        <h4>4.4 Alterações de Preço</h4>
        <p>Reservamo-nos o direito de alterar nossos preços a qualquer momento. As alterações de preço serão comunicadas aos assinantes existentes com pelo menos 30 dias de antecedência antes de entrarem em vigor.</p>
      </p>

      <h3>5. ESPECIFICAÇÕES DE DESEMPENHO</h3>
      <p>
        <h4>5.1 Capacidades de Geração</h4>
        <p>
          <ul>
            <li>Velocidade de Geração: Mais de 50 ativos em 30 minutos</li>
            <li>Suporte a Idiomas: 10 idiomas com 53 variações regionais</li>
            <li>Opções de Voz da Marca: 9 tons distintos</li>
            <li>Tempo de Configuração: 5 minutos</li>
            <li>Videos Tutoriais Online: 5 vídeos</li>
          </ul>
        </p>

        <h4>5.2 Limitações de Desempenho</h4>
        <p>O desempenho pode variar com base na carga do sistema, complexidade das solicitações e outros fatores. As especificações fornecidas são estimativas e não garantias.</p>

        <h4>5.3 Limites de Uso</h4>
        <p>Cada nível de assinatura tem limites de uso específicos. Exceder esses limites pode resultar em cobranças adicionais ou limitações temporárias de serviço.</p>
      </p>

      <h3>6. ACORDO DE NÍVEL DE SERVIÇO</h3>
      <p>
        <h4>6.1 Garantia de Tempo de Atividade</h4>
        <p>Garantimos 99,9% de tempo de atividade para os Serviços, excluindo manutenção programada.</p>

        <h4>6.2 Tempo de Resposta do Suporte</h4>
        <p>
          <ul>
            <li>Nível Start: Resposta em até 24 horas em dias úteis</li>
            <li>Nível Scale: Resposta em até 12 horas em dias úteis</li>
            <li>Nível Enterprise: Resposta em até 4 horas em dias úteis</li>
          </ul>
        </p>

        <h4>6.3 Manutenção Programada</h4>
        <p>Forneceremos aviso prévio de manutenção programada. A manutenção geralmente ocorre durante horários de baixo uso.</p>

        <h4>6.4 Backup de Dados</h4>
        <p>Realizamos backups diários dos dados do sistema. No entanto, os Usuários são responsáveis por manter seus próprios backups de conteúdo importante.</p>

        <h4>6.5 Créditos de Serviço</h4>
        <p>Se não cumprirmos nossa garantia de tempo de atividade, clientes elegíveis podem solicitar créditos de serviço de acordo com a seguinte programação:
          <ul>
            <li>99,0% a 99,9% de tempo de atividade: crédito de 10% da taxa mensal</li>
            <li>95,0% a 98,9% de tempo de atividade: crédito de 25% da taxa mensal</li>
            <li>Abaixo de 95,0% de tempo de atividade: crédito de 50% da taxa mensal</li>
          </ul>
          Solicitações de crédito de serviço devem ser enviadas dentro de 30 dias do incidente e/ou descontados na nota fiscal do mês seguinte.
        </p>
      </p>

      <h3>7. DIREITOS DE PROPRIEDADE INTELECTUAL</h3>
      <p>
        <h4>7.1 Propriedade do Conteúdo Gerado</h4>
        <p>Sujeito ao pagamento das taxas aplicáveis, você possui todos os direitos sobre o Conteúdo Gerado criado através do seu uso dos Serviços. No entanto, essa propriedade não se estende a qualquer tecnologia, algoritmos ou modelos subjacentes usados para criar tal conteúdo.</p>

        <h4>7.2 Propriedade da Plataforma</h4>
        <p>Os Serviços, incluindo todo o software, algoritmos, interfaces de usuário, marcas registradas, logotipos e outro conteúdo fornecido por nós, são de propriedade da Empresa e protegidos por leis de propriedade intelectual. Nenhum direito de propriedade sobre a plataforma ou tecnologia subjacente é transferido para você.</p>

        <h4>7.3 Propriedade dos Dados do Cliente</h4>
        <p>Você mantém todos os direitos de propriedade sobre seu Conteúdo do Usuário. Ao enviar Conteúdo do Usuário, você nos concede uma licença conforme descrito na Seção 8.</p>

        <h4>7.4 Feedback</h4>
        <p>Se você fornecer sugestões, ideias ou feedback sobre os Serviços ("Feedback"), você nos concede uma licença não exclusiva, mundial, livre de royalties, sublicenciável e transferível para usar, reproduzir, distribuir, criar trabalhos derivados, exibir e executar o Feedback para qualquer finalidade sem compensação para você.</p>
      </p>

      <h3>8. CONTEÚDO DO USUÁRIO E LICENÇAS</h3>
      <p>
        <h4>8.1 Licença para Conteúdo do Usuário</h4>
        <p>Ao enviar Conteúdo do Usuário aos Serviços, você nos concede uma licença não exclusiva, mundial, livre de royalties para usar, reproduzir, processar, adaptar, modificar, publicar, transmitir e exibir tal Conteúdo do Usuário exclusivamente para os fins de:
          <ul>
            <li>Fornecer e manter os Serviços</li>
            <li>Melhorar e desenvolver os Serviços</li>
            <li>Cumprir obrigações legais</li>
            <li>Fazer cumprir nossos termos e políticas</li>
          </ul>
        </p>

        <h4>8.2 Licença de Treinamento de IA para Usuários em Teste Gratuito, Trial Pago de 7 dias, Trial Pago de 14 dias ou Trial Pago de 30 dias</h4>
        <p>Se você utilizar nossos Serviços durante um teste gratuito, Trial Pago por 7 dias, Trial Pago de 14 dias ou Trial Pago de 30 dias, você adicionalmente nos concede uma licença não exclusiva, mundial, livre de royalties para usar seu Conteúdo do Usuário para treinar, testar e melhorar nossos modelos e algoritmos de inteligência artificial. Esta licença adicional é revogável ao fazer upgrade para uma assinatura paga (Plano Mensal ou Plano Anual) ou depois de terminar o período de trial de 7 dias, 14 dias ou 30 dias e o usuário iniciar uma assinatura paga (Plano Mensal ou Plano Anual).</p>

        <h4>8.3 Usuários com Assinatura Paga</h4>
        <p>Para usuários com assinatura paga (Plano Mensal ou Plano Anual), não utilizaremos seu Conteúdo do Usuário para treinar nossos modelos de IA sem seu consentimento explícito.</p>

        <h4>8.4 Declarações sobre o Conteúdo do Usuário</h4>
        <p>Você declara e garante que:
          <ul>
            <li>Você possui ou obteve todos os direitos necessários sobre o Conteúdo do Usuário</li>
            <li>Seu Conteúdo do Usuário não viola quaisquer direitos de terceiros</li>
            <li>Seu Conteúdo do Usuário está em conformidade com todas as leis e regulamentos aplicáveis</li>
            <li>Você tem o direito legal de conceder as licenças descritas neste Acordo</li>
          </ul>
        </p>

        <h4>8.5 Remoção de Conteúdo</h4>
        <p>Reservamo-nos o direito de remover qualquer Conteúdo do Usuário que viole este Acordo ou que determinemos ser prejudicial, ofensivo ou de outra forma censurável.</p>
      </p>

      <h3>9. PROTEÇÃO DE DADOS E SEGURANÇA</h3>
      <p>
        <h4>9.1 Política de Privacidade</h4>
        <p>Nossa coleta e uso de informações pessoais são regidos por nossa Política de Privacidade, que está incorporada a este Acordo por referência.</p>

        <h4>9.2 Conformidade com a LGPD</h4>
        <p>Cumprimos a Lei Geral de Proteção de Dados (LGPD) brasileira e outras leis de proteção de dados aplicáveis.</p>

        <h4>9.3 Medidas de Segurança</h4>
        <p>Implementamos e mantemos salvaguardas técnicas, administrativas e físicas razoáveis projetadas para proteger seus dados, incluindo controles de acesso e requisitos de autenticação.</p>

        <h4>9.4 Notificação de Violação de Dados</h4>
        <p>No caso de uma violação de dados afetando suas informações pessoais, notificaremos você de acordo com a lei aplicável.</p>

        <h4>9.5 Retenção de Dados</h4>
        <p>Retemos o Conteúdo do Usuário e informações da conta enquanto sua conta estiver ativa ou conforme necessário para fornecer os Serviços. Após o término da conta, podemos reter certas informações conforme exigido por lei ou para fins comerciais legítimos.</p>

        <h4>9.6 Processamento de Dados por Terceiros</h4>
        <p>Podemos contratar prestadores de serviços terceirizados para processar dados em nosso nome. Esses prestadores estão vinculados por obrigações de confidencialidade e são proibidos de usar os dados para qualquer finalidade que não seja a prestação de serviços para nós.</p>
      </p>

      <h3>10. TERMOS DA CONTA</h3>
      <p>
        <h4>10.1 Criação de Conta</h4>
        <p>Você deve criar uma conta para utilizar os Serviços. Você é responsável por manter a segurança das credenciais da sua conta.</p>

        <h4>10.2 Uma Conta Por Usuário</h4>
        <p>Cada usuário tem permissão para criar apenas uma conta. Múltiplas contas criadas pelo mesmo usuário podem ser encerradas.</p>

        <h4>10.3 Informações Precisas</h4>
        <p>Você deve fornecer informações precisas e completas ao criar sua conta e manter essas informações atualizadas.</p>

        <h4>10.4 Segurança da Conta</h4>
        <p>Você é responsável por todas as atividades que ocorrem em sua conta. Você deve:
          <ul>
            <li>Criar uma senha forte e única</li>
            <li>Manter sua senha confidencial</li>
            <li>Sair da sua conta ao usar dispositivos compartilhados</li>
            <li>Nos notificar imediatamente sobre qualquer acesso não autorizado</li>
          </ul>
        </p>

        <h4>10.5 Proibição de Compartilhamento de Conta</h4>
        <p>Sua conta é pessoal e não pode ser compartilhada ou transferida para qualquer outra pessoa ou entidade. O compartilhamento de conta é estritamente proibido e pode resultar em rescisão imediata.</p>

        <h4>10.6 Contas Inativas</h4>
        <p>Contas que permanecem inativas por mais de 180 dias consecutivos podem ser consideradas inativas. Reservamo-nos o direito de encerrar contas inativas após fornecer aviso.</p>
      </p>

      <h3>11. TERMOS DE PAGAMENTO</h3>
      <p>
        <h4>11.1 Ciclo de Faturamento</h4>
        <p>As assinaturas são cobradas antecipadamente mensalmente ou anualmente, salvo especificação em contrário. Todas as cobranças são não reembolsáveis, exceto conforme expressamente previsto neste Acordo.</p>

        <h4>11.2 Métodos de Pagamento</h4>
        <p>Aceitamos pagamento via cartão de crédito, cartão de débito e outros métodos de pagamento conforme especificado em nosso site. Você nos autoriza a cobrar seu método de pagamento por todas as taxas incorridas.</p>

        <h4>11.3 Pagamento Anual Antecipado</h4>
        <p>Opções de pagamento anual antecipado estão disponíveis com desconto conforme especificado em nossa página de preços.</p>

        <h4>11.4 Impostos</h4>
        <p>Todas as taxas são exclusivas de impostos. Você é responsável pelo pagamento de todos os impostos aplicáveis, incluindo imposto sobre vendas, imposto sobre valor agregado e outros impostos similares.</p>

        <h4>11.5 Pagamentos Atrasados</h4>
        <p>Pagamentos atrasados podem resultar em suspensão do serviço. Uma taxa de atraso de 2% ao mês pode ser aplicada a saldos vencidos.</p>

        <h4>11.6 Política de Reembolso</h4>
        <p>Nenhum reembolso será fornecido para meses parciais de serviço ou para períodos em que a conta foi temporariamente suspensa devido ao não cumprimento deste Acordo.</p>

        <h4>11.7 Disputas de Faturamento</h4>
        <p>Você deve nos notificar sobre quaisquer disputas de faturamento dentro de 30 dias da data da cobrança. Disputas enviadas após este período podem não ser consideradas.</p>

        <h4>11.8 Cancelamento</h4>
        <p>Você pode cancelar sua assinatura a qualquer momento fornecendo aviso por escrito com 30 dias de antecedência. O cancelamento será efetivo no final do seu ciclo de faturamento atual.</p>
      </p>

      <h3>12. TESTE GRATUITO ou TRIAL DE 7 DIAS ou TRIAL de 30 DIAS</h3>
      <p>
        <h4>12.1 Elegibilidade para Teste ou Trial</h4>
        <p>Podemos oferecer um teste gratuito dos Serviços ou trial pago de 1 real no Brasil ou 1 USD fora do Brasil por 7 dias, 14 dias ou 30 dias. A elegibilidade para testes gratuitos ou trials é determinada a nosso exclusivo critério.</p>

        <h4>12.2 Período de Teste</h4>
        <p>A duração de qualquer período de teste gratuito será especificada no momento do cadastro.</p>

        <h4>12.3 Limitações do Teste</h4>
        <p>Testes gratuitos, trials de 7 dias ou trials de 30 dias podem ter limitações de recursos ou restrições de uso em comparação com assinaturas pagas (Plano Mensal ou Plano Anual).</p>

        <h4>12.4 Requisito de Cartão de Crédito</h4>
        <p>Podemos exigir que você forneça um cartão de crédito válido para iniciar um teste gratuito. Seu cartão não será cobrado durante o período de teste, mas será automaticamente cobrado quando o teste terminar, a menos que você cancele antes dessa data.</p>
        <p>Podemos exigir que você forneça um cartão de crédito válido para iniciar um trial de 7 dias ou trial de 30 dias por 1 real no Brasil ou 1 USD fora do Brasil. Seu cartão será cobrado durante o período de trial por um valor mínimo de 1 real no Brasil ou 1 USD fora do Brasil e será automaticamente cobrado o valor normal quando o teste terminar, a menos que você cancele antes dessa data.</p>

        <h4>12.5 Conversão Automática</h4>
        <p>No final do período de teste gratuito ou de trial de 7 dias ou de trial de 30 dias, sua conta será automaticamente convertida em uma assinatura paga (Plano Mensal ou Plano Anual), a menos que você cancele antes do término do teste gratuito ou trial.</p>

        <h4>12.6 Um Teste Por Usuário</h4>
        <p>Testes gratuitos ou trials de 7 dias ou 30 dias são limitados a um por usuário. Tentativas de criar múltiplas contas para obter testes gratuitos adicionais são proibidas.</p>
      </p>

      <h3>13. TERMOS DE ASSINATURA</h3>
      <p>
        <h4>13.1 Período de Assinatura</h4>
        <p>As assinaturas começam na data do pagamento inicial e continuam pelo período especificado (mensal ou anual).</p>

        <h4>13.2 Renovação Automática</h4>
        <p>As assinaturas são renovadas automaticamente no final de cada período de assinatura, a menos que sejam canceladas antes da data de renovação.</p>

        <h4>13.3 Processo de Cancelamento</h4>
        <p>Para cancelar sua assinatura, você deve fornecer aviso por escrito pelo menos 30 dias antes do final do seu período de assinatura atual. Solicitações de cancelamento devem ser enviadas para suporte@shifters.com.br</p>

        <h4>13.4 Efeito do Cancelamento</h4>
        <p>Após o cancelamento, seu acesso aos Serviços continuará até o final do seu período de assinatura atual. Nenhum reembolso será fornecido para cancelamento antecipado.</p>

        <h4>13.5 Alterações na Assinatura</h4>
        <p>Você pode fazer upgrade da sua assinatura a qualquer momento. Downgrades entrarão em vigor no início do próximo ciclo de faturamento.</p>

        <h4>13.6 Proteção de Preço</h4>
        <p>Se aumentarmos o preço da sua assinatura, notificaremos você com pelo menos 30 dias de antecedência. Você pode cancelar sua assinatura antes que o aumento de preço entre em vigor.</p>
      </p>

      <h3>14. USOS PROIBIDOS</h3>
      <p>
        <h4>14.1 Proibições Gerais</h4>
        <p>Você não pode usar os Serviços para:
          <ul>
            <li>Violar qualquer lei ou regulamento aplicável</li>
            <li>Infringir os direitos de propriedade intelectual de outros</li>
            <li>Gerar conteúdo que seja ilegal, prejudicial, ameaçador, abusivo, assediador, difamatório, vulgar, obsceno, pornográfico, invasivo da privacidade ou de outra forma censurável</li>
            <li>Distribuir malware, vírus ou outro código prejudicial</li>
            <li>Interferir ou interromper os Serviços ou servidores</li>
            <li>Tentar obter acesso não autorizado aos Serviços ou outras contas</li>
            <li>Envolver-se em qualquer atividade que possa danificar, desativar ou prejudicar os Serviços</li>
          </ul>
        </p>

        <h4>14.2 Restrições de Conteúdo</h4>
        <p>Você não pode usar os Serviços para criar:
          <ul>
            <li>Conteúdo que promova atividades ilegais</li>
            <li>Conteúdo que promova discriminação, intolerância, racismo ou ódio</li>
            <li>Conteúdo que seja sexualmente explícito ou pornográfico</li>
            <li>Conteúdo que seja prejudicial a menores</li>
            <li>Conteúdo que seja enganoso ou fraudulento</li>
            <li>Conteúdo que se faça passar por qualquer pessoa ou entidade</li>
            <li>Conteúdo que contenha informações pessoais de terceiros sem consentimento</li>
          </ul>
        </p>

        <h4>14.3 Restrições Técnicas</h4>
        <p>Você não pode:
          <ul>
            <li>Fazer engenharia reversa, descompilar ou desmontar qualquer parte dos Serviços</li>
            <li>Usar scripts automatizados, bots ou outro software para acessar ou interagir com os Serviços</li>
            <li>Contornar ou burlar quaisquer recursos de segurança dos Serviços</li>
            <li>Conduzir testes de desempenho ou vulnerabilidade sem nossa permissão expressa</li>
            <li>Usar os Serviços para análise competitiva ou para desenvolver um produto concorrente</li>
          </ul>
        </p>

        <h4>14.4 Limitações de Uso</h4>
        <p>Você não pode exceder as limitações de uso do seu nível de assinatura. O uso excessivo pode resultar em cobranças adicionais ou limitações de serviço.</p>

        <h4>14.5 Política de Uso Justo</h4>
        <p>Mantemos uma política de uso justo para garantir acesso equitativo aos Serviços para todos os usuários. Se seu uso exceder significativamente o uso médio de outros usuários com o mesmo nível de assinatura, podemos:
          <ul>
            <li>Contatá-lo para discutir seus padrões de uso</li>
            <li>Recomendar um nível de assinatura superior</li>
            <li>Limitar seu uso até o próximo ciclo de faturamento</li>
            <li>Encerrar sua conta em casos de uso excessivo repetido</li>
          </ul>
        </p>
      </p>

      <h3>15. SERVIÇOS DE TERCEIROS</h3>
      <p>
        <h4>15.1 Integrações com Terceiros</h4>
        <p>Os Serviços podem integrar-se ou fornecer acesso a serviços, aplicativos ou sites de terceiros ("Serviços de Terceiros"). Esses Serviços de Terceiros não estão sob nosso controle, e não somos responsáveis pelo seu conteúdo, funcionalidade ou práticas de privacidade.</p>

        <h4>15.2 Termos de Terceiros</h4>
        <p>Seu uso de Serviços de Terceiros pode estar sujeito a termos e condições adicionais fornecidos pelo terceiro. É sua responsabilidade revisar e cumprir esses termos.</p>

        <h4>15.3 Compartilhamento de Dados com Terceiros</h4>
        <p>Quando você se conecta a Serviços de Terceiros através de nossa plataforma, você pode estar compartilhando informações com esses terceiros. Não somos responsáveis por como terceiros coletam, usam ou divulgam suas informações.</p>

        <h4>15.4 Responsabilidade de Terceiros</h4>
        <p>Isentamo-nos de toda responsabilidade por qualquer perda ou dano decorrente do seu uso de Serviços de Terceiros.</p>
      </p>

      <h3>16. LIMITAÇÃO DE RESPONSABILIDADE</h3>
      <p>
        <h4>16.1 Limite de Responsabilidade</h4>
        <p>NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, NOSSA RESPONSABILIDADE TOTAL PARA COM VOCÊ POR QUAISQUER RECLAMAÇÕES DECORRENTES OU RELACIONADAS A ESTE ACORDO OU AOS SERVIÇOS, INDEPENDENTEMENTE DA FORMA DE AÇÃO, SERÁ LIMITADA AO VALOR PAGO POR VOCÊ A NÓS NOS DOIS (2) MESES ANTERIORES À RECLAMAÇÃO.</p>

        <h4>16.2 Exclusão de Danos</h4>
        <p>EM NENHUM CASO SEREMOS RESPONSÁVEIS POR QUAISQUER DANOS INDIRETOS, PUNITIVOS, INCIDENTAIS, ESPECIAIS, CONSEQUENCIAIS OU EXEMPLARES, INCLUINDO, SEM LIMITAÇÃO, DANOS POR PERDA DE LUCROS, FUNDO DE COMÉRCIO, USO, DADOS OU OUTRAS PERDAS INTANGÍVEIS, DECORRENTES OU RELACIONADAS AO SEU USO OU INCAPACIDADE DE USAR OS SERVIÇOS.</p>

        <h4>16.3 Base do Acordo</h4>
        <p>AS LIMITAÇÕES DE RESPONSABILIDADE NESTA SEÇÃO SE APLICAM A QUALQUER TEORIA DE RESPONSABILIDADE, SEJA BASEADA EM GARANTIA, CONTRATO, ESTATUTO, ATO ILÍCITO (INCLUINDO NEGLIGÊNCIA) OU DE OUTRA FORMA, E INDEPENDENTEMENTE DE TERMOS SIDO INFORMADOS DA POSSIBILIDADE DE TAIS DANOS.</p>

        <h4>16.4 Propósito Essencial</h4>
        <p>AS LIMITAÇÕES DE RESPONSABILIDADE NESTA SEÇÃO SE APLICAM MESMO SE QUALQUER RECURSO FALHAR EM SEU PROPÓSITO ESSENCIAL.</p>

        <h4>16.5 Exceções</h4>
        <p>Algumas jurisdições não permitem a exclusão de certas garantias ou a limitação ou exclusão de responsabilidade por certos tipos de danos. Consequentemente, algumas das limitações acima podem não se aplicar a você.</p>
      </p>

      <h3>17. ISENÇÕES DE RESPONSABILIDADE E GARANTIAS</h3>
      <p>
        <h4>17.1 Isenção de Responsabilidade do Serviço</h4>
        <p>OS SERVIÇOS SÃO FORNECIDOS "NO ESTADO EM QUE SE ENCONTRAM" E "CONFORME DISPONÍVEIS" SEM GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO A, GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM, TÍTULO E NÃO VIOLAÇÃO.</p>

        <h4>17.2 Isenção de Responsabilidade de Conteúdo</h4>
        <p>NÃO GARANTIMOS QUE O CONTEÚDO GERADO PELOS SERVIÇOS SERÁ LIVRE DE ERROS, PRECISO, CONFIÁVEL OU ATENDERÁ ÀS SUAS NECESSIDADES. VOCÊ É EXCLUSIVAMENTE RESPONSÁVEL POR AVALIAR E VERIFICAR TODO O CONTEÚDO GERADO PELOS SERVIÇOS.</p>

        <h4>17.3 Limitações da IA</h4>
        <p>VOCÊ RECONHECE QUE A TECNOLOGIA DE IA TEM LIMITAÇÕES INERENTES E PODE PRODUZIR CONTEÚDO INCORRETO, INADEQUADO OU ENGANOSO. VOCÊ É RESPONSÁVEL POR REVISAR TODO O CONTEÚDO GERADO ANTES DO USO.</p>

        <h4>17.4 Interrupções do Serviço</h4>
        <p>NÃO GARANTIMOS QUE OS SERVIÇOS SERÃO ININTERRUPTOS, OPORTUNOS, SEGUROS OU LIVRES DE ERROS. VOCÊ ENTENDE QUE OS SERVIÇOS PODEM ESTAR INDISPONÍVEIS DEVIDO A MANUTENÇÃO PROGRAMADA, ATUALIZAÇÕES DO SISTEMA OU FATORES ALÉM DO NOSSO CONTROLE.</p>

        <h4>17.5 Isenção de Responsabilidade de Segurança</h4>
        <p>EMBORA IMPLEMENTEMOS MEDIDAS DE SEGURANÇA RAZOÁVEIS, NÃO PODEMOS GARANTIR QUE TERCEIROS NÃO AUTORIZADOS NUNCA SERÃO CAPAZES DE DERROTAR NOSSAS MEDIDAS DE SEGURANÇA OU USAR SUAS INFORMAÇÕES PESSOAIS PARA FINS IMPRÓPRIOS.</p>
      </p>

      <h3>18. INDENIZAÇÃO</h3>
      <p>
        <h4>18.1 Indenização pelo Usuário</h4>
        <p>Você concorda em indenizar, defender e isentar a Empresa, suas afiliadas, diretores, funcionários, agentes e licenciadores de e contra quaisquer reclamações, responsabilidades, danos, perdas, custos, despesas ou taxas (incluindo honorários advocatícios razoáveis) que surjam ou estejam relacionados a:
          <ul>
            <li>Seu uso dos Serviços</li>
            <li>Seu Conteúdo do Usuário</li>
            <li>Sua violação deste Acordo</li>
            <li>Sua violação de quaisquer direitos de outra pessoa ou entidade</li>
            <li>Sua violação de quaisquer leis ou regulamentos aplicáveis</li>
          </ul>
        </p>

        <h4>18.2 Controle de Defesa</h4>
        <p>Reservamo-nos o direito, às nossas próprias custas, de assumir a defesa e o controle exclusivos de qualquer assunto de outra forma sujeito a indenização por você, caso em que você cooperará conosco na afirmação de quaisquer defesas disponíveis.</p>

        <h4>18.3 Requisito de Notificação</h4>
        <p>Você concorda em nos notificar prontamente sobre quaisquer reclamações de terceiros, cooperar conosco na defesa de tais reclamações e pagar todas as taxas, custos e despesas associadas à defesa de tais reclamações, incluindo honorários advocatícios.</p>
      </p>

      <h3>19. PRAZO E RESCISÃO</h3>
      <p>
        <h4>19.1 Prazo</h4>
        <p>Este Acordo começa na data em que você primeiro utiliza os Serviços e continua até ser rescindido conforme descrito nesta seção.</p>

        <h4>19.2 Rescisão pelo Usuário</h4>
        <p>Você pode rescindir este Acordo a qualquer momento:
          <ul>
            <li>Cancelando sua assinatura conforme descrito na Seção 13.3</li>
            <li>Excluindo sua conta</li>
            <li>Cessando todo uso dos Serviços</li>
          </ul>
        </p>

        <h4>19.3 Rescisão pela Empresa</h4>
        <p>Podemos rescindir este Acordo e seu acesso aos Serviços a qualquer momento, com ou sem causa, e com ou sem aviso prévio. Razões para rescisão podem incluir, mas não se limitam a:
          <ul>
            <li>Violação deste Acordo</li>
            <li>Falha em pagar taxas quando devidas</li>
            <li>Atividade fraudulenta, abusiva ou ilegal</li>
            <li>Períodos prolongados de inatividade</li>
            <li>Mediante solicitação de autoridades policiais ou agências governamentais</li>
            <li>Se fornecer os Serviços a você se tornar ilegal</li>
          </ul>
        </p>

        <h4>19.4 Efeito da Rescisão</h4>
        <p>Após a rescisão:
          <ul>
            <li>Seu direito de acessar e usar os Serviços cessará imediatamente</li>
            <li>Podemos excluir sua conta e todos os dados associados</li>
            <li>Quaisquer obrigações de pagamento pendentes se tornarão imediatamente devidas</li>
            <li>Seções deste Acordo que por sua natureza devem sobreviver à rescisão sobreviverão</li>
          </ul>
        </p>

        <h4>19.5 Retenção de Dados Após Rescisão</h4>
        <p>Após a rescisão, podemos reter seus dados por um período limitado conforme exigido por lei ou para fins comerciais legítimos. Excluiremos seus dados de forma segura quando não forem mais necessários.</p>
      </p>

      <h3>20. MODIFICAÇÕES NOS TERMOS OU SERVIÇOS</h3>
      <p>
        <h4>20.1 Modificação dos Termos</h4>
        <p>Reservamo-nos o direito de modificar este Acordo a qualquer momento. Notificaremos você sobre alterações materiais publicando o Acordo atualizado em nosso site ou enviando um e-mail. Seu uso continuado dos Serviços após tal notificação constitui sua aceitação do Acordo modificado.</p>

        <h4>20.2 Modificação dos Serviços</h4>
        <p>Reservamo-nos o direito de modificar, suspender ou descontinuar qualquer parte dos Serviços a qualquer momento, com ou sem aviso prévio. Não seremos responsáveis perante você ou qualquer terceiro por qualquer modificação, suspensão ou descontinuação dos Serviços.</p>

        <h4>20.3 Alterações de Preço</h4>
        <p>Podemos alterar nossos preços a qualquer momento. Alterações de preço para assinantes existentes entrarão em vigor no início do próximo período de assinatura após o aviso ser fornecido.</p>

        <h4>20.4 Objeção a Alterações</h4>
        <p>Se você não concordar com quaisquer modificações neste Acordo, deve parar de usar os Serviços e cancelar sua assinatura.</p>
      </p>

      <h3>21. LEI APLICÁVEL E RESOLUÇÃO DE DISPUTAS</h3>
      <p>
        <h4>21.1 Lei Aplicável</h4>
        <p>Este Acordo será regido e interpretado de acordo com as leis de São Paulo, Brasil, sem considerar seus princípios de conflito de leis.</p>

        <h4>21.2 Resolução de Disputas</h4>
        <p>Qualquer disputa decorrente ou relacionada a este Acordo ou aos Serviços será resolvida da seguinte forma:</p>

        <h4>21.2.1 Resolução Informal</h4>
        <p>Antes de apresentar uma reclamação, você concorda em tentar resolver a disputa informalmente entrando em contato conosco em suporte@shifters.com.br. Tentaremos resolver a disputa entrando em contato com você por e-mail. Se a disputa não for resolvida dentro de 30 dias, qualquer uma das partes poderá prosseguir com a resolução formal de disputas.</p>

        <h4>21.2.2 Procedimentos Formais</h4>
        <p>Qualquer disputa não resolvida informalmente será resolvida exclusivamente por meio de arbitragem vinculativa em São Paulo, Brasil, de acordo com as regras de arbitragem da Câmara de Comércio Brasil-Canadá.</p>

        <h4>21.2.3 Renúncia de Ação Coletiva</h4>
        <p>TODAS AS RECLAMAÇÕES E DISPUTAS NO ÂMBITO DESTE ACORDO DEVEM SER ARBITRADAS EM BASE INDIVIDUAL E NÃO EM BASE COLETIVA. RECLAMAÇÕES DE MAIS DE UM CLIENTE NÃO PODEM SER ARBITRADAS OU LITIGADAS CONJUNTAMENTE OU CONSOLIDADAS COM AS DE QUALQUER OUTRO CLIENTE.</p>

        <h4>21.2.4 Exceções</h4>
        <p>Não obstante o acima exposto, qualquer uma das partes pode buscar medida cautelar ou outro recurso equitativo em qualquer tribunal de jurisdição competente para prevenir a violação real ou ameaçada, apropriação indébita ou violação dos direitos de propriedade intelectual de uma parte.</p>

        <h4>21.3 Limitação de Tempo</h4>
        <p>Qualquer reclamação decorrente ou relacionada a este Acordo deve ser apresentada dentro de um (1) ano após o surgimento de tal reclamação; caso contrário, a reclamação será permanentemente impedida.</p>
      </p>

      <h3>22. DISPOSIÇÕES GERAIS</h3>
      <p>
        <h4>22.1 Acordo Integral</h4>
        <p>Este Acordo constitui o acordo integral entre você e a Empresa em relação aos Serviços e substitui todos os acordos e entendimentos anteriores, sejam escritos ou orais.</p>

        <h4>22.2 Independência das Cláusulas</h4>
        <p>Se qualquer disposição deste Acordo for considerada inexequível ou inválida, essa disposição será limitada ou eliminada na medida mínima necessária para que este Acordo permaneça em pleno vigor e efeito.</p>

        <h4>22.3 Não Renúncia</h4>
        <p>Nossa falha em fazer cumprir qualquer direito ou disposição deste Acordo não será considerada uma renúncia a tal direito ou disposição. A renúncia a qualquer direito ou disposição será efetiva apenas se por escrito e assinada por um representante devidamente autorizado da Empresa.</p>

        <h4>22.4 Cessão</h4>
        <p>Você não pode ceder ou transferir este Acordo, por operação da lei ou de outra forma, sem nosso consentimento prévio por escrito. Qualquer tentativa de ceder ou transferir este Acordo sem tal consentimento será nula. Podemos ceder ou transferir este Acordo, a nosso exclusivo critério, sem restrição.</p>

        <h4>22.5 Força Maior</h4>
        <p>Não seremos responsáveis por qualquer falha ou atraso no cumprimento de nossas obrigações sob este Acordo que se deva a causas além do nosso controle razoável, incluindo atos de Deus, desastres naturais, pandemia, epidemia, guerra, terrorismo, tumultos, distúrbios civis, ações governamentais, falhas de energia ou interrupções de internet ou telecomunicações.</p>

        <h4>22.6 Sem Agência</h4>
        <p>Nenhuma agência, parceria, joint venture ou emprego é criado como resultado deste Acordo, e você não tem qualquer autoridade de qualquer tipo para vincular-nos em qualquer aspecto.</p>

        <h4>22.7 Notificações</h4>
        <p>Todas as notificações sob este Acordo serão por escrito e serão consideradas devidamente entregues quando recebidas, se entregues pessoalmente; quando o recebimento for confirmado eletronicamente, se transmitidas por e-mail; e após o recebimento, se enviadas por correio certificado ou registrado, com aviso de recebimento.</p>

        <h4>22.8 Controle de Exportação</h4>
        <p>Você não pode usar, exportar ou reexportar os Serviços em violação de quaisquer leis ou regulamentos aplicáveis, incluindo, sem limitação, leis e regulamentos de exportação dos Estados Unidos e do Brasil.</p>

        <h4>22.9 Idioma</h4>
        <p>Este Acordo está em inglês e português. Em caso de conflito entre as duas versões, a versão em português prevalecerá.</p>
      </p>

      <h3>INFORMAÇÕES DE CONTATO</h3>
      <p>Se você tiver alguma dúvida sobre este Acordo ou os Serviços, entre em contato conosco em:
        <ul>
          <li>Email: suporte@shifters.com.br</li>
          <li>Suporte HelpDesk:{' '}
            <Link to="https://www.shifters.com.br/AiGU75oYrw9c" target="_blank">https://www.shifters.com.br/AiGU75oYrw9c</Link>
          </li>
          <li>Endereço: Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brasil</li>
          <li>SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP</li>
          <li>CNPJ/MF: 15.563.090/0001-04</li>
        </ul>
      </p>

      <p>Ao utilizar o CopyCut AI, você reconhece que leu, entendeu e concorda em estar vinculado a este Acordo.</p>
    </Container>
  );
}
