import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .line-up {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
     flex-direction: column;
    }
  }

  .line-down {

    .title-area {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      h3 {
        font-weight: 500;
      }

      img {
        width: 16px;
      }
    }

    .cards-area {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 16px;
      margin-bottom: 8px;

      /* display: flex;
      align-items: stretch;
      flex: 1;
      justify-content: space-between;
      gap: 16px; */

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
      gap: 40px;
      }
    }
  }

  .card {
    background: ${({ theme }) => theme.grayMenu};
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
    width: 100%;
  }

  .welcome-card {
    flex: 1;
  }

  .feed-card {
    flex: 2;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
     flex: 1;
    }
  }

  /* Demo content styling */
  .video-placeholder {
      background: #f0f0f0;
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      iframe {
        background-color: #000;
        border: none;
      }
  }
`;

export const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 22px;
    font-weight: 500;
  }

  a {
    font-size: 14px;
    color: ${({ theme }) => theme.yellow};
  }

  a:hover {
    font-size: 14px;
    color: ${({ theme }) => theme.white};
  }


`;

export const ContainerBody = styled.div`
  position: relative;
  height: 87%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .no-access-container {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .container-video {
    overflow-y: auto;

    > span {
      font-size: 12px;
      color: ${({ theme }) => theme.grayLight}
    }
  }

  .container-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;
    background-color: ${({ theme }) => theme.grayBackground};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.yellow};
    transition: .2s ease-in-out;
  }

  .container-button:hover {
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.grayBackground};
  }
`;

export const VideoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackground};
  padding: 16px 0;

  > span {
    font-size: 16px;
    flex: 1;
  }

  > a {
    padding: 0px 8px 0px 0px;
    border: none;
    text-align: right !important;
    align-content: flex-end !important;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      display: initial;
    }
  }

  > a:hover {
    color: ${({ theme }) => theme.yellow};
    border: none;
  }
`;

export const CardFeatures = styled.div`
  background: ${({ theme }) => theme.grayMenu};
  background: linear-gradient(180deg, ${({ theme }) => theme.grayMenu} 0%, rgba(20,20,20,1) 100%);
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.4);
  width: 100%;
  position: relative;

  .image-laptop {
    position: absolute;
    width: 130px;
    top: -5px;
  }

  .link-feature {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.gray1};
    background-color: ${({ theme }) => theme.gray1};
    border-radius: 4px;
    text-decoration: none;
    transition: .3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.gray2};
    }
  }

  .info-container {
    margin-top: 120px;

    h3 {
      font-weight: 500;
      font-size: 24px;
    }
  }
`;
