import React, { useContext } from 'react';
import { PrivacyPolicyContentPT } from './PrivacyPolicyContentPT';
import { PrivacyPolicyContentEN } from './PrivacyPolicyContentEN';
import BackButton from '../../../components/BackButton';
import { AreaContainer } from './style';

export function PrivacyPolicyContent() {
  const language = JSON.parse(localStorage.getItem('language'));
  const PrivacyPolicyContent = language === 'pt-BR' ? PrivacyPolicyContentPT : PrivacyPolicyContentEN;
  return (
    <AreaContainer>
      <BackButton />
      <PrivacyPolicyContent />
    </AreaContainer>
  );
}
