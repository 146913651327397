class CopyCutPaid {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async postPrompt({ infos }) {
    const path = `${this.baseUrl}/copycut-paid/layer-1`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt2({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-2`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt3({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-3`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt4({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-4`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt5({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-5`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt6({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-6`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt7({ id }) {
    const path = `${this.baseUrl}/copycut-paid/layer-7`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async findOne({ id }) {
    const path = `${this.baseUrl}/copycut-paid/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new CopyCutPaid();
