/**
  - Introduction video is different as languages (pt-BR and en-US)
  - Links to the first video modules are automatic
 */

import React, {
  useContext, useDebugValue, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../../context/AuthContext';
import { ModulesContext } from '../../../context/ModulesContext';
import { FeedContext } from '../../../context/FeedContext';

import Loader from '../../../components/Loader';
import { ButtonLink } from '../../../components/ButtonLink';
import { ProductsContext } from '../../../context/ProductsContext';

import LaptopCopyCut from '../../../styles/images/laptop-copycut.png';
import LaptopGreen from '../../../styles/images/laptop-verde.png';
import LaptopYellow from '../../../styles/images/laptop-amarelo.png';

import IconLink from '../../../styles/logos/link.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, ContainerBody, VideoRow, CardFeatures,
} from './style';


export default function Home() {
  const translate = Translate();

  const { userInfo: { firstName }, accessHash } = useContext(AuthContext);
  const { videosFeed, isLoadingVideos } = useContext(FeedContext);
  const { modules } = useContext(ModulesContext);
  const { products } = useContext(ProductsContext);

  const [userAllowedFeed, setUserAllowedFeed] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [firstVideoLink, setFirstVideoLink] = useState({ perfilPerformance: '/client', pipePerformance: '/client' });

  useEffect(() => {
    async function getFirstIDVideoFromAcademyModules() {
      if (products) {
        const listProduct = await products.filter((prod) => prod.id === 1 || prod.id === 3);

        if (listProduct.length > 0) {
          setFirstVideoLink((prevState) => ({
            ...prevState,
            perfilPerformance: `/client/${listProduct[0]?.type}/${listProduct[0]?.id}/${listProduct[0]?.first_video_id}`,
            pipePerformance: `/client/${listProduct[1]?.type}/${listProduct[1]?.id}/${listProduct[1]?.first_video_id}`,
          }));
        }
      }
    }

    getFirstIDVideoFromAcademyModules();
  }, [products]);


  useEffect(() => {
    verifyFeedPermission();
  }, [accessHash, modules]);

  function verifyFeedPermission() {
    if (modules.length > 0) {
      setLoadingPermission(true);
      const userAllowFeed = accessHash.includes(modules?.filter((item) => item.id === 19).map((item) => item.accessHash)[0]);
      setUserAllowedFeed(userAllowFeed);
      setLoadingPermission(false);
    }
  }

  const link = translate.language === 'pt-BR'
    ? 'https://player.vimeo.com/video/981451617?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    : 'https://player.vimeo.com/video/981451679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';


  return (
    <>
      <Container>
        <div className="line-up">
          {/* Welcome + Video */}
          <div className="card welcome-card">
            <TitleContainer>
              <h4>{translate.welcomeText}{', '}{firstName}</h4>
            </TitleContainer>
            <div className="video-placeholder">
              <iframe
                src={link}
                allow="autoplay; fullscreen; picture-in-picture"
                width="100%"
                height="100%"
                title="Crescimento Cruzado - Introdução - 20210802"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen
              />
            </div>
          </div>

          <div className="card feed-card">
            <TitleContainer>
              <h4>{translate.titleFeed}</h4>
              {/* <Icon to="/client/feed">
                {translate.btnLinkToFeed}
              </Icon> */}
            </TitleContainer>
            {(isLoadingVideos) && <Loader loading={(isLoadingVideos)} />}
            {(!isLoadingVideos && userAllowedFeed) && (
              <ContainerBody>
                <div className="container-video">
                  {videosFeed && videosFeed?.map((video) => (
                    <VideoRow key={video?.id}>
                      <span>{translate.language === 'pt-BR' ? video?.title_pt : video?.title_en}</span>
                      <ButtonLink color="ghost" to="/client/feed">{translate.btnWatch}</ButtonLink>
                    </VideoRow>
                  )).slice(0, 2)}
                  <span>{translate.msgTwoMorevideos}</span>
                </div>
                {/* <Link to="/client/feed" className="container-button">
                  {translate.btnLinkToFeed}
                </Link> */}
              </ContainerBody>
            )}
            {(!isLoadingVideos && !userAllowedFeed) && (
              <ContainerBody>
                <div className="no-access-container">
                  <p>{translate.msgRestrictedAccess} <br /> {translate.msgAdmContact}</p>
                </div>
              </ContainerBody>
            )}
          </div>
        </div>

        <div className="line-down">
          <div className="title-area">
            <h3>Links Úteis</h3>
            <img src={IconLink} alt="Link" />
          </div>
          <div className="cards-area">
            <CardFeatures>
              <img src={LaptopCopyCut} alt="laptop" className="image-laptop" />
              <Link to="/client/ailab/copycut-assets" className="link-feature">{translate.btnLinkAccess}</Link>
              <div className="info-container">
                <h3>{translate.titleCopyCutAssets}</h3>
                <div>{translate.msgCopyCutAssets}</div>
              </div>
            </CardFeatures>

            <CardFeatures>
              <img src={LaptopCopyCut} alt="laptop" className="image-laptop" />
              <Link to="/client/ailab/copycut-unpaid-posts" className="link-feature">{translate.btnLinkAccess}</Link>
              <div className="info-container">
                <h3>{translate.titleCopyCutUnpaidPosts}</h3>
                <div>{translate.msgCopyCutUnpaidPosts}</div>
              </div>
            </CardFeatures>

            <CardFeatures>
              <img src={LaptopGreen} alt="laptop" className="image-laptop" />
              <Link to={firstVideoLink.perfilPerformance} className="link-feature">{translate.btnLinkAccess}</Link>
              <div className="info-container">
                <h3>{translate.titlePerfilP}</h3>
                <div>{translate.msgPerfilP}</div>
              </div>
            </CardFeatures>

            <CardFeatures>
              <img src={LaptopYellow} alt="laptop" className="image-laptop" />
              <Link to={firstVideoLink.pipePerformance} className="link-feature">{translate.btnLinkAccess}</Link>
              <div className="info-container">
                <h3>{translate.titlePipeP}</h3>
                <div>{translate.msgPipeP}</div>
              </div>
            </CardFeatures>
          </div>

        </div>
      </Container>
    </>
  );
}
