import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import Square from '../../components/Square';
import TitlePage from '../../components/TitlePage';
import shiftersLogo from '../../styles/logos/shiftersy.png';
import * as S from './style';
import { Translate } from './translate';

export default function About() {
  const translate = Translate();
  const version = process.env.REACT_APP_VERSION;

  return (
    <>
      <TitlePage title="">
        <BackButton />
      </TitlePage>
      <Square>
        <S.Container>
          <img src={shiftersLogo} alt="Shifters" />
          <p>
            {translate.about}
            {' '}
            <a href="https://www.shifters.com.br/" target="_blank" rel="noreferrer"> www.shifters.com.br</a>

          </p>
          <p>
            Version:
            <strong>
              {version}
              {' '}
            </strong>
          </p>
          <div className="container-terms">
            <Link to="/client/legal?assets=privacy-policy">Política de Privacidade</Link>
            <Link to="/client/legal?assets=terms-and-conditions">Termos e Condições</Link>
          </div>
        </S.Container>
      </Square>
    </>
  );
}
