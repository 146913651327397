/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../style';

export function PrivacyPolicyContentPT() {
  return (
    <Container>
      <h2>POLÍTICA DE PRIVACIDADE - COPYCUT AI</h2>

      <p>(Um produto da SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP)</p>
      <p>Última Atualização: 12 de março de 2025</p>

      <h3>1. INTRODUÇÃO</h3>
      <p>A SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP ("SHIFTERS", "nós", "nosso" ou "nos"), registrada sob o CNPJ/MF: 15.563.090/0001-04, com sede na Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 em São Paulo, Brasil, opera o CopyCut AI, uma plataforma de geração de conteúdo de marketing baseada em inteligência artificial. Esta Política de Privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações quando você utiliza nossos serviços, visita nosso site em  https://app.shifters.ai ou https://www.shifters.ai ou https://shifters.ai ou interage com qualquer uma de nossas aplicações, plataformas ou serviços relacionados (coletivamente, os "Serviços").
        Como uma empresa sediada no Brasil, somos principalmente regidos pelas leis brasileiras de proteção de dados, especificamente a Lei Geral de Proteção de Dados (LGPD), Lei nº 13.709/2018. No entanto, respeitamos e cumprimos outros regulamentos aplicáveis de proteção de dados na medida em que se aplicam às nossas operações.
        Esta Política de Privacidade foi elaborada para ajudá-lo a entender nossas práticas em relação às suas informações pessoais e informá-lo sobre seus direitos de privacidade e como a lei o protege. Estamos comprometidos em proteger sua privacidade e tratar seus dados de maneira aberta e transparente.
        Ao acessar ou usar nossos Serviços, você reconhece que leu, entendeu e concorda em estar vinculado a esta Política de Privacidade. Se você não concordar com nossas políticas e práticas, por favor, não utilize nossos Serviços.
        AVISO IMPORTANTE: Embora nos esforcemos para fornecer conteúdo gerado por IA preciso e confiável, os modelos de linguagem podem cometer erros. Os usuários são exclusivamente responsáveis por revisar todo o conteúdo gerado quanto à precisão, adequação e conformidade legal e de avaliar eventuais violações de leis de direitos de autor antes do uso. O CopyCut AI não assume nenhuma responsabilidade por decisões tomadas ou ações realizadas com base no conteúdo gerado por IA.
      </p>

      <h3>2. DEFINIÇÕES</h3>
      <p>Para ajudá-lo a entender melhor esta Política de Privacidade, definimos os seguintes termos:
        "Dados Pessoais" ou "Informações Pessoais": Qualquer informação relacionada a uma pessoa identificada ou identificável, como nome, endereço de e-mail, número de identificação, dados de localização, identificador online ou fatores específicos da identidade física, fisiológica, genética, mental, econômica, cultural ou social dessa pessoa.
        "Tratamento": Qualquer operação realizada com Dados Pessoais, seja ou não por meios automatizados, como coleta, registro, organização, estruturação, armazenamento, adaptação ou alteração, recuperação, consulta, uso, divulgação por transmissão, disseminação ou qualquer outra forma de disponibilização, alinhamento ou combinação, restrição, apagamento ou destruição.
        "Controlador de Dados": A entidade que determina as finalidades e os meios do Tratamento de Dados Pessoais. Para os fins desta Política de Privacidade, a SHIFTERS é o Controlador de Dados.
        "Operador de Dados": Uma entidade que realiza o Tratamento de Dados Pessoais em nome do Controlador de Dados.
        "Titular dos Dados": Uma pessoa natural identificada ou identificável a quem os Dados Pessoais se referem.
        "Usuário", "você" ou "seu": Qualquer indivíduo que acessa ou utiliza nossos Serviços.
        "Conteúdo Gerado": Qualquer conteúdo criado por nossos sistemas de IA com base em suas entradas, prompts ou instruções.
        "LGPD": Lei Geral de Proteção de Dados (Lei Brasileira de Proteção de Dados), Lei nº 13.709/2018, que é a principal lei de proteção de dados que rege nossas operações.
        "ANPD": Autoridade Nacional de Proteção de Dados, a autoridade brasileira de proteção de dados.
        "Categorias Especiais de Dados Pessoais": Dados Pessoais que revelam origem racial ou étnica, opiniões políticas, crenças religiosas ou filosóficas, filiação sindical, dados genéticos, dados biométricos, dados relativos à saúde, dados relativos à vida sexual ou orientação sexual de uma pessoa.
        "Cookies": Pequenos arquivos de texto que são colocados em seu dispositivo quando você visita um site, que permitem várias funcionalidades e coleta de dados.
      </p>

      <h3>3. INFORMAÇÕES QUE COLETAMOS</h3>
      <p>Coletamos vários tipos de informações de e sobre os usuários de nossos Serviços, incluindo:</p>
      <p>
        <h4>A. Informações Pessoais que Você Nos Fornece</h4>
        <p>
          <ul>
            <li>Informações da Conta: Quando você se registra para uma conta, coletamos seu nome, endereço de e-mail, número de telefone, nome de usuário, senha e informações de perfil.</li>
            <li>Informações Comerciais: Nome da empresa, tipo de negócio, setor, tamanho, cargo/posição e endereço comercial.</li>
            <li>Informações de Pagamento: Endereço de cobrança, detalhes do cartão de pagamento, informações da conta bancária e histórico de transações. Observe que o processamento de pagamentos é realizado por nossos processadores de pagamento terceirizados, e não armazenamos informações completas de cartão de pagamento em nossos servidores.</li>
            <li>Dados de Comunicação: Informações que você fornece ao entrar em contato conosco para suporte, responder a pesquisas ou se comunicar conosco por qualquer canal.</li>
            <li>Dados de Perfil: Suas preferências, feedback e respostas a pesquisas.</li>
            <li>Conteúdo do Usuário: Informações que você insere em nosso sistema para gerar conteúdo, incluindo diretrizes de marca, objetivos de marketing, informações sobre o público-alvo e quaisquer outros dados que você forneça para personalizar o resultado.</li>
          </ul>
        </p>

        <h4>B. Informações que Coletamos Automaticamente</h4>
        <p>
          <ul>
            <li>Dados de Uso: Informações sobre como você usa nossos Serviços, incluindo recursos acessados, tempo gasto em páginas, caminhos de navegação, frequência de visitas e outras métricas de engajamentos.</li>
            <li>Informações do Dispositivo: Informações sobre o dispositivo que você usa para acessar nossos Serviços, incluindo modelo de hardware, sistema operacional, identificadores únicos de dispositivo, informações de rede móvel e configurações do dispositivo.</li>
            <li>Dados de Localização: Informações gerais de localização derivadas do seu endereço IP. Não coletamos dados precisos de geolocalização, a menos que você nos autorize explicitamente a fazê-lo.</li>
            <li>Dados de Log: Endereço de protocolo da Internet (IP), tipo e versão do navegador, configuração de fuso horário, tipos e versões de plug-ins do navegador, sistema operacional e plataforma.</li>
            <li>Dados de Interação: Informações sobre suas interações com nossos Serviços, como dados de clickstream, carimbos de data/hora e movimentos do mouse.</li>
          </ul>
        </p>

        <h4>C. Conteúdo Gerado</h4>
        <p>
          <ul>
            <li>Ativos Gerados por IA: Materiais de marketing, scripts de VSL, campanhas publicitárias, sequências de e-mail e outros conteúdos gerados por nossos sistemas de IA com base em suas entradas.</li>
            <li>Modelos Personalizados: Modelos que você cria ou personaliza dentro de nossa plataforma.</li>
            <li>Histórico de Saída: Registros de conteúdo gerado através do seu uso de nossos Serviços.</li>
          </ul>
        </p>

        <h4>D. Informações de Terceiros</h4>
        <p>
          <ul>
            <li>Dados de Mídias Sociais: Se você optar por vincular suas contas de mídia social aos nossos Serviços, podemos coletar informações dessas contas, como suas informações de perfil e conexões.</li>
            <li>Informações de Parceiros: Dados que recebemos de nossos parceiros de negócios, como parceiros de marketing, provedores de análise ou redes de publicidade.</li>
            <li>Fontes Públicas: Informações de fontes publicamente disponíveis, como registros de empresas, perfis de mídia social e redes profissionais.</li>
          </ul>
        </p>

        <h4>E. Categorias Especiais de Dados Pessoais</h4>
        <p>Não coletamos ou processamos intencionalmente Categorias Especiais de Dados Pessoais. No entanto, se você incluir voluntariamente tais informações em suas entradas para nossos sistemas de IA ou em comunicações conosco, trataremos essas informações de acordo com a LGPD e outras leis aplicáveis. Por favor, evite compartilhar informações pessoais sensíveis, a menos que seja absolutamente necessário para o serviço que você está solicitando.</p>
      </p>

      <h3>4. COMO USAMOS SUAS INFORMAÇÕES</h3>
      <p>Usamos as informações que coletamos para várias finalidades, incluindo:</p>
      <p>
        <h4>A. Fornecimento e Melhoria de Nossos Serviços</h4>
        <p>
          <ul>
            <li>Para criar e manter sua conta</li>
            <li>Para fornecer os Serviços que você solicitou, incluindo a geração de conteúdo de IA</li>
            <li>Para processar e concluir transações</li>
            <li>Para fornecer suporte ao cliente e responder a consultas</li>
            <li>Para personalizar sua experiência e entregar conteúdo relevante para seus interesses</li>
            <li>Para melhorar nossos Serviços, incluindo o aprimoramento de recursos existentes e o desenvolvimento de novos recursos</li>
            <li>Para manter a segurança e funcionalidade de nossa plataforma</li>
          </ul>
        </p>

        <h4>B. Comunicação e Marketing</h4>
        <p>
          <ul>
            <li>Para comunicar com você sobre sua conta ou transações</li>
            <li>Para enviar avisos técnicos, atualizações, alertas de segurança e mensagens de suporte</li>
            <li>Para fornecer notícias, ofertas especiais e informações gerais sobre outros serviços que oferecemos</li>
            <li>Para medir a eficácia de nossas campanhas de marketing</li>
            <li>Para entregar anúncios direcionados com base em suas preferências e padrões de uso</li>
          </ul>
        </p>

        <h4>C. Pesquisa e Análise</h4>
        <p>
          <ul>
            <li>Para conduzir pesquisas e análises para entender melhor como os usuários acessam e usam nossos Serviços</li>
            <li>Para gerar análises e estatísticas agregadas e não identificáveis</li>
            <li>Para avaliar e melhorar o desempenho e a qualidade de nossos Serviços</li>
            <li>Para desenvolver novos produtos, serviços, recursos e funcionalidades</li>
          </ul>
        </p>

        <h4>D. Finalidades Legais e Operacionais</h4>
        <p>
          <ul>
            <li>Para cumprir obrigações legais sob a lei brasileira</li>
            <li>Para fazer cumprir nossos Termos de Serviço e outros acordos</li>
            <li>Para proteger nossos direitos, privacidade, segurança ou propriedade, e os de nossos usuários ou outros</li>
            <li>Para detectar, prevenir ou abordar fraudes, problemas de segurança ou técnicos</li>
            <li>Para administração de negócios, incluindo manutenção de registros, auditoria e outras funções internas</li>
          </ul>
        </p>

        <h4>E. Treinamento e Aprimoramento de IA</h4>
        <p>
          <ul>
            <li>Para treinar, testar e melhorar nossos modelos e algoritmos de IA</li>
            <li>Para aprimorar a precisão e relevância de nosso conteúdo gerado por IA</li>
            <li>Para desenvolver novos recursos e capacidades de IA</li>
            <li>Para usuários com assinatura paga, não usaremos suas entradas de conteúdo para treinar nossos modelos de IA sem seu consentimento explícito. Usuários de teste gratuito ou trial de 7 dias ou trial de 14 dias ou trial de 30 dias nos concedem uma licença para usar seu conteúdo para fins de treinamento de IA, que é revogável ao fazer upgrade para uma assinatura paga.</li>
          </ul>
        </p>
      </p>

      <h3>5. BASE LEGAL PARA O TRATAMENTO</h3>
      <p>Processamos seus Dados Pessoais apenas quando temos uma base legal válida para fazê-lo. De acordo com a LGPD, podemos nos basear em uma ou mais das seguintes bases legais:</p>
      <p>
        <h4>A. Consentimento (Artigo 7, I da LGPD)</h4>
        <p>Processamos seus Dados Pessoais quando você nos deu consentimento específico, informado e inequívoco para fazê-lo. Você pode retirar seu consentimento a qualquer momento entrando em contato conosco usando as informações fornecidas na seção "Informações de Contato".</p>

        <h4>B. Necessidade Contratual (Artigo 7, V da LGPD)</h4>
        <p>Processamos seus Dados Pessoais quando necessário para cumprir nossas obrigações contratuais com você, incluindo o fornecimento de nossos Serviços conforme descrito em nossos Termos de Serviço.</p>

        <h4>C. Obrigação Legal (Artigo 7, II da LGPD)</h4>
        <p>Processamos seus Dados Pessoais quando necessário para cumprir uma obrigação legal sob a lei brasileira, como responder a solicitações legítimas de autoridades públicas ou reter certas informações conforme exigido pelas leis fiscais.</p>

        <h4>D. Interesses Legítimos (Artigo 7, IX da LGPD)</h4>
        <p>Processamos seus Dados Pessoais quando é do nosso interesse legítimo fazê-lo, desde que esses interesses não sejam anulados por seus direitos e liberdades. Nossos interesses legítimos incluem:</p>
        <p>
          <ul>
            <li>Operar e melhorar nossos Serviços</li>
            <li>Proteger a segurança de nossos sistemas e usuários</li>
            <li>Entender como os usuários interagem com nossos Serviços</li>
            <li>Marketing e promoção de nossos Serviços</li>
            <li>Prevenção de fraudes e uso não autorizado</li>
            <li>Fazer cumprir nossos direitos legais e cumprir obrigações legais</li>
          </ul>
        </p>

        <h4>E. Exercício Regular de Direitos (Artigo 7, VI da LGPD)</h4>
        <p>Processamos seus Dados Pessoais quando necessário para o exercício regular de direitos em processos judiciais, administrativos ou arbitrais.</p>

        <h4>F. Proteção da Vida ou Segurança Física (Artigo 7, VII da LGPD)</h4>
        <p>Podemos processar seus Dados Pessoais para proteger sua vida ou segurança física ou a de terceiros.</p>

        <h4>G. Proteção da Saúde (Artigo 7, VIII da LGPD)</h4>
        <p>Em circunstâncias específicas, podemos processar seus Dados Pessoais para proteger sua saúde, em procedimentos realizados por profissionais de saúde ou entidades de saúde.</p>

        <h4>H. Pesquisa (Artigo 7, IV da LGPD)</h4>
        <p>Podemos processar seus Dados Pessoais para estudos por entidades de pesquisa, garantindo, sempre que possível, a anonimização dos dados pessoais.</p>

        <h4>I. Proteção ao Crédito (Artigo 7, X da LGPD)</h4>
        <p>Podemos processar seus Dados Pessoais para proteção ao crédito, inclusive conforme previsto na legislação aplicável.</p>
      </p>

      <h3>6. PROTEÇÃO E SEGURANÇA DE DADOS</h3>
      <p>Implementamos medidas técnicas e organizacionais apropriadas para proteger seus Dados Pessoais contra processamento não autorizado ou ilegal, perda acidental, destruição ou danos. Essas medidas incluem:</p>
      <p>
        <h4>A. Salvaguardas Técnicas</h4>
        <p>
          <ul>
            <li>Controles de Acesso: Implementamos controles de acesso rigorosos e mecanismos de autenticação para garantir que apenas pessoal autorizado possa acessar seus dados.</li>
            <li>Firewalls e Detecção de Intrusão: Nossos sistemas são protegidos por firewalls e sistemas de detecção de intrusão para prevenir acesso não autorizado.</li>
            <li>Práticas de Desenvolvimento Seguro: Seguimos práticas de codificação segura e realizamos revisões de segurança durante todo o nosso processo de desenvolvimento.</li>
          </ul>
        </p>

        <h4>B. Salvaguardas Organizacionais</h4>
        <p>
          <ul>
            <li>Treinamento de Funcionários: Todos os funcionários recebem treinamento regular sobre práticas de proteção e segurança de dados, com foco específico nos requisitos brasileiros de proteção de dados.</li>
            <li>Limitações de Acesso: O acesso a Dados Pessoais é limitado a funcionários que precisam dele para desempenhar suas funções de trabalho.</li>
            <li>Acordos de Confidencialidade: Todos os funcionários e contratados estão vinculados por obrigações de confidencialidade.</li>
            <li>Políticas de Segurança: Mantemos políticas e procedimentos abrangentes de segurança que são regularmente revisados e atualizados.</li>
            <li>Plano de Resposta a Incidentes: Temos um plano de resposta a incidentes documentado para abordar prontamente quaisquer possíveis violações de segurança, em conformidade com os requisitos da LGPD.</li>
          </ul>
        </p>

        <h4>C. Segurança de Terceiros</h4>
        <p>
          <ul>
            <li>Selecionamos cuidadosamente provedores de serviços terceirizados e exigimos que eles mantenham medidas de segurança apropriadas.</li>
            <li>Celebramos acordos de processamento de dados com nossos provedores de serviços que incluem requisitos de segurança.</li>
            <li>Revisamos regularmente as práticas de segurança de nossos provedores de serviços terceirizados.</li>
          </ul>
        </p>

        <h4>D. Responsabilidades do Usuário</h4>
        <p>
          <ul>
            <li>Você é responsável por manter a confidencialidade de suas credenciais de conta.</li>
            <li>Recomendamos o uso de senhas fortes e únicas e a ativação da autenticação de dois fatores quando disponível.</li>
            <li>Você deve nos notificar imediatamente se suspeitar de qualquer acesso não autorizado à sua conta.</li>
          </ul>
        </p>
        <p>Embora implementemos essas salvaguardas, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro. Portanto, embora nos esforcemos para proteger seus Dados Pessoais, não podemos garantir sua segurança absoluta.</p>
      </p>

      <h3>7. COMPARTILHAMENTO E DIVULGAÇÃO DE DADOS</h3>
      <p>Podemos compartilhar suas informações nas seguintes circunstâncias:</p>
      <p>
        <h4>A. Provedores de Serviços</h4>
        <p>Compartilhamos informações com provedores de serviços terceirizados que realizam serviços em nosso nome, como:</p>
        <p>
          <ul>
            <li>Provedores de hospedagem e infraestrutura em nuvem</li>
            <li>Processadores de pagamento</li>
            <li>Serviços de suporte ao cliente</li>
            <li>Serviços de e-mail e comunicação</li>
            <li>Provedores de análise</li>
            <li>Parceiros de marketing e publicidade</li>
            <li>Serviços de segurança e prevenção de fraudes</li>
          </ul>
        </p>
        <p>Esses provedores de serviços são contratualmente obrigados a usar suas informações apenas para os fins de fornecer serviços para nós e de acordo com esta Política de Privacidade e a LGPD.</p>

        <h4>B. Transferências de Negócios</h4>
        <p>Se estivermos envolvidos em uma fusão, aquisição, financiamento, reorganização, falência ou venda de ativos da empresa, suas informações podem ser transferidas como parte dessa transação. Notificaremos você sobre qualquer mudança de propriedade ou controle de seus Dados Pessoais.</p>

        <h4>C. Requisitos Legais</h4>
        <p>Podemos divulgar suas informações se exigido pela lei brasileira ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal, a ANPD ou agência governamental). Também podemos divulgar suas informações para:</p>
        <p>
          <ul>
            <li>Fazer cumprir nossos Termos de Serviço e outros acordos</li>
            <li>Proteger e defender nossos direitos ou propriedade</li>
            <li>Prevenir ou investigar possíveis irregularidades relacionadas aos Serviços</li>
            <li>Proteger a segurança pessoal dos usuários dos Serviços ou do público</li>
            <li>Proteger contra responsabilidade legal</li>
          </ul>
        </p>

        <h4>D. Com Seu Consentimento</h4>
        <p>Podemos compartilhar suas informações com terceiros quando você nos der seu consentimento para fazê-lo.</p>

        <h4>E. Dados Agregados ou Desidentificados</h4>
        <p>Podemos compartilhar informações agregadas ou desidentificadas, que não podem ser razoavelmente usadas para identificá-lo, com terceiros para pesquisa, marketing, análise e outros fins.</p>

        <h4>F. Parceiros de Negócios</h4>
        <p>Com seu consentimento, podemos compartilhar suas informações com nossos parceiros de negócios para oferecer a você certos produtos, serviços ou promoções.</p>

        <h4>G. Lista Atual de Principais Processadores Terceirizados</h4>
        <p>Atualmente, usamos os seguintes principais provedores de serviços terceirizados que podem processar seus Dados Pessoais:</p>
        <p>
          <ul>
            <li>Amazon Web Services (AWS) Brasil - Hospedagem em nuvem e infraestrutura</li>
            <li>Liquid Web - Provedor americano de serviços de servidores</li>
            <li>PagSeguro - Processamento de pagamentos brasileiro</li>
            <li>Stripe - Processamento de pagamentos internacional</li>
            <li>Zendesk - Suporte ao cliente</li>
            <li>KARTRA - Comunicações por e-mail</li>
            <li>Close - CRM e marketing</li>
            <li>PipeDrive - CRM e marketing</li>
            <li>Google Analytics - Análises</li>
          </ul>
        </p>
        <p>Esta lista pode ser atualizada de tempos em tempos. Para a lista mais atual de nossos processadores terceirizados, entre em contato conosco usando as informações fornecidas na seção "Informações de Contato".</p>
      </p>

      <h3>8. TRANSFERÊNCIAS INTERNACIONAIS DE DADOS</h3>
      <p>Como uma empresa sediada no Brasil, nossas atividades primárias de processamento de dados ocorrem dentro do Brasil. No entanto, em alguns casos, podemos transferir seus Dados Pessoais para outros países para processamento. Quando o fazemos, garantimos que salvaguardas apropriadas estejam em vigor para proteger seus dados.</p>
      <p>
        <h4>A. Mecanismos de Transferência</h4>
        <p>Quando transferimos Dados Pessoais para fora do Brasil, garantimos que salvaguardas apropriadas estejam em vigor, de acordo com o Artigo 33 da LGPD, que podem incluir:</p>
        <p>
          <ul>
            <li>Transferências para países ou organizações internacionais que proporcionem um nível adequado de proteção de dados pessoais, conforme determinado pela ANPD</li>
            <li>Transferências onde obtivemos seu consentimento específico e destacado para a transferência</li>
            <li>Transferências necessárias para a execução de um contrato ou procedimentos preliminares relacionados a um contrato do qual você é parte</li>
            <li>Transferências necessárias para o exercício regular de direitos em processos judiciais, administrativos ou arbitrais</li>
            <li>Transferências necessárias para proteger sua vida ou segurança física ou a de terceiros</li>
            <li>Transferências autorizadas pela ANPD</li>
            <li>Transferências baseadas em cláusulas contratuais padrão aprovadas pela ANPD ou regras corporativas globais</li>
          </ul>
        </p>

        <h4>B. Países de Processamento</h4>
        <p>Seus Dados Pessoais podem ser processados nos seguintes países:</p>
        <p>
          <ul>
            <li>Brasil (nossa localização principal)</li>
            <li>Estados Unidos (para certos serviços e ferramentas em nuvem)</li>
            <li>Estados membros da União Europeia (para certos serviços)</li>
          </ul>
        </p>

        <h4>C. Salvaguardas Adicionais</h4>
        <p>Além dos mecanismos de transferência descritos acima, implementamos salvaguardas adicionais para proteger seus dados durante transferências internacionais, incluindo:</p>
        <p>
          <ul>
            <li>Criptografia de dados em trânsito e em repouso</li>
            <li>Minimização da quantidade de Dados Pessoais transferidos</li>
            <li>Avaliações regulares das práticas de proteção de dados nos países receptores</li>
            <li>Compromissos contratuais dos destinatários para manter medidas de segurança apropriadas</li>
          </ul>
        </p>

        <h4>D. Seus Direitos Relativos a Transferências Internacionais</h4>
        <p>Você tem o direito de solicitar informações sobre as transferências internacionais de seus Dados Pessoais, incluindo os países para os quais seus dados são transferidos e as salvaguardas que temos em vigor. Para exercer este direito, entre em contato conosco usando as informações fornecidas na seção "Informações de Contato".</p>
      </p>

      <h3>9. RETENÇÃO DE DADOS</h3>
      <p>Retemos seus Dados Pessoais apenas pelo tempo necessário para cumprir as finalidades para as quais os coletamos, incluindo para fins de satisfazer quaisquer requisitos legais, regulatórios, fiscais, contábeis ou de relatórios sob a lei brasileira.</p>
      <p>
        <h4>A. Períodos de Retenção</h4>
        <p>Os períodos específicos de retenção para diferentes tipos de dados são os seguintes:</p>
        <p>
          <ul>
            <li>Informações da Conta: Retemos suas informações de conta durante a vigência de sua conta conosco. Se você excluir sua conta, excluiremos ou anonimizaremos suas informações de conta dentro de 90 dias, exceto conforme observado abaixo.</li>
            <li>Conteúdo Gerado: Retemos seu Conteúdo Gerado de acordo com as configurações da sua conta. Você pode excluir seu Conteúdo Gerado a qualquer momento através das configurações da sua conta.</li>
            <li>Informações de Pagamento: Retemos informações de pagamento por 10 anos para cumprir requisitos fiscais e contábeis brasileiros.</li>
            <li>Dados de Comunicação: Retemos comunicações com nossa equipe de suporte por 5 anos para manter a qualidade do serviço e resolver quaisquer disputas.</li>
            <li>Dados de Uso: Retemos dados de uso por 2 anos para analisar tendências e melhorar nossos Serviços.</li>
            <li>Dados de Log: Retemos dados de log por 1 ano para fins de segurança e solução de problemas.</li>
            <li>Dados para Fins de Responsabilidade: Em conformidade com nossa limitação de responsabilidade estabelecida nos Termos de Serviço, mantemos registros relevantes para possíveis reclamações por um período mínimo de 2 meses, que corresponde ao período durante o qual nossa responsabilidade é limitada aos valores pagos por você nesse intervalo.</li>
          </ul>
        </p>

        <h4>B. Retenção Estendida</h4>
        <p>Podemos reter seus Dados Pessoais por períodos mais longos nas seguintes circunstâncias:</p>
        <p>
          <ul>
            <li>Para cumprir requisitos legais, regulatórios, fiscais, contábeis ou de relatórios sob a lei brasileira</li>
            <li>Para resolver disputas ou fazer cumprir nossos acordos</li>
            <li>Para proteger nossos direitos legais, propriedade ou segurança, e os de nossos usuários ou outros</li>
            <li>Quando a retenção continuada é necessária para fins comerciais legítimos que são compatíveis com a finalidade original da coleta</li>
          </ul>
        </p>

        <h4>C. Exclusão de Dados</h4>
        <p>Quando seus Dados Pessoais não forem mais necessários para as finalidades para as quais foram coletados, os excluiremos ou anonimizaremos. Se a exclusão completa não for possível (por exemplo, porque seus Dados Pessoais foram armazenados em arquivos de backup), armazenaremos seus Dados Pessoais com segurança e os isolaremos de qualquer processamento adicional até que a exclusão seja possível.</p>

        <h4>D. Dados Anonimizados</h4>
        <p>Podemos reter dados anonimizados ou agregados, que não podem ser razoavelmente usados para identificá-lo, por tempo indeterminado.</p>
      </p>

      <h3>10. SEUS DIREITOS SOB A LEI BRASILEIRA</h3>
      <p>Como titular de dados sob a LGPD, você tem os seguintes direitos em relação aos seus Dados Pessoais:</p>
      <p>
        <h4>A. Direito de Confirmação e Acesso (Artigo 18, I e II da LGPD)</h4>
        <p>Você tem o direito de confirmar a existência de tratamento de seus Dados Pessoais e de acessar seus Dados Pessoais que mantemos.</p>

        <h4>B. Direito de Correção (Artigo 18, III da LGPD)</h4>
        <p>Você tem o direito de solicitar a correção de Dados Pessoais incompletos, imprecisos ou desatualizados.</p>

        <h4>C. Direito de Anonimização, Bloqueio ou Eliminação (Artigo 18, IV da LGPD)</h4>
        <p>Você tem o direito de solicitar a anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários, excessivos ou tratados ilegalmente.</p>

        <h4>D. Direito à Portabilidade de Dados (Artigo 18, V da LGPD)</h4>
        <p>Você tem o direito de solicitar a portabilidade de seus Dados Pessoais para outro provedor de serviços ou produtos, sujeito a segredos comerciais e industriais.</p>

        <h4>E. Direito à Eliminação (Artigo 18, VI da LGPD)</h4>
        <p>Você tem o direito de solicitar a eliminação de Dados Pessoais tratados com seu consentimento, exceto nos casos em que a retenção é exigida por lei ou regulamento.</p>

        <h4>F. Direito à Informação (Artigo 18, VII e VIII da LGPD)</h4>
        <p>Você tem o direito de receber informações sobre as entidades públicas e privadas com as quais compartilhamos seus Dados Pessoais, e sobre a possibilidade de não fornecer consentimento e as consequências de tal recusa.</p>

        <h4>G. Direito de Revogar o Consentimento (Artigo 18, IX da LGPD)</h4>
        <p>Você tem o direito de revogar seu consentimento a qualquer momento através de um procedimento simples e gratuito.</p>

        <h4>H. Direito de Revisão de Decisões Automatizadas (Artigo 20 da LGPD)</h4>
        <p>Você tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de Dados Pessoais que afetem seus interesses, incluindo decisões que definam seu perfil pessoal, profissional, de consumo e de crédito ou aspectos de sua personalidade.</p>

        <h4>I. Como Exercer Seus Direitos</h4>
        <p>Para exercer qualquer um desses direitos, entre em contato conosco usando as informações fornecidas na seção "Informações de Contato". Podemos precisar verificar sua identidade antes de responder à sua solicitação. Responderemos à sua solicitação dentro de 15 dias, conforme exigido pela LGPD.</p>

        <h4>J. Limitações</h4>
        <p>Seus direitos podem estar sujeitos a limitações conforme previsto pela LGPD. Por exemplo, podemos reter certas informações conforme exigido por lei ou para fins comerciais legítimos. Também podemos reter cópias em cache ou arquivadas de informações sobre você por um determinado período de tempo.</p>
      </p>

      <h3>11. COOKIES E TECNOLOGIAS DE RASTREAMENTO</h3>
      <p>Usamos cookies e tecnologias de rastreamento similares para coletar e usar informações sobre você e sua interação com nossos Serviços.</p>
      <p>
        <h4>A. Tipos de Cookies que Usamos</h4>
        <p>
          <ul>
            <li>Cookies Essenciais: Esses cookies são necessários para o funcionamento do site e não podem ser desativados em nossos sistemas. Eles geralmente são definidos apenas em resposta a ações feitas por você que equivalem a uma solicitação de serviços, como definir suas preferências de privacidade, fazer login ou preencher formulários.</li>
            <li>Cookies de Desempenho: Esses cookies nos permitem contar visitas e fontes de tráfego para que possamos medir e melhorar o desempenho do nosso site. Eles nos ajudam a saber quais páginas são as mais e menos populares e ver como os visitantes navegam pelo site.</li>
            <li>Cookies de Funcionalidade: Esses cookies permitem que o site forneça funcionalidade e personalização aprimoradas. Eles podem ser definidos por nós ou por provedores terceirizados cujos serviços adicionamos às nossas páginas.</li>
            <li>Cookies de Direcionamento: Esses cookies podem ser definidos através do nosso site por nossos parceiros de publicidade. Eles podem ser usados por essas empresas para criar um perfil de seus interesses e mostrar anúncios relevantes em outros sites.</li>
            <li>Cookies de Mídia Social: Esses cookies são definidos por serviços de mídia social que adicionamos ao site para permitir que você compartilhe nosso conteúdo com seus amigos e redes.</li>
          </ul>
        </p>

        <h4>B. Gerenciamento de Cookies</h4>
        <p>Você pode configurar seu navegador para recusar todos ou alguns cookies do navegador, ou para alertá-lo quando os sites definem ou acessam cookies. Se você desativar ou recusar cookies, observe que algumas partes de nossos Serviços podem se tornar inacessíveis ou não funcionar adequadamente.</p>

        <h4>C. Não Rastrear</h4>
        <p>Alguns navegadores têm um recurso "Não Rastrear" que sinaliza para os sites que você visita que não deseja ter sua atividade online rastreada. Nossos Serviços atualmente não respondem a sinais "Não Rastrear".</p>

        <h4>D. Análises de Terceiros</h4>
        <p>Usamos serviços de análise de terceiros, como o Google Analytics, para nos ajudar a entender como os usuários interagem com nossos Serviços. Esses serviços podem usar cookies e tecnologias similares para coletar informações sobre seu uso dos Serviços e outros sites.</p>

        <h4>E. Publicidade</h4>
        <p>Podemos trabalhar com empresas de publicidade terceirizadas para veicular anúncios quando você visita nossos Serviços ou outros sites. Essas empresas podem usar informações sobre suas visitas aos nossos Serviços e outros sites para fornecer anúncios sobre bens e serviços de interesse para você.</p>

        <h4>F. Política de Cookies</h4>
        <p>Para informações mais detalhadas sobre os cookies que usamos e como gerenciá-los, consulte nossa Política de Cookies, que está incorporada a esta Política de Privacidade por referência.</p>
      </p>

      <h3>12. INTELIGÊNCIA ARTIFICIAL E APRENDIZADO DE MÁQUINA</h3>
      <p>Como uma plataforma baseada em IA, usamos tecnologias de inteligência artificial e aprendizado de máquina para fornecer nossos Serviços. Esta seção explica como usamos essas tecnologias e como elas interagem com seus dados.</p>
      <p>
        <h4>A. Conteúdo Gerado por IA</h4>
        <p>Nossos Serviços usam modelos de IA para gerar conteúdo de marketing com base em suas entradas e instruções. O conteúdo gerado por nossos sistemas de IA é fornecido para seu uso sujeito aos nossos Termos de Serviço.</p>

        <h4>B. Treinamento e Aprimoramento de IA</h4>
        <p>Para usuários de FREE TRIAL ou TRIAL PAGO de 7 dias, 14 dias ou 30 dias (com custo de 1 BRL no Brasil ou 1 USD fora do Brasil) podemos usar dados coletados através de nossos Serviços para treinar, testar e melhorar nossos modelos de IA. Isso nos ajuda a aprimorar a qualidade, precisão e relevância de nosso conteúdo gerado por IA.</p>
        <p>Para usuários com assinatura paga, não usaremos suas entradas de conteúdo para treinar nossos modelos de IA sem seu consentimento explícito. Os usuários de teste gratuito ou TRIAL PAGO de 7 dias, 14 dias ou 30 dias (com custo de 1 BRL no Brasil ou 1 USD fora do Brasil) nos concedem uma licença para usar seu conteúdo para fins de treinamento de IA, que é revogável ao fazer upgrade para uma assinatura paga.</p>

        <h4>C. Tomada de Decisão por IA</h4>
        <p>Nossos Serviços podem usar processos automatizados de tomada de decisão para:</p>
        <p>
          <ul>
            <li>Personalizar sua experiência</li>
            <li>Recomendar recursos ou conteúdo</li>
            <li>Otimizar a geração de materiais de marketing</li>
            <li>Detectar e prevenir fraudes ou abusos</li>
          </ul>
        </p>
        <p>Esses processos automatizados analisam vários fatores, incluindo suas entradas, preferências e padrões de uso, para tomar decisões que aprimoram sua experiência com nossos Serviços.</p>

        <h4>D. Seus Direitos Relativos ao Processamento de IA</h4>
        <p>De acordo com o Artigo 20 da LGPD, você tem o direito de:</p>
        <p>
          <ul>
            <li>Solicitar informações sobre os critérios e procedimentos utilizados para decisões automatizadas</li>
            <li>Solicitar revisão humana de decisões automatizadas que afetem seus interesses</li>
            <li>Contestar decisões automatizadas</li>
          </ul>
        </p>
        <p>Para exercer esses direitos, entre em contato conosco usando as informações fornecidas na seção "Informações de Contato".</p>

        <h4>E. Limitações da Tecnologia de IA</h4>
        <p>Embora nos esforcemos para fornecer conteúdo gerado por IA preciso e confiável, a tecnologia de IA tem limitações inerentes. Nossos sistemas de IA podem ocasionalmente produzir conteúdo impreciso, inadequado ou não alinhado com suas expectativas. Você é responsável por revisar e editar todo o conteúdo gerado por IA antes do uso.</p>
      </p>

      <h3>13. PRIVACIDADE DE CRIANÇAS</h3>
      <p>Nossos Serviços não são destinados a crianças menores de 18 anos, e não coletamos conscientemente Dados Pessoais de crianças menores de 18 anos. Se você é pai ou responsável e acredita que seu filho nos forneceu Dados Pessoais, entre em contato conosco usando as informações fornecidas na seção "Informações de Contato", e tomaremos medidas para excluir tais informações.</p>
      <p>Se descobrirmos que coletamos Dados Pessoais de uma criança menor de 18 anos sem verificação do consentimento dos pais, tomaremos medidas para excluir essas informações o mais rapidamente possível.</p>

      <h3>14. INCIDENTES DE SEGURANÇA</h3>
      <p>Levamos incidentes de segurança de dados a sério e implementamos procedimentos para abordá-los prontamente e efetivamente, em conformidade com a LGPD.</p>
      <p>
        <h4>A. Plano de Resposta a Incidentes</h4>
        <p>Mantemos um plano abrangente de resposta a incidentes que descreve as etapas que tomamos em caso de um incidente de segurança, incluindo:</p>
        <p>
          <ul>
            <li>Identificação e contenção do incidente</li>
            <li>Avaliação da natureza e escopo do incidente</li>
            <li>Mitigação de quaisquer riscos contínuos</li>
            <li>Investigação da causa do incidente</li>
            <li>Implementação de medidas para prevenir incidentes similares no futuro</li>
          </ul>
        </p>

        <h4>B. Notificação de Incidentes de Segurança</h4>
        <p>Em caso de um incidente de segurança envolvendo seus Dados Pessoais, nós:</p>
        <p>
          <ul>
            <li>Notificaremos a ANPD e os usuários afetados dentro de 48 horas após a descoberta da violação, ou conforme exigido pela LGPD</li>
            <li>Forneceremos informações sobre a natureza da violação, os tipos de dados afetados e as medidas que estamos tomando para abordar a violação</li>
            <li>Ofereceremos orientação sobre medidas que você pode tomar para se proteger</li>
            <li>Cooperaremos com a ANPD e outras autoridades reguladoras conforme exigido por lei</li>
          </ul>
        </p>

        <h4>C. Medidas Preventivas</h4>
        <p>Para minimizar o risco de incidentes de segurança, nós:</p>
        <p>
          <ul>
            <li>Revisamos e atualizamos regularmente nossas medidas de segurança</li>
            <li>Realizamos avaliações de segurança e testes de penetração</li>
            <li>Treinamos nossos funcionários sobre as melhores práticas de segurança</li>
            <li>Monitoramos nossos sistemas para atividades suspeitas</li>
            <li>Implementamos controles de acesso e mecanismos de autenticação</li>
          </ul>
        </p>

        <h4>D. Suas Responsabilidades</h4>
        <p>Para ajudar a proteger seus Dados Pessoais, recomendamos que você:</p>
        <p>
          <ul>
            <li>Use senhas fortes e únicas para sua conta</li>
            <li>Ative a autenticação de dois fatores quando disponível</li>
            <li>Seja cauteloso ao compartilhar suas credenciais de conta</li>
            <li>Relate qualquer atividade suspeita relacionada à sua conta</li>
            <li>Mantenha seus dispositivos e software atualizados</li>
          </ul>
        </p>
      </p>
      <h3>15. SERVIÇOS DE TERCEIROS</h3>
      <p>Nossos Serviços podem conter links para sites, produtos ou serviços de terceiros. Esta Política de Privacidade se aplica apenas aos nossos Serviços. Quando você usa links para outros sites, produtos ou serviços, suas políticas de privacidade se aplicam à coleta e uso de suas informações.</p>
      <p>
        <h4>A. Integrações de Terceiros</h4>
        <p>Podemos oferecer integrações com serviços de terceiros, como:</p>
        <p>
          <ul>
            <li>Plataformas de mídia social</li>
            <li>Ferramentas de marketing</li>
            <li>Serviços de análise</li>
            <li>Processadores de pagamento</li>
            <li>Provedores de armazenamento em nuvem</li>
          </ul>
        </p>
        <p>Quando você habilita essas integrações, pode estar compartilhando informações com esses terceiros. Incentivamos você a revisar as políticas de privacidade desses terceiros antes de usar essas integrações.</p>

        <h4>B. Widgets de Terceiros</h4>
        <p>Nossos Serviços podem incluir widgets, que são mini-programas interativos que são executados em nossos Serviços para fornecer serviços específicos de outra empresa. Os widgets podem coletar seu endereço IP, qual página você está visitando em nossos Serviços, e podem definir um cookie para permitir que o widget funcione adequadamente. Os widgets são hospedados por um terceiro ou hospedados diretamente em nossos Serviços. Suas interações com esses widgets são regidas pela política de privacidade da empresa que fornece o widget.</p>

        <h4>C. Cookies de Terceiros</h4>
        <p>Alguns de nossos parceiros terceirizados podem usar cookies ou outras tecnologias de rastreamento em nossos Serviços. Não temos acesso ou controle sobre esses cookies. Esta Política de Privacidade abrange apenas o uso de cookies por nós e não abrange o uso de cookies por quaisquer terceiros.</p>

        <h4>D. Responsabilidade de Terceiros</h4>
        <p>Não somos responsáveis pelas práticas de privacidade ou conteúdo de quaisquer serviços de terceiros. Incentivamos você a revisar as políticas de privacidade de quaisquer serviços de terceiros que você use em conexão com nossos Serviços.</p>
      </p>

      <h3>16. SINAIS DE NÃO RASTREAMENTO</h3>
      <p>Alguns navegadores têm um recurso "Não Rastrear" que sinaliza para os sites que você visita que não deseja ter sua atividade online rastreada. Devido à falta de uma interpretação comum dos sinais Não Rastrear em toda a indústria, atualmente não respondemos a sinais Não Rastrear em nossos Serviços.</p>
      <p>No entanto, você pode configurar seu navegador para recusar todos ou alguns cookies do navegador, ou para alertá-lo quando os cookies estão sendo enviados. Para saber como você pode gerenciar suas configurações de cookies, consulte a seção "Cookies e Tecnologias de Rastreamento" desta Política de Privacidade.</p>

      <h3>17. ALTERAÇÕES NESTA POLÍTICA</h3>
      <p>Podemos atualizar esta Política de Privacidade de tempos em tempos para refletir mudanças em nossas práticas, tecnologia, requisitos legais ou outros fatores. Quando fizermos alterações nesta Política de Privacidade, atualizaremos a data de "Última Atualização" no topo desta Política de Privacidade.</p>
      <p>
        <h4>A. Alterações Materiais</h4>
        <p>Se fizermos alterações materiais nesta Política de Privacidade, notificaremos você por:</p>
        <p>
          <ul>
            <li>Enviar um e-mail para o endereço de e-mail associado à sua conta</li>
            <li>Exibir um aviso proeminente em nossos Serviços</li>
            <li>Publicar um aviso em nosso site</li>
          </ul>
        </p>
        <p>Incentivamos você a revisar esta Política de Privacidade periodicamente para se manter informado sobre nossas práticas de dados.</p>

        <h4>B. Seu Uso Continuado</h4>
        <p>Seu uso continuado de nossos Serviços após termos publicado alterações nesta Política de Privacidade significa que você concorda com a Política de Privacidade atualizada. Se você não concordar com a Política de Privacidade atualizada, deve parar de usar nossos Serviços.</p>

        <h4>C. Arquivo de Versões Anteriores</h4>
        <p>Mantemos um arquivo de versões anteriores desta Política de Privacidade, que você pode solicitar entrando em contato conosco usando as informações fornecidas na seção "Informações de Contato".</p>
      </p>

      <h3>18. RESOLUÇÃO DE DISPUTAS</h3>
      <p>Se você tiver uma reclamação ou disputa sobre como lidamos com seus Dados Pessoais, incentivamos você a entrar em contato conosco primeiro usando as informações fornecidas na seção "Informações de Contato". Trabalharemos com você para resolver sua reclamação ou disputa.</p>
      <p>
        <h4>A. Resolução Informal</h4>
        <p>Nos esforçamos para resolver todas as reclamações e disputas informalmente através de comunicação direta. Por favor, forneça-nos informações detalhadas sobre sua reclamação ou disputa, e responderemos prontamente.</p>

        <h4>B. Resolução Formal de Disputas</h4>
        <p>Se não conseguirmos resolver sua reclamação ou disputa informalmente, você pode buscar resolução formal de disputas através dos seguintes canais:</p>
        <p>
          <ul>
            <li>Você pode apresentar uma reclamação à Autoridade Nacional de Proteção de Dados (ANPD) do Brasil.</li>
            <li>Você pode buscar recursos judiciais através do sistema judicial brasileiro.</li>
          </ul>
        </p>

        <h4>C. Arbitragem</h4>
        <p>Para disputas que não podem ser resolvidas através dos canais acima, você concorda em resolver quaisquer reivindicações relacionadas a esta Política de Privacidade através de arbitragem final e vinculante.</p>
        <p>A arbitragem será conduzida em São Paulo, Brasil, de acordo com as regras de arbitragem da Câmara de Comércio Brasil-Canadá.</p>

        <h4>D. Exceções ao Acordo de Arbitragem</h4>
        <p>Qualquer uma das partes pode apresentar uma ação judicial nos tribunais de São Paulo, Brasil, exclusivamente para obter medida cautelar para interromper o uso não autorizado ou abuso dos Serviços ou violação de direitos de propriedade intelectual sem primeiro se envolver no processo informal de resolução de disputas ou arbitragem.</p>

        <h4>E. Sem Ações Coletivas</h4>
        <p>Você só pode resolver disputas conosco em uma base individual e não pode apresentar uma reivindicação como autor ou membro de uma classe em uma ação coletiva, consolidada ou representativa.</p>

        <h4>F. Lei Aplicável</h4>
        <p>Esta Política de Privacidade e quaisquer disputas relacionadas a ela ou aos nossos Serviços serão regidas pelas leis do Brasil, sem considerar princípios de conflito de leis.</p>

        <h4>G. Jurisdição</h4>
        <p>Os tribunais de São Paulo, Brasil, terão jurisdição exclusiva sobre quaisquer disputas decorrentes ou relacionadas a esta Política de Privacidade ou aos nossos Serviços.</p>
      </p>

      <h3>19. INFORMAÇÕES DE CONTATO</h3>
      <p>Se você tiver alguma dúvida, preocupação ou solicitação relacionada a esta Política de Privacidade ou nossas práticas de dados, entre em contato conosco usando as seguintes informações:</p>
      <p>
        <h4>Encarregado de Proteção de Dados:</h4>
        <p>
          <ul>
            <li>Email: suporte@shifters.com.br</li>
            <li>Endereço: Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brasil</li>
          </ul>
        </p>

        <h4>Para Consultas sobre Privacidade:</h4>
        <p>
          <ul>
            <li>Email: suporte@shifters.com.br</li>
            <li>Suporte: https://www.shifters.com.br/AiGU75oYrw9c</li>
          </ul>
        </p>

        <h4>Endereço Postal:</h4>
        <p>
          <ul>
            <li>SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP</li>
            <li>Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brasil</li>
            <li>CNPJ/MF: 15.563.090/0001-04</li>
          </ul>
        </p>
      </p>

      <p>Ao usar o CopyCut AI, você está ciente que leu, entendeu e que concorda com esta politica de privacidade.</p>
    </Container>
  );
}
