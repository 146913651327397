/* eslint-disable no-param-reassign */
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import AILabService from '../../../../services/AILab';
import CopyCutPaidService from '../../../../services/CopyCutPaid';
import CopyCutPaidAdsService from '../../../../services/CopyCutPaidAds';
import CopyCutUnpaidPostsService from '../../../../services/CopyCutUnpaidPosts';

import toast from '../../../../helpers/toast';

const ManageProjectContext = createContext();

function ManageProjectProvider({ children }) {
  const navigate = useNavigate();

  // STATES SIDEBAR
  const [listOfPrompts, setListOfPrompts] = useState([]);
  const [isLoadingPromptList, setIsLoandingPromptList] = useState(true);

  // STATES RECENTS
  const [listOfRecentsPrompts, setListOfRecentsPrompts] = useState([]);
  const [isLoadingRecentsPromptList, setIsLoandingRecentsPromptList] = useState(true);

  // STATE edit-name page
  const [name, setName] = useState('');

  // STATE project Selected
  const [projectID, setProjectID] = useState(null);
  const [projectInfos, setProjectInfos] = useState(null);
  const [isLoadingProjectInfo, setIsLoadingProjectInfo] = useState(true);
  const [toogleModal, setToogleModal] = useState(false);

  // GET SIDEBAR ITEMS
  useEffect(() => {
    getListOfPrompts();
    getListOfRecentsPrompts();

    return () => {
      setListOfPrompts([]);
      setListOfRecentsPrompts([]);
    };
  }, []);

  // UPDATE PROJECT INFOS
  useEffect(() => {
    if (projectID) {
      getProjectInfos();
    }

    return () => {
      setProjectInfos(null);
    };
  }, [projectID]);

  async function getListOfPrompts() {
    try {
      setIsLoandingPromptList(true);
      const promptsDB = await AILabService.findPrompts();
      setListOfPrompts(promptsDB);
      setIsLoandingPromptList(false);
    } catch (error) {
      toast('danger', 'Error to get list of Projects. Reload the page, please');
    }
  }

  async function getListOfRecentsPrompts() {
    setIsLoandingRecentsPromptList(true);
    const promptsDB = await AILabService.findRecentsPrompts();
    setListOfRecentsPrompts(promptsDB);
    setIsLoandingRecentsPromptList(false);
  }

  async function handleEditName(event, id) {
    event.preventDefault();

    if (!id) {
      toast('danger', 'ID is required');
      return;
    }

    try {
      const response = await AILabService.update({ id, name });
      toast('success', response.message);
      updateListWithNewNameUpdated({ id, name });
      setProjectInfos((prevState) => ({ ...prevState, name }));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/client/ailab/manage-projects');
    }
  }

  async function getProjectInfos() {
    setIsLoadingProjectInfo(true);
    try {
      const aiLab = await AILabService.findOne({ id: projectID });

      let infos;

      if (aiLab.type === 'copycut_assets' || aiLab.type === 'copycut_paid') {
        infos = await CopyCutPaidService.findOne({ id: projectID });
      }
      if (aiLab.type === 'copycut_paid_ads' || aiLab.type === 'copycut_unpaid') {
        infos = await CopyCutPaidAdsService.findOne({ id: projectID });
      }
      if (aiLab.type === 'copycut_unpaid_posts') {
        infos = await CopyCutUnpaidPostsService.findOne({ id: projectID });
      }

      setProjectInfos(infos);
    } catch (error) {
      toast('danger', error.message);
      navigate('/client/copycut/manage-projects');
    } finally {
      setIsLoadingProjectInfo(false);
    }
  }

  async function handleDeleteProject() {
    try {
      await AILabService.delete({ id: projectID });
      toast('success', 'Project deleted successfully');
      deletedListWithNoDeleteItem(projectID);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setToogleModal(false);
      navigate('/client/ailab/manage-projects');
    }
  }

  function updateListWithNewNameUpdated({ id, name }) {
    const promptsList = listOfPrompts.map((item) => {
      if (Number(item.id) === Number(id)) {
        item.name = name;
      }
      return item;
    });
    const promptsRecentList = listOfRecentsPrompts.map((item) => {
      if (Number(item.id) === Number(id)) {
        item.name = name;
      }
      return item;
    });
    setListOfPrompts(promptsList);
    setListOfRecentsPrompts(promptsRecentList);
  }

  function deletedListWithNoDeleteItem(id) {
    const deletedPromptList = listOfPrompts.filter((prompt) => Number(prompt.id) !== Number(id));
    const deletedRecentPromptList = listOfRecentsPrompts.filter((prompt) => Number(prompt.id) !== Number(id));
    setListOfPrompts(deletedPromptList);
    setListOfRecentsPrompts(deletedRecentPromptList);
  }

  function handleToogleModal() {
    setToogleModal((prevState) => !prevState);
  }

  const contextValues = useMemo(() => ({
    listOfPrompts,
    listOfRecentsPrompts,
    isLoadingPromptList,
    isLoadingRecentsPromptList,
    projectInfos,
    name,
    isLoadingProjectInfo,
    toogleModal,
    setName,
    handleEditName,
    setProjectID,
    handleToogleModal,
    handleDeleteProject,
  }), [
    listOfPrompts,
    listOfRecentsPrompts,
    isLoadingPromptList,
    isLoadingRecentsPromptList,
    projectInfos,
    name,
    isLoadingProjectInfo,
    toogleModal,
    setName,
    handleEditName,
    setProjectID,
    handleToogleModal,
    handleDeleteProject,
  ]);

  return (
    <ManageProjectContext.Provider value={contextValues}>
      {children}
    </ManageProjectContext.Provider>
  );
}

export { ManageProjectContext, ManageProjectProvider };
