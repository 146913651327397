import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ManageProjectContext } from '../../manageProjects.context';
import { AuthContext } from '../../../../../../context/AuthContext';
import Loader from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import IconClipboard from '../../../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../../../styles/logos/check_icon.svg';
import IconTrash from '../../../../../../styles/logos/icon_trash_white.svg';
import IconEdit from '../../../../../../styles/logos/icon_edit_pencil.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, EntranceContainer, ResponseContainer, ResponseCardsContainer, Card,
} from './style';

export function InfosCopyCutPaidAds() {
  const translate = Translate();
  const { id } = useParams();
  const {
    setProjectID, projectInfos, isLoadingProjectInfo, toogleModal, handleToogleModal, handleDeleteProject,
  } = useContext(ManageProjectContext);
  const { userInfo } = useContext(AuthContext);

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy1: false,
    foundationalCopy2: false,
    foundationalCopy3: false,
    foundationalCopy4: false,
  });

  // CHANGE ID FROM Context function
  useEffect(() => {
    setProjectID(id);
  }, [id]);

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function translateToneOfVoice(toneOfVoice) {
    let toneOfVoiceTranslated = '';

    switch (toneOfVoice) {
      case 'saleMode':
        toneOfVoiceTranslated = translate.toneOfVoiceSaleMode;
        break;

      case 'profissional':
        toneOfVoiceTranslated = translate.toneOfVoiceProfissional;
        break;

      case 'expert':
        toneOfVoiceTranslated = translate.toneOfVoiceExpert;
        break;

      case 'businessAndTrust':
        toneOfVoiceTranslated = translate.toneOfVoiceBusinessAndTrust;
        break;

      case 'sensitiveCommunication':
        toneOfVoiceTranslated = translate.toneOfVoiceSensitiveCommunication;
        break;

      case 'friendly':
        toneOfVoiceTranslated = translate.toneOfVoiceFriendly;
        break;

      default:
        toneOfVoiceTranslated = toneOfVoice.charAt(0).toUpperCase() + toneOfVoice.slice(1);
        break;
    }

    return toneOfVoiceTranslated;
  }

  return (
    <>
      {isLoadingProjectInfo && <Loader loading={isLoadingProjectInfo} />}
      {!isLoadingProjectInfo && (
        <Container>
          <TitleContainer>
            <div className="title-infos">
              <div className="title-area">
                <h2>{projectInfos?.name}</h2>
                <Link to={`/client/ailab/manage-projects/edit-name/${projectInfos.id}?name=${projectInfos.name}`}>
                  <img src={IconEdit} alt="edit" />
                </Link>
              </div>
              <p>{translate.doneBy}<span>{projectInfos?.firstName} {projectInfos?.lastName} | {projectInfos?.email}</span></p>
            </div>
            <div className="title-actions">
              <Button
                color="red"
                onClick={() => handleToogleModal()}
              >
                <img
                  src={IconTrash}
                  alt="Delete Prompt"
                />
              </Button>
            </div>
          </TitleContainer>

          <EntranceContainer>
            <h3>Inputs</h3>
            <div className="info-group-d-flex">
              <div className="info-group">
                {/* <label>{translate.labelBusinessModel}</label> */}
                <label>Localização</label>
                <div>
                  {projectInfos?.continent}{' | '}
                  {projectInfos?.country}{' | '}
                  {projectInfos?.state}
                </div>
              </div>
              <div className="info-group">
                <label>{translate.labelLanguage}</label>
                <div>{projectInfos?.language ? projectInfos?.language : '---'}</div>
              </div>
              <div className="info-group">
                <label>{translate.labelToneOfVoice}</label>
                <div>{projectInfos?.toneOfVoice ? translateToneOfVoice(projectInfos?.toneOfVoice) : '---'}</div>
              </div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelSite} </label>
              <div>{projectInfos?.siteData}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelAvatar}</label>
              <div>{projectInfos?.avatar}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelNiche}</label>
              <div>{projectInfos?.niche}</div>
            </div>
          </EntranceContainer>

          <ResponseContainer>
            <h3>Outputs</h3>
            <div className="container-painels">
              <div className="container-layers">
                <h4>{translate.group1Title}</h4>

                {(!projectInfos?.foundationalCopy1
                  && !projectInfos?.foundationalCopy2
                  && !projectInfos?.foundationalCopy3
                  && !projectInfos?.foundationalCopy4
                  && !projectInfos?.vslDirectionToPleasure
                  && !projectInfos?.vslWithdrawalFromPain
                  && !projectInfos?.vslImpactfulStatistics
                  && !projectInfos?.vslPhysicalGuidance
                  && !projectInfos?.vslValueStatement
                  && !projectInfos?.vslTestimonial
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {/* TODO: Melhorar isso aqui depois */}
                  {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                  <>
                    {(projectInfos?.foundationalCopy1 && projectInfos?.foundationalCopy1.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy1}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy1', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy1}
                      </div>
                    </Card>
                    )}

                    {(projectInfos?.foundationalCopy2 && projectInfos?.foundationalCopy2.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy2}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy2', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy2}
                      </div>
                    </Card>
                    )}

                    {(projectInfos?.foundationalCopy3 && projectInfos?.foundationalCopy3.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy3}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy3', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy3}
                      </div>
                    </Card>
                    )}

                    {(projectInfos?.foundationalCopy4 && projectInfos?.foundationalCopy4.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy4}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy4', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy4}
                      </div>
                    </Card>
                    )}
                  </>
                  )}

                  {(projectInfos?.vslDirectionToPleasure && projectInfos?.vslDirectionToPleasure.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLDirectionToPleasure}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslDirectionToPleasure}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslWithdrawalFromPain && projectInfos?.vslWithdrawalFromPain.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLWithdrawalFromPain}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslWithdrawalFromPain}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslImpactfulStatistics && projectInfos?.vslImpactfulStatistics.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLImpactfulStatistics}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslImpactfulStatistics}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslPhysicalGuidance && projectInfos?.vslPhysicalGuidance.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLPhysicalGuidance}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslPhysicalGuidance}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslValueStatement && projectInfos?.vslValueStatement.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLValueStatement}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslValueStatement}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslTestimonial && projectInfos?.vslTestimonial.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleCardVSLTestimonial}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslTestimonial}
                    </div>
                  </Card>
                  )}

                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.group2Title}</h4>

                {(!projectInfos?.instagramPrimaryGoal
                  && !projectInfos?.instagramPrimaryComplaint
                  && !projectInfos?.instagramSecondGoal
                  && !projectInfos?.instagramSecondComplaint
                  && !projectInfos?.instagramPromisses
                  && !projectInfos?.instagramBenefits
                  && !projectInfos?.instagramObjections
                  && !projectInfos?.instagramConfusions
                  && !projectInfos?.instagramUltimateFear
                  && !projectInfos?.instagramFalseSolution
                  && !projectInfos?.instagramMistakenBeliefs
                  && !projectInfos?.instagramExpensiveAlternatives
                  && !projectInfos?.instagramBusinessPurpose
                  && !projectInfos?.instagramCaseStudies
                  && !projectInfos?.instagramPersonalValues
                  && !projectInfos?.instagramPersonalPrinciples
                  && !projectInfos?.instagramBusinessBeliefs
                  && !projectInfos?.instagramBusinessThesis
                  && !projectInfos?.instagramBusinessMovement
                  && !projectInfos?.instagramBusinessFairCause
                  && !projectInfos?.instagramMarketingUniqueMechanism
                  && !projectInfos?.instagramFounderPositioning
                  && !projectInfos?.instagramBrandPositioning
                  && !projectInfos?.instagramIrresistibleOffer
                  && !projectInfos?.instagramProductPositioning
                  && !projectInfos?.instagramProductPositioningName
                  && !projectInfos?.instagramProductPositioningBenefits
                  && !projectInfos?.instagramProductPositioningFeatures
                  && !projectInfos?.instagramProductPositioningPrice
                  && !projectInfos?.instagramProductPositioningDescription
                  && !projectInfos?.instagramMinimumViableProduct
                  && !projectInfos?.instagramMarketProblem
                  && !projectInfos?.instagramMarketDesire
                  && !projectInfos?.instagramMarketNeed
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.instagramPrimaryGoal && projectInfos?.instagramPrimaryGoal.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPrimaryGoal}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPrimaryComplaint && projectInfos?.instagramPrimaryComplaint.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPrimaryComplaint}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramSecondGoal && projectInfos?.instagramSecondGoal.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramSecondGoal}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramSecondComplaint && projectInfos?.instagramSecondComplaint.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramSecondComplaint}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPromisses && projectInfos?.instagramPromisses.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #5</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPromisses}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBenefits && projectInfos?.instagramBenefits.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #6</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBenefits}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramObjections && projectInfos?.instagramObjections.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #7</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramObjections}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramConfusions && projectInfos?.instagramConfusions.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #8</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramConfusions}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramUltimateFear && projectInfos?.instagramUltimateFear.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #9</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramUltimateFear}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramFalseSolution && projectInfos?.instagramFalseSolution.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #10</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramFalseSolution}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMistakenBeliefs && projectInfos?.instagramMistakenBeliefs.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #11</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramMistakenBeliefs}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramExpensiveAlternatives && projectInfos?.instagramExpensiveAlternatives.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #12</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramExpensiveAlternatives}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBusinessPurpose && projectInfos?.instagramExpensiveAlternatives.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #13</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramExpensiveAlternatives}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramCaseStudies && projectInfos?.instagramCaseStudies.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #14</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramCaseStudies}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPersonalValues && projectInfos?.instagramPersonalValues.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #15</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPersonalValues}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPersonalPrinciples && projectInfos?.instagramPersonalPrinciples.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #16</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPersonalPrinciples}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBusinessBeliefs && projectInfos?.instagramBusinessBeliefs.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #17</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBusinessBeliefs}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBusinessThesis && projectInfos?.instagramBusinessThesis.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #18</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBusinessThesis}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBusinessMovement && projectInfos?.instagramBusinessMovement.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #19</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBusinessMovement}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBusinessFairCause && projectInfos?.instagramBusinessFairCause.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #20</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBusinessFairCause}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMarketingUniqueMechanism && projectInfos?.instagramBusinessFairCause.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #21</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBusinessFairCause}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramFounderPositioning && projectInfos?.instagramFounderPositioning.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #22</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramFounderPositioning}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramBrandPositioning && projectInfos?.instagramBrandPositioning.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #23</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramBrandPositioning}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramIrresistibleOffer && projectInfos?.instagramIrresistibleOffer.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #24</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramIrresistibleOffer}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioning && projectInfos?.instagramProductPositioning.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #25</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioning}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioningName && projectInfos?.instagramProductPositioningName.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #26</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioningName}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioningBenefits && projectInfos?.instagramProductPositioningBenefits.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #27</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioningBenefits}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioningFeatures && projectInfos?.instagramProductPositioningFeatures.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #28</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioningFeatures}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioningPrice && projectInfos?.instagramProductPositioningPrice.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #29</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioningPrice}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramProductPositioningDescription && projectInfos?.instagramProductPositioningDescription.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #30</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramProductPositioningDescription}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMinimumViableProduct && projectInfos?.instagramMinimumViableProduct.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #31</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramMinimumViableProduct}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMarketProblem && projectInfos?.instagramMarketProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #32</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramMarketProblem}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMarketDesire && projectInfos?.instagramMarketDesire.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #33</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramMarketDesire}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramMarketNeed && projectInfos?.instagramMarketNeed.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #34</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramMarketNeed}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

            </div>
          </ResponseContainer>
        </Container>
      )}

      <Modal
        danger
        visible={toogleModal}
        title={translate.modalTitle}
        btnMessage={translate.modalBtn}
        onAction={() => handleDeleteProject()}
        onClose={() => handleToogleModal()}
      >
        {projectInfos?.name}
      </Modal>
    </>

  );
}
