export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    title: 'CopyCut AI - Paid Ads',
    messageProccessInfoNoSec: 'We are organizing your information. Please do not leave this screen.',

    card1Title: 'VSLs - Complete Solutions for B2C/SMB and Enterprise',
    card1Description: 'Create professional scripts for high-conversion VSLs, including: VSL-Direction to Pleasure to awaken desire, VSL-Pain Avoidance to solve problems, VSL-Impactful Statistics for credibility with data, VSL-Physical Guidance for practical demonstrations, VSL-Value Statement to highlight unique benefits, and VSL-Testimonial for convincing social proof.',

    card2Title: 'Instagram Posts - Content Strategies for Engagement and Conversion',
    card2Description: 'Receive 34 professional Instagram post texts aligned with your brand positioning, including ideal narratives based on concentric intelligence.',

    borderTitleGeo: 'Location',
    borderTitleLanguage: 'Language',

    questionModel: 'Use templates from:',
    questionLanguage: 'What\'s the language of the answers?',
    questionToneOfVoice: 'Tone of Voice',

    labelContext: 'Context *',
    spanContext: '(Site Data / Landing Page Data / Competitors Data / Emails Data / Ecommerce / SKU)',
    placeholderContext: 'Enter your site\'s information',

    labelCheckBox: 'Target Audience and Niche Customization',
    infoCheckBox: 'CopyCut will automatically create a suitable target audience and niche based on your context.',

    labelAvatar: 'Target Audience',
    spanAvatar: '(Ideal Customer / Persona / ICP / Audience / AVATAR)',
    placeholderAvatar: 'Describe the necessary information about your target audience (Clicking on the \'Explore templates\' button can help you with the ideal choice)',

    labelNiche: 'Niche',
    spanNiche: '(Niche or mass market)',
    placeholderNiche: 'Enter the market niche (Clicking on the \'Explore templates\' button can help you with the ideal choice)',

    optionSellingMode: 'Selling Mode',
    optionFriendly: 'Friendly',
    optionProfessional: 'Professional',
    optionExpert: 'Expert',
    optionBusinessAndTrust: 'Business and Trust',
    optionSocialMedia: 'Social Media',
    optionSensitiveCommunication: 'Sensitive Communication',

    titleVSLDirectionToPleasure: 'VSL Direction To Pleasure',
    titleVSLWithdrawalFromPain: 'VSL Withdrawal From Pain',
    titleVSLImpactfulStatistics: 'VSL Impactful Statistics',
    titleVSLPhysicalGuidance: 'VSL Physical Guidance',
    titleVSLValueStatement: 'VSL Value Statement',
    titleVSLTestimonial: 'VSL Testimonial',

    btnAddAll: 'Add all',
    btnDeleteAll: 'Delete all',
    btnExplore: 'Explore templates',

    estimatedTime: 'Estimated time',
    btn: 'Create',

    important: 'Important',
    warningMessage: 'Paste the content text directly. CopyCut does not process URLs or links - plain text only.',

    msgCard: 'Create professional VSL scripts segmented for: VSL-SMB for small businesses, VSL-Enterprise for corporations, VSL-Prismatic for multiple decision-makers, and VSL-Ecommerce for online sales. All scripts ensure privacy policy compliance and maximum conversion.',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        title: 'CopyCut AI - Paid Ads',
        messageProccessInfoNoSec: 'Estamos organizando suas informações. Por favor, não saia desta tela.',

        btnOne: 'Criar VSL Copy - B2C/SMB e Enterprise',

        card1Title: 'VSLs - Soluções Completas para B2C/SMB e Enterprise',
        card1Description: 'Crie scripts profissionais para VSLs de alta conversão, incluindo: VSL-Direção ao Prazer para despertar desejo, VSL-Afastamento da Dor para resolver problemas, VSL-Estatísticas Impactantes para credibilidade com dados, VSL-Orientação Física para demonstrações práticas, VSL-Declaração de Valor para destacar benefícios únicos, e VSL-Depoimento para prova social convincente.',

        card2Title: 'Instagram Posts - Estratégias de Conteúdo para Engajamento e Conversão',
        card2Description: 'Receba 34 textos profissionais para posts do Instagram alinhados com o posicionamento da sua marca, incluindo narrativas ideais conforme a inteligência concêntrica.',

        borderTitleGeo: 'Localização',
        borderTitleLanguage: 'Linguagem',

        questionModel: 'Usar templates de:',
        questionLanguage: 'Qual o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',

        labelContext: 'Contexto *',
        spanContext: '(Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        placeholderContext: 'Insira as informações do seu site/contexto',

        labelCheckBox: 'Customizar Público-Alvo e Nicho',
        infoCheckBox: 'CopyCut criará automaticamente um público-alvo e nicho adequado com base no seu contexto.',

        labelAvatar: 'Público-Alvo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        placeholderAvatar: 'Descreva as informações necessárias do seu público-alvo (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho ou mercado de massas)',
        placeholderNiche: 'Informe o nicho de mercado (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        optionSellingMode: 'Modo Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        titleVSLDirectionToPleasure: 'VSL Direção ao Prazer',
        titleVSLWithdrawalFromPain: 'VSL Afastamento da Dor',
        titleVSLImpactfulStatistics: 'VSL Estatísticas Impactantes',
        titleVSLPhysicalGuidance: 'VSL Orientação Física',
        titleVSLValueStatement: 'VSL Declaração de Valor',
        titleVSLTestimonial: 'VSL Depoimento',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Deletar todos',
        btnExplore: 'Explorar templates',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',

        important: 'Importante',
        warningMessage: 'Cole o texto do conteúdo diretamente. O CopyCut não processa URLs ou links - apenas texto simples.',

        msgCard: 'Crie scripts profissionais para VSLs segmentados, incluindo: VSL-PME para pequenas empresas, VSL-Enterprise para corporações, VSL-Prismática para múltiplos decisores, e VSL-Ecommerce para vendas online. Todos os roteiros garantem conformidade com políticas de privacidade e máxima conversão.',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        title: 'CopyCut AI - Paid Ads',
        messageProccessInfoNoSec: 'Estamos a organizar as suas informações. Por favor, não saia deste ecrã.',

        btnOne: 'Criar VSL Copy - B2C/SMB e Enterprise',

        card1Title: 'VSLs - Soluções Completas para B2C/SMB e Enterprise',
        card1Description: 'Crie scripts profissionais para VSLs de alta conversão, incluindo: VSL-Direção ao Prazer para despertar desejo, VSL-Afastamento da Dor para resolver problemas, VSL-Estatísticas Impactantes para credibilidade com dados, VSL-Orientação Física para demonstrações práticas, VSL-Declaração de Valor para destacar benefícios únicos, e VSL-Depoimento para prova social convincente.',

        card2Title: 'Instagram Posts - Estratégias de Conteúdo para Envolvimento e Conversão',
        card2Description: 'Receba 34 textos profissionais para publicações no Instagram alinhados com o posicionamento da sua marca, incluindo narrativas ideais conforme a inteligência concêntrica.',

        borderTitleGeo: 'Localização',
        borderTitleLanguage: 'Linguagem',

        questionModel: 'Usar modelos de:',
        questionLanguage: 'Qual é o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',

        labelContext: 'Contexto *',
        spanContext: '(Dados do site / Landing Page / Concorrentes / Dados de e-mail / Ecommerce / SKU)',
        placeholderContext: 'Insira as informações do seu site',

        labelCheckBox: 'Personalizar Público-Alvo e Nicho',
        infoCheckBox: 'CopyCut irá criar automaticamente um público-alvo e nicho adequado com base no seu contexto.',

        labelAvatar: 'Público-Alvo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        placeholderAvatar: 'Descreva as informações necessárias do seu público-alvo (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho ou mercado de massas)',
        placeholderNiche: 'Informe o nicho de mercado (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        optionSellingMode: 'Modo de Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        titleVSLDirectionToPleasure: 'VSL Direção ao Prazer',
        titleVSLWithdrawalFromPain: 'VSL Afastamento da Dor',
        titleVSLImpactfulStatistics: 'VSL Estatísticas Impactantes',
        titleVSLPhysicalGuidance: 'VSL Orientação Física',
        titleVSLValueStatement: 'VSL Declaração de Valor',
        titleVSLTestimonial: 'VSL Testemunho',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Apagar todos',
        btnExplore: 'Explorar modelos',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',

        important: 'Importante',
        warningMessage: 'Cole o texto do conteúdo diretamente. O CopyCut não processa URLs ou links - apenas texto simples.',

        msgCard: 'Crie guiões profissionais para VSLs segmentados, incluindo: VSL-PME para pequenas empresas, VSL-Enterprise para empresas, VSL-Prismática para múltiplos decisores, e VSL-Ecommerce para vendas online. Todos os guiões garantem conformidade com políticas de privacidade e máxima conversão.',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        title: 'CopyCut AI - Paid Ads',
        messageProccessInfoNoSec: 'Estamos organizando tu información. Por favor, no abandones esta pantalla.',

        btnOne: 'Crear VSL Copy - B2C/SMB y Enterprise',

        card1Title: 'VSLs - Soluciones Completas para B2C/SMB y Enterprise',
        card1Description: 'Crea guiones profesionales para VSLs de alta conversión, incluyendo: VSL-Dirección al Placer para despertar deseo, VSL-Alejamiento del Dolor para resolver problemas, VSL-Estadísticas Impactantes para credibilidad con datos, VSL-Orientación Física para demostraciones prácticas, VSL-Declaración de Valor para destacar beneficios únicos, y VSL-Testimonio para prueba social convincente.',

        card2Title: 'Instagram Posts - Estrategias de Contenido para Engagement y Conversión',
        card2Description: 'Reciba 34 textos profesionales para publicaciones de Instagram alineados con el posicionamiento de su marca, incluyendo narrativas ideales según la inteligencia concéntrica.',

        borderTitleGeo: 'Ubicación',
        borderTitleLanguage: 'Lenguaje',

        questionModel: 'Usar plantillas de:',
        questionLanguage: '¿Cuál es el idioma de las respuestas?',
        questionToneOfVoice: 'Tono de Voz',

        labelContext: 'Contexto *',
        spanContext: '¿(Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico / Ecommerce / SKU)',
        placeholderContext: 'Ingresa la información de tu sitio web',

        labelCheckBox: 'Personalizar Público Objetivo y Nicho',
        infoCheckBox: 'CopyCut creará automáticamente un público objetivo y nicho adecuado según tu contexto.',

        labelAvatar: 'Público Objetivo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiencia / AVATAR)',
        placeholderAvatar: 'Describe la información necesaria de tu público objetivo (Hacer clic en el botón \'Explorar Plantillas\' puede ayudarte en la elección ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho o mercado masivo)',
        placeholderNiche: 'Indique el nicho de mercado (Hacer clic en el botón \'Explorar plantillas\' puede ayudarte en la elección ideal)',

        optionSellingMode: 'Modo Venta',
        optionFriendly: 'Amigable',
        optionProfessional: 'Profesional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negocios y Confianza',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicación Sensible',

        titleVSLDirectionToPleasure: 'VSL Dirección al Placer',
        titleVSLWithdrawalFromPain: 'VSL Alejamiento del Dolor',
        titleVSLImpactfulStatistics: 'VSL Estadísticas Impactantes',
        titleVSLPhysicalGuidance: 'VSL Orientación Física',
        titleVSLValueStatement: 'VSL Declaración de Valor',
        titleVSLTestimonial: 'VSL Testimonio',

        btnAddAll: 'Agregar todos',
        btnDeleteAll: 'Eliminar todos',
        btnExplore: 'Explorar plantillas',

        estimatedTime: 'Tiempo estimado',
        btn: 'Crear',

        important: 'Importante',
        warningMessage: 'Pegue el texto del contenido directamente. CopyCut no procesa URLs o enlaces - solo texto sin formato.',

        msgCard: 'Cree guiones profesionales para VSL segmentados, incluyendo: VSL-PYME para pequeñas empresas, VSL-Enterprise para corporaciones, VSL-Prismático para múltiples decisores, y VSL-Ecommerce para ventas online. Todos los guiones garantizan el cumplimiento de políticas de privacidad y máxima conversión.',
        language,
      };

    default:
      return terms;
  }
}
