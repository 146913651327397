export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    mainTitle: 'What would you like to create now?',

    card1Title: 'LinkedIn Posts',
    card1Description: 'Create strategic posts with high sharing potential, addressing: counterintuitive leadership insights, data-driven market predictions, debunking corporate world beliefs, exclusive management frameworks, and thought-provoking questions that stimulate debate in the comments.',

    card2Title: 'Instagram Posts',
    card2Description: 'Create content that generates immediate engagement, combining: Powerful quotes from innovative leaders, educational carousels with practical productivity tips, interactive stories with polls about professional dilemmas, and strategic captions that encourage sharing in DMs. Relevant hashtags and clear calls-to-action to maximize reach and stimulate authentic interactions with your audience.',

    card3Title: 'Facebook Posts',
    card3Description: 'Create strategic Facebook posts with high engagement, focusing on: In-depth analyses of market trends with shareable graphs, debates on ethical business dilemmas, practical productivity guides with actionable steps, and reflective questions that encourage meaningful conversations in the comments.',

    card4Title: 'TikTok Posts',
    card4Description: 'Create viral short-form content with: Professional tips in quick and direct format, trends adapted to corporate context, visual storytelling about overcome challenges, creative transitions to reveal business insights, and powerful hooks in the first 3 seconds. End with calls-to-action that encourage comments and shares.',

    card5Title: 'Email Marketing',
    card5Description: 'Create email campaigns with high open and conversion rates, including: Intriguing subject lines that spark curiosity, personalized content that solves specific audience problems, strategic storytelling that connects emotionally, clear and persuasive CTAs, and responsive design with well-segmented information blocks. Structure automated sequences that nurture leads and progressively establish authority.',

    titleAssetsCopy: 'Assets Copy - Marketing and Sales',
    msgAssetsCopy: 'Generate persuasive copy and content for your marketing campaigns and sales materials. Get impactful headlines, strategic FAQs, presentation copy, optimized LinkedIn messages, outbound emails, sales conversation scripts, and success stories.',

    titleEngineValidation: 'Engine Validation - Sales Funnel Optimizer (Order Bump/Upsell)',
    msgEngineValidation: 'Develop efficient cross-selling and upselling strategies with optimized messaging. Receive complementary offer templates and upgrade models designed to naturally and persuasively increase your average sales value.',

    titleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    msgScaleCopy: 'Generate optimized content for paid campaigns, organic communication, and videos with different narratives. Get persuasive copy for Google Ads, Meta Ads, LinkedIn Ads, email, WhatsApp, and more, always aligned with each platform\'s best practices.',

    titleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',
    msgVideoAds: 'Create persuasive scripts for various video ad formats, from evolutionary narratives to disruptive approaches. Generate optimized content for Video Ads with different strategic angles, including transformational storytelling, myth-busting, success stories, and exclusive hacks, adapted for both B2C and Enterprise.',

    titleEmailMarketing: 'Email Marketing - High-Converting Persuasive Scripts',
    msgEmailMarketing: 'Get ready-to-use templates for different sales objectives, from authority building to presenting transformative solutions, while maintaining high engagement and conversion rates.',

    estimatedTime: 'Estimated time',
    btn: 'Create',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        mainTitle: 'O que deseja criar agora?',

        card1Title: 'LinkedIn Posts',
        card1Description: 'Crie posts estratégicos com alto potencial de compartilhamento, abordando: insights contraintuitivos sobre liderança, previsões de mercado baseadas em dados, desmistificação de crenças do mundo corporativo, frameworks exclusivos de gestão e perguntas provocativas que estimulam debate nos comentários.',

        card2Title: 'Instagram Posts',
        card2Description: 'Crie conteúdo que gere engajamento imediato, combinando: Citações poderosas de líderes inovadores, carrosséis educativos com dicas práticas de produtividade, stories interativos com enquetes sobre dilemas profissionais e legendas estratégicas que incentivam compartilhamentos nos DMs. Hashtags relevantes e calls-to-action claros para maximizar o alcance e estimular interações autênticas com sua audiência.',

        card3Title: 'Facebook Posts',
        card3Description: 'Crie posts estratégicos para Facebook com alto engajamento, focando em: Análises aprofundadas de tendências de mercado com gráficos compartilháveis, debates sobre dilemas éticos nos negócios, guias práticos de produtividade com etapas acionáveis e perguntas reflexivas que incentivam conversas significativas nos comentários.',

        card4Title: 'TikTok Posts',
        card4Description: 'Crie conteúdo viral de curta duração com: Dicas profissionais em formato rápido e direto, trends adaptadas ao contexto corporativo, storytelling visual sobre desafios superados, transições criativas para revelar insights de negócios e hooks poderosos nos primeiros 3 segundos. Finalize com calls-to-action que incentivem comentários e compartilhamentos.',

        card5Title: 'Email Marketing',
        card5Description: 'Crie campanhas de email com alta taxa de abertura e conversão, incluindo: Linhas de assunto intrigantes que despertam curiosidade, conteúdo personalizado que resolve problemas específicos do público, storytelling estratégico que conecta emocionalmente, CTAs claros e persuasivos, e design responsivo com blocos de informação bem segmentados. Estruture sequências automatizadas que nutrem leads e estabelecem autoridade progressivamente.',

        titleAssetsCopy: 'Assets Copy - Marketing e Vendas',
        msgAssetsCopy: 'Gere textos e conteúdos persuasivos para suas campanhas de marketing e materiais de vendas. Receba headlines impactantes, FAQs estratégicas, textos para apresentações, mensagens otimizadas para LinkedIn, e-mails outbound, roteiros de conversas comerciais e cases de sucesso.',

        titleEngineValidation: 'Engine Validation - Otimizador de Funil de Vendas (Order Bump/Upsell)',
        msgEngineValidation: 'Desenvolva estratégias eficientes de venda cruzada e venda incremental com mensagens otimizadas. Receba modelos de ofertas complementares e propostas de upgrade, projetados para aumentar o valor médio das suas vendas de forma natural e persuasiva.',

        titleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        msgScaleCopy: 'Gere conteúdo otimizado para campanhas pagas, comunicação orgânica e vídeos com diferentes narrativas. Obtenha textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, e-mails, WhatsApp e muito mais, sempre alinhados às melhores práticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',
        msgVideoAds: 'Crie roteiros persuasivos para diferentes formatos de vídeos publicitários, desde narrativas evolutivas até abordagens disruptivas. Gere conteúdo otimizado para Video Ads com diferentes angles estratégicos, incluindo storytelling transformacional, destruição de mitos, casos de sucesso e hacks exclusivos, adaptados tanto para B2C quanto para Enterprise.',

        titleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',
        msgEmailMarketing: 'Receba modelos prontos para diferentes objetivos de venda, desde a construção de autoridade até a apresentação de soluções transformadoras, sempre mantendo alto engajamento e taxas de conversão.',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        mainTitle: 'O que pretende criar agora?',

        card1Title: 'LinkedIn Posts',
        card1Description: 'Crie publicações estratégicas com elevado potencial de partilha, abordando: perspetivas contraintuitivas sobre liderança, previsões de mercado baseadas em dados, desmistificação de crenças do mundo empresarial, modelos exclusivos de gestão e questões provocadoras que estimulam o debate nos comentários.',

        card2Title: 'Instagram Posts',
        card2Description: 'Crie conteúdo que gere envolvimento imediato, combinando: Citações poderosas de líderes inovadores, carrosséis educativos com dicas práticas de produtividade, stories interativos com sondagens sobre dilemas profissionais e legendas estratégicas que incentivam partilhas nas mensagens diretas. Hashtags relevantes e apelos à ação claros para maximizar o alcance e estimular interações autênticas com o seu público.',

        card3Title: 'Facebook Posts',
        card3Description: 'Crie publicações estratégicas para Facebook com elevado envolvimento, focando em: Análises aprofundadas de tendências de mercado com gráficos partilháveis, debates sobre dilemas éticos nos negócios, guias práticos de produtividade com etapas acionáveis e perguntas reflexivas que incentivam conversas significativas nos comentários.',

        card4Title: 'TikTok Posts',
        card4Description: 'Crie conteúdo viral de curta duração com: Dicas profissionais em formato rápido e direto, tendências adaptadas ao contexto empresarial, narrativa visual sobre desafios superados, transições criativas para revelar insights de negócios e ganchos poderosos nos primeiros 3 segundos. Finalize com apelos à ação que incentivem comentários e partilhas.',

        card5Title: 'Email Marketing',
        card5Description: 'Crie campanhas de email com alta taxa de abertura e conversão, incluindo: Linhas de assunto intrigantes que despertam curiosidade, conteúdo personalizado que resolve problemas específicos do público, narrativa estratégica que conecta emocionalmente, CTAs claros e persuasivos, e design responsivo com blocos de informação bem segmentados. Estruture sequências automatizadas que nutrem leads e estabelecem autoridade progressivamente.',

        titleAssetsCopy: 'Assets Copy - Marketing e Vendas',
        msgAssetsCopy: 'Gere textos e conteúdos persuasivos para as suas campanhas de marketing e materiais de vendas. Receba títulos impactantes, FAQs estratégicas, textos para apresentações, mensagens otimizadas para LinkedIn, e-mails outbound, guiões de conversas comerciais e casos de sucesso.',

        titleEngineValidation: 'Engine Validation - Otimizador de Funil de Vendas (Order Bump/Upsell)',
        msgEngineValidation: 'Desenvolva estratégias eficientes de venda cruzada e venda incremental com mensagens otimizadas. Receba modelos de ofertas complementares e propostas de atualização, projetados para aumentar o valor médio das suas vendas de forma natural e persuasiva.',

        titleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        msgScaleCopy: 'Gere conteúdo otimizado para campanhas pagas, comunicação orgânica e vídeos com diferentes narrativas. Obtenha textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, correio eletrónico, WhatsApp e muito mais, sempre alinhados às melhores práticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',
        msgVideoAds: 'Crie guiões persuasivos para diferentes formatos de vídeos publicitários, desde narrativas evolutivas até abordagens disruptivas. Gere conteúdo otimizado para Video Ads com diferentes ângulos estratégicos, incluindo narrativas transformacionais, desmistificação, casos de sucesso e dicas exclusivas, adaptados tanto para B2C como para Enterprise.',

        titleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',
        msgEmailMarketing: 'Receba modelos prontos para diferentes objetivos de venda, desde a construção de autoridade até à apresentação de soluções transformadoras, mantendo sempre um elevado envolvimento e taxas de conversão.',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        mainTitle: '¿Qué desea crear ahora?',

        card1Title: 'LinkedIn Posts',
        card1Description: 'Cree publicaciones estratégicas con alto potencial de compartición, abordando: perspectivas contraintuitivas sobre liderazgo, predicciones de mercado basadas en datos, desmitificación de creencias del mundo corporativo, marcos exclusivos de gestión y preguntas provocativas que estimulen el debate en los comentarios.',

        card2Title: 'Instagram Posts',
        card2Description: 'Crea contenido que genere engagement inmediato, combinando: Citas poderosas de líderes innovadores, carruseles educativos con consejos prácticos de productividad, stories interactivas con encuestas sobre dilemas profesionales y pies de foto estratégicos que fomenten compartir en mensajes directos. Hashtags relevantes y llamadas a la acción claras para maximizar el alcance y estimular interacciones auténticas con tu audiencia.',

        card3Title: 'Facebook Posts',
        card3Description: 'Crea publicaciones estratégicas para Facebook con alto engagement, centrándote en: Análisis profundos de tendencias de mercado con gráficos compartibles, debates sobre dilemas éticos en los negocios, guías prácticas de productividad con pasos accionables y preguntas reflexivas que fomenten conversaciones significativas en los comentarios.',

        card4Title: 'TikTok Posts',
        card4Description: 'Crea contenido viral de corta duración con: Consejos profesionales en formato rápido y directo, tendencias adaptadas al contexto corporativo, storytelling visual sobre desafíos superados, transiciones creativas para revelar insights de negocios y ganchos poderosos en los primeros 3 segundos. Finaliza con llamadas a la acción que fomenten comentarios y compartidos.',

        card5Title: 'Email Marketing',
        card5Description: 'Crea campañas de email con alta tasa de apertura y conversión, incluyendo: Líneas de asunto intrigantes que despiertan curiosidad, contenido personalizado que resuelve problemas específicos del público, storytelling estratégico que conecta emocionalmente, CTAs claros y persuasivos, y diseño responsivo con bloques de información bien segmentados. Estructura secuencias automatizadas que nutren leads y establecen autoridad progresivamente.',

        titleAssetsCopy: 'Assets Copy - Marketing y Ventas',
        msgAssetsCopy: 'Genere textos y contenidos persuasivos para sus campañas de marketing y materiales de ventas. Reciba títulos impactantes, FAQs estratégicas, textos para presentaciones, mensajes optimizados para LinkedIn, correos outbound, guiones de conversaciones comerciales y casos de éxito.',

        titleEngineValidation: 'Engine Validation - Optimizador del Embudo de Ventas (Order Bump/Upsell)',
        msgEngineValidation: 'Desarrolle estrategias eficientes de venta cruzada y venta ascendente con mensajes optimizados. Reciba plantillas de ofertas complementarias y modelos de mejora, diseñados para aumentar el valor medio de sus ventas de forma natural y persuasiva.',

        titleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        msgScaleCopy: 'Genere contenido optimizado para campañas pagadas, comunicación orgánica y vídeos con diferentes narrativas. Obtenga textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, correo electrónico, WhatsApp y mucho más, siempre alineados con las mejores prácticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',
        msgVideoAds: 'Crea guiones persuasivos para diferentes formatos de videos publicitarios, desde narrativas evolutivas hasta enfoques disruptivos. Genera contenido optimizado para Video Ads con diferentes ángulos estratégicos, incluyendo storytelling transformacional, desmitificación, casos de éxito y trucos exclusivos, adaptados tanto para B2C como para Enterprise.',

        titleEmailMarketing: 'Email Marketing - Scripts Persuasivos de Alta Conversión',
        msgEmailMarketing: 'Reciba plantillas listas para diferentes objetivos de venta, desde la construcción de autoridad hasta la presentación de soluciones transformadoras, manteniendo siempre un alto compromiso y tasas de conversión.',

        estimatedTime: 'Tiempo estimado',
        btn: 'Crear',
        language,
      };

    default:
      return terms;
  }
}
