import styled, { css } from 'styled-components';

export const Card = styled.div`
  margin: 8px 0px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;

  img {
    width: ${({ size }) => (size === 'small' ? '13px' : '16px')};
  }

  .text {
    font-weight: 400;
    font-size: ${({ size }) => (size === 'small' ? '13px' : '16px')};
  }


  ${({ type }) => type === 'warning' && css`
    border-left: 7px solid ${({ theme }) => theme.yellow};
    background-color: #FCF6BA;
    .text {
      color: #A16207;
    }
  `}

  ${({ type }) => type === 'warning-dark' && css`
    border-left: 7px solid ${({ theme }) => theme.yellow};
    border-top: 1px solid ${({ theme }) => theme.gray2};
    border-right: 1px solid ${({ theme }) => theme.gray2};
    border-bottom: 1px solid ${({ theme }) => theme.gray2};
    background-color:${({ theme }) => theme.gray1};
    .text {
      color: ${({ theme }) => theme.white};
    }
  `}

  ${({ type }) => type === 'success' && css`
    border-left: 7px solid ${({ theme }) => theme.green};
    background-color: #E2FCF2;
    .text {
      color: #1C6C50;
    }
  `}

  ${({ type }) => type === 'success-dark' && css`
    border-left: 7px solid ${({ theme }) => theme.green};
    border-top: 1px solid ${({ theme }) => theme.gray2};
    border-right: 1px solid ${({ theme }) => theme.gray2};
    border-bottom: 1px solid ${({ theme }) => theme.gray2};
    background-color:${({ theme }) => theme.gray1};
    .text {
      color: ${({ theme }) => theme.white};
    }
  `}

  ${({ type }) => type === 'error' && css`
    border-left: 7px solid ${({ theme }) => theme.red};
    background-color: #FFE4D9;
    .text {
      color: #973207;
    }
  `}

  ${({ type }) => type === 'error-dark' && css`
    border-left: 7px solid ${({ theme }) => theme.red};
    border-top: 1px solid ${({ theme }) => theme.gray2};
    border-right: 1px solid ${({ theme }) => theme.gray2};
    border-bottom: 1px solid ${({ theme }) => theme.gray2};
    background-color:${({ theme }) => theme.gray1};
    .text {
      color: ${({ theme }) => theme.white};
    }
  `}

  ${({ type }) => type === 'default' && css`
    border-left: 7px solid ${({ theme }) => theme.blue};
    background-color: #C1E3E7;
    .text {
      color: #1A7179;
    }
  `}

  ${({ type }) => type === 'default-dark' && css`
    border-left: 7px solid ${({ theme }) => theme.blue};
    border-top: 1px solid ${({ theme }) => theme.gray2};
    border-right: 1px solid ${({ theme }) => theme.gray2};
    border-bottom: 1px solid ${({ theme }) => theme.gray2};
    background-color:${({ theme }) => theme.gray1};
    .text {
      color: ${({ theme }) => theme.white};
    }
  `}

`;
