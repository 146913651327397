export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    continent: 'Continent',
    country: 'Country',
    state: 'St./Prov.',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        continent: 'Continente',
        country: 'País',
        state: 'Est./Prov.',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        continent: 'Continente',
        country: 'País',
        state: 'Est./Dis.',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        continent: 'Continente',
        country: 'País',
        state: 'Est./Prov.',
        language,
      };

    default:
      return terms;
  }
}
