import styled from 'styled-components';

export const MultiSelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 0px 4px; */
    padding-bottom: 4px;

    img {
      width: 18px;
      cursor: pointer;
      margin-right: 4px;
    }

    img:hover {
      filter: invert(67%) sepia(99%) saturate(433%) hue-rotate(360deg) brightness(190%) contrast(109%);
    }
  }

  /* Botão Dropdown */
  .multiselect-btn {
      width: 100%;
      max-width: 400px;
      border: 1px solid ${({ theme }) => theme.grayLight};
      border-radius: 4px;
      text-align: left;
      cursor: pointer;
      background-color: transparent;
      font-family: inherit;
      font-size: 16px;
      padding: 8px 10px;
      max-height: 40px;

      /* Adicionando essas propriedades */
      white-space: nowrap;     /* Impede a quebra de linha */
      overflow: hidden;        /* Oculta o conteúdo que não cabe */
      text-overflow: ellipsis;
  }

  .search-input {
    border: none;
    border-bottom: 1px solid transparent;
    width: 100%;
  }

  .search-input:focus {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.grayLight};
    border-radius: 0px;
  }
`;

export const MultiSelectOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: fit-content;
  max-width: 150px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.grayMenu};
  border: 1px solid ${({ theme }) => theme.grayLight};
  z-index: 1;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  white-space: nowrap;

  label {
    display: block;
    padding: 6px 10px;
    cursor: pointer;
    font-family: inherit;
    font-size: 13px;
  }

  label:hover {
      background-color: ${({ theme }) => theme.grayDark};
  }

  label.selected {
      background-color: ${({ theme }) => theme.grayDark};
  }

  input[type="checkbox"] {
      margin-right: 10px;
  }

`;
