/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { MultiSelectContainer, MultiSelectOptions } from './style';
import IconClear from '../../styles/logos/icon_clear.svg';

export function MultiSelect({
  options, label, placeholder, onSetFilter, name, hasCleanFilter,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para armazenar o termo de busca
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Filtra as opções com base no termo de busca
  const filteredOptions = useMemo(() => options?.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase())), [options, searchTerm]);

  // Adiciona o escopo do evento de clique fora ao dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Adiciona o listener de evento para detectar cliques fora
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    onSetFilter((prevState) => ({ ...prevState, [name]: selectedOptions }));
  }, [selectedOptions]);

  // Aplica o foco no input de pesquisa quando o dropdown é aberto
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Atualiza o estado das opções selecionadas
  function handleCheckboxChange(e) {
    const { value, checked } = e.target;
    setSelectedOptions((prevSelected) => (checked ? [...prevSelected, value] : prevSelected.filter((option) => option !== value)));
  }

  // Atualiza o rótulo do botão com as opções selecionadas
  function getButtonLabel() {
    return selectedOptions.length > 0 ? selectedOptions.join(', ') : placeholder;
  }

  // Abre ou fecha a lista de opções
  function toggleOptions(e) {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  }

  // Atualiza o termo de busca conforme o usuário digita
  function handleSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  function clearOptions() {
    setSelectedOptions([]);
  }

  return (
    // id={idRef.current}
    <MultiSelectContainer ref={dropdownRef}>
      <div className="title-container">
        <span>{label}</span>
        <img onClick={clearOptions} src={IconClear} alt="Clear all" title="Clear all" />
      </div>
      <button type="button" className="multiselect-btn" onClick={toggleOptions}>
        {getButtonLabel()}
      </button>
      {isOpen && (
        <MultiSelectOptions $isOpen={isOpen}>
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          {filteredOptions?.length > 0 ? (
            filteredOptions?.map((option) => (
              <label key={`${option}_${Math.random()}`}>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={handleCheckboxChange}
                />
                {option}
              </label>
            ))
          ) : (
            <p>No options found</p>
          )}
        </MultiSelectOptions>
      )}
    </MultiSelectContainer>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSetFilter: PropTypes.node,
  name: PropTypes.string,
  hasCleanFilter: PropTypes.bool,
};
