export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    title: 'CopyCut AI - Assets',
    messageProccessInfoNoSec: 'We are organizing your information. Please do not leave this screen.',

    borderTitleLanguage: 'Language',

    labelContext: 'Context *',
    spanContext: '(Site Data / Landing Page Data / Competitors Data / Emails Data / Ecommerce / SKU)',
    placeholderContext: 'Enter your site\'s information',

    labelCheckBox: 'Target Audience and Niche Customization',
    infoCheckBox: 'CopyCut will automatically create a suitable target audience and niche based on your context.',

    labelAvatar: 'Target Audience',
    spanAvatar: '(Ideal Customer / Persona / ICP / Audience / AVATAR)',
    placeholderAvatar: 'Describe the necessary information about your target audience (Clicking on the \'Explore templates\' button can help you with the ideal choice)',

    labelNiche: 'Niche',
    spanNiche: '(Niche or mass market)',
    placeholderNiche: 'Enter the market niche (Clicking on the \'Explore templates\' button can help you with the ideal choice)',

    h4TitleVslCopy: 'VSLs - B2C/SMB e Enterprise',
    h4TitleVslPolicyFree: 'VSLs Policy Free - B2C/SMB and Enterprise',
    h4TitleAssets: 'Assets Copy - Marketing and Sales',
    h4TitleEngineValidation: 'Engine Validation - Order Bump and Upsell',
    h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    h4TitleGAKeywords: 'Google Ads - Keywords',
    h4TitleGAAnnouncementGroup: 'Google Ads - Ad Groups',
    h4TitleGAAnnouncement: 'Google Ads - Campaigns and Ad Groups',
    h4TitleGAEditorKeywords: 'Google Ads Editor (CSV) - Keywords',
    h4TitleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Ad Groups',
    h4TitleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campaigns and Ad Groups',
    h4TitleGAEditorInstructions: 'Google Ads Editor (CSV) - Instructions',
    h4TitleMetaAds: 'Meta Ads - Budgets, Campaigns, Interests, Ads and Optimization',
    h4TitleTiktokAds: 'TikTok Ads - Video Formats, Viral Elements, Posting Strategies and Engagement Elements',
    h4TitleLinkedinAds: 'LinkedIn Ads - Targeting, Campaigns, Ads and Optimization',
    h4TitleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',
    h4TitleEmailMarketing: 'Email Marketing - High-Converting Persuasive Scripts',

    questionModel: 'Is your business B2C or B2B?',
    questionLanguage: 'What\'s the language of the answers?',
    questionToneOfVoice: 'Tone of Voice',
    questionAvatar: 'What is your perfect customer? (ICP / Audience / Persona / AVATAR)',
    questionNiche: 'What is your niche market? (Niche or mass market)',
    questionContext: 'Context: ',
    questionSite: 'Site Data / Landing Page Data / Competitors Data / Emails Data / Ecommerce / SKU ?',

    placeholderSite: 'Enter your site\'s information',

    titleVslSMB: 'Sales Script Copy / VSL Text - SMB',
    titleVslEnterprise: 'Sales Script Copy / VSL Text - Enterprise',
    titleVslPrismatic: 'Sales Script Copy / VSL Text - Prismatic',
    titleVslEcommerce: 'Sales Script Copy / VSL Text - Ecommerce',
    titleVslSMBPolicyFree: 'Sales Script Copy / VSL Text - SMB (Policy Free)',
    titleVslEnterprisePolicyFree: 'Sales Script Copy / VSL Text - Enterprise (Policy Free)',
    titleSalesConversationalB2C: 'Sales Conversation B2C/ SMB',
    titleSalesConversationalEnterprise: 'Sales Conversation Enterprise',
    titleSingleMechanism: 'Unique Mechanism',
    titleIrresistibleOfferLevel1: 'Irresistible Offer - Basic',
    titleIrresistibleOfferLevel2: 'Irresistible Offer - Pro',
    titleFAQS: 'FAQs for Landing Pages and SEO Optimization',
    titleSlideShow: 'Bullet Points for Slides and Landing Pages',
    titleTwentyNames: 'Product Names / Domains / Business',
    titleImagePrompts: 'High-Impact Image Prompts',
    titleGAVideoAdsHistoricalEvolution: 'Historical Evolution',
    titleGAVideoAdsPersonaPainPoints: 'Persona Pain Points',
    titleGAVideoAdsTransformationTimeLapse: 'Transformation Time Lapse',
    titleGAVideoAdsWhistleblower: 'Whistleblower Control Beater',
    titleGAVideoAdsPermissionSkip: 'Skip Permission',
    titleGAVideoAdsUnexpectedDelivery: 'Unexpected Delivery',
    titleGAVideoAdsMithBuster: 'Myth Buster',
    titleGAVideoAdsWeirdHack: 'Security Breach',
    titleEmailUpselling: 'Email - Upselling',
    titleEmailTabooBreaker: 'Email - Taboo Breaker',
    titleEmailFalseChoice: 'Email - False Choice',
    titleEmailPrincipleOfPolarization: 'Email - Polarization Principle',
    titleEmailForbiddenCuriosity: 'Email - Forbidden Curiosity',
    titleEmailParadoxicalTruthty: 'Email - Paradoxical Truth',
    titleEmailSingleSolutionFocusedResults: 'Email - Single Solution Results',
    titleEmailPASWhatsTheHell: 'Email - PAS What\'s The Hell',
    titleEmailEliminatesCommonProblem: 'Email - Common Problem Elimination',
    titleEmailKnowItAll: 'Email - Know-It-All',
    titleEmailIdealLife: 'Email - Ideal Life',
    titleEmailUniqueMechanismProblem: 'Email - Unique Mechanism Problem',
    titleEmailImpressedAuthority: 'Email - Authority Impression',
    titleEmailSurpriseUnifiedSource: 'Email - Unified Source Surprise',
    titleEmailDisadvantageAscension: 'Email - Disadvantage Ascension',
    titleEmailFuneralSolution: 'Email - Funeral Solution',

    optionSellingMode: 'Selling Mode',
    optionFriendly: 'Friendly',
    optionProfessional: 'Professional',
    optionExpert: 'Expert',
    optionBusinessAndTrust: 'Business and Trust',
    optionSocialMedia: 'Social Media',
    optionSensitiveCommunication: 'Sensitive Communication',

    btnAddAll: 'Add all',
    btnDeleteAll: 'Delete all',
    btnExplore: 'Explore templates',
    estimatedTime: 'Estimated time',
    btn: 'Create',

    important: 'Important: ',
    warningMessage: 'Paste the content text directly. CopyCut does not process URLs or links - plain text only.',

    msgCard: 'Create professional VSL scripts segmented for: VSL-SMB for small businesses, VSL-Enterprise for corporations, VSL-Prismatic for multiple decision-makers, and VSL-Ecommerce for online sales. All scripts ensure privacy policy compliance and maximum conversion.',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        title: 'CopyCut AI - Assets',
        // messageProccessInfo: 'Estamos organizando suas informações. Isso pode levar até 90 segundos. Por favor, não saia desta tela.',
        messageProccessInfoNoSec: 'Estamos organizando suas informações. Por favor, não saia desta tela.',

        borderTitleLanguage: 'Linguagem',

        labelContext: 'Contexto *',
        spanContext: '(Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        placeholderContext: 'Insira as informações do seu site/contexto',

        labelCheckBox: 'Customizar Público-Alvo e Nicho',
        infoCheckBox: 'CopyCut criará automaticamente um público-alvo e nicho adequado com base no seu contexto.',

        labelAvatar: 'Público-Alvo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        placeholderAvatar: 'Descreva as informações necessárias do seu público-alvo (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho ou mercado de massas)',
        placeholderNiche: 'Informe o nicho de mercado (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',


        h4TitleVslCopy: 'VSLs - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSLs Livre de Política - B2C/SMB e Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        h4TitleGAKeywords: 'Google Ads - Palavras-chave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anúncio',
        h4TitleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        h4TitleGAEditorKeywords: 'Google Ads Editor (CSV) - Palavras-chave',
        h4TitleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anúncio',
        h4TitleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campanhas e Grupos de anúncio',
        h4TitleGAEditorInstructions: 'Google Ads Editor (CSV) - Instruções',
        h4TitleMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Postagem e Elementos de Engajamento',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        h4TitleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',

        questionModel: 'Seu negócio é de ofertas B2C ou B2B?',
        questionLanguage: 'Qual o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionContext: 'Contexto: ',
        questionSite: 'Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU ?',

        titleVslSMB: 'Copy para Script de Vendas / Texto de VSL - SMB',
        titleVslEnterprise: 'Copy para Script de Vendas / Texto de VSL - Enterprise',
        titleVslPrismatic: 'Copy para Script de Vendas / Texto de VSL - Prismática',
        titleVslEcommerce: 'Copy para Script de Vendas / Texto de VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Script de Vendas / Texto de VSL - SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Script de Vendas / Texto de VSL - Enterprise (Livre de Políticas)',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irresistível - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irresistível - Pro',
        titleFAQS: 'FAQs para Páginas de Destino e Otimização SEO',
        titleSlideShow: 'Marcadores para Slides e Páginas de Destino',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleImagePrompts: 'Prompts para Imagens de Alto Impacto',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos de Dor da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Linha do Tempo da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permissão para Pular',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmistificador',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',
        titleEmailUpselling: 'Email - Venda Incremental',
        titleEmailTabooBreaker: 'Email - Quebra-Tabus',
        titleEmailFalseChoice: 'Email - Falsa Escolha',
        titleEmailPrincipleOfPolarization: 'Email - Princípio de Polarização',
        titleEmailForbiddenCuriosity: 'Email - Curiosidade Proibida',
        titleEmailParadoxicalTruthty: 'Email - Verdade Paradoxal',
        titleEmailSingleSolutionFocusedResults: 'Email - Solução Única Focada',
        titleEmailPASWhatsTheHell: 'Email - PAS Surpreendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminação de Problema Comum',
        titleEmailKnowItAll: 'Email - Especialista Total',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridade Impressionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fonte Unificada Surpreendente',
        titleEmailDisadvantageAscension: 'Email - Ascensão por Desvantagem',
        titleEmailFuneralSolution: 'Email - Solução Definitiva',

        optionSellingMode: 'Modo Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Deletar todos',
        btnExplore: 'Explorar templates',
        estimatedTime: 'Tempo estimado',
        btn: 'Criar',

        important: 'Importante: ',
        warningMessage: 'Cole o texto do conteúdo diretamente. O CopyCut não processa URLs ou links - apenas texto simples.',

        msgCard: 'Crie scripts profissionais para VSLs segmentados, incluindo: VSL-PME para pequenas empresas, VSL-Enterprise para corporações, VSL-Prismática para múltiplos decisores, e VSL-Ecommerce para vendas online. Todos os roteiros garantem conformidade com políticas de privacidade e máxima conversão.',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        title: 'CopyCut AI - Assets',
        // messageProccessInfo: 'Estamos a organizar as suas informações. Isto pode demorar até 90 segundos. Por favor, não saia deste ecrã.',
        messageProccessInfoNoSec: 'Estamos a organizar as suas informações. Por favor, não saia deste ecrã.',

        borderTitleLanguage: 'Linguagem',

        labelContext: 'Contexto *',
        spanContext: '(Dados do site / Landing Page / Concorrentes / Dados de e-mail / Ecommerce / SKU)',
        placeholderContext: 'Insira as informações do seu site',

        labelCheckBox: 'Personalizar Público-Alvo e Nicho',
        infoCheckBox: 'CopyCut irá criar automaticamente um público-alvo e nicho adequado com base no seu contexto.',

        labelAvatar: 'Público-Alvo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        placeholderAvatar: 'Descreva as informações necessárias do seu público-alvo (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho ou mercado de massas)',
        placeholderNiche: 'Informe o nicho de mercado (Clicando no botão \'Explorar templates\' pode te ajudar na escolha ideal)',


        h4TitleVslCopy: 'VSLs - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSLs Livre de Política - B2C/SMB e Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        h4TitleGAKeywords: 'Google Ads - Palavras-chave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anúncios',
        h4TitleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        h4TitleGAEditorKeywords: 'Google Ads Editor (CSV) - Palavras-chave',
        h4TitleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anúncios',
        h4TitleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campanhas e Grupos de anúncio',
        h4TitleGAEditorInstructions: 'Google Ads Editor (CSV) - Instruções',
        h4TitleMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Publicação e Elementos de Envolvimento',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        h4TitleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',

        questionModel: 'O seu negócio é B2C ou B2B?',
        questionLanguage: 'Qual é o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionContext: 'Contexto: ',
        questionSite: 'Dados do site / Landing Page / Concorrentes / Dados de e-mail / Ecommerce / SKU ?',

        titleVslSMB: 'Copy para Guião de Vendas / Texto de VSL - SMB',
        titleVslEnterprise: 'Copy para Guião de Vendas / Texto de VSL - Enterprise',
        titleVslPrismatic: 'Copy para Guião de Vendas / Texto de VSL - Prismática',
        titleVslEcommerce: 'Copy para Guião de Vendas / Texto de VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Guião de Vendas / Texto de VSL - SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Guião de Vendas / Texto de VSL - Enterprise (Livre de Políticas)',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irrecusável - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irrecusável - Pro',
        titleFAQS: 'FAQs para Páginas de Destino e Otimização SEO',
        titleSlideShow: 'Marcadores para Diapositivos e Páginas de Destino',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleImagePrompts: 'Prompts para Imagens de Alto Impacto',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos Críticos da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronologia da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Corporativas',
        titleGAVideoAdsPermissionSkip: 'Permissão de Ignorar',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Imprevista',
        titleGAVideoAdsMithBuster: 'Desmistificação',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',
        titleEmailUpselling: 'Email - Venda Adicional',
        titleEmailTabooBreaker: 'Email - Quebra-Tabus',
        titleEmailFalseChoice: 'Email - Falsa Escolha',
        titleEmailPrincipleOfPolarization: 'Email - Princípio de Polarização',
        titleEmailForbiddenCuriosity: 'Email - Curiosidade Proibida',
        titleEmailParadoxicalTruthty: 'Email - Verdade Paradoxal',
        titleEmailSingleSolutionFocusedResults: 'Email - Solução Única Focada',
        titleEmailPASWhatsTheHell: 'Email - PAS Surpreendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminação de Problema Comum',
        titleEmailKnowItAll: 'Email - Especialista Absoluto',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridade Impressionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fonte Unificada Surpreendente',
        titleEmailDisadvantageAscension: 'Email - Ascensão por Desvantagem',
        titleEmailFuneralSolution: 'Email - Solução Definitiva',

        optionSellingMode: 'Modo de Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Apagar todos',
        btnExplore: 'Explorar templates',
        estimatedTime: 'Tempo estimado',
        btn: 'Criar',

        important: 'Importante: ',
        warningMessage: 'Cole o texto do conteúdo diretamente. O CopyCut não processa URLs ou links - apenas texto simples.',

        msgCard: 'Crie guiões profissionais para VSLs segmentados, incluindo: VSL-PME para pequenas empresas, VSL-Enterprise para empresas, VSL-Prismática para múltiplos decisores, e VSL-Ecommerce para vendas online. Todos os guiões garantem conformidade com políticas de privacidade e máxima conversão.',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        title: 'CopyCut AI - Assets',
        // messageProccessInfo: 'Estamos organizando tu información. Esto puede tardar hasta 90 segundos. Por favor, no abandones esta pantalla.',
        messageProccessInfoNoSec: 'Estamos organizando tu información. Por favor, no abandones esta pantalla.',

        borderTitleLanguage: 'Lenguaje',

        labelContext: 'Contexto *',
        spanContext: '¿(Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico / Ecommerce / SKU)',
        placeholderContext: 'Ingresa la información de tu sitio web',

        labelCheckBox: 'Personalizar Público Objetivo y Nicho',
        infoCheckBox: 'CopyCut creará automáticamente un público objetivo y nicho adecuado según tu contexto.',

        labelAvatar: 'Público Objetivo',
        spanAvatar: '(Cliente Ideal / Persona / ICP / Audiencia / AVATAR)',
        placeholderAvatar: 'Describe la información necesaria de tu público objetivo (Hacer clic en el botón \'Explorar Plantillas\' puede ayudarte en la elección ideal)',

        labelNiche: 'Nicho',
        spanNiche: '(Nicho o mercado masivo)',
        placeholderNiche: 'Indique el nicho de mercado (Hacer clic en el botón \'Explorar plantillas\' puede ayudarte en la elección ideal)',

        h4TitleVslCopy: 'VSLs - B2C/SMB y Enterprise',
        h4TitleVslPolicyFree: 'VSLs Libre de Política - B2C/SMB y Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing y Ventas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump y Upsell',
        h4TitleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        h4TitleGAKeywords: 'Google Ads - Palabras clave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anuncios',
        h4TitleGAAnnouncement: 'Google Ads - Campañas y Grupos de anuncios',
        h4TitleGAEditorKeywords: 'Google Ads Editor (CSV) - Palabras clave',
        h4TitleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anuncios',
        h4TitleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campañas y Grupos de anuncios',
        h4TitleGAEditorInstructions: 'Google Ads Editor (CSV) - Instrucciones',
        h4TitleMetaAds: 'Meta Ads - Presupuestos, Campañas, Intereses, Anuncios y Optimización',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Video, Elementos Virales, Estrategias de Publicación y Elementos de Participación',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentación, Campañas, Anuncios y Optimización',
        h4TitleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'Email Marketing - Scripts Persuasivos de Alta Conversión',

        questionModel: '¿Tu negocio es B2C o B2B?',
        questionLanguage: '¿Cuál es el idioma de las respuestas?',
        questionToneOfVoice: 'Tono de Voz',
        questionAvatar: '¿Cuál es tu cliente perfecto? (ICP / Audiencia / Persona / AVATAR)',
        questionNiche: '¿Cuál es tu nicho de mercado? (Nicho o mercado masivo)',
        questionContext: 'Contexto: ',
        questionSite: '¿Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico / Ecommerce / SKU ?',


        titleVslSMB: 'Copy para Guion de Ventas / Texto VSL - SMB',
        titleVslEnterprise: 'Copy para Guion de Ventas / Texto VSL - Enterprise',
        titleVslPrismatic: 'Copy para Guion de Ventas / Texto VSL - Prismática',
        titleVslEcommerce: 'Copy para Guion de Ventas / Texto VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Guion de Ventas / Texto VSL - SMB (Libre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Guion de Ventas / Texto VSL - Enterprise (Libre de Políticas)',
        titleSalesConversationalB2C: 'Conversación de Ventas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversación de Ventas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irresistible - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irresistible - Pro',
        titleFAQS: 'FAQs para Páginas de Aterrizaje y Optimización SEO',
        titleSlideShow: 'Viñetas para Diapositivas y Páginas de Aterrizaje',
        titleTwentyNames: 'Nombres de Productos / Dominios / Negocios',
        titleImagePrompts: 'Prompts para Imágenes de Alto Impacto',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Puntos de Dolor de la Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronología de la Transformación',
        titleGAVideoAdsWhistleblower: 'Controlador de Denuncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permiso de Omisión',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmitificador',
        titleGAVideoAdsWeirdHack: 'Vulnerabilidad de Seguridad',
        titleEmailUpselling: 'Email - Venta Adicional',
        titleEmailTabooBreaker: 'Email - Rompe Tabúes',
        titleEmailFalseChoice: 'Email - Falsa Elección',
        titleEmailPrincipleOfPolarization: 'Email - Principio de Polarización',
        titleEmailForbiddenCuriosity: 'Email - Curiosidad Prohibida',
        titleEmailParadoxicalTruthty: 'Email - Verdad Paradójica',
        titleEmailSingleSolutionFocusedResults: 'Email - Solución Única Enfocada',
        titleEmailPASWhatsTheHell: 'Email - PAS Sorprendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminación de Problema Común',
        titleEmailKnowItAll: 'Email - Experto Total',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridad Impresionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fuente Unificada Sorprendente',
        titleEmailDisadvantageAscension: 'Email - Ascensión por Desventaja',
        titleEmailFuneralSolution: 'Email - Solución Definitiva',

        optionSellingMode: 'Modo Venta',
        optionFriendly: 'Amigable',
        optionProfessional: 'Profesional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negocios y Confianza',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicación Sensible',

        btnAddAll: 'Agregar todos',
        btnDeleteAll: 'Eliminar todos',
        btnExplore: 'Explorar plantillas',
        estimatedTime: 'Tiempo estimado',
        btn: 'Crear',

        important: 'Importante: ',
        warningMessage: 'Pegue el texto del contenido directamente. CopyCut no procesa URLs o enlaces - solo texto sin formato.',

        msgCard: 'Cree guiones profesionales para VSL segmentados, incluyendo: VSL-PYME para pequeñas empresas, VSL-Enterprise para corporaciones, VSL-Prismático para múltiples decisores, y VSL-Ecommerce para ventas online. Todos los guiones garantizan el cumplimiento de políticas de privacidad y máxima conversión.',
        language,
      };

    default:
      return terms;
  }
}
