import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ManageProjectContext } from '../manageProjects.context';

import IconEdit from '../../../../../styles/logos/icon_edit_pencil.svg';

import { SidebarArea } from './style';
import { Translate } from './translate';

export function Sidebar() {
  const translate = Translate();
  const { listOfPrompts, isLoadingPromptList } = useContext(ManageProjectContext);

  // copycut_paid copycut_unpaid

  return (
    <SidebarArea>
      {isLoadingPromptList && (
        <>{translate.loadingList}...</>
      )}
      {!isLoadingPromptList && (
        <>
          <div className="title-area">
            <p>
              <Link to="/client/ailab/manage-projects" alt="Manager prompt">
                {translate.titleSidebar}
              </Link>
            </p>
          </div>
          <div>
            <ul>
              {listOfPrompts.length > 0 && (
                listOfPrompts.map((prompt) => (
                  <li key={prompt.id}>

                    {/* //TODO: Delete copycut_paid after update at db  */}
                    {(prompt.type === 'copycut_paid' || prompt.type === 'copycut_assets') && (
                      <Link
                        className="prompt-name"
                        to={`infos/copycut-assets/${prompt.id}`}
                      >
                        {prompt.name}
                      </Link>
                    )}

                    {/* //TODO: Delete copycut_unpaid after update at db  */}
                    {(prompt.type === 'copycut_unpaid' || prompt.type === 'copycut_paid_ads') && (
                      <Link
                        className="prompt-name"
                        to={`infos/copycut-paid-ads/${prompt.id}`}
                      >
                        {prompt.name}
                      </Link>
                    )}
                    {prompt.type === 'copycut_unpaid_posts' && (
                      <Link
                        className="prompt-name"
                        to={`infos/copycut-unpaid-posts/${prompt.id}`}
                      >
                        {prompt.name}
                      </Link>
                    )}
                    <span>
                      <Link to={`edit-name/${prompt.id}?name=${prompt.name}`}>
                        <img src={IconEdit} alt="edit" />
                      </Link>
                    </span>
                  </li>
                ))
              )}
            </ul>
          </div>
        </>
      )}
    </SidebarArea>
  );
}
