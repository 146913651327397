export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    doneBy: 'Created by: ',

    labelBusinessModel: 'Business Model',
    labelSite: 'Context (Site Data / Landing Page Data / Competitors Data / Emails Data / Ecommerce / SKU)',
    labelAvatar: 'Target Audience (Ideal Customer / Persona / ICP / Audience / AVATAR)',
    labelNiche: 'Niche (Niche or mass market)',
    labelLanguage: 'Language',
    labelToneOfVoice: 'Tone of Voice',

    modalTitle: 'Are you sure you want to delete this item?',
    modalBtn: 'Delete',

    titleVslSMB: 'Sales Script Copy / VSL Text - SMB',
    titleVslEnterprise: 'Sales Script Copy / VSL Text - Enterprise',
    titleVslSMBPolicyFree: 'Sales Script Copy / VSL Text - SMB (Policy Free)',
    titleVslPrismatic: 'Sales Script Copy / VSL Text - Prismatic',
    titleVslEcommerce: 'Sales Script Copy / VSL Text - Ecommerce',
    titleVslEnterprisePolicyFree: 'Sales Script Copy / VSL Text - Enterprise (Policy Free)',
    titleSingleMechanism: 'Single Mechanism',
    titleIrresistibleOfferLevel1: 'Irresistible Offer - Basic',
    titleIrresistibleOfferLevel2: 'Irresistible Offer - Pro',
    titleFAQS: 'FAQs for Landing Pages and SEO Optimization',
    titleSlideShow: 'Bullet Points for Slides and Landing Pages',
    titleTwentyNames: 'Product Names / Domains / Business',
    titleImagePrompts: 'High-Impact Image Prompts',
    titleGAKeywords: 'Google Ads - Keywords',
    titleGAAnnouncementGroup: 'Google Ads - Ad Groups',
    titleGAAnnouncement: 'Google Ads - Campaigns and Ad Groups',
    titleGAEditorKeywords: 'Google Ads Editor (CSV) - Keywords',
    titleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Ad Groups',
    titleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campaigns and Ad Groups',
    titleGAEditorInstructions: 'Google Ads Editor (CSV) - Instructions',
    titleGAMetaAds: 'Meta Ads - Budgets, Campaigns, Interests, Ads and Optimization',
    titleGATiktokAds: 'TikTok Ads - Video Formats, Viral Elements, Posting Strategies and Engagement Elements',
    titleGALinkedinAds: 'LinkedIn Ads - Targeting, Campaigns, Ads and Optimization',
    titleGAVideoAdsHistoricalEvolution: 'Historical Evolution',
    titleGAVideoAdsPersonaPainPoints: 'Persona Pain Points',
    titleGAVideoAdsTransformationTimeLapse: 'Transformation Time Lapse',
    titleGAVideoAdsWhistleblower: 'Whistleblower Control Beater',
    titleGAVideoAdsPermissionSkip: 'Skip Permission',
    titleGAVideoAdsUnexpectedDelivery: 'Unexpected Delivery',
    titleGAVideoAdsMithBuster: 'Myth Buster',
    titleGAVideoAdsWeirdHack: 'Security Breach',
    titleEmailUpselling: 'Email - Upselling',
    titleEmailTabooBreaker: 'Email - Taboo Breaker',
    titleEmailFalseChoice: 'Email - False Choice',
    titleEmailPrincipleOfPolarization: 'Email - Polarization Principle',
    titleEmailForbiddenCuriosity: 'Email - Forbidden Curiosity',
    titleEmailParadoxicalTruthty: 'Email - Paradoxical Truth',
    titleEmailSingleSolutionFocusedResults: 'Email - Single Solution Results',
    titleEmailPASWhatsTheHell: 'Email - PAS What\'s The Hell',
    titleEmailEliminatesCommonProblem: 'Email - Common Problem Elimination',
    titleEmailKnowItAll: 'Email - Know-It-All',
    titleEmailIdealLife: 'Email - Ideal Life',
    titleEmailUniqueMechanismProblem: 'Email - Unique Mechanism Problem',
    titleEmailImpressedAuthority: 'Email - Authority Impression',
    titleEmailSurpriseUnifiedSource: 'Email - Unified Source Surprise',
    titleEmailDisadvantageAscension: 'Email - Disadvantage Ascension',
    titleEmailFuneralSolution: 'Email - Funeral Solution',

    noData: 'No content in this layer.',

    h4TitleVslCopy: 'VSL Copy - B2C/SMB and Enterprise',
    h4TitleVslPolicyFree: 'VSL Policy Free - B2C/SMB and Enterprise',
    h4TitleEngineValidation: 'Engine Validation - Order Bump and Upsell',
    h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    h4TitleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',
    h4TitleEmailMarketing: 'Email Marketing - High-Converting Persuasive Scripts',

    toneOfVoiceSaleMode: 'Selling Mode',
    toneOfVoiceProfissional: 'Professional',
    toneOfVoiceExpert: 'Expert',
    toneOfVoiceBusinessAndTrust: 'Business and Trust',
    toneOfVoiceSensitiveCommunication: 'Sensitive Communication',
    toneOfVoiceFriendly: 'Friendly',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelSite: 'Contexto (Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        labelAvatar: 'Público-Alvo (Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        labelNiche: 'Nicho (Nicho ou mercado de massas)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem certeza que deseja deletar esse item?',
        modalBtn: 'Deletar',

        titleVslSMB: 'Copy para Script de Vendas / Texto de VSL - SMB',
        titleVslEnterprise: 'Copy para Script de Vendas / Texto de VSL - Enterprise',
        titleVslPrismatic: 'Copy para Script de Vendas / Texto de VSL - Prismática',
        titleVslEcommerce: 'Copy para Script de Vendas / Texto de VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Script de Vendas / Texto de VSL - SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Script de Vendas / Texto de VSL - Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irresistível - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irresistível - Pro',
        titleFAQS: 'FAQs para Páginas de Destino e Otimização SEO',
        titleSlideShow: 'Marcadores para Slides e Páginas de Destino',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleImagePrompts: 'Prompts para Imagens de Alto Impacto',
        titleGAKeywords: 'Google Ads - Palavras-chave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anúncio',
        titleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        titleGAEditorKeywords: 'Google Ads Editor (CSV) - Palavras-chave',
        titleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anúncio',
        titleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campanhas e Grupos de anúncio',
        titleGAEditorInstructions: 'Google Ads Editor (CSV) - Instruções',
        titleGAMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        titleGATiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Postagem e Elementos de Engajamento',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos de Dor da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Linha do Tempo da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permissão para Pular',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmistificador',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',
        titleEmailUpselling: 'Email - Venda Incremental',
        titleEmailTabooBreaker: 'Email - Quebra-Tabus',
        titleEmailFalseChoice: 'Email - Falsa Escolha',
        titleEmailPrincipleOfPolarization: 'Email - Princípio de Polarização',
        titleEmailForbiddenCuriosity: 'Email - Curiosidade Proibida',
        titleEmailParadoxicalTruthty: 'Email - Verdade Paradoxal',
        titleEmailSingleSolutionFocusedResults: 'Email - Solução Única Focada',
        titleEmailPASWhatsTheHell: 'Email - PAS Surpreendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminação de Problema Comum',
        titleEmailKnowItAll: 'Email - Especialista Total',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridade Impressionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fonte Unificada Surpreendente',
        titleEmailDisadvantageAscension: 'Email - Ascensão por Desvantagem',
        titleEmailFuneralSolution: 'Email - Solução Definitiva',

        noData: 'Sem conteúdo nesta camada.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        h4TitleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelSite: 'Contexto (Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        labelAvatar: 'Público-Alvo (Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        labelNiche: 'Nicho (Nicho ou mercado de massas)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem a certeza que pretende eliminar este item?',
        modalBtn: 'Eliminar',

        titleVslSMB: 'Copy para Guião de Vendas / Texto de VSL - SMB',
        titleVslEnterprise: 'Copy para Guião de Vendas / Texto de VSL - Enterprise',
        titleVslPrismatic: 'Copy para Guião de Vendas / Texto de VSL - Prismática',
        titleVslEcommerce: 'Copy para Guião de Vendas / Texto de VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Guião de Vendas / Texto de VSL - SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Guião de Vendas / Texto de VSL - Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irrecusável - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irrecusável - Pro',
        titleFAQS: 'FAQs para Páginas de Destino e Otimização SEO',
        titleSlideShow: 'Marcadores para Diapositivos e Páginas de Destino',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleImagePrompts: 'Prompts para Imagens de Alto Impacto',
        titleGAKeywords: 'Google Ads - Palavras-chave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anúncios',
        titleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        titleGAEditorKeywords: 'Google Ads Editor (CSV) - Palavras-chave',
        titleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anúncios',
        titleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campanhas e Grupos de anúncio',
        titleGAEditorInstructions: 'Google Ads Editor (CSV) - Instruções',
        titleGAMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        titleGATiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Publicação e Elementos de Envolvimento',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos Críticos da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronologia da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Corporativas',
        titleGAVideoAdsPermissionSkip: 'Permissão de Ignorar',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Imprevista',
        titleGAVideoAdsMithBuster: 'Desmistificação',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',
        titleEmailUpselling: 'Email - Venda Adicional',
        titleEmailTabooBreaker: 'Email - Quebra-Tabus',
        titleEmailFalseChoice: 'Email - Falsa Escolha',
        titleEmailPrincipleOfPolarization: 'Email - Princípio de Polarização',
        titleEmailForbiddenCuriosity: 'Email - Curiosidade Proibida',
        titleEmailParadoxicalTruthty: 'Email - Verdade Paradoxal',
        titleEmailSingleSolutionFocusedResults: 'Email - Solução Única Focada',
        titleEmailPASWhatsTheHell: 'Email - PAS Surpreendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminação de Problema Comum',
        titleEmailKnowItAll: 'Email - Especialista Absoluto',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridade Impressionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fonte Unificada Surpreendente',
        titleEmailDisadvantageAscension: 'Email - Ascensão por Desvantagem',
        titleEmailFuneralSolution: 'Email - Solução Definitiva',

        noData: 'Sem conteúdo nesta camada.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        h4TitleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'es-ES':
      return {
        ...terms,
        doneBy: 'Creado por: ',

        labelBusinessModel: 'Modelo de Negocio',
        labelSite: 'Contexto (Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico / Ecommerce / SKU)',
        labelAvatar: 'Público Objetivo (Cliente Ideal / Persona / ICP / Audiencia / AVATAR)',
        labelNiche: 'Nicho (Nicho o mercado masivo)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tono de voz',

        modalTitle: '¿Estás seguro de que quieres eliminar este elemento?',
        modalBtn: 'Eliminar',

        titleVslSMB: 'Copy para Guion de Ventas / Texto VSL - SMB',
        titleVslEnterprise: 'Copy para Guion de Ventas / Texto VSL - Enterprise',
        titleVslPrismatic: 'Copy para Guion de Ventas / Texto VSL - Prismática',
        titleVslEcommerce: 'Copy para Guion de Ventas / Texto VSL - Ecommerce',
        titleVslSMBPolicyFree: 'Copy para Guion de Ventas / Texto VSL - SMB (Libre de Políticas)',
        titleVslEnterprisePolicyFree: 'Copy para Guion de Ventas / Texto VSL - Enterprise (Libre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleIrresistibleOfferLevel1: 'Oferta Irresistible - Basic',
        titleIrresistibleOfferLevel2: 'Oferta Irresistible - Pro',
        titleFAQS: 'FAQs para Páginas de Aterrizaje y Optimización SEO',
        titleSlideShow: 'Viñetas para Diapositivas y Páginas de Aterrizaje',
        titleTwentyNames: 'Nombres de Productos / Dominios / Negocios',
        titleImagePrompts: 'Prompts para Imágenes de Alto Impacto',
        titleGAKeywords: 'Google Ads - Palabras clave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anuncios',
        titleGAAnnouncement: 'Google Ads - Campañas y Grupos de anuncios',
        titleGAEditorKeywords: 'Google Ads Editor (CSV) - Palabras clave',
        titleGAEditorAnnouncementGroup: 'Google Ads Editor (CSV) - Grupos de anuncios',
        titleGAEditorAnnouncement: 'Google Ads Editor (CSV) - Campañas y Grupos de anuncios',
        titleGAEditorInstructions: 'Google Ads Editor (CSV) - Instrucciones',
        titleGAMetaAds: 'Meta Ads - Presupuestos, Campañas, Intereses, Anuncios y Optimización',
        titleGATiktokAds: 'TikTok Ads - Formatos de Video, Elementos Virales, Estrategias de Publicación y Elementos de Participación',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentación, Campañas, Anuncios y Optimización',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Puntos de Dolor de la Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronología de la Transformación',
        titleGAVideoAdsWhistleblower: 'Controlador de Denuncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permiso de Omisión',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmitificador',
        titleGAVideoAdsWeirdHack: 'Vulnerabilidad de Seguridad',
        titleEmailUpselling: 'Email - Venta Adicional',
        titleEmailTabooBreaker: 'Email - Rompe Tabúes',
        titleEmailFalseChoice: 'Email - Falsa Elección',
        titleEmailPrincipleOfPolarization: 'Email - Principio de Polarización',
        titleEmailForbiddenCuriosity: 'Email - Curiosidad Prohibida',
        titleEmailParadoxicalTruthty: 'Email - Verdad Paradójica',
        titleEmailSingleSolutionFocusedResults: 'Email - Solución Única Enfocada',
        titleEmailPASWhatsTheHell: 'Email - PAS Sorprendente',
        titleEmailEliminatesCommonProblem: 'Email - Eliminación de Problema Común',
        titleEmailKnowItAll: 'Email - Experto Total',
        titleEmailIdealLife: 'Email - Vida Ideal',
        titleEmailUniqueMechanismProblem: 'Email - Mecanismo Único',
        titleEmailImpressedAuthority: 'Email - Autoridad Impresionante',
        titleEmailSurpriseUnifiedSource: 'Email - Fuente Unificada Sorprendente',
        titleEmailDisadvantageAscension: 'Email - Ascensión por Desventaja',
        titleEmailFuneralSolution: 'Email - Solución Definitiva',

        noData: 'Sin contenido en esta capa.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB y Enterprise',
        h4TitleVslPolicyFree: 'VSL Libre de Políticas - B2C/SMB y Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump y Upsell',
        h4TitleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        h4TitleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',
        h4TitleEmailMarketing: 'Email Marketing - Scripts Persuasivos de Alta Conversión',

        toneOfVoiceSaleMode: 'Modo de venta',
        toneOfVoiceProfissional: 'Profesional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negocios y Confianza',
        toneOfVoiceSensitiveCommunication: 'Comunicación Sensible',
        toneOfVoiceFriendly: 'Amigable',

        language,
      };

    default:
      return terms;
  }
}
