import styled from 'styled-components';

export const AreaContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled.div`
  .center-container {
    text-align: center;
    margin-bottom: 16px;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 8px;
  }

  h3 {
    font-weight: bold;
    margin: 0 !important;
  }

  p {
    margin-bottom: 16px;

    ul {
      list-style-position: inside;
    }

    > h4 {
      font-weight: 500;
    }
  }

  .c-2 {
    display: flex;
    align-items: center;
  }
`;
