import React, { useContext } from 'react';
import TitlePage from '../../../../components/TitlePage';
import { Translate } from './translate';
import { CardContainer, Card } from './style';
import { AuthContext } from '../../../../context/AuthContext';

export function IndexAILab() {
  const translate = Translate();
  const { userInfo: { email } } = useContext(AuthContext);
  return (
    <>
      <TitlePage title="AILab Hub" />
      <CardContainer>
        <Card to="/client/ailab/copycut-assets">
          <div className="tool-name">CopyCut AI - Assets</div>
          <div className="description">{translate.txtCopyCutAssets}</div>
        </Card>
        {(email === 'suporte+111menu@shifters.com.br')
          ? (
            <Card to="/client/ailab/copycut-paid-ads">
              <div className="tool-name">CopyCut AI - Paid Ads</div>
              <div className="description">{translate.txtCopyPaidAds}</div>
              <div className="badge green">{translate.badgeNew}</div>
            </Card>
          )
          : (
            <Card className="soon">
              <div className="tool-name">CopyCut AI - Paid Ads</div>
              <div className="description">{translate.txtCopyPaidAds}</div>
              <div className="badge red">{translate.badgeSoon}</div>
            </Card>
          )}

        <Card to="/client/ailab/copycut-unpaid-posts">
          <div className="tool-name">CopyCut AI - Unpaid Posts</div>
          <div className="description">{translate.txtCopyUnpaidPosts}</div>
          <div className="badge green">{translate.badgeNew}</div>
        </Card>
        <Card className="soon">
          <div className="tool-name">Translate AI</div>
          <div className="description">{translate.txtTranslateAI}</div>
          <div className="badge red">{translate.badgeSoon}</div>
        </Card>
      </CardContainer>
    </>
  );
}

