import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled(Link)`
  text-decoration: none;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.grayMenu};
  border-radius: 4px;
  transition: .2s ease-in-out;
  position: relative;

  background: ${({ theme }) => theme.grayMenu};
  /* background: linear-gradient(180deg, ${({ theme }) => theme.grayMenu} 0%, rgba(20,20,20,1) 100%); */
  box-shadow: 0px 6px 6px rgba(0,0,0,0.3);

  &:hover {
    border: 1px solid ${({ theme }) => theme.gray3};
  }

  &.soon {
    cursor: not-allowed;
  }

  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.fontWhite};
    padding: 4px 8px;
    border-radius: 4px;
  }

  .badge.red {
    background-color: ${({ theme }) => theme.red};
  }

  .badge.green {
    background-color: ${({ theme }) => theme.green};
  }

  .tool-name {
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: 500;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding-right: 40px;
    }
  }

  .description {
    color: ${({ theme }) => theme.gray5}
  }
`;
