import React from 'react';
import { Container, CardArea } from './style';
import { Card } from '../../components/Card';
import { Translate } from './translate';

export function ButtonsArea({
  buttonsArea,
  isDisabled,
  onGetDataFirstStage,
  onGetDataSecondStage,
  onGetDataThirdStage,
  onGetDataFourthStage,
  onGetDataFifthStage,
}) {
  const translate = Translate();
  const allButtonsTrue = Object.values(buttonsArea.buttons).every((item) => item === true);
  const itWasGenerateAtLeastOneResponse = (
    buttonsArea.buttons.first
    || buttonsArea.buttons.second
    || buttonsArea.buttons.third
    || buttonsArea.buttons.fourth
    || buttonsArea.buttons.fifth
  );

  return (
    <Container>
      {(itWasGenerateAtLeastOneResponse && !allButtonsTrue) && (
        <h2>{translate.mainTitle}</h2>
      )}
      <CardArea>
        {buttonsArea.buttons.first && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="160-180 s"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card1Title}
            mainMessage={translate.card1Description}
            onHandleClick={onGetDataFirstStage}
            isDisabled={isDisabled}
          />
        )}

        {buttonsArea.buttons.second && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="50-70 s"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card2Title}
            mainMessage={translate.card2Description}
            onHandleClick={onGetDataSecondStage}
            isDisabled={isDisabled}
          />
        )}

        {buttonsArea.buttons.third && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="50-70 s"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card3Title}
            mainMessage={translate.card3Description}
            onHandleClick={onGetDataThirdStage}
            isDisabled={isDisabled}
          />
        )}

        {buttonsArea.buttons.fourth && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="50-70 s"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card4Title}
            mainMessage={translate.card4Description}
            onHandleClick={onGetDataFourthStage}
            isDisabled={isDisabled}
          />
        )}

        {buttonsArea.buttons.fifth && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="50-70 s"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card5Title}
            mainMessage={translate.card5Description}
            onHandleClick={onGetDataFifthStage}
            isDisabled={isDisabled}
          />
        )}
      </CardArea>
    </Container>
  );
}
