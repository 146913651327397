class AILab {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findOne({ id }) {
    const path = `${this.baseUrl}/ai-lab/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findPrompts() {
    const path = `${this.baseUrl}/ai-lab`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);

    return body;
  }

  async findRecentsPrompts() {
    const path = `${this.baseUrl}/ai-lab/recents`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async update({ id, name }) {
    const path = `${this.baseUrl}/ai-lab/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify({ name }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async delete({ id }) {
    const path = `${this.baseUrl}/ai-lab/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new AILab();
