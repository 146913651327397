import styled from 'styled-components';

export const SidebarArea = styled.div`
  background-color: ${({ theme }) => theme.graySubMenu};
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0;
  border-color: ${({ theme }) => theme.grayBackground};
  border-style: solid;
  height: 100vh;
  overflow-y: auto;
  width: 100%;

   .title-area {
    padding: 16px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.grayBackground};

    p {
      text-align: center;

      a {
        text-decoration: none;
        font-weight: 500 !important;
      }

      a:hover {
        color: ${({ theme }) => theme.gray5};
      }
    }
   }

   ul {
    list-style: none;
   }

   li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
   }

   li:hover {
    background-color: ${({ theme }) => theme.grayBackground};
   }

   .prompt-name {
    padding: 10px 16px;
    display: inline-block;
    width: 85%;
    text-decoration: none;
    height: 100%;
    border-radius: 4px;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    /* Adicione estas propriedades */
    white-space: nowrap;
    text-overflow: ellipsis;
   }

   .prompt-name:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to left, ${({ theme }) => theme.graySubMenu}, transparent);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

   span {
    cursor: pointer;
    padding: 8px 0px;
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    img {
      width: 15px;
      transition: 0.3s ease-in-out;
    }

    img:hover {
      filter: invert(30%) sepia(95%) saturate(517%) hue-rotate(359deg) brightness(102%) contrast(102%);
    }
   }

   li:hover span {
    display: block !important;
   }

   li:hover a:before {
    background: linear-gradient(to left, ${({ theme }) => theme.grayBackground}, transparent);
    opacity: 1;
   }
`;
