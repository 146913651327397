/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import { PropTypes } from 'prop-types';
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { regions } from './regions';
import { sortAscString, sortDescString } from '../../helpers/sortString';

const RegionInputContext = createContext();

function RegionInputProvider({ children }) {
  const [continentsList, setContinentsList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [continentsSelected, setContinentsSelected] = useState({});
  const [countriesSelected, setCountriesSelected] = useState({});
  const [statesSelected, setStatesSelected] = useState({});
  const [alreadyHandleField, setAlreadyHandleField] = useState({
    continent: false,
    country: false,
    state: false,
  });
  const [hasCleanFilter, setHasCleanFilter] = useState({
    continent: false,
    country: false,
    state: false,
  });

  useEffect(() => {
    generateAllListsOnFirstRender();
  }, []);

  useEffect(() => {
    filterCountriesOnListBySelectedContinents();
    filterStatesOnListBySelectedContinentsBySelectedCountries();
  }, [continentsSelected, countriesSelected, statesSelected]);


  function generateAllListsOnFirstRender() {
    generatesContinentsList();
    generatesCountriesList();
    generatesStatesList();
  }

  function generatesContinentsList() {
    const continents = sortDescString(Object.keys(regions));
    setContinentsList(continents);
  }

  function generatesCountriesList() {
    const continents = sortAscString(Object.keys(regions));
    const countries = sortAscString(continents.flatMap((continent) => Object.keys(regions[continent])));
    setCountriesList(countries);
  }

  function generatesStatesList() {
    const statesArr = Object.values(regions)
      .reduce((acc, countryObj) => {
        const stateArrays = Object.values(countryObj);
        return [...acc, ...stateArrays.flat()];
      }, []);
    setStatesList(sortAscString(statesArr));
  }

  function filterCountriesOnListBySelectedContinents() {
    const arrayContinentsSelected = continentsSelected?.continent || [];
    const continentsChoicedEqualtoAll = arrayContinentsSelected.length === 0 || !arrayContinentsSelected;

    if (continentsChoicedEqualtoAll) {
      generatesCountriesList();
      return;
    }

    const countriesSelected = [];
    arrayContinentsSelected.forEach((continent) => {
      const countries = regions[continent];
      for (const [countryKey] of Object.entries(countries)) {
        countriesSelected.push(countryKey);
      }
    });
    setCountriesList(sortAscString(countriesSelected));
  }

  function filterStatesOnListBySelectedContinentsBySelectedCountries() {
    const arrayContinentsSelected = continentsSelected?.continent || [];
    const arrayCountriesSelected = countriesSelected?.country || [];
    const continentsChoicedEqualtoAll = arrayContinentsSelected.length === 0 || !arrayContinentsSelected;
    const countriesChoicedEqualtoAll = arrayCountriesSelected.length === 0 || !arrayCountriesSelected;
    const isContinentAllAndCountryAll = continentsChoicedEqualtoAll && countriesChoicedEqualtoAll;
    const isContinentSelectedAndCountryAll = !continentsChoicedEqualtoAll && countriesChoicedEqualtoAll;

    if (isContinentAllAndCountryAll) {
      generatesStatesList();
      return;
    }

    if (isContinentSelectedAndCountryAll) {
      const statesFiltered = [];
      arrayContinentsSelected.forEach((continent) => {
        const countries = regions[continent];
        for (const [countryKey, stateValue] of Object.entries(countries)) {
          statesFiltered.push(stateValue);
        }
      });
      setStatesList(sortAscString(statesFiltered.flat()));
      return;
    }

    // All the remaing alternatives
    const statesFiltered = [];
    arrayCountriesSelected.forEach((country) => {
      for (const [continentKey, countryValue] of Object.entries(regions)) {
        if (countryValue[country] !== undefined) {
          statesFiltered.push(countryValue[country]);
        }
      }
    });
    setStatesList(sortAscString(statesFiltered.flat()));
  }

  // Context values
  const contextValues = useMemo(() => ({
    continentsList,
    countriesList,
    statesList,
    hasCleanFilter,
    continentsSelected,
    countriesSelected,
    statesSelected,
    setContinentsSelected,
    setCountriesSelected,
    setStatesSelected,
  }), [
    continentsList,
    countriesList,
    statesList,
    hasCleanFilter,
    continentsSelected,
    countriesSelected,
    statesSelected,
  ]);

  return (
    <RegionInputContext.Provider value={contextValues}>
      {children}
    </RegionInputContext.Provider>
  );
}

RegionInputProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RegionInputContext, RegionInputProvider };

