export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    welcomeText: 'Welcome',
    titleFeed: 'Daily feed',

    btnWatch: 'Watch →',
    btnLinkAccess: 'Access →',

    msgTwoMorevideos: '...4 more videos selected for you!',
    msgRestrictedAccess: 'Restricted access',
    msgAdmContact: 'Contact the administrator...',

    titleCopyCutAssets: 'CopyCut AI - Assets',
    msgCopyCutAssets: 'AI Tool for Digital Marketing that creates professional content and automates over 50 types of personalized assets.',

    titleCopyCutUnpaidPosts: 'CopyCut AI - Unpaid Posts',
    msgCopyCutUnpaidPosts: 'AI Tool for Digital Marketing that automates the creation of organic and professional posts for LinkedIn, Instagram, Facebook, TikTok, and Email Marketing.',

    titlePerfilP: 'Performance People',
    msgPerfilP: 'Professional development program guiding career transformations through 6 structured steps.',

    titlePipeP: 'Pipe Performance',
    msgPipeP: 'Training program that teaches how to solve 12 business problems using data and analytics to reduce CAC.',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        welcomeText: 'Olá',
        titleFeed: 'Feed diário',

        btnWatch: 'Assistir →',
        btnLinkAccess: 'Acessar →',

        msgTwoMorevideos: '...mais 4 vídeos selecionados para você!',
        msgRestrictedAccess: 'Acesso restrito',
        msgAdmContact: 'Entre em contato com o administrador...',

        titleCopyCutAssets: 'CopyCut AI - Assets',
        msgCopyCutAssets: 'Ferramenta de IA para Marketing Digital que cria conteúdos profissionais e automatiza mais de 50 tipos de assets personalizados.',

        titleCopyCutUnpaidPosts: 'CopyCut AI - Unpaid Posts',
        msgCopyCutUnpaidPosts: 'Ferramenta de IA para Marketing Digital que automatiza a criação de posts orgânicos e profissionais para LinkedIn, Instagram, Facebook, TikTok e Email Marketing.',

        titlePerfilP: 'Perfil Performance',
        msgPerfilP: 'Programa de desenvolvimento profissional que guia transformações de carreira através de 6 passos estruturados.',

        titlePipeP: 'Pipe Performance',
        msgPipeP: 'Programa de treinamento que ensina a solucionar 12 problemas de negócios usando dados e analytics para reduzir CAC.',

        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        welcomeText: 'Olá',
        titleFeed: 'Feed diário',

        btnWatch: 'Assistir →',
        btnLinkAccess: 'Acessar →',

        msgTwoMorevideos: '...mais 4 vídeos selecionados para você!',
        msgRestrictedAccess: 'Acesso restrito',
        msgAdmContact: 'Entre em contato com o administrador...',

        titleCopyCutAssets: 'CopyCut AI - Assets',
        msgCopyCutAssets: 'Ferramenta de IA para Marketing Digital que cria conteúdos profissionais e automatiza mais de 50 tipos de ativos personalizados.',

        titleCopyCutUnpaidPosts: 'CopyCut AI - Unpaid Posts',
        msgCopyCutUnpaidPosts: 'Ferramenta de IA para Marketing Digital que automatiza a criação de publicações orgânicas e profissionais para LinkedIn, Instagram, Facebook, TikTok e Email Marketing.',

        titlePerfilP: 'Perfil Performance',
        msgPerfilP: 'Programa de desenvolvimento profissional que orienta transformações de carreira através de 6 etapas estruturadas',

        titlePipeP: 'Pipe Performance',
        msgPipeP: 'Programa de formação que ensina a resolver 12 problemas empresariais utilizando dados e análises para reduzir CAC',

        language,
      };

    case 'es-ES':
      return {
        ...terms,
        welcomeText: 'Olá',
        titleFeed: 'Feed diário',

        btnWatch: 'Assistir →',
        btnLinkAccess: 'Acessar →',

        msgTwoMorevideos: '...mais 4 vídeos selecionados para você!',
        msgRestrictedAccess: 'Acesso restrito',
        msgAdmContact: 'Entre em contato com o administrador...',

        titleCopyCutAssets: 'CopyCut AI - Assets',
        msgCopyCutAssets: 'Herramienta de IA para Marketing Digital que crea contenidos profesionales y automatiza más de 50 tipos de activos personalizados.',

        titleCopyCutUnpaidPosts: 'CopyCut AI - Unpaid Posts',
        msgCopyCutUnpaidPosts: 'Herramienta de IA para Marketing Digital que automatiza la creación de publicaciones orgánicas y profesionales para LinkedIn, Instagram, Facebook, TikTok y Email Marketing.',

        titlePerfilP: 'Perfil Performance',
        msgPerfilP: 'Programa de desarrollo profesional que guía transformaciones de carrera a través de 6 pasos estructurados',

        titlePipeP: 'Pipe Performance',
        msgPipeP: 'Programa de entrenamiento que enseña a resolver 12 problemas empresariales usando datos y análisis para reducir CAC',

        language,
      };

    default:
      return terms;
  }
}

