import {
  useState, useEffect, createContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import ProductService from '../../services/Products';

const SideBarVideosContext = createContext();

function SideBarVideosProvider({ children }) {
  const params = useParams();
  const [product, setProduct] = useState({});
  const [list, setList] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [percentageWatched, setPercentageWatched] = useState(0);

  const idProduct = Number(params['*'].split('/')[1]); // client/academy/1[getthisValue]/46

  useEffect(() => {
    const productArea = params['*'].split('/')[0]; // client/academy[getthisValue]/1/46
    const isAProduct = idProduct && ['home', 'academy', 'consulting', 'intelligence'].includes(productArea);

    if (isAProduct) {
      async function init() {
        setIsLoadingList(true);
        await getProduct();
        await getList();
        setIsLoadingList(false);
      }

      init();
    }

    return () => {
      setProduct({});
      setList([]);
      setIsLoadingList(true);
    };
  }, [idProduct]);

  async function getProduct() {
    const product = await ProductService.findOne(idProduct);
    const percentageWatched = await ProductService.calculatePercentageWatched({ idProduct });
    setProduct(product);
    setPercentageWatched(percentageWatched);
  }

  async function getList() {
    try {
      setList([]);
      const listDB = await ProductService.getList(idProduct);
      setList(listDB);
    } catch {
      setList([]);
    }
  }

  const contextValues = useMemo(() => ({
    product,
    list,
    isLoadingList,
    percentageWatched,
  }), [
    product,
    list,
    isLoadingList,
    percentageWatched,
  ]);

  return (
    <SideBarVideosContext.Provider value={contextValues}>
      {children}
    </SideBarVideosContext.Provider>
  );
}

export { SideBarVideosContext, SideBarVideosProvider };
