import React from 'react';
import { Container, CardArea } from './style';
import { Card } from '../../components/Card';
import { Translate } from './translate';

export function ButtonsArea({
  buttonsArea,
  onGetDataSecondStage,
  onGetDataThirdStage,
  onGetDataFourthStage,
  onGetDataFifthStage,
  onGetDataSixthStage,
  onGetDataSeventhStage,
}) {
  const translate = Translate();

  const showTitle = buttonsArea.buttons.second;
  //! Ir colocando gradualmente
  // || buttonsArea.buttons.third
  // || buttonsArea.buttons.fourth
  // || buttonsArea.buttons.fifth
  // || buttonsArea.buttons.sixth
  // || buttonsArea.buttons.seventh;

  return (
    <Container>
      {(showTitle) && (
        <h2>{translate.mainTitle}</h2>
      )}
      <CardArea>
        {buttonsArea.buttons.second && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="6-7 min"
            estimatedTimeText={translate.estimatedTime}
            title={translate.card2Title}
            mainMessage={translate.card2Description}
            onHandleClick={onGetDataSecondStage}
          />
        )}

        {/* {buttonsArea.buttons.third && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="2-3 min"
            estimatedTimeText={translate.estimatedTime}
            mainMessage={translate.msgAssetsCopy}
            onHandleClick={onGetDataThirdStage}
            title={translate.titleAssetsCopy}
          />
        )}

        {buttonsArea.buttons.fourth && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="20-50 s"
            estimatedTimeText={translate.estimatedTime}
            mainMessage={translate.msgEngineValidation}
            onHandleClick={onGetDataFourthStage}
            title={translate.titleEngineValidation}
          />
        )}

        {buttonsArea.buttons.fifth && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="2-3 m"
            estimatedTimeText={translate.estimatedTime}
            mainMessage={translate.msgScaleCopy}
            onHandleClick={onGetDataFifthStage}
            title={translate.titleScaleCopy}
          />
        )}

        {buttonsArea.buttons.sixth && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="2-3 m"
            estimatedTimeText={translate.estimatedTime}
            mainMessage={translate.msgVideoAds}
            onHandleClick={onGetDataSixthStage}
            title={translate.titleVideoAds}
          />
        )}

        {buttonsArea.buttons.seventh && (
          <Card
            buttonContent={translate.btn}
            colorButton="yellow"
            estimatedTimeDuration="3-4 m"
            estimatedTimeText={translate.estimatedTime}
            mainMessage={translate.msgEmailMarketing}
            onHandleClick={onGetDataSeventhStage}
            title={translate.titleEmailMarketing}
          />
        )} */}
      </CardArea>
    </Container>
  );
}
