class CopyCutUnpaidPosts {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findOne({ id }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async linkedInLayer({ infos }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/linkedin-layer`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async instagramLayer({ infos }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/instagram-layer`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async facebookLayer({ infos }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/facebook-layer`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async tiktokLayer({ infos }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/tiktok-layer`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async emailLayer({ infos }) {
    const path = `${this.baseUrl}/copycut-unpaid-posts/email-layer`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }
}

export default new CopyCutUnpaidPosts();
