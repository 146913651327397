export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    titleSidebar: 'My Labs',
    loadingList: 'Loading list',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        titleSidebar: 'Meus Labs',
        loadingList: 'Carregando lista',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        titleSidebar: 'Meus Labs',
        loadingList: 'A carregar lista',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        titleSidebar: 'Mis Labs',
        loadingList: 'Cargando lista',
        language,
      };

    default:
      return terms;
  }
}
