export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    mainTitle: 'What would you like to create now?',

    titleVSLPolicyFree: 'VSL Policy Free - B2C/SMB and Enterprise',
    msgVSLPolicyFree: 'Create new optimized VSLs for both end consumers (SMB) and corporate clients (Enterprise), ensuring full compliance with Meta\'s privacy policies.',

    titleAssetsCopy: 'Assets Copy - Marketing and Sales',
    msgAssetsCopy: 'Generate persuasive texts and content for your marketing campaigns and sales materials. Receive impactful headlines, strategic FAQs, presentation texts, irresistible offers, optimized LinkedIn messages, outbound emails, sales conversation scripts, product names, prompts for high-conversion images, and success stories.',

    titleEngineValidation: 'Engine Validation - Sales Funnel Optimizer (Order Bump/Upsell)',
    msgEngineValidation: 'Develop efficient cross-selling and upselling strategies with optimized messaging. Receive complementary offer templates and upgrade models designed to naturally and persuasively increase your average sales value.',

    titleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    msgScaleCopy: 'Generate optimized content for paid campaigns, organic communication, and videos with different narratives. Get persuasive copy for Google Ads, Meta Ads, LinkedIn Ads, email, WhatsApp, and more, always aligned with each platform\'s best practices.',

    titleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',
    msgVideoAds: 'Create persuasive scripts for various video ad formats, from evolutionary narratives to disruptive approaches. Generate optimized content for Video Ads with different strategic angles, including transformational storytelling, myth-busting, success stories, and exclusive hacks, adapted for both B2C and Enterprise.',

    titleEmailMarketing: 'Email Marketing - High-Converting Persuasive Scripts',
    msgEmailMarketing: 'Get ready-to-use templates for different sales objectives, from authority building to presenting transformative solutions, while maintaining high engagement and conversion rates.',

    estimatedTime: 'Estimated time',
    btn: 'Create',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        mainTitle: 'O que deseja criar agora?',

        titleVSLPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        msgVSLPolicyFree: 'Crie novas VSLs otimizadas tanto para consumidores finais (SMB) como para clientes empresariais (Enterprise), garantindo total conformidade com as políticas de privacidade do Meta.',

        titleAssetsCopy: 'Assets Copy - Marketing e Vendas',
        msgAssetsCopy: 'Gere textos e conteúdos persuasivos para suas campanhas de marketing e materiais de vendas. Receba headlines impactantes, FAQs estratégicas, textos para apresentações, ofertas irresistíveis, mensagens otimizadas para LinkedIn, e-mails outbound, roteiros de conversas comerciais, nomes de produtos, prompts para imagens de alta conversão e cases de sucesso.',

        titleEngineValidation: 'Engine Validation - Otimizador de Funil de Vendas (Order Bump/Upsell)',
        msgEngineValidation: 'Desenvolva estratégias eficientes de venda cruzada e venda incremental com mensagens otimizadas. Receba modelos de ofertas complementares e propostas de upgrade, projetados para aumentar o valor médio das suas vendas de forma natural e persuasiva.',

        titleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        msgScaleCopy: 'Gere conteúdo otimizado para campanhas pagas, comunicação orgânica e vídeos com diferentes narrativas. Obtenha textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, e-mails, WhatsApp e muito mais, sempre alinhados às melhores práticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',
        msgVideoAds: 'Crie roteiros persuasivos para diferentes formatos de vídeos publicitários, desde narrativas evolutivas até abordagens disruptivas. Gere conteúdo otimizado para Video Ads com diferentes angles estratégicos, incluindo storytelling transformacional, destruição de mitos, casos de sucesso e hacks exclusivos, adaptados tanto para B2C quanto para Enterprise.',

        titleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',
        msgEmailMarketing: 'Receba modelos prontos para diferentes objetivos de venda, desde a construção de autoridade até a apresentação de soluções transformadoras, sempre mantendo alto engajamento e taxas de conversão.',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        mainTitle: 'O que pretende criar agora?',

        titleVSLPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        msgVSLPolicyFree: 'Crie novas VSLs otimizadas tanto para consumidores finais (SMB) como para clientes empresariais (Enterprise), garantindo total conformidade com as políticas de privacidade do Meta.',

        titleAssetsCopy: 'Assets Copy - Marketing e Vendas',
        msgAssetsCopy: 'Gere textos e conteúdos persuasivos para as suas campanhas de marketing e materiais de vendas. Receba títulos impactantes, FAQs estratégicas, textos para apresentações, ofertas irresistíveis, mensagens otimizadas para LinkedIn, e-mails de prospeção, guiões de conversas comerciais, nomes de produtos, prompts para imagens de alta conversão e casos de sucesso.',

        titleEngineValidation: 'Engine Validation - Otimizador de Funil de Vendas (Order Bump/Upsell)',
        msgEngineValidation: 'Desenvolva estratégias eficientes de venda cruzada e venda incremental com mensagens otimizadas. Receba modelos de ofertas complementares e propostas de atualização, projetados para aumentar o valor médio das suas vendas de forma natural e persuasiva.',

        titleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        msgScaleCopy: 'Gere conteúdo otimizado para campanhas pagas, comunicação orgânica e vídeos com diferentes narrativas. Obtenha textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, correio eletrónico, WhatsApp e muito mais, sempre alinhados às melhores práticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',
        msgVideoAds: 'Crie guiões persuasivos para diferentes formatos de vídeos publicitários, desde narrativas evolutivas até abordagens disruptivas. Gere conteúdo otimizado para Video Ads com diferentes ângulos estratégicos, incluindo narrativas transformacionais, desmistificação, casos de sucesso e dicas exclusivas, adaptados tanto para B2C como para Enterprise.',

        titleEmailMarketing: 'E-mail Marketing - Scripts Persuasivos de Alta Conversão',
        msgEmailMarketing: 'Receba modelos prontos para diferentes objetivos de venda, desde a construção de autoridade até à apresentação de soluções transformadoras, mantendo sempre um elevado envolvimento e taxas de conversão.',

        estimatedTime: 'Tempo estimado',
        btn: 'Criar',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        mainTitle: '¿Qué desea crear ahora?',

        titleVSLPolicyFree: 'VSL Libre de Políticas - B2C/SMB y Enterprise',
        msgVSLPolicyFree: 'Cree nuevos VSLs optimizados tanto para consumidores finales (SMB) como para clientes corporativos (Enterprise), garantizando el total cumplimiento de las políticas de privacidad de Meta.',

        titleAssetsCopy: 'Assets Copy - Marketing y Ventas',
        msgAssetsCopy: 'Genere textos y contenidos persuasivos para sus campañas de marketing y materiales de ventas. Reciba titulares impactantes, FAQs estratégicas, textos para presentaciones, ofertas irresistibles, mensajes optimizados para LinkedIn, correos electrónicos de prospección, guiones de conversaciones comerciales, nombres de productos, prompts para imágenes de alta conversión y casos de éxito.',

        titleEngineValidation: 'Engine Validation - Optimizador del Embudo de Ventas (Order Bump/Upsell)',
        msgEngineValidation: 'Desarrolle estrategias eficientes de venta cruzada y venta ascendente con mensajes optimizados. Reciba plantillas de ofertas complementarias y modelos de mejora, diseñados para aumentar el valor medio de sus ventas de forma natural y persuasiva.',

        titleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        msgScaleCopy: 'Genere contenido optimizado para campañas pagadas, comunicación orgánica y vídeos con diferentes narrativas. Obtenga textos persuasivos para Google Ads, Meta Ads, LinkedIn Ads, correo electrónico, WhatsApp y mucho más, siempre alineados con las mejores prácticas de cada plataforma.',

        titleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',
        msgVideoAds: 'Crea guiones persuasivos para diferentes formatos de videos publicitarios, desde narrativas evolutivas hasta enfoques disruptivos. Genera contenido optimizado para Video Ads con diferentes ángulos estratégicos, incluyendo storytelling transformacional, desmitificación, casos de éxito y trucos exclusivos, adaptados tanto para B2C como para Enterprise.',

        titleEmailMarketing: 'Email Marketing - Scripts Persuasivos de Alta Conversión',
        msgEmailMarketing: 'Reciba plantillas listas para diferentes objetivos de venta, desde la construcción de autoridad hasta la presentación de soluciones transformadoras, manteniendo siempre un alto compromiso y tasas de conversión.',

        estimatedTime: 'Tiempo estimado',
        btn: 'Crear',
        language,
      };

    default:
      return terms;
  }
}
