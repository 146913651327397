class CopyCutPaidAds {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findOne({ id }) {
    const path = `${this.baseUrl}/copycut-paid-ads/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postLayer1({ infos }) {
    const path = `${this.baseUrl}/copycut-paid-ads/layer-1`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postLayer2({ id }) {
    const path = `${this.baseUrl}/copycut-paid-ads/layer-2`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });
    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }
}

export default new CopyCutPaidAds();
