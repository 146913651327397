export const regions = {
  'North America': {
    Canada: [
      'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
      'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan',
      'Northwest Territories', 'Nunavut', 'Yukon',
    ],
    USA: [
      'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
      'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
      'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
      'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
      'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
      'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
      'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
      'Wisconsin', 'Wyoming',
    ],
    Mexico: [
      'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas',
      'Chihuahua', 'Coahuila', 'Colima', 'Durango', 'Guanajuato', 'Guerrero', 'Hidalgo',
      'Jalisco', 'México', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca',
      'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora',
      'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas',
    ],
    Guatemala: [
      'Alta Verapaz', 'Baja Verapaz', 'Chimaltenango', 'Chiquimula', 'El Progreso',
      'Escuintla', 'Guatemala', 'Huehuetenango', 'Izabal', 'Jalapa', 'Jutiapa', 'Petén',
      'Quetzaltenango', 'Quiché', 'Retalhuleu', 'Sacatepéquez', 'San Marcos',
      'Santa Rosa', 'Sololá', 'Suchitepéquez', 'Totonicapán', 'Zacapa',
    ],
    Honduras: [
      'Atlántida', 'Choluteca', 'Colón', 'Comayagua', 'Copán', 'Cortés', 'El Paraíso',
      'Francisco Morazán', 'Gracias a Dios', 'Intibucá', 'Islas de la Bahía', 'La Paz',
      'Lempira', 'Ocotepeque', 'Olancho', 'Santa Bárbara', 'Valle', 'Yoro',
    ],
    Nicaragua: [
      'Boaco', 'Carazo', 'Chinandega', 'Chontales', 'Costa Caribe Norte', 'Costa Caribe Sur',
      'Estelí', 'Granada', 'Jinotega', 'León', 'Madriz', 'Managua', 'Masaya', 'Matagalpa',
      'Nueva Segovia', 'Río San Juan', 'Rivas',
    ],
    'El Salvador': [
      'Ahuachapán', 'Cabañas', 'Chalatenango', 'Cuscatlán', 'La Libertad', 'La Paz',
      'La Unión', 'Morazán', 'San Miguel', 'San Salvador', 'San Vicente', 'Santa Ana',
      'Sonsonate', 'Usulután',
    ],
    'Costa Rica': [
      'Alajuela', 'Cartago', 'Guanacaste', 'Heredia', 'Limón', 'Puntarenas', 'San José',
    ],
    Panama: [
      'Bocas del Toro', 'Chiriquí', 'Coclé', 'Colón', 'Darién', 'Emberá-Wounaan',
      'Guna Yala', 'Herrera', 'Los Santos', 'Ngäbe-Buglé', 'Panamá', 'Panamá Oeste',
      'Veraguas',
    ],
    Belize: [
      'Belize', 'Cayo', 'Corozal', 'Orange Walk', 'Stann Creek', 'Toledo',
    ],
  },

  'South America': {
    Brazil: [
      'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal',
      'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul',
      'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí',
      'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia',
      'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins',
    ],
    Argentina: [
      'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'Ciudad Autónoma de Buenos Aires',
      'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy', 'La Pampa', 'La Rioja',
      'Mendoza', 'Misiones', 'Neuquén', 'Río Negro', 'Salta', 'San Juan', 'San Luis',
      'Santa Cruz', 'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucumán',
    ],
    Colombia: [
      'Amazonas', 'Antioquia', 'Arauca', 'Atlántico', 'Bogotá', 'Bolívar', 'Boyacá',
      'Caldas', 'Caquetá', 'Casanare', 'Cauca', 'Cesar', 'Chocó', 'Córdoba', 'Cundinamarca',
      'Guainía', 'Guaviare', 'Huila', 'La Guajira', 'Magdalena', 'Meta', 'Nariño',
      'Norte de Santander', 'Putumayo', 'Quindío', 'Risaralda', 'San Andrés y Providencia',
      'Santander', 'Sucre', 'Tolima', 'Valle del Cauca', 'Vaupés', 'Vichada',
    ],
    Peru: [
      'Amazonas', 'Áncash', 'Apurímac', 'Arequipa', 'Ayacucho', 'Cajamarca', 'Callao',
      'Cusco', 'Huancavelica', 'Huánuco', 'Ica', 'Junín', 'La Libertad', 'Lambayeque',
      'Lima', 'Loreto', 'Madre de Dios', 'Moquegua', 'Pasco', 'Piura', 'Puno',
      'San Martín', 'Tacna', 'Tumbes', 'Ucayali',
    ],
    Venezuela: [
      'Amazonas', 'Anzoátegui', 'Apure', 'Aragua', 'Barinas', 'Bolívar', 'Carabobo',
      'Cojedes', 'Delta Amacuro', 'Distrito Capital', 'Falcón', 'Guárico', 'Lara',
      'Mérida', 'Miranda', 'Monagas', 'Nueva Esparta', 'Portuguesa', 'Sucre', 'Táchira',
      'Trujillo', 'Vargas', 'Yaracuy', 'Zulia',
    ],
    Chile: [
      'Arica y Parinacota', 'Tarapacá', 'Antofagasta', 'Atacama', 'Coquimbo',
      'Valparaíso', 'Región Metropolitana', 'O\'Higgins', 'Maule', 'Ñuble', 'Biobío',
      'La Araucanía', 'Los Ríos', 'Los Lagos', 'Aysén', 'Magallanes',
    ],
    Ecuador: [
      'Azuay', 'Bolívar', 'Cañar', 'Carchi', 'Chimborazo', 'Cotopaxi', 'El Oro',
      'Esmeraldas', 'Galápagos', 'Guayas', 'Imbabura', 'Loja', 'Los Ríos', 'Manabí',
      'Morona Santiago', 'Napo', 'Orellana', 'Pastaza', 'Pichincha', 'Santa Elena',
      'Santo Domingo de los Tsáchilas', 'Sucumbíos', 'Tungurahua', 'Zamora Chinchipe',
    ],
    Bolivia: [
      'Beni', 'Chuquisaca', 'Cochabamba', 'La Paz', 'Oruro', 'Pando', 'Potosí',
      'Santa Cruz', 'Tarija',
    ],
    Paraguay: [
      'Alto Paraguay', 'Alto Paraná', 'Amambay', 'Boquerón', 'Caaguazú', 'Caazapá',
      'Canindeyú', 'Central', 'Concepción', 'Cordillera', 'Guairá', 'Itapúa',
      'Misiones', 'Ñeembucú', 'Paraguarí', 'Presidente Hayes', 'San Pedro',
    ],
    Uruguay: [
      'Artigas', 'Canelones', 'Cerro Largo', 'Colonia', 'Durazno', 'Flores', 'Florida',
      'Lavalleja', 'Maldonado', 'Montevideo', 'Paysandú', 'Río Negro', 'Rivera',
      'Rocha', 'Salto', 'San José', 'Soriano', 'Tacuarembó', 'Treinta y Tres',
    ],
    Guyana: [
      'Barima-Waini', 'Cuyuni-Mazaruni', 'Demerara-Mahaica', 'East Berbice-Corentyne',
      'Essequibo Islands-West Demerara', 'Mahaica-Berbice', 'Pomeroon-Supenaam',
      'Potaro-Siparuni', 'Upper Demerara-Berbice', 'Upper Takutu-Upper Essequibo',
    ],
    Suriname: [
      'Brokopondo', 'Commewijne', 'Coronie', 'Marowijne', 'Nickerie', 'Para',
      'Paramaribo', 'Saramacca', 'Sipaliwini', 'Wanica',
    ],
    'French Guiana': [
      'Cayenne', 'Saint-Laurent-du-Maroni',
    ],
    'Falkland Islands': [
      'East Falkland', 'West Falkland', 'Other Islands',
    ],
  },

  Europe: {
    'United Kingdom': [
      'England', 'Scotland', 'Wales', 'Northern Ireland',
    ],
    France: [
      'Auvergne-Rhône-Alpes', 'Bourgogne-Franche-Comté', 'Bretagne', 'Centre-Val de Loire',
      'Corse', 'Grand Est', 'Hauts-de-France', 'Île-de-France', 'Normandie',
      'Nouvelle-Aquitaine', 'Occitanie', 'Pays de la Loire', 'Provence-Alpes-Côte d\'Azur',
    ],
    Germany: [
      'Baden-Württemberg', 'Bavaria', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg',
      'Hesse', 'Lower Saxony', 'Mecklenburg-Vorpommern', 'North Rhine-Westphalia',
      'Rhineland-Palatinate', 'Saarland', 'Saxony', 'Saxony-Anhalt',
      'Schleswig-Holstein', 'Thuringia',
    ],
    Italy: [
      'Abruzzo', 'Aosta Valley', 'Apulia', 'Basilicata', 'Calabria', 'Campania',
      'Emilia-Romagna', 'Friuli Venezia Giulia', 'Lazio', 'Liguria', 'Lombardy',
      'Marche', 'Molise', 'Piedmont', 'Sardinia', 'Sicily', 'Trentino-South Tyrol',
      'Tuscany', 'Umbria', 'Veneto',
    ],
    Spain: [
      'Andalusia', 'Aragon', 'Asturias', 'Balearic Islands', 'Basque Country',
      'Canary Islands', 'Cantabria', 'Castile and León', 'Castilla-La Mancha',
      'Catalonia', 'Extremadura', 'Galicia', 'La Rioja', 'Madrid', 'Murcia',
      'Navarre', 'Valencia',
    ],
    Portugal: [
      'Azores', 'Alentejo', 'Algarve', 'Centro', 'Lisbon', 'Madeira', 'Norte',
    ],
    Ireland: [
      'Connacht', 'Leinster', 'Munster', 'Ulster',
    ],
    Netherlands: [
      'Drenthe', 'Flevoland', 'Friesland', 'Gelderland', 'Groningen', 'Limburg',
      'North Brabant', 'North Holland', 'Overijssel', 'South Holland', 'Utrecht', 'Zeeland',
    ],
    Belgium: [
      'Brussels-Capital Region', 'Flanders', 'Wallonia',
    ],
    Switzerland: [
      'Aargau', 'Appenzell Ausserrhoden', 'Appenzell Innerrhoden', 'Basel-Landschaft',
      'Basel-Stadt', 'Bern', 'Fribourg', 'Geneva', 'Glarus', 'Graubünden', 'Jura',
      'Lucerne', 'Neuchâtel', 'Nidwalden', 'Obwalden', 'Schaffhausen', 'Schwyz',
      'Solothurn', 'St. Gallen', 'Thurgau', 'Ticino', 'Uri', 'Valais', 'Vaud',
      'Zug', 'Zürich',
    ],
    Austria: [
      'Burgenland', 'Carinthia', 'Lower Austria', 'Upper Austria', 'Salzburg',
      'Styria', 'Tyrol', 'Vorarlberg', 'Vienna',
    ],
    Sweden: [
      'Blekinge', 'Dalarna', 'Gävleborg', 'Gotland', 'Halland', 'Jämtland',
      'Jönköping', 'Kalmar', 'Kronoberg', 'Norrbotten', 'Örebro', 'Östergötland',
      'Skåne', 'Södermanland', 'Stockholm', 'Uppsala', 'Värmland', 'Västerbotten',
      'Västernorrland', 'Västmanland', 'Västra Götaland',
    ],
    Norway: [
      'Agder', 'Innlandet', 'Møre og Romsdal', 'Nordland', 'Oslo', 'Rogaland',
      'Troms og Finnmark', 'Trøndelag', 'Vestfold og Telemark', 'Vestland', 'Viken',
    ],
    Denmark: [
      'Capital Region', 'Central Denmark', 'North Denmark', 'Region Zealand',
      'Region of Southern Denmark',
    ],
    Finland: [
      'Åland Islands', 'Central Finland', 'Central Ostrobothnia', 'Kainuu', 'Kymenlaakso',
      'Lapland', 'North Karelia', 'Northern Ostrobothnia', 'Northern Savonia',
      'Ostrobothnia', 'Päijänne Tavastia', 'Pirkanmaa', 'Satakunta', 'South Karelia',
      'Southern Ostrobothnia', 'Southern Savonia', 'Southwest Finland', 'Tavastia Proper',
      'Uusimaa',
    ],
    Poland: [
      'Greater Poland', 'Kuyavian-Pomeranian', 'Lesser Poland', 'Łódź', 'Lower Silesian',
      'Lublin', 'Lubusz', 'Masovian', 'Opole', 'Podkarpackie', 'Podlaskie', 'Pomeranian',
      'Silesian', 'Świętokrzyskie', 'Warmian-Masurian', 'West Pomeranian',
    ],
    'Czech Republic': [
      'Central Bohemian', 'Hradec Králové', 'Karlovy Vary', 'Liberec', 'Moravian-Silesian',
      'Olomouc', 'Pardubice', 'Plzeň', 'Prague', 'South Bohemian', 'South Moravian',
      'Ústí nad Labem', 'Vysočina', 'Zlín',
    ],
    Slovakia: [
      'Banská Bystrica', 'Bratislava', 'Košice', 'Nitra', 'Prešov', 'Trenčín',
      'Trnava', 'Žilina',
    ],
    Hungary: [
      'Bács-Kiskun', 'Baranya', 'Békés', 'Borsod-Abaúj-Zemplén', 'Budapest',
      'Csongrád-Csanád', 'Fejér', 'Győr-Moson-Sopron', 'Hajdú-Bihar', 'Heves',
      'Jász-Nagykun-Szolnok', 'Komárom-Esztergom', 'Nógrád', 'Pest', 'Somogy',
      'Szabolcs-Szatmár-Bereg', 'Tolna', 'Vas', 'Veszprém', 'Zala',
    ],
    Romania: [
      'Alba', 'Arad', 'Argeș', 'Bacău', 'Bihor', 'Bistrița-Năsăud', 'Botoșani',
      'Brăila', 'Brașov', 'București', 'Buzău', 'Călărași', 'Caraș-Severin',
      'Cluj', 'Constanța', 'Covasna', 'Dâmbovița', 'Dolj', 'Galați', 'Giurgiu',
      'Gorj', 'Harghita', 'Hunedoara', 'Ialomița', 'Iași', 'Ilfov', 'Maramureș',
      'Mehedinți', 'Mureș', 'Neamț', 'Olt', 'Prahova', 'Sălaj', 'Satu Mare',
      'Sibiu', 'Suceava', 'Teleorman', 'Timiș', 'Tulcea', 'Vâlcea', 'Vaslui', 'Vrancea',
    ],
    Bulgaria: [
      'Blagoevgrad', 'Burgas', 'Dobrich', 'Gabrovo', 'Haskovo', 'Kardzhali',
      'Kyustendil', 'Lovech', 'Montana', 'Pazardzhik', 'Pernik', 'Pleven', 'Plovdiv',
      'Razgrad', 'Ruse', 'Shumen', 'Silistra', 'Sliven', 'Smolyan', 'Sofia City',
      'Sofia Province', 'Stara Zagora', 'Targovishte', 'Varna', 'Veliko Tarnovo',
      'Vidin', 'Vratsa', 'Yambol',
    ],
    Greece: [
      'Attica', 'Central Greece', 'Central Macedonia', 'Crete', 'East Macedonia and Thrace',
      'Epirus', 'Ionian Islands', 'North Aegean', 'Peloponnese', 'South Aegean',
      'Thessaly', 'West Greece', 'West Macedonia',
    ],
    Croatia: [
      'Bjelovar-Bilogora', 'Brod-Posavina', 'Dubrovnik-Neretva', 'Istria',
      'Karlovac', 'Koprivnica-Križevci', 'Krapina-Zagorje', 'Lika-Senj',
      'Međimurje', 'Osijek-Baranja', 'Požega-Slavonia', 'Primorje-Gorski Kotar',
      'Šibenik-Knin', 'Sisak-Moslavina', 'Split-Dalmatia', 'Varaždin',
      'Virovitica-Podravina', 'Vukovar-Syrmia', 'Zadar', 'Zagreb County', 'City of Zagreb',
    ],
  },

  Asia: {
    China: [
      'Anhui', 'Beijing', 'Chongqing', 'Fujian', 'Gansu', 'Guangdong', 'Guangxi',
      'Guizhou', 'Hainan', 'Hebei', 'Heilongjiang', 'Henan', 'Hubei', 'Hunan',
      'Inner Mongolia', 'Jiangsu', 'Jiangxi', 'Jilin', 'Liaoning', 'Ningxia',
      'Qinghai', 'Shaanxi', 'Shandong', 'Shanghai', 'Shanxi', 'Sichuan', 'Tianjin',
      'Tibet', 'Xinjiang', 'Yunnan', 'Zhejiang',
    ],
    Japan: [
      'Aichi', 'Akita', 'Aomori', 'Chiba', 'Ehime', 'Fukui', 'Fukuoka', 'Fukushima',
      'Gifu', 'Gunma', 'Hiroshima', 'Hokkaido', 'Hyogo', 'Ibaraki', 'Ishikawa',
      'Iwate', 'Kagawa', 'Kagoshima', 'Kanagawa', 'Kochi', 'Kumamoto', 'Kyoto',
      'Mie', 'Miyagi', 'Miyazaki', 'Nagano', 'Nagasaki', 'Nara', 'Niigata', 'Oita',
      'Okayama', 'Okinawa', 'Osaka', 'Saga', 'Saitama', 'Shiga', 'Shimane',
      'Shizuoka', 'Tochigi', 'Tokushima', 'Tokyo', 'Tottori', 'Toyama', 'Wakayama',
      'Yamagata', 'Yamaguchi', 'Yamanashi',
    ],
    India: [
      'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa',
      'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala',
      'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland',
      'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
      'Uttar Pradesh', 'Uttarakhand', 'West Bengal',
    ],
    'South Korea': [
      'Seoul', 'Busan', 'Daegu', 'Incheon', 'Gwangju', 'Daejeon', 'Ulsan', 'Sejong',
      'Gyeonggi', 'Gangwon', 'North Chungcheong', 'South Chungcheong', 'North Jeolla',
      'South Jeolla', 'North Gyeongsang', 'South Gyeongsang', 'Jeju',
    ],
    'North Korea': [
      'Chagang', 'North Hamgyong', 'South Hamgyong', 'North Hwanghae', 'South Hwanghae',
      'Kangwon', 'North Pyongan', 'South Pyongan', 'Ryanggang', 'Rason', 'Pyongyang',
    ],
    Vietnam: [
      'An Giang', 'Ba Ria-Vung Tau', 'Bac Giang', 'Bac Kan', 'Bac Lieu', 'Bac Ninh',
      'Ben Tre', 'Binh Dinh', 'Binh Duong', 'Binh Phuoc', 'Binh Thuan', 'Ca Mau',
      'Can Tho', 'Cao Bang', 'Da Nang', 'Dak Lak', 'Dak Nong', 'Dien Bien', 'Dong Nai',
      'Dong Thap', 'Gia Lai', 'Ha Giang', 'Ha Nam', 'Ha Noi', 'Ha Tinh', 'Hai Duong',
      'Hai Phong', 'Hau Giang', 'Hoa Binh', 'Hung Yen', 'Khanh Hoa', 'Kien Giang',
      'Kon Tum', 'Lai Chau', 'Lam Dong', 'Lang Son', 'Lao Cai', 'Long An', 'Nam Dinh',
      'Nghe An', 'Ninh Binh', 'Ninh Thuan', 'Phu Tho', 'Phu Yen', 'Quang Binh',
      'Quang Nam', 'Quang Ngai', 'Quang Ninh', 'Quang Tri', 'Soc Trang', 'Son La',
      'Tay Ninh', 'Thai Binh', 'Thai Nguyen', 'Thanh Hoa', 'Thua Thien-Hue',
      'Tien Giang', 'Tra Vinh', 'Tuyen Quang', 'Vinh Long', 'Vinh Phuc', 'Yen Bai',
    ],
    Thailand: [
      'Bangkok', 'Amnat Charoen', 'Ang Thong', 'Bueng Kan', 'Buriram', 'Chachoengsao',
      'Chai Nat', 'Chaiyaphum', 'Chanthaburi', 'Chiang Mai', 'Chiang Rai', 'Chonburi',
      'Chumphon', 'Kalasin', 'Kamphaeng Phet', 'Kanchanaburi', 'Khon Kaen',
      'Krabi', 'Lampang', 'Lamphun', 'Loei', 'Lopburi', 'Mae Hong Son',
      'Maha Sarakham', 'Mukdahan', 'Nakhon Nayok', 'Nakhon Pathom', 'Nakhon Phanom',
      'Nakhon Ratchasima', 'Nakhon Sawan', 'Nakhon Si Thammarat', 'Nan',
      'Narathiwat', 'Nong Bua Lamphu', 'Nong Khai', 'Nonthaburi', 'Pathum Thani',
      'Pattani', 'Phang Nga', 'Phatthalung', 'Phayao', 'Phetchabun', 'Phetchaburi',
      'Phichit', 'Phitsanulok', 'Phra Nakhon Si Ayutthaya', 'Phrae', 'Phuket',
      'Prachinburi', 'Prachuap Khiri Khan', 'Ranong', 'Ratchaburi', 'Rayong',
      'Roi Et', 'Sa Kaeo', 'Sakon Nakhon', 'Samut Prakan', 'Samut Sakhon',
      'Samut Songkhram', 'Saraburi', 'Satun', 'Sing Buri', 'Sisaket', 'Songkhla',
      'Sukhothai', 'Suphan Buri', 'Surat Thani', 'Surin', 'Tak', 'Trang',
      'Trat', 'Ubon Ratchathani', 'Udon Thani', 'Uthai Thani', 'Uttaradit',
      'Yala', 'Yasothon',
    ],
    Indonesia: [
      'Aceh', 'Bali', 'Bangka Belitung', 'Banten', 'Bengkulu', 'Central Java',
      'Central Kalimantan', 'Central Sulawesi', 'East Java', 'East Kalimantan',
      'East Nusa Tenggara', 'Gorontalo', 'Jakarta', 'Jambi', 'Lampung', 'Maluku',
      'North Kalimantan', 'North Maluku', 'North Sulawesi', 'North Sumatra',
      'Papua', 'Riau', 'Riau Islands', 'South Kalimantan', 'South Sulawesi',
      'South Sumatra', 'Southeast Sulawesi', 'West Java', 'West Kalimantan',
      'West Nusa Tenggara', 'West Papua', 'West Sulawesi', 'West Sumatra', 'Yogyakarta',
    ],
    Malaysia: [
      'Johor', 'Kedah', 'Kelantan', 'Kuala Lumpur', 'Labuan', 'Malacca', 'Negeri Sembilan',
      'Pahang', 'Penang', 'Perak', 'Perlis', 'Putrajaya', 'Sabah', 'Sarawak', 'Selangor',
      'Terengganu',
    ],
    Philippines: [
      'Abra', 'Agusan del Norte', 'Agusan del Sur', 'Aklan', 'Albay', 'Antique',
      'Apayao', 'Aurora', 'Basilan', 'Bataan', 'Batanes', 'Batangas', 'Benguet',
      'Biliran', 'Bohol', 'Bukidnon', 'Bulacan', 'Cagayan', 'Camarines Norte',
      'Camarines Sur', 'Camiguin', 'Capiz', 'Catanduanes', 'Cavite', 'Cebu',
      'Cotabato', 'Davao de Oro', 'Davao del Norte', 'Davao del Sur', 'Davao Occidental',
      'Davao Oriental', 'Dinagat Islands', 'Eastern Samar', 'Guimaras', 'Ifugao',
      'Ilocos Norte', 'Ilocos Sur', 'Iloilo', 'Isabela', 'Kalinga', 'La Union',
      'Laguna', 'Lanao del Norte', 'Lanao del Sur', 'Leyte', 'Maguindanao',
      'Marinduque', 'Masbate', 'Metro Manila', 'Misamis Occidental', 'Misamis Oriental',
      'Mountain Province', 'Negros Occidental', 'Negros Oriental', 'Northern Samar',
      'Nueva Ecija', 'Nueva Vizcaya', 'Occidental Mindoro', 'Oriental Mindoro',
      'Palawan', 'Pampanga', 'Pangasinan', 'Quezon', 'Quirino', 'Rizal', 'Romblon',
      'Samar', 'Sarangani', 'Siquijor', 'Sorsogon', 'South Cotabato', 'Southern Leyte',
      'Sultan Kudarat', 'Sulu', 'Surigao del Norte', 'Surigao del Sur', 'Tarlac',
      'Tawi-Tawi', 'Zambales', 'Zamboanga del Norte', 'Zamboanga del Sur',
      'Zamboanga Sibugay',
    ],
    Pakistan: [
      'Azad Kashmir', 'Balochistan', 'Gilgit-Baltistan', 'Islamabad',
      'Khyber Pakhtunkhwa', 'Punjab', 'Sindh',
    ],
    Bangladesh: [
      'Barisal', 'Chittagong', 'Dhaka', 'Khulna', 'Mymensingh', 'Rajshahi',
      'Rangpur', 'Sylhet',
    ],
    Myanmar: [
      'Ayeyarwady', 'Bago', 'Chin', 'Kachin', 'Kayah', 'Kayin', 'Magway',
      'Mandalay', 'Mon', 'Naypyidaw', 'Rakhine', 'Sagaing', 'Shan', 'Tanintharyi',
      'Yangon',
    ],
    Cambodia: [
      'Banteay Meanchey', 'Battambang', 'Kampong Cham', 'Kampong Chhnang',
      'Kampong Speu', 'Kampong Thom', 'Kampot', 'Kandal', 'Kep', 'Koh Kong',
      'Kratie', 'Mondulkiri', 'Oddar Meanchey', 'Pailin', 'Phnom Penh',
      'Preah Sihanouk', 'Preah Vihear', 'Prey Veng', 'Pursat', 'Ratanakiri',
      'Siem Reap', 'Stung Treng', 'Svay Rieng', 'Takeo', 'Tboung Khmum',
    ],
    Laos: [
      'Attapeu', 'Bokeo', 'Bolikhamxai', 'Champasak', 'Houaphanh', 'Khammouane',
      'Luang Namtha', 'Luang Prabang', 'Oudomxay', 'Phongsaly', 'Salavan',
      'Savannakhet', 'Sekong', 'Vientiane Capital', 'Vientiane Province', 'Xaisomboun',
      'Xayaboury', 'Xieng Khouang',
    ],
    Mongolia: [
      'Arkhangai', 'Bayan-Ölgii', 'Bayankhongor', 'Bulgan', 'Darkhan-Uul', 'Dornod',
      'Dornogovi', 'Dundgovi', 'Govi-Altai', 'Govisümber', 'Khentii', 'Khovd',
      'Khövsgöl', 'Ömnögovi', 'Orkhon', 'Övörkhangai', 'Selenge', 'Sükhbaatar',
      'Töv', 'Ulaanbaatar', 'Uvs', 'Zavkhan',
    ],
    Nepal: [
      'Province No. 1', 'Province No. 2', 'Bagmati', 'Gandaki', 'Lumbini',
      'Karnali', 'Sudurpashchim',
    ],
    'Sri Lanka': [
      'Central', 'Eastern', 'North Central', 'Northern', 'North Western',
      'Sabaragamuwa', 'Southern', 'Uva', 'Western',
    ],
    Kazakhstan: [
      'Akmola', 'Aktobe', 'Almaty', 'Almaty City', 'Atyrau', 'East Kazakhstan',
      'Jambyl', 'Karaganda', 'Kostanay', 'Kyzylorda', 'Mangystau', 'North Kazakhstan',
      'Nur-Sultan', 'Pavlodar', 'Shymkent', 'Turkestan', 'West Kazakhstan',
    ],
    Uzbekistan: [
      'Andijan', 'Bukhara', 'Fergana', 'Jizzakh', 'Karakalpakstan', 'Namangan',
      'Navoiy', 'Qashqadaryo', 'Samarqand', 'Sirdaryo', 'Surxondaryo', 'Tashkent',
      'Tashkent City', 'Xorazm',
    ],
    Turkmenistan: [
      'Ahal', 'Ashgabat', 'Balkan', 'Dasoguz', 'Lebap', 'Mary',
    ],
    Kyrgyzstan: [
      'Batken', 'Bishkek', 'Chuy', 'Issyk-Kul', 'Jalal-Abad', 'Naryn', 'Osh',
      'Osh City', 'Talas',
    ],
    Tajikistan: [
      'Districts of Republican Subordination', 'Gorno-Badakhshan', 'Khatlon', 'Sughd',
    ],
    Afghanistan: [
      'Badakhshan', 'Badghis', 'Baghlan', 'Balkh', 'Bamyan', 'Daykundi', 'Farah',
      'Faryab', 'Ghazni', 'Ghor', 'Helmand', 'Herat', 'Jowzjan', 'Kabul', 'Kandahar',
      'Kapisa', 'Khost', 'Kunar', 'Kunduz', 'Laghman', 'Logar', 'Nangarhar',
      'Nimruz', 'Nuristan', 'Paktia', 'Paktika', 'Panjshir', 'Parwan', 'Samangan',
      'Sar-e Pol', 'Takhar', 'Uruzgan', 'Wardak', 'Zabul',
    ],
  },

  Africa: {
    Algeria: [
      'Adrar', 'Ain Defla', 'Ain Temouchent', 'Algiers', 'Annaba', 'Batna',
      'Bechar', 'Bejaia', 'Biskra', 'Blida', 'Bordj Bou Arreridj', 'Bouira',
      'Boumerdes', 'Chlef', 'Constantine', 'Djelfa', 'El Bayadh', 'El Oued',
      'El Tarf', 'Ghardaia', 'Guelma', 'Illizi', 'Jijel', 'Khenchela', 'Laghouat',
      'Mascara', 'Medea', 'Mila', 'Mostaganem', 'MSila', 'Naama', 'Oran',
      'Ouargla', 'Oum El Bouaghi', 'Relizane', 'Saida', 'Setif', 'Sidi Bel Abbes',
      'Skikda', 'Souk Ahras', 'Tamanrasset', 'Tebessa', 'Tiaret', 'Tindouf',
      'Tipaza', 'Tissemsilt', 'Tizi Ouzou', 'Tlemcen',
    ],
    Egypt: [
      'Alexandria', 'Aswan', 'Asyut', 'Beheira', 'Beni Suef', 'Cairo', 'Dakahlia',
      'Damietta', 'Faiyum', 'Gharbia', 'Giza', 'Ismailia', 'Kafr el-Sheikh',
      'Luxor', 'Matrouh', 'Minya', 'Monufia', 'New Valley', 'North Sinai',
      'Port Said', 'Qalyubia', 'Qena', 'Red Sea', 'Sharqia', 'Sohag',
      'South Sinai', 'Suez',
    ],
    'South Africa': [
      'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo',
      'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape',
    ],
    Nigeria: [
      'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue',
      'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'Gombe',
      'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara',
      'Lagos', 'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau',
      'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara', 'Federal Capital Territory',
    ],
    Morocco: [
      'Beni Mellal-Khenifra', 'Casablanca-Settat', 'Dakhla-Oued Ed-Dahab',
      'Draa-Tafilalet', 'Fes-Meknes', 'Guelmim-Oued Noun', 'Laayoune-Sakia El Hamra',
      'Marrakech-Safi', 'Oriental', 'Rabat-Sale-Kenitra', 'Souss-Massa',
      'Tanger-Tetouan-Al Hoceima',
    ],
    Kenya: [
      'Baringo', 'Bomet', 'Bungoma', 'Busia', 'Elgeyo Marakwet', 'Embu', 'Garissa',
      'Homa Bay', 'Isiolo', 'Kajiado', 'Kakamega', 'Kericho', 'Kiambu', 'Kilifi',
      'Kirinyaga', 'Kisii', 'Kisumu', 'Kitui', 'Kwale', 'Laikipia', 'Lamu',
      'Machakos', 'Makueni', 'Mandera', 'Marsabit', 'Meru', 'Migori', 'Mombasa',
      'Murang\'a', 'Nairobi', 'Nakuru', 'Nandi', 'Narok', 'Nyamira', 'Nyandarua',
      'Nyeri', 'Samburu', 'Siaya', 'Taita Taveta', 'Tana River', 'Tharaka Nithi',
      'Trans Nzoia', 'Turkana', 'Uasin Gishu', 'Vihiga', 'Wajir', 'West Pokot',
    ],
    Ethiopia: [
      'Addis Ababa', 'Afar', 'Amhara', 'Benishangul-Gumuz', 'Dire Dawa', 'Gambela',
      'Harari', 'Oromia', 'Sidama', 'Somali', 'Southern Nations, Nationalities and Peoples',
      'Tigray',
    ],
    Ghana: [
      'Ahafo', 'Ashanti', 'Bono', 'Bono East', 'Central', 'Eastern', 'Greater Accra',
      'North East', 'Northern', 'Oti', 'Savannah', 'Upper East', 'Upper West', 'Volta',
      'Western', 'Western North',
    ],
    Tanzania: [
      'Arusha', 'Dar es Salaam', 'Dodoma', 'Geita', 'Iringa', 'Kagera', 'Katavi',
      'Kigoma', 'Kilimanjaro', 'Lindi', 'Manyara', 'Mara', 'Mbeya', 'Morogoro',
      'Mtwara', 'Mwanza', 'Njombe', 'Pemba North', 'Pemba South', 'Pwani',
      'Rukwa', 'Ruvuma', 'Shinyanga', 'Simiyu', 'Singida', 'Songwe', 'Tabora',
      'Tanga', 'Zanzibar Central/South', 'Zanzibar North', 'Zanzibar Urban/West',
    ],
    Uganda: [
      'Central Region', 'Eastern Region', 'Northern Region', 'Western Region',
    ],
    Mozambique: [
      'Cabo Delgado', 'Gaza', 'Inhambane', 'Manica', 'Maputo', 'Maputo City',
      'Nampula', 'Niassa', 'Sofala', 'Tete', 'Zambezia',
    ],
    Madagascar: [
      'Alaotra-Mangoro', 'Amoron\'i Mania', 'Analamanga', 'Analanjirofo',
      'Androy', 'Anosy', 'Atsimo-Andrefana', 'Atsinanana', 'Betsiboka',
      'Boeny', 'Bongolava', 'Diana', 'Haute Matsiatra', 'Ihorombe', 'Itasy',
      'Melaky', 'Menabe', 'Sava', 'Sofia', 'Vakinankaratra', 'Vatovavy-Fitovinany',
    ],
    Cameroon: [
      'Adamawa', 'Centre', 'East', 'Far North', 'Littoral', 'North', 'Northwest',
      'South', 'Southwest', 'West',
    ],
    'Ivory Coast': [
      'Abidjan', 'Bas-Sassandra', 'Comoé', 'Denguélé', 'Gôh-Djiboua',
      'Lacs', 'Lagunes', 'Montagnes', 'Sassandra-Marahoué', 'Savanes',
      'Vallée du Bandama', 'Woroba', 'Yamoussoukro', 'Zanzan',
    ],
    Angola: [
      'Bengo', 'Benguela', 'Bié', 'Cabinda', 'Cuando Cubango', 'Cuanza Norte',
      'Cuanza Sul', 'Cunene', 'Huambo', 'Huíla', 'Luanda', 'Lunda Norte',
      'Lunda Sul', 'Malanje', 'Moxico', 'Namibe', 'Uíge', 'Zaire',
    ],
    Senegal: [
      'Dakar', 'Diourbel', 'Fatick', 'Kaffrine', 'Kaolack', 'Kédougou',
      'Kolda', 'Louga', 'Matam', 'Saint-Louis', 'Sédhiou', 'Tambacounda',
      'Thiès', 'Ziguinchor',
    ],
    Mali: [
      'Bamako', 'Gao', 'Kayes', 'Kidal', 'Koulikoro', 'Menaka', 'Mopti',
      'Segou', 'Sikasso', 'Taoudenit', 'Timbuktu',
    ],
    'Burkina Faso': [
      'Boucle du Mouhoun', 'Cascades', 'Centre', 'Centre-Est', 'Centre-Nord',
      'Centre-Ouest', 'Centre-Sud', 'Est', 'Hauts-Bassins', 'Nord',
      'Plateau-Central', 'Sahel', 'Sud-Ouest',
    ],
    Zambia: [
      'Central', 'Copperbelt', 'Eastern', 'Luapula', 'Lusaka', 'Muchinga',
      'Northern', 'North-Western', 'Southern', 'Western',
    ],
    Zimbabwe: [
      'Bulawayo', 'Harare', 'Manicaland', 'Mashonaland Central',
      'Mashonaland East', 'Mashonaland West', 'Masvingo', 'Matabeleland North',
      'Matabeleland South', 'Midlands',
    ],
  },

  Oceania: {
    Australia: [
      'Australian Capital Territory', 'New South Wales', 'Northern Territory',
      'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia',
    ],
    'New Zealand': [
      'Northland', 'Auckland', 'Waikato', 'Bay of Plenty', 'Gisborne',
      'Hawke\'s Bay', 'Taranaki', 'Manawatu-Whanganui', 'Wellington',
      'Tasman', 'Nelson', 'Marlborough', 'West Coast', 'Canterbury',
      'Otago', 'Southland',
    ],
    'Papua New Guinea': [
      'Bougainville', 'Central', 'Chimbu', 'East New Britain', 'East Sepik',
      'Eastern Highlands', 'Enga', 'Gulf', 'Hela', 'Jiwaka', 'Madang', 'Manus',
      'Milne Bay', 'Morobe', 'National Capital District', 'New Ireland',
      'Northern', 'Southern Highlands', 'West New Britain', 'West Sepik',
      'Western', 'Western Highlands',
    ],
    Fiji: [
      'Central', 'Eastern', 'Northern', 'Western',
    ],
    'Solomon Islands': [
      'Central', 'Choiseul', 'Guadalcanal', 'Isabel', 'Makira-Ulawa', 'Malaita',
      'Rennell and Bellona', 'Temotu', 'Western',
    ],
    Vanuatu: [
      'Malampa', 'Penama', 'Sanma', 'Shefa', 'Tafea', 'Torba',
    ],
    'New Caledonia': [
      'Loyalty Islands', 'North Province', 'South Province',
    ],
    'French Polynesia': [
      'Austral Islands', 'Gambier Islands', 'Marquesas Islands',
      'Society Islands', 'Tuamotu Archipelago',
    ],
    Samoa: [
      'A\'ana', 'Aiga-i-le-Tai', 'Atua', 'Fa\'asaleleaga', 'Gaga\'emauga',
      'Gaga\'ifomauga', 'Palauli', 'Satupa\'itea', 'Tuamasaga', 'Va\'a-o-Fonoti',
      'Vaisigano',
    ],
    Tonga: [
      'Eua', 'Ha\'apai', 'Niuas', 'Tongatapu', 'Vava\'u',
    ],
    Micronesia: [
      'Chuuk', 'Kosrae', 'Pohnpei', 'Yap',
    ],
    Kiribati: [
      'Gilbert Islands', 'Line Islands', 'Phoenix Islands',
    ],
    Tuvalu: [
      'Funafuti', 'Nanumanga', 'Nanumea', 'Niutao', 'Nui', 'Nukufetau',
      'Nukulaelae', 'Vaitupu',
    ],
    Palau: [
      'Aimeliik', 'Airai', 'Angaur', 'Hatohobei', 'Kayangel', 'Koror', 'Melekeok',
      'Ngaraard', 'Ngarchelong', 'Ngardmau', 'Ngatpang', 'Ngchesar', 'Ngeremlengui',
      'Ngiwal', 'Peleliu', 'Sonsorol',
    ],
    'Marshall Islands': [
      'Ailinglaplap', 'Ailuk', 'Arno', 'Aur', 'Ebon', 'Enewetak', 'Jabat',
      'Jaluit', 'Kili', 'Kwajalein', 'Lae', 'Lib', 'Likiep', 'Majuro',
      'Maloelap', 'Mejit', 'Mili', 'Namdrik', 'Namu', 'Rongelap', 'Ujae',
      'Ujelang', 'Utirik', 'Wotho', 'Wotje',
    ],
    Nauru: [
      'Aiwo', 'Anabar', 'Anetan', 'Anibare', 'Baiti', 'Boe', 'Buada', 'Denigomodu',
      'Ewa', 'Ijuw', 'Meneng', 'Nibok', 'Uaboe', 'Yaren',
    ],
  },

  Antarctica: {
    Territories: [
      'Adélie Land (France)',
      'Australian Antarctic Territory (Australia)',
      'British Antarctic Territory (United Kingdom)',
      'Chilean Antarctic Territory (Chile)',
      'Argentine Antarctica (Argentina)',
      'Peter I Island (Norway)',
      'Queen Maud Land (Norway)',
      'Ross Dependency (New Zealand)',
      'Marie Byrd Land (Unclaimed)',
    ],
    'Research Stations': [
      '(American Stations) McMurdo Station',
      '(American Stations) Amundsen-Scott South Pole Station',
      '(American Stations) Palmer Station',
      '(Russian Stations) Vostok Station',
      '(Russian Stations) Novolazarevskaya Station',
      '(Russian Stations) Bellingshausen Station',
      '(Russian Stations) Progress Station',
      '(Russian Stations) Mirny Station',
      '(British Stations) Rothera Research Station',
      '(British Stations) Halley Research Station',
      '(British Stations) Signy Research Station',
      '(Chinese Stations) Great Wall Station',
      '(Chinese Stations) Zhongshan Station',
      '(Chinese Stations) Kunlun Station',
      '(Chinese Stations) Taishan Station',
      '(Australian Stations) Mawson Station',
      '(Australian Stations) Davis Station',
      '(Australian Stations) Casey Station',
      '(Japanese Stations) Syowa Station',
      '(Japanese Stations) Dome Fuji Station',
      '(French Stations) Dumont d\'Urville Station',
      '(French Stations) Concordia Station',
      '(German Stations) Neumayer-Station III',
      '(Italian Stations) Mario Zucchelli Station',
      '(New Zealand Stations) Scott Base',
      '(South Korean Stations) King Sejong Station',
      '(South Korean Stations) Jang Bogo Station',
      '(Indian Stations) Bharati Station',
      '(Indian Stations) Maitri Station',
    ],
    Features: [
      'Antarctic Peninsula',
      'Ross Ice Shelf',
      'Transantarctic Mountains',
      'Vinson Massif',
      'Mount Erebus',
      'Dry Valleys',
      'Lake Vostok',
      'Weddell Sea',
      'Ross Sea',
      'South Pole',
    ],
  },
};
