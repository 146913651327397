export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    txtCopyCutAssets: 'AI Tool that transforms a single input into powerful and persuasive texts. Creates adaptable VSLs, impactful sales letters, high-converting headlines, and offers a complete copywriting suite.',

    txtCopyPaidAds: 'AI Tool that transforms a single input into powerful and persuasive texts for your social media. Generates optimized content for paid channels like Instagram, Facebook, LinkedIn, and TikTok, as well as creates highly engaging email marketing sequences.',

    txtCopyUnpaidPosts: 'AI Tool for Digital Marketing that automates the creation of organic and professional posts. Produce optimized content for LinkedIn, Instagram, Facebook, TikTok, and Email Marketing campaigns, saving time and maximizing results.',

    txtTranslateAI: 'Complete translation solution that combines the best of both worlds: literal precision when needed and intelligent contextual adaptation, respecting every cultural and linguistic nuance.',
    badgeSoon: 'Coming soon',
    badgeNew: 'New',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        txtCopyCutAssets: 'Ferramenta de IA que transforma um único input em textos poderosos e persuasivos. Cria VSLs adaptáveis, cartas de vendas impactantes, headlines que convertem e oferece uma suíte completa de copywriting.',

        txtCopyPaidAds: 'Ferramenta de IA que transforma um único input em textos poderosos e persuasivos para suas redes sociais. Gera conteúdos otimizados para canais pagos, como Instagram, Facebook, LinkedIn e TikTok, além de criar sequências de e-mail marketing altamente engajadoras.',

        txtCopyUnpaidPosts: 'Ferramenta de IA para Marketing Digital que automatiza a criação de posts orgânicos e profissionais. Produza conteúdos otimizados para LinkedIn, Instagram, Facebook, TikTok e campanhas de E-mail Marketing, economizando tempo e maximizando resultados.',

        txtTranslateAI: 'Solução completa de tradução que une o melhor dos dois mundos: precisão literal quando necessário e adaptação contextual inteligente, respeitando cada nuance cultural e linguística.',
        badgeSoon: 'Em breve',
        badgeNew: 'Novo',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        txtCopyCutAssets: 'Ferramenta de IA que transforma um único input em textos poderosos e persuasivos. Cria VSLs adaptáveis, cartas de vendas impactantes, headlines que convertem e oferece uma suite completa de copywriting.',

        txtCopyPaidAds: 'Ferramenta de IA que transforma um único input em textos poderosos e persuasivos para as suas redes sociais. Gera conteúdos otimizados para canais pagos, como Instagram, Facebook, LinkedIn e TikTok, além de criar sequências de e-mail marketing altamente envolventes.',

        txtCopyUnpaidPosts: 'Ferramenta de IA para Marketing Digital que automatiza a criação de publicações orgânicas e profissionais. Produza conteúdos otimizados para LinkedIn, Instagram, Facebook, TikTok e campanhas de E-mail Marketing, economizando tempo e maximizando resultados.',

        txtTranslateAI: 'Solução completa de tradução que une o melhor dos dois mundos: precisão literal quando necessário e adaptação contextual inteligente, respeitando cada nuance cultural e linguística.',
        badgeSoon: 'Em breve',
        badgeNew: 'Novo',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        txtCopyCutAssets: 'Herramienta de IA que transforma un único input en textos poderosos y persuasivos. Crea VSLs adaptables, cartas de ventas impactantes, titulares que convierten y ofrece una suite completa de copywriting.',

        txtCopyPaidAds: 'Herramienta de IA que transforma un único input en textos poderosos y persuasivos para tus redes sociales. Genera contenidos optimizados para canales pagos, como Instagram, Facebook, LinkedIn y TikTok, además de crear secuencias de email marketing altamente atractivas.',

        txtCopyUnpaidPosts: 'Herramienta de IA para Marketing Digital que automatiza la creación de publicaciones orgánicas y profesionales. Produce contenidos optimizados para LinkedIn, Instagram, Facebook, TikTok y campañas de Email Marketing, ahorrando tiempo y maximizando resultados.',

        txtTranslateAI: 'Solución completa de traducción que une lo mejor de ambos mundos: precisión literal cuando es necesario y adaptación contextual inteligente, respetando cada matiz cultural y lingüístico.',
        badgeSoon: 'Próximamente',
        badgeNew: 'Nuevo',
        language,
      };

    default:
      return terms;
  }
}
