/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../style';

export function PrivacyPolicyContentEN() {
  return (
    <Container>
      <h2>PRIVACY POLICY - COPYCUT AI</h2>
      <p>(A product of SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP)</p>
      <p>Last Updated: March 12, 2025</p>

      <h3>1. INTRODUCTION</h3>
      <p>SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP ("SHIFTERS," "we," "our," or "us"), registered under CNPJ/MF: 15.563.090/0001-04, with headquarters in Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011, São Paulo, Brazil, operates CopyCut AI, an AI-powered marketing content generation platform. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services, visit our website at https://app.shifters.ai or https://www.shifters.ai or https://shifters.ai, or interact with any of our applications, platforms, or related services (collectively, the "Services").
        As a company based in Brazil, we are primarily governed by Brazilian data protection laws, specifically the Lei Geral de Proteção de Dados (LGPD), Law No. 13,709/2018. However, we respect and comply with other applicable data protection regulations to the extent they apply to our operations.
        This Privacy Policy is designed to help you understand our practices regarding your personal information and to inform you about your privacy rights and how the law protects you. We are committed to protecting your privacy and handling your data in an open and transparent manner.
        By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. If you do not agree with our policies and practices, please do not use our Services.
        IMPORTANT NOTICE: While we strive to provide accurate and reliable AI-generated content, language models can make mistakes. Users are solely responsible for reviewing all generated content for accuracy, appropriateness, and legal compliance before use. CopyCut AI assumes no liability for decisions made or actions taken based on AI-generated content.
      </p>

      <h3>2. DEFINITIONS</h3>
      <p>To help you better understand this Privacy Policy, we define the following terms:
        "Personal Data" or "Personal Information": Any information that relates to an identified or identifiable individual, such as name, email address, identification number, location data, online identifier, or factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that person.
        "Processing": Any operation performed on Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.
        "Data Controller": The entity that determines the purposes and means of the Processing of Personal Data. For the purposes of this Privacy Policy, SHIFTERS is the Data Controller.
        "Data Processor": An entity that Processes Personal Data on behalf of the Data Controller.
        "Data Subject": An identified or identifiable natural person to whom the Personal Data relates.
        "User", "you", or "your": Any individual who accesses or uses our Services.
        "Generated Content": Any content created by our AI systems based on your inputs, prompts, or instructions.
        "LGPD": Lei Geral de Proteção de Dados (Brazilian General Data Protection Law), Law No. 13,709/2018, which is the primary data protection law governing our operations.
        "ANPD": Autoridade Nacional de Proteção de Dados, the Brazilian National Data Protection Authority.
        "Special Categories of Personal Data": Personal Data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, data concerning health, data concerning a person's sex life or sexual orientation.
        "Cookies": Small text files that are placed on your device when you visit a website, which enable various functionalities and data collection.
      </p>

      <h3>3. INFORMATION WE COLLECT</h3>
      <p>We collect several types of information from and about users of our Services, including:</p>
      <p>
        <h4>A. Personal Information You Provide to Us</h4>
        <ul>
          <li>Account Information: When you register for an account, we collect your name, email address, phone number, username, password, and profile information.</li>
          <li>Business Information: Company name, business type, industry, size, role/position, and business address.</li>
          <li>Payment Information: Billing address, payment card details, bank account information, and transaction history. Note that payment processing is handled by our third-party payment processors, and we do not store complete payment card information on our servers.</li>
          <li>Communication Data: Information you provide when you contact us for support, respond to surveys, or communicate with us through any channel.</li>
          <li>Profile Data: Your preferences, feedback, and survey responses.</li>
          <li>User Content: Information you input into our system to generate content, including brand guidelines, marketing objectives, target audience information, and any other data you provide to customize the output.</li>
        </ul>
      </p>

      <p>
        <h4>B. Communication and Marketing</h4>
        <ul>
          <li>To communicate with you about your account or transactions</li>
          <li>To send you technical notices, updates, security alerts, and support messages</li>
          <li>To provide news, special offers, and general information about other services we offer</li>
          <li>To measure the effectiveness of our marketing campaigns</li>
          <li>To deliver targeted advertisements based on your preferences and usage patterns</li>
        </ul>
      </p>

      <p>
        <h4>C. Research and Analytics</h4>
        <ul>
          <li>To conduct research and analysis to better understand how users access and use our Services</li>
          <li>To generate aggregated, non-identifying analytics and statistics</li>
          <li>To evaluate and improve the performance and quality of our Services</li>
          <li>To develop new products, services, features, and functionality</li>
        </ul>
      </p>

      <p>
        <h4>D. Legal and Operational Purposes</h4>
        <ul>
          <li>To comply with legal obligations under Brazilian law</li>
          <li>To enforce our Terms of Service and other agreements</li>
          <li>To protect our rights, privacy, safety, or property, and that of our users or others</li>
          <li>To detect, prevent, or address fraud, security, or technical issues</li>
          <li>For business administration, including maintaining records, auditing, and other internal functions</li>
        </ul>
      </p>

      <p>
        <h4>E. AI Training and Improvement</h4>
        <ul>
          <li>To train, test, and improve our AI models and algorithms</li>
          <li>To enhance the accuracy and relevance of our AI-generated content</li>
          <li>To develop new AI capabilities and features</li>
          <li>For users with paid subscription, we will not use your content inputs to train our AI models without your explicit consent. Free trial users or PAID TRIAL users of 7 days, 14 days, or 30 days grant us a license to use their content for AI training purposes, which is revocable upon upgrading to a paid subscription.</li>
        </ul>
      </p>

      <h3>5. LEGAL BASIS FOR PROCESSING</h3>
      <p>We process your Personal Data only when we have a valid legal basis to do so. In accordance with the LGPD, we may rely on one or more of the following legal bases:</p>

      <p>
        <h4>A. Consent (Article 7, I of LGPD)</h4>
        <p>We process your Personal Data when you have given us specific, informed, and unambiguous consent to do so. You can withdraw your consent at any time by contacting us using the information provided in the "Contact Information" section.</p>
      </p>

      <p>
        <h4>B. Contractual Necessity (Article 7, V of LGPD)</h4>
        <p>We process your Personal Data when necessary to perform our contractual obligations to you, including providing our Services as described in our Terms of Service.</p>
      </p>

      <p>
        <h4>C. Legal Obligation (Article 7, II of LGPD)</h4>
        <p>We process your Personal Data when necessary to comply with a legal obligation under Brazilian law, such as responding to lawful requests from public authorities or retaining certain information as required by tax laws.</p>
      </p>

      <p>
        <h4>D. Legitimate Interests (Article 7, IX of LGPD)</h4>
        <p>We process your Personal Data when it is in our legitimate interests to do so, provided these interests are not overridden by your rights and freedoms. Our legitimate interests include:</p>
        <ul>
          <li>Operating and improving our Services</li>
          <li>Protecting the security of our systems and users</li>
          <li>Understanding how users interact with our Services</li>
          <li>Marketing and promoting our Services</li>
          <li>Preventing fraud and unauthorized use</li>
          <li>Enforcing our legal rights and complying with legal obligations</li>
        </ul>
      </p>

      <p>
        <h4>E. Regular Exercise of Rights (Article 7, VI of LGPD)</h4>
        <p>We process your Personal Data when necessary for the regular exercise of rights in judicial, administrative, or arbitration procedures.</p>
      </p>

      <p>
        <h4>F. Protection of Life or Physical Safety (Article 7, VII of LGPD)</h4>
        <p>We may process your Personal Data to protect your life or physical safety or that of a third party.</p>
      </p>

      <p>
        <h4>G. Health Protection (Article 7, VIII of LGPD)</h4>
        <p>In specific circumstances, we may process your Personal Data to protect your health, in procedures carried out by health professionals or health entities.</p>
      </p>

      <p>
        <h4>H. Research (Article 7, IV of LGPD)</h4>
        <p>We may process your Personal Data for studies by research entities, ensuring, whenever possible, the anonymization of personal data.</p>
      </p>

      <p>
        <h4>I. Credit Protection (Article 7, X of LGPD)</h4>
        <p>We may process your Personal Data for credit protection, including as provided by applicable legislation.</p>
      </p>

      <h3>6. DATA PROTECTION AND SECURITY</h3>
      <p>We have implemented appropriate technical and organizational measures to protect your Personal Data against unauthorized or unlawful processing, accidental loss, destruction, or damage. These measures include:</p>

      <p>
        <h4>A. Technical Safeguards</h4>
        <ul>
          <li>Encryption: We use industry-standard encryption technologies to protect data in transit and at rest, including TLS/SSL protocols for data transmission and AES-256 encryption for stored data.</li>
          <li>Access Controls: We implement strict access controls and authentication mechanisms to ensure that only authorized personnel can access your data.</li>
          <li>Firewalls and Intrusion Detection: Our systems are protected by firewalls and intrusion detection systems to prevent unauthorized access.</li>
          <li>Regular Security Testing: We conduct regular security assessments, including penetration testing and vulnerability scanning.</li>
          <li>Secure Development Practices: We follow secure coding practices and conduct security reviews throughout our development process.</li>
        </ul>
      </p>

      <p>
        <h4>B. Organizational Safeguards</h4>
        <ul>
          <li>Employee Training: All employees receive regular training on data protection and security practices, with specific focus on Brazilian data protection requirements.</li>
          <li>Access Limitations: Access to Personal Data is limited to employees who need it to perform their job functions.</li>
          <li>Confidentiality Agreements: All employees and contractors are bound by confidentiality obligations.</li>
          <li>Security Policies: We maintain comprehensive security policies and procedures that are regularly reviewed and updated.</li>
          <li>Incident Response Plan: We have a documented incident response plan to address any potential security breaches promptly, in compliance with LGPD requirements.</li>
        </ul>
      </p>

      <p>
        <h4>C. Third-Party Security</h4>
        <ul>
          <li>We carefully select third-party service providers and require them to maintain appropriate security measures.</li>
          <li>We enter into data processing agreements with our service providers that include security requirements.</li>
          <li>We regularly review the security practices of our third-party service providers.</li>
        </ul>
      </p>

      <p>
        <h4>D. User Responsibilities</h4>
        <ul>
          <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
          <li>We recommend using strong, unique passwords and enabling two-factor authentication when available.</li>
          <li>You should notify us immediately if you suspect any unauthorized access to your account.</li>
        </ul>
      </p>

      <p>While we implement these safeguards, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, while we strive to protect your Personal Data, we cannot guarantee its absolute security.</p>

      <h3>7. DATA SHARING AND DISCLOSURE</h3>
      <p>We may share your information in the following circumstances:</p>

      <p>
        <h4>A. Service Providers</h4>
        <p>We share information with third-party service providers who perform services on our behalf, such as:</p>
        <ul>
          <li>Cloud hosting and infrastructure providers</li>
          <li>Payment processors</li>
          <li>Customer support services</li>
          <li>Email and communication services</li>
          <li>Analytics providers</li>
          <li>Marketing and advertising partners</li>
          <li>Security and fraud prevention services</li>
        </ul>
        <p>These service providers are contractually obligated to use your information only for the purposes of providing services to us and in accordance with this Privacy Policy and the LGPD.</p>
      </p>

      <p>
        <h4>B. Business Transfers</h4>
        <p>If we are involved in a merger, acquisition, financing, reorganization, bankruptcy, or sale of company assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your Personal Data.</p>
      </p>

      <p>
        <h4>C. Legal Requirements</h4>
        <p>We may disclose your information if required to do so by Brazilian law or in response to valid requests by public authorities (e.g., a court, the ANPD, or government agency). We may also disclose your information to:</p>
        <ul>
          <li>Enforce our Terms of Service and other agreements</li>
          <li>Protect and defend our rights or property</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Services</li>
          <li>Protect the personal safety of users of the Services or the public</li>
          <li>Protect against legal liability</li>
        </ul>
      </p>

      <p>
        <h4>D. With Your Consent</h4>
        <p>We may share your information with third parties when you have given us your consent to do so.</p>
      </p>

      <p>
        <h4>E. Aggregated or De-identified Data</h4>
        <p>We may share aggregated or de-identified information, which cannot reasonably be used to identify you, with third parties for research, marketing, analytics, and other purposes.</p>
      </p>

      <p>
        <h4>F. Business Partners</h4>
        <p>With your consent, we may share your information with our business partners to offer you certain products, services, or promotions.</p>
      </p>

      <p>
        <h4>G. Current List of Key Third-Party Processors</h4>
        <p>We currently use the following key third-party service providers who may process your Personal Data:</p>
        <ul>
          <li>Amazon Web Services (AWS) Brazil - Cloud hosting and infrastructure</li>
          <li>Liquid Web - American server services provider</li>
          <li>PagSeguro - Brazilian payment processing</li>
          <li>Stripe - International payment processing</li>
          <li>Zendesk - Customer support</li>
          <li>KARTRA - Email communications</li>
          <li>Close - CRM and marketing</li>
          <li>Pipedrive - CRM and marketing</li>
          <li>Google Analytics - Analytics</li>
        </ul>
        <p>This list may be updated from time to time. For the most current list of our third-party processors, please contact us using the information provided in the "Contact Information" section.</p>
      </p>

      <h3>8. INTERNATIONAL DATA TRANSFERS</h3>
      <p>As a company based in Brazil, our primary data processing activities occur within Brazil. However, in some cases, we may transfer your Personal Data to other countries for processing. When we do so, we ensure that appropriate safeguards are in place to protect your data.</p>

      <p>
        <h4>A. Transfer Mechanisms</h4>
        <p>When we transfer Personal Data outside of Brazil, we ensure that appropriate safeguards are in place, in accordance with Article 33 of the LGPD, which may include:</p>
        <ul>
          <li>Transfers to countries or international organizations that provide an adequate level of protection of personal data, as determined by the ANPD</li>
          <li>Transfers where we have obtained your specific and highlighted consent for the transfer</li>
          <li>Transfers necessary for the execution of a contract or preliminary procedures related to a contract to which you are a party</li>
          <li>Transfers necessary for the regular exercise of rights in judicial, administrative, or arbitration procedures</li>
          <li>Transfers necessary to protect your life or physical safety or that of a third party</li>
          <li>Transfers authorized by the ANPD</li>
          <li>Transfers based on standard contractual clauses approved by the ANPD or global corporate rules</li>
        </ul>
      </p>

      <p>
        <h4>B. Countries of Processing</h4>
        <p>Your Personal Data may be processed in the following countries:</p>
        <ul>
          <li>Brazil (our primary location)</li>
          <li>United States (for certain cloud services and abilities)</li>
          <li>European Union member states (for certain services)</li>
        </ul>
      </p>

      <p>
        <h4>C. Additional Safeguards</h4>
        <p>In addition to the transfer mechanisms described above, we implement additional safeguards to protect your data during international transfers, including:</p>
        <ul>
          <li>Encryption of data in transit and at rest</li>
          <li>Minimizing the amount of Personal Data transferred</li>
          <li>Regular assessments of the data protection practices in recipient countries</li>
          <li>Contractual commitments from recipients to maintain appropriate security measures</li>
        </ul>
      </p>

      <p>
        <h4>D. Your Rights Regarding International Transfers</h4>
        <p>You have the right to request information about the international transfers of your Personal Data, including the countries to which your data is transferred and the safeguards we have in place. To exercise this right, please contact us using the information provided in the "Contact Information" section.</p>
      </p>

      <h3>9. DATA RETENTION</h3>
      <p>We retain your Personal Data only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements under Brazilian law.</p>

      <p>
        <h4>A. Retention Periods</h4>
        <p>The specific retention periods for different types of data are as follows:</p>
        <ul>
          <li>Account Information: We retain your account information for the duration of your account with us. If you delete your account, we will delete or anonymize your account information within 90 days, except as noted below.</li>
          <li>Generated Content: We retain your Generated Content according to your account settings. You can delete your Generated Content at any time through your account settings.</li>
          <li>Payment Information: We retain payment information for 10 years to comply with Brazilian tax and accounting requirements.</li>
          <li>Communication Data: We retain communications with our support team for 5 years to maintain service quality and address any disputes.</li>
          <li>Usage Data: We retain usage data for 2 years to analyze trends and improve our Services.</li>
          <li>Log Data: We retain log data for 1 year for security and troubleshooting purposes.</li>
          <li>Liability-Related Data: In accordance with our limitation of liability established in the Terms of Service, we maintain relevant records for potential claims for a minimum period of 2 months, which corresponds to the period during which our liability is limited to the amounts paid by you in that interval.</li>
        </ul>
      </p>

      <p>
        <h4>B. Extended Retention</h4>
        <p>We may retain your Personal Data for longer periods in the following circumstances:</p>
        <ul>
          <li>To comply with legal, regulatory, tax, accounting, or reporting requirements under Brazilian law</li>
          <li>To resolve disputes or enforce our agreements</li>
          <li>To protect our legal rights, property, or safety, and those of our users or others</li>
          <li>When continued retention is necessary for legitimate business purposes that are compatible with the original purpose of collection</li>
        </ul>
      </p>

      <p>
        <h4>C. Data Deletion</h4>
        <p>When your Personal Data is no longer required for the purposes for which it was collected, we will delete or anonymize it. If complete deletion is not possible (for example, because your Personal Data has been stored in backup archives), we will securely store your Personal Data and isolate it from any further processing until deletion is possible.</p>
      </p>

      <p>
        <h4>D. Anonymized Data</h4>
        <p>We may retain anonymized or aggregated data, which cannot reasonably be used to identify you, indefinitely.</p>
      </p>

      <h3>10. YOUR RIGHTS UNDER BRAZILIAN LAW</h3>
      <p>As a data subject under the LGPD, you have the following rights regarding your Personal Data:</p>

      <p>
        <h4>A. Right to Confirmation and Access (Article 18, I and II of LGPD)</h4>
        <p>You have the right to confirm the existence of processing of your Personal Data and to access your Personal Data that we hold.</p>
      </p>

      <p>
        <h4>B. Right to Correction (Article 18, III of LGPD)</h4>
        <p>You have the right to request the correction of incomplete, inaccurate, or outdated Personal Data.</p>
      </p>

      <p>
        <h4>C. Right to Anonymization, Blocking, or Deletion (Article 18, IV of LGPD)</h4>
        <p>You have the right to request the anonymization, blocking, or deletion of unnecessary, excessive, or unlawfully processed Personal Data.</p>
      </p>

      <p>
        <h4>D. Right to Data Portability (Article 18, V of LGPD)</h4>
        <p>You have the right to request the portability of your Personal Data to another service or product provider, subject to commercial and industrial secrets.</p>
      </p>

      <p>
        <h4>E. Right to Deletion (Article 18, VI of LGPD)</h4>
        <p>You have the right to request the deletion of Personal Data processed with your consent, except in cases where retention is required by law or regulation.</p>
      </p>

      <p>
        <h4>F. Right to Information (Article 18, VII and VIII of LGPD)</h4>
        <p>You have the right to receive information about the public and private entities with which we have shared your Personal Data, and about the possibility of not providing consent and the consequences of such refusal.</p>
      </p>

      <p>
        <h4>G. Right to Revoke Consent (Article 18, IX of LGPD)</h4>
        <p>You have the right to revoke your consent at any time through a simple procedure free of charge.</p>
      </p>

      <p>
        <h4>H. Right to Review Automated Decisions (Article 20 of LGPD)</h4>
        <p>You have the right to request review of decisions made solely based on automated processing of Personal Data that affects your interests, including decisions that define your personal, professional, consumer, and credit profile or aspects of your personality.</p>
      </p>

      <p>
        <h4>I. How to Exercise Your Rights</h4>
        <p>To exercise any of these rights, please contact us using the information provided in the "Contact Information" section. We may need to verify your identity before responding to your request. We will respond to your request within 15 days, as required by the LGPD.</p>
      </p>

      <p>
        <h4>J. Limitations</h4>
        <p>Your rights may be subject to limitations as provided by the LGPD. For example, we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time.</p>
      </p>

      <h3>11. COOKIES AND TRACKING TECHNOLOGIES</h3>
      <p>We use cookies and similar tracking technologies to collect and use information about you and your interaction with our Services.</p>

      <p>
        <h4>A. Types of Cookies We Use</h4>
        <ul>
          <li>Essential Cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.</li>
          <li>Performance Cookies: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.</li>
          <li>Functionality Cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.</li>
          <li>Targeting Cookies: These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant advertisements on other sites.</li>
          <li>Social Media Cookies: These cookies are set by social media services that we have added to the site to enable you to share our content with your friends and networks.</li>
        </ul>
      </p>

      <p>
        <h4>B. Cookie Management</h4>
        <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Services may become inaccessible or not function properly.</p>
      </p>

      <p>
        <h4>C. Do Not Track</h4>
        <p>Some browsers have a "Do Not Track" feature that signals to websites that you visit that you do not want to have your online activity tracked. Our Services do not currently respond to "Do Not Track" signals.</p>
      </p>

      <p>
        <h4>D. Third-Party Analytics</h4>
        <p>We use third-party analytics services, such as Google Analytics, to help us understand how users engage with our Services. These services may use cookies and similar technologies to collect information about your use of the Services and other websites.</p>
      </p>

      <p>
        <h4>E. Advertising</h4>
        <p>We may work with third-party advertising companies to serve ads when you visit our Services or other websites. These companies may use information about your visits to our Services and other websites to provide advertisements about goods and services of interest to you.</p>
      </p>

      <p>
        <h4>F. Cookie Policy</h4>
        <p>For more detailed information about the cookies we use and how to manage them, please refer to our Cookie Policy, which is incorporated into this Privacy Policy by reference.</p>
      </p>

      <h3>12. ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING</h3>
      <p>As an AI-powered platform, we use artificial intelligence and machine learning technologies to provide our Services. This section explains how we use these technologies and how they interact with your data.</p>

      <p>
        <h4>A. AI-Generated Content</h4>
        <p>Our Services use AI models to generate marketing content based on your inputs and instructions. The content generated by our AI systems is provided for your use subject to our Terms of Service.</p>
      </p>

      <p>
        <h4>B. AI Training and Improvement</h4>
        <p>For FREE TRIAL users or PAID TRIAL users of 7 days, 14 days, or 30 days (costing 1 BRL in Brazil or 1 USD outside Brazil), we may use data collected through our Services to train, test, and improve our AI models. This helps us enhance the quality, accuracy, and relevance of our AI-generated content.</p>
        <p>For users with paid subscription, we will not use your content inputs to train our AI models without your explicit consent. Free trial users or PAID TRIAL users of 7 days, 14 days, or 30 days (costing 1 BRL in Brazil or 1 USD outside Brazil) grant us a license to use their content for AI training purposes, which is revocable upon upgrading to a paid subscription.</p>
      </p>

      <p>
        <h4>C. AI Decision-Making</h4>
        <p>Our Services may use automated decision-making processes to:</p>
        <ul>
          <li>Personalize your experience</li>
          <li>Recommend features or content</li>
          <li>Optimize the generation of marketing materials</li>
          <li>Detect and prevent fraud or abuse</li>
        </ul>
        <p>These automated processes analyze various factors, including your inputs, preferences, and usage patterns, to make decisions that enhance your experience with our Services.</p>
      </p>

      <p>
        <h4>D. Your Rights Regarding AI Processing</h4>
        <p>In accordance with Article 20 of the LGPD, you have the right to:</p>
        <ul>
          <li>Request information about the criteria and procedures used for automated decisions</li>
          <li>Request human review of automated decisions that affect your interests</li>
          <li>Contest automated decisions</li>
        </ul>
        <p>To exercise these rights, please contact us using the information provided in the "Contact Information" section.</p>
      </p>

      <p>
        <h4>E. Limitations of AI Technology</h4>
        <p>While we strive to provide accurate and reliable AI-generated content, AI technology has inherent limitations. Our AI systems may occasionally produce content that is inaccurate, inappropriate, or not aligned with your expectations. You are responsible for reviewing and editing all AI-generated content before use.</p>
      </p>

      <h3>13. CHILDREN'S PRIVACY</h3>
      <p>Our Services are not intended for children under the age of 18, and we do not knowingly collect Personal Data from children under 18. If you are a parent or guardian and you believe that your child has provided us with Personal Data, please contact us using the information provided in the "Contact Information" section, and we will take steps to delete such information.</p>
      <p>If we learn that we have collected Personal Data from a child under 18 without verification of parental consent, we will take steps to delete that information as quickly as possible.</p>

      <h3>14. SECURITY INCIDENTS</h3>
      <p>We take data security incidents seriously and have implemented procedures to address them promptly and effectively, in compliance with the LGPD.</p>

      <p>
        <h4>A. Incident Response Plan</h4>
        <p>We maintain a comprehensive incident response plan that outlines the steps we take in the event of a security incident, including:</p>
        <ul>
          <li>Identifying and containing the incident</li>
          <li>Assessing the nature and scope of the incident</li>
          <li>Mitigating any ongoing risks</li>
          <li>Investigating the cause of the incident</li>
          <li>Implementing measures to prevent similar incidents in the future</li>
        </ul>
      </p>

      <p>
        <h4>B. Notification of Security Incidents</h4>
        <p>In the event of a security incident involving your Personal Data, we will:</p>
        <ul>
          <li>Notify the ANPD and affected users within 48 hours of discovering the breach, or as required by the LGPD</li>
          <li>Provide information about the nature of the breach, the types of data affected, and the steps we are taking to address the breach</li>
          <li>Offer guidance on steps you can take to protect yourself</li>
          <li>Cooperate with the ANPD and other regulatory authorities as required by law</li>
        </ul>
      </p>

      <p>
        <h4>C. Preventive Measures</h4>
        <p>To minimize the risk of security incidents, we:</p>
        <ul>
          <li>Regularly review and update our security measures</li>
          <li>Conduct security assessments and penetration testing</li>
          <li>Train our employees on security best practices</li>
          <li>Monitor our systems for suspicious activity</li>
          <li>Implement access controls and authentication mechanisms</li>
        </ul>
      </p>

      <p>
        <h4>D. Your Responsibilities</h4>
        <p>To help protect your Personal Data, we recommend that you:</p>
        <ul>
          <li>Use strong, unique passwords for your account</li>
          <li>Enable two-factor authentication when available</li>
          <li>Be cautious about sharing your account credentials</li>
          <li>Report any suspicious activity related to your account</li>
          <li>Keep your devices and software up to date</li>
        </ul>
      </p>

      <h3>15. THIRD-PARTY SERVICES</h3>
      <p>Our Services may contain links to third-party websites, products, or services. This Privacy Policy applies only to our Services. When you use links to other websites, products, or services, their privacy policies apply to the collection and use of your information.</p>

      <p>
        <h4>A. Third-Party Integrations</h4>
        <p>We may offer integrations with third-party services, such as:</p>
        <ul>
          <li>Social media platforms</li>
          <li>Marketing abilities</li>
          <li>Analytics services</li>
          <li>Payment processors</li>
          <li>Cloud storage providers</li>
        </ul>
        <p>When you enable these integrations, you may be sharing information with these third parties. We encourage you to review the privacy policies of these third parties before using these integrations.</p>
      </p>

      <p>
        <h4>B. Third-Party Widgets</h4>
        <p>Our Services may include widgets, which are interactive mini-programs that run on our Services to provide specific services from another company. Widgets may collect your IP address, which page you are visiting on our Services, and may set a cookie to enable the widget to function properly. Widgets are either hosted by a third party or hosted directly on our Services. Your interactions with these widgets are governed by the privacy policy of the company providing the widget.</p>
      </p>

      <p>
        <h4>C. Third-Party Cookies</h4>
        <p>Some of our third-party partners may use cookies or other tracking technologies on our Services. We do not have access to or control over these cookies. This Privacy Policy covers the use of cookies by us only and does not cover the use of cookies by any third parties.</p>
      </p>

      <p>
        <h4>D. Third-Party Responsibility</h4>
        <p>We are not responsible for the privacy practices or content of any third-party services. We encourage you to review the privacy policies of any third-party services you use in connection with our Services.</p>
      </p>

      <h3>16. DO NOT TRACK SIGNALS</h3>
      <p>Some browsers have a "Do Not Track" feature that signals to websites that you visit that you do not want to have your online activity tracked. Due to the lack of a common interpretation of Do Not Track signals across the industry, we currently do not respond to Do Not Track signals on our Services.</p>
      <p>However, you can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your cookie settings, please see the "Cookies and Tracking Technologies" section of this Privacy Policy.</p>

      <h3>17. CHANGES TO THIS POLICY</h3>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements, or other factors. When we make changes to this Privacy Policy, we will update the "Last Updated" date at the top of this Privacy Policy.</p>

      <p>
        <h4>A. Material Changes</h4>
        <p>If we make material changes to this Privacy Policy, we will notify you by:</p>
        <ul>
          <li>Sending an email to the email address associated with your account</li>
          <li>Displaying a prominent notice on our Services</li>
          <li>Posting a notice on our website</li>
        </ul>
        <p>We encourage you to review this Privacy Policy periodically to stay informed about our data practices.</p>
      </p>

      <p>
        <h4>B. Your Continued Use</h4>
        <p>Your continued use of our Services after we have posted changes to this Privacy Policy means that you agree to the updated Privacy Policy. If you do not agree to the updated Privacy Policy, you must stop using our Services.</p>
      </p>

      <p>
        <h4>C. Archive of Previous Versions</h4>
        <p>We maintain an archive of previous versions of this Privacy Policy, which you can request by contacting us using the information provided in the "Contact Information" section.</p>
      </p>

      <h3>18. DISPUTE RESOLUTION</h3>
      <p>If you have a complaint or dispute about how we handle your Personal Data, we encourage you to contact us first using the information provided in the "Contact Information" section. We will work with you to resolve your complaint or dispute.</p>

      <p>
        <h4>A. Informal Resolution</h4>
        <p>We strive to resolve all complaints and disputes informally through direct communication. Please provide us with detailed information about your complaint or dispute, and we will respond promptly.</p>
      </p>

      <p>
        <h4>B. Formal Dispute Resolution</h4>
        <p>If we are unable to resolve your complaint or dispute informally, you may pursue formal dispute resolution through the following channels:</p>
        <ul>
          <li>You may file a complaint with the Brazilian National Data Protection Authority (ANPD).</li>
          <li>You may seek judicial remedies through the Brazilian court system.</li>
        </ul>
      </p>

      <p>
        <h4>C. Arbitration</h4>
        <p>For disputes that cannot be resolved through the above channels, you agree to resolve any claims relating to this Privacy Policy through final and binding arbitration.</p>
        <p>The arbitration will be conducted in São Paulo, Brazil, in accordance with the arbitration rules of the Câmara de Comércio Brasil-Canadá.</p>
      </p>

      <p>
        <h4>D. Exceptions to Agreement to Arbitrate</h4>
        <p>Either party may bring a lawsuit in the courts of São Paulo, Brazil solely for injunctive relief to stop unauthorized use or abuse of the Services or infringement of intellectual property rights without first engaging in the informal dispute resolution process or arbitration.</p>
      </p>

      <p>
        <h4>E. No Class Actions</h4>
        <p>You may only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action.</p>
      </p>

      <p>
        <h4>F. Governing Law</h4>
        <p>This Privacy Policy and any disputes related to it or our Services shall be governed by the laws of Brazil, without regard to conflict of law principles.</p>
      </p>

      <p>
        <h4>G. Jurisdiction</h4>
        <p>The courts of São Paulo, Brazil shall have exclusive jurisdiction over any disputes arising out of or related to this Privacy Policy or our Services.</p>
      </p>

      <h3>19. CONTACT INFORMATION</h3>
      <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us using the following information:</p>

      <p>
        <p>Data Protection Officer:</p>
        <ul>
          <li>Email: suporte@shifters.com.br</li>
          <li>Address: Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brazil</li>
        </ul>
      </p>

      <p>
        <p>For Privacy Inquiries:</p>
        <ul>
          <li>Email: suporte@shifters.com.br</li>
          <li>Support: https://www.shifters.com.br/AiGU75oYrw9c</li>
        </ul>
      </p>

      <p>
        <p>Postal Address:</p>
        <ul>
          <li>SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP</li>
          <li>Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brazil</li>
          <li>CNPJ/MF: 15.563.090/0001-04</li>
        </ul>
      </p>

      <p>By using CopyCut AI, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy Agreement.</p>
    </Container>
  );
}
