/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import { AuthContext } from '../../../../context/AuthContext';
import CopyCutPaidService from '../../../../services/CopyCutPaid';

import TitlePage from '../../../../components/TitlePage';
import { Card as CardBtn } from '../components/Card';
import { Warning } from '../../../../components/Warnings';
import toast from '../../../../helpers/toast';

import IconClipboard from '../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../styles/logos/check_icon.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';
import IconAdd from '../../../../styles/logos/add.svg';
import IconClear from '../../../../styles/logos/icon_clear.svg';
import IconInfoGray from '../../../../styles/logos/icon_info_gray.svg';
import IconMic from '../../../../styles/logos/icon_mic.svg';
import IconSound from '../../../../styles/logos/icon_sound_animated.svg';

import { nicheList } from './lists/nicheList';
import { avatarList } from './lists/avatarList';
import { LoaderContainerMemo } from '../components/LoaderContainer';

import { Translate } from './translate';
import {
  Container,
  LoaderContainer,
  QuestionArea,
  CheckboxContainer,
  QuestionAreaTwoColumns,
  TextAreaContainer,
  QuestionContainer,
  ResponseArea,
  ResponseContainer,
  Card,
} from './style';
import { ButtonsArea } from './ButtonsArea';

export function CopyCutPaid() {
  const translate = Translate();
  const [text, setText] = useState('');
  const { userInfo } = useContext(AuthContext);
  const [infos, setInfos] = useState({
    id: null,
    businessModel: 'B2B',
    infoSite: '',
    niche: '',
    avatar: '',
    language: translate.language === 'pt-BR' ? 'pt-BR' : 'en-US',
    toneOfVoice: 'saleMode',
    checkboxSelected: false,
  });
  const [promptsResponse, setPromptsResponse] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [buttonsArea, setButtonsArea] = useState({
    show: false,
    buttons: {
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      sixth: true,
      seventh: true,
    },
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [colletedDataFirstStage, setCollectedDataFirstStage] = useState(false);
  const [colletedDataSecondStage, setCollectedDataSecondStage] = useState(false);
  const [colletedDataThirdStage, setCollectedDataThirdStage] = useState(false);
  const [colletedDataFourthStage, setCollectedDataFourthStage] = useState(false);
  const [colletedDataFifthStage, setCollectedDataFifthStage] = useState(false);
  const [colletedDataSixthStage, setCollectedDataSixthStage] = useState(false);
  const [colletedDataSeventhStage, setCollectedDataSeventhStage] = useState(false);
  const [countLoader, setCountLoader] = useState(0);
  const [showAvatarAndICPFields, setShowAvatarAndICPFields] = useState(false);
  const intervalLoader = useRef(null);

  const checkboxCustomizeRef = useRef(null);

  const [disabledTextArea, setDisabledTextArea] = useState(false);

  const [avatarListB2B, setAvatarListB2B] = useState(avatarList('B2B', translate.language));
  const [avatarListB2C, setAvatarListB2C] = useState(avatarList('B2C', translate.language));
  const [nicheListB2B, setNicheListB2B] = useState(nicheList('B2B', translate.language));
  const [nicheListB2C, setNicheListB2C] = useState(nicheList('B2C', translate.language));

  const [visibleOptions, setVisibleOptions] = useState({
    avatar: false,
    niche: false,
  });

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  const divOptionsAvatar = useRef(null);
  const divOptionsNiche = useRef(null);

  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  // CLEAR ALL EVENTS OF THE PAGE
  useEffect(() => () => {
    clearInterval(intervalLoader.current);
  }, []);

  // MONITORS BTN TO ENABLED AND DISBLED
  useEffect(() => {
    toogleMainBtn();
  }, [infos]);

  useEffect(() => {
    setInfos((prevState) => ({ ...prevState, infoSite: text }));
  }, [text]);

  async function getDataFirstStage() {
    // Clear values
    setIsLoadingData(true);
    setPromptsResponse({});

    // For users don't update texts
    setDisabledTextArea(true);

    // Init counter
    const interval = loaderCounter({ segToComplete: 180 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const responsePromptsDb = await CopyCutPaidService.postPrompt({ infos });
      setPromptsResponse((prev) => ({
        ...prev,
        ...responsePromptsDb,
        id: responsePromptsDb.id,
      }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ ...prevState, show: true }));
      setCollectedDataFirstStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('first-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataSecondStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 100 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt2({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        vslSMBPolicyFree: promptResponseDb.vslSMBPolicyFree,
        vslEnterprisePolicyFree: promptResponseDb.vslEnterprisePolicyFree,
      }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, second: false } }));
      setCollectedDataSecondStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('second-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataThirdStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 300 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt3({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        salesLetterSMB: promptResponseDb.comercialSMB,
        salesLetterEnterprise: promptResponseDb.comercialEnterprise,
        headlines: promptResponseDb.headlines,
        singleMechanism: promptResponseDb.singleMechanism,
        irresistibleOfferLevel1: promptResponseDb.irresistibleOfferLevel1,
        irresistibleOfferLevel2: promptResponseDb.irresistibleOfferLevel2,
        caseStudyB2B: promptResponseDb.caseStudyB2B,
        faqs: promptResponseDb.faqs,
        slideshow: promptResponseDb.slideshow,
        linkedinOutboundSMB: promptResponseDb.linkedinOutboundSMB,
        linkedinOutboundEnterprise: promptResponseDb.linkedinOutboundEnterprise,
        emailOutboundSMB: promptResponseDb.emailOutboundSMB,
        emailOutboundEnterprise: promptResponseDb.emailOutboundEnterprise,
        productsNames: promptResponseDb.productsNames,
        imagesPromptInstagram: promptResponseDb.imagesPromptInstagram,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, third: false } }));
      setCollectedDataThirdStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('third-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataFourthStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 100 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt4({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        orderBump: promptResponseDb.orderBump,
        upsell: promptResponseDb.upsell,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, fourth: false } }));
      setCollectedDataFourthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('fourth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataFifthStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 200 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt5({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        googleAdsKeywords: promptResponseDb.googleAdsKeywords,
        googleAdsAnnouncementGroup: promptResponseDb.googleAdsAnnouncementGroup,
        googleAdsAnnouncement: promptResponseDb.googleAdsAnnouncement,
        googleAdwordsEditorKeywords: promptResponseDb.googleAdwordsEditorKeywords,
        googleAdwordsEditorAnnouncementGroup: promptResponseDb.googleAdwordsEditorAnnouncementGroup,
        googleAdwordsEditorAnnouncement: promptResponseDb.googleAdwordsEditorAnnouncement,
        googleAdwordsEditorInstructions: promptResponseDb.googleAdwordsEditorInstructions,
        metaAds: promptResponseDb.metaAds,
        tiktokAds: promptResponseDb.tiktokAds,
        linkedinAds: promptResponseDb.linkedinAds,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, fifth: false } }));
      setCollectedDataFifthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('fifth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataSixthStage() {
    //  Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 200 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt6({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        videoAds: promptResponseDb.videoAds,
        videoAdsHistoricalEvolution: promptResponseDb.videoAdsHistoricalEvolution,
        videoAdsPermissionSkip: promptResponseDb.videoAdsPermissionSkip,
        videoAdsPersonaProblem: promptResponseDb.videoAdsPersonaProblem,
        videoAdsTransformationsTimeLapse: promptResponseDb.videoAdsTransformationTimeLapse,
        videoAdsUnexpectedDelivery: promptResponseDb.videoAdsUnexpectedDelivery,
        videoAdsMithBuster: promptResponseDb.videoAdsMithBuster,
        videoAdsWhistleblowerControl: promptResponseDb.videoAdsWhistleblowerControl,
        videoAdsWeirdHack: promptResponseDb.videoAdsWeirdHack,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, sixth: false } }));
      setCollectedDataSixthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('sixth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataSeventhStage() {
    //  Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 300 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const promptResponseDb = await CopyCutPaidService.postPrompt7({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        emailUpselling: promptResponseDb.emailUpselling,
        emailTabooBreaker: promptResponseDb.emailTabooBreaker,
        emailFalseChoice: promptResponseDb.emailFalseChoice,
        emailPrincipleOfPolarization: promptResponseDb.emailPrincipleOfPolarization,
        emailForbiddenCuriosity: promptResponseDb.emailForbiddenCuriosity,
        emailParadoxicalTruth: promptResponseDb.emailParadoxicalTruth,
        emailSingleSolutionFocusedResults: promptResponseDb.emailSingleSolutionFocusedResults,
        emailPASWhatsTheHell: promptResponseDb.emailPASWhatsTheHell,
        emailEliminatesCommonProblem: promptResponseDb.emailEliminatesCommonProblem,
        emailKnowItAll: promptResponseDb.emailKnowItAll,
        emailIdealLife: promptResponseDb.emailIdealLife,
        emailUniqueMechanismProblem: promptResponseDb.emailUniqueMechanismProblem,
        emailImpressedAuthority: promptResponseDb.emailImpressedAuthority,
        emailSurpriseUnifiedSource: promptResponseDb.emailSurpriseUnifiedSource,
        emailDisadvantageAscension: promptResponseDb.emailDisadvantageAscension,
        emailFuneralSolution: promptResponseDb.emailFuneralSolution,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, seventh: false } }));
      setCollectedDataSeventhStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('seventh-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function toogleMainBtn() {
    //! Apagar esse aqui
    // const fieldsFilled = infos.infoSite.length > 0 && infos.niche.length > 0 && infos.avatar.length > 0;
    // if (fieldsFilled) {
    //   setIsBtnDisabled(false);
    // } else {
    //   setIsBtnDisabled(true);
    // }

    // ? Inserido esse aqui
    const contextIsFilled = infos.infoSite.length > 0;
    if (!contextIsFilled) {
      setIsBtnDisabled(true);
      return;
    }

    setIsBtnDisabled(false);
  }

  function editFormValues(event) {
    setInfos((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  function handleOptionsArea(containerId) {
    setVisibleOptions((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function toogleSelectedOptions(event, type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const checked = event.target.checked;
      const value = event.target.value;

      if (checked) {
        // ADD INTO STRING
        if (!disabledTextArea) {
          const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
          if (stringTurnedIntoArray?.includes(value)) return;
          stringTurnedIntoArray.push(value);
          setInfos((prev) => ({ ...prev, [key]: stringTurnedIntoArray.join(', ') }));
        }
      } else if (!disabledTextArea) {
        // REMOVE FROM STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        const filteredArray = stringTurnedIntoArray.filter((item) => item !== value);
        setInfos((prev) => ({ ...prev, [key]: filteredArray.join(', ') }));
      }
    }
  }

  function addAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const arrOfOptions = type === 'avatar' ? [...avatarListB2B, ...avatarListB2C] : [...nicheListB2B, ...nicheListB2C];
      setInfos((prev) => ({ ...prev, [key]: arrOfOptions.join(', ') }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = true;
      }
    }
  }

  function deleteAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      setInfos((prev) => ({ ...prev, [key]: '' }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = false;
      }
    }
  }

  function handleCheckBoxCustomize() {
    setShowAvatarAndICPFields(checkboxCustomizeRef.current.checked);
    setInfos((prevState) => ({ ...prevState, checkboxSelected: checkboxCustomizeRef.current.checked }));
  }

  function loaderCounter({ segToComplete }) {
    const incrementBySec = 100 / segToComplete;

    // Limpar qualquer intervalo existente
    if (intervalLoader.current) {
      clearInterval(intervalLoader.current);
    }

    // Iniciar o contador em 0
    setCountLoader(0);

    // Configurar o novo intervalo
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => {
        const newCount = Number(prevCount) + incrementBySec;
        return newCount > 100 ? 100 : newCount;
      });
    }, 1000);

    return intervalLoader.current;
  }

  const toggleListening = () => {
    if (isListening) {
      // Para o reconhecimento
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      // Limpa o texto anterior e inicia nova gravação
      setText('');
      // Inicia o reconhecimento
      recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognitionRef.current.lang = infos.language; // use a proper language app
      recognitionRef.current.continuous = true;
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        setText((prev) => `${prev} ${transcript}`);
      };
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <QuestionArea>
          <QuestionAreaTwoColumns>
            {/* Language Inputs */}
            <div className="container-selects">
              <div className="container-selects-border">
                <h4>{translate.borderTitleLanguage}</h4>
                <div className="container-selects-inputs">
                  <QuestionContainer>
                    <label htmlFor="language">{translate.questionLanguage}</label>
                    <select
                      name="language"
                      id="language"
                      value={infos.language}
                      onChange={(e) => editFormValues(e)}
                    >
                      {/* <!-- Portuguese --> */}
                      <option value="pt-BR">Português (Brazil)</option>
                      <option value="pt-PT">Português (Portugal)</option>
                      <option value="pt-AO">Português (Angola)</option>
                      <option value="pt-MZ">Português (Mozambique)</option>
                      <option value="pt">Português (Generic)</option>

                      {/* <!-- English --> */}
                      <option value="en-US">English (United States)</option>
                      <option value="en-GB">English (United Kingdom)</option>
                      <option value="en-AU">English (Australia)</option>
                      <option value="en-CA">English (Canada)</option>
                      <option value="en-NZ">English (New Zealand)</option>
                      <option value="en-IE">English (Ireland)</option>
                      <option value="en">English (Generic)</option>

                      {/* <!-- Spanish --> */}
                      <option value="es-ES">Español (Spain)</option>
                      <option value="es-MX">Español (Mexico)</option>
                      <option value="es-AR">Español (Argentina)</option>
                      <option value="es-CL">Español (Chile)</option>
                      <option value="es-CO">Español (Colombia)</option>
                      <option value="es-PE">Español (Peru)</option>
                      <option value="es-VE">Español (Venezuela)</option>
                      <option value="es">Español (Generic)</option>

                      {/* <!-- French --> */}
                      <option value="fr-FR">Français (France)</option>
                      <option value="fr-CA">Français (Canada)</option>
                      <option value="fr-BE">Français (Belgium)</option>
                      <option value="fr-CH">Français (Switzerland)</option>
                      <option value="fr">Français (Generic)</option>

                      {/* <!-- German --> */}
                      <option value="de-DE">Deutsch (Germany)</option>
                      <option value="de-AT">Deutsch (Austria)</option>
                      <option value="de-CH">Deutsch (Switzerland)</option>
                      <option value="de">Deutsch (Generic)</option>

                      {/* <!-- Chinese --> */}
                      <option value="zh-CN">中文 - 简体 (Simplified Chinese)</option>
                      <option value="zh-TW">中文 - 繁體 (Traditional Chinese)</option>
                      <option value="zh-HK">中文 - 香港 (Hong Kong)</option>
                      <option value="zh">中文 (Generic)</option>

                      {/* <!-- Hindi --> */}
                      <option value="hi-IN">हिंदी (India)</option>
                      <option value="hi">हिंदी (Generic)</option>

                      {/* <!-- Bengali --> */}
                      <option value="bn-BD">বাংলা (Bangladesh)</option>
                      <option value="bn-IN">বাংলা (India)</option>
                      <option value="bn">বাংলা (Generic)</option>

                      {/* <!-- Arabic --> */}
                      <option value="ar-SA">العربية (Saudi Arabia)</option>
                      <option value="ar-EG">العربية (Egypt)</option>
                      <option value="ar-DZ">العربية (Algeria)</option>
                      <option value="ar-MA">العربية (Morocco)</option>
                      <option value="ar-TN">العربية (Tunisia)</option>
                      <option value="ar-IQ">العربية (Iraq)</option>
                      <option value="ar-SY">العربية (Syria)</option>
                      <option value="ar-LB">العربية (Lebanon)</option>
                      <option value="ar-AE">العربية (United Arab Emirates)</option>
                      <option value="ar-KW">العربية (Kuwait)</option>
                      <option value="ar-QA">العربية (Qatar)</option>
                      <option value="ar-BH">العربية (Bahrain)</option>
                      <option value="ar-OM">العربية (Oman)</option>
                      <option value="ar-YE">العربية (Yemen)</option>
                      <option value="ar-JO">العربية (Jordan)</option>
                      <option value="ar">العربية (Generic)</option>
                    </select>
                  </QuestionContainer>
                  <QuestionContainer>
                    <label htmlFor="toneOfVoice">{translate.questionToneOfVoice}</label>
                    <select
                      name="toneOfVoice"
                      value={infos.toneOfVoice}
                      onChange={(e) => editFormValues(e)}
                    >
                      <option value="saleMode">{translate.optionSellingMode} ⭐</option>
                      <option value="businessAndTrust">{translate.optionBusinessAndTrust} ⭐</option>
                      <option value="profissional">{translate.optionProfessional} ⭐</option>
                      <option value="formal">Formal</option>
                      <option value="expert">{translate.optionExpert}</option>
                      <option value="casual">Casual</option>
                      <option value="socialMedia">{translate.optionSocialMedia}</option>
                      <option value="sensitiveCommunication">{translate.optionSensitiveCommunication}</option>
                      <option value="friendly">{translate.optionFriendly}</option>
                    </select>
                  </QuestionContainer>
                </div>
              </div>
            </div>
          </QuestionAreaTwoColumns>

          {/* Context Input Area */}
          <QuestionContainer isListening={isListening} isDisabled={disabledTextArea}>
            <label htmlFor="infoSite">
              <span>{translate.labelContext}</span>
              <span className="context-tip">{' '}{translate.spanContext}</span>
            </label>
            <div className="text-area-container">
              <textarea
                name="infoSite"
                value={infos.infoSite}
                onChange={(e) => editFormValues(e)}
                placeholder={translate.placeholderContext}
                disabled={disabledTextArea}
              />
              <button
                type="button"
                onClick={toggleListening}
              >
                <img src={isListening ? IconSound : IconMic} alt="Microfone" />
              </button>
            </div>
            <Warning
              type="warning-dark"
              text={translate.warningMessage}
              size="small"
            />
          </QuestionContainer>

          {/* Avatar and Niche Input Area */}
          <CheckboxContainer>
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="checkBoxOptions"
                id="checkBoxOptions"
                ref={checkboxCustomizeRef}
                onChange={() => handleCheckBoxCustomize()}
              />
              <label htmlFor="checkBoxOptions">
                <span>{translate.labelCheckBox} </span>
              </label>
            </div>

            {(!infos.checkboxSelected) && (
              <div className="checkbox-message">
                <img src={IconInfoGray} alt="info" />
                <span>{translate.infoCheckBox}</span>
              </div>
            )}

            {(infos.checkboxSelected) && (
              <TextAreaContainer>
                <QuestionContainer $open={visibleOptions.avatar} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelAvatar}</span>
                    <span>{translate.spanAvatar}</span>
                  </label>
                  <textarea
                    name="avatar"
                    value={infos.avatar}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderAvatar}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('avatar')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsAvatar}>
                    <div>
                      <h4>B2B</h4>
                      {avatarListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {avatarListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
                <QuestionContainer $open={visibleOptions.niche} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelNiche}</span>
                    <span>{translate.spanNiche}</span>
                  </label>
                  <textarea
                    name="niche"
                    value={infos.niche}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderNiche}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('niche')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsNiche}>
                    <div>
                      <h4>B2B</h4>
                      {nicheListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {nicheListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
              </TextAreaContainer>
            )}
          </CheckboxContainer>
        </QuestionArea>

        <ResponseArea>
          {/* Button #1 Layer */}
          {(!colletedDataFirstStage && !isLoadingData) && (
            <CardBtn
              buttonContent={translate.btn}
              colorButton="yellow"
              estimatedTimeDuration="90-120 s"
              estimatedTimeText={translate.estimatedTime}
              mainMessage={translate.msgCard}
              onHandleClick={getDataFirstStage}
              title={translate.h4TitleVslCopy}
              isDisabled={isBtnDisabled}
            />
          )}

          {/* #1 Layer Response */}
          {(colletedDataFirstStage) && (
            <ResponseContainer id="first-stage-response">
              <h4>{translate.h4TitleVslCopy}</h4>
              <div className="response-area">
                {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                <>
                  {(promptsResponse?.foundationalCopy && promptsResponse?.foundationalCopy.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy}
                      </div>
                    </Card>
                  )}
                  {(promptsResponse?.foundationalCopyHumanized && promptsResponse?.foundationalCopyHumanized.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopyHumanized}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopyHumanized}
                      </div>
                    </Card>
                  )}
                </>
                )}

                {(promptsResponse?.vslSMB && promptsResponse?.vslSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslSMB}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslPrismatic && promptsResponse?.vslPrismatic.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslPrismatic}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslPrismatic}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslEnterprise && promptsResponse?.vslEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslEnterprise}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslEcommerce && promptsResponse?.vslEcommerce.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslEcommerce}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslEcommerce}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #2 Layer Response */}
          {colletedDataSecondStage && (
            <ResponseContainer id="second-stage-response">
              <h4>{translate.h4TitleVslPolicyFree}</h4>
              <div className="response-area">
                {(promptsResponse?.vslSMBPolicyFree && promptsResponse?.vslSMBPolicyFree.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslSMBPolicyFree}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslSMBPolicyFree}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslEnterprisePolicyFree && promptsResponse?.vslEnterprisePolicyFree.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslEnterprisePolicyFree}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslEnterprisePolicyFree}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #3 Layer Response */}
          {(colletedDataThirdStage) && (
            <ResponseContainer id="third-stage-response">
              <h4>{translate.h4TitleAssets}</h4>
              <div className="response-area">
                {(promptsResponse?.headlines && promptsResponse?.headlines.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Headlines</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.headlines}
                  </div>
                </Card>
                )}

                {(promptsResponse?.singleMechanism && promptsResponse?.singleMechanism.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSingleMechanism}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.singleMechanism}
                  </div>
                </Card>
                )}

                {(promptsResponse?.irresistibleOfferLevel1 && promptsResponse?.irresistibleOfferLevel1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleIrresistibleOfferLevel1}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.irresistibleOfferLevel1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.irresistibleOfferLevel2 && promptsResponse?.irresistibleOfferLevel2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleIrresistibleOfferLevel2}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.irresistibleOfferLevel2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.faqs && promptsResponse?.faqs.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleFAQS}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.faqs}
                  </div>
                </Card>
                )}

                {(promptsResponse?.slideshow && promptsResponse?.slideshow.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSlideShow}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.slideshow}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinOutboundSMB && promptsResponse?.linkedinOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn Outbound SMB</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinOutboundSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinOutboundEnterprise && promptsResponse?.linkedinOutboundEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn Outbound Enterprise</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinOutboundEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailOutboundSMB && promptsResponse?.emailOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>E-mail Outbound SMB</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailOutboundSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailOutboundEnterprise && promptsResponse?.emailOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>E-mail Outbound Enterprise</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailOutboundEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.salesLetterSMB && promptsResponse?.salesLetterSMB.length > 0) && (
                <Card id="second-stage-response" $visible>
                  <div className="title">
                    <span>{translate.titleSalesConversationalB2C}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.salesLetterSMB}
                  </div>
                </Card>

                )}

                {(promptsResponse?.salesLetterEnterprise && promptsResponse?.salesLetterEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSalesConversationalEnterprise}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.salesLetterEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.caseStudyB2B && promptsResponse?.caseStudyB2B.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Case Study B2B</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.caseStudyB2B}
                  </div>
                </Card>
                )}

                {(promptsResponse?.productsNames && promptsResponse?.productsNames.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleTwentyNames}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.productsNames}
                  </div>
                </Card>
                )}

                {(promptsResponse?.imagesPromptInstagram && promptsResponse?.imagesPromptInstagram.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleImagePrompts}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.imagesPromptInstagram}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #4 Layer Response */}
          {(colletedDataFourthStage) && (
            <ResponseContainer id="fourth-stage-response">
              <h4>{translate.h4TitleEngineValidation}</h4>
              <div className="response-area">
                {(promptsResponse?.orderBump && promptsResponse?.orderBump.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Order Bump</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.orderBump}
                  </div>
                </Card>
                )}

                {(promptsResponse?.upsell && promptsResponse?.upsell.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Upsell</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.upsell}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #5 Layer Response */}
          {(colletedDataFifthStage) && (
            <ResponseContainer id="fifth-stage-response">
              <h4>{translate.h4TitleScaleCopy}</h4>
              <div className="response-area">
                {(promptsResponse?.googleAdsKeywords && promptsResponse?.googleAdsKeywords.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAKeywords}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsKeywords}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdsAnnouncementGroup && promptsResponse?.googleAdsAnnouncementGroup.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAAnnouncementGroup}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsAnnouncementGroup}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdsAnnouncement && promptsResponse?.googleAdsAnnouncement.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAAnnouncement}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsAnnouncement}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdwordsEditorKeywords && promptsResponse?.googleAdwordsEditorKeywords.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAEditorKeywords}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdwordsEditorKeywords}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdwordsEditorAnnouncementGroup && promptsResponse?.googleAdwordsEditorAnnouncementGroup.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAEditorAnnouncementGroup}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdwordsEditorAnnouncementGroup}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdwordsEditorAnnouncement && promptsResponse?.googleAdwordsEditorAnnouncementGroup.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAEditorAnnouncement}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdwordsEditorAnnouncement}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdwordsEditorInstructions && promptsResponse?.googleAdwordsEditorInstructions.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAEditorInstructions}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdwordsEditorInstructions}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.metaAds && promptsResponse?.metaAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleMetaAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.metaAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.tiktokAds && promptsResponse?.tiktokAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleTiktokAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.tiktokAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.linkedinAds && promptsResponse?.linkedinAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleLinkedinAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.linkedinAds}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #6 Layer Response */}
          {(colletedDataSixthStage) && (
            <ResponseContainer id="sixth-stage-response">
              <h4>{translate.h4TitleVideoAds}</h4>
              <div className="response-area">
                {(promptsResponse?.videoAds && promptsResponse?.videoAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Video Ads</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsHistoricalEvolution && promptsResponse?.videoAdsHistoricalEvolution.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsHistoricalEvolution}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsHistoricalEvolution}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsPersonaProblem && promptsResponse?.videoAdsPersonaProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPersonaPainPoints}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsPersonaProblem}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsTransformationsTimeLapse && promptsResponse?.videoAdsTransformationsTimeLapse.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsTransformationTimeLapse}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsTransformationsTimeLapse}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsWhistleblowerControl && promptsResponse?.videoAdsWhistleblowerControl.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWhistleblower}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsWhistleblowerControl}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsPermissionSkip && promptsResponse?.videoAdsPermissionSkip.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPermissionSkip}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsPermissionSkip}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsUnexpectedDelivery && promptsResponse?.videoAdsUnexpectedDelivery.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsUnexpectedDelivery}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsUnexpectedDelivery}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsMithBuster && promptsResponse?.videoAdsMithBuster.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsMithBuster}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsMithBuster}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsWeirdHack && promptsResponse?.videoAdsWeirdHack.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWeirdHack}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsWeirdHack}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #6 Layer Response */}
          {(colletedDataSeventhStage) && (
            <ResponseContainer id="seventh-stage-response">
              <h4>{translate.h4TitleEmailMarketing}</h4>
              <div className="response-area">
                {(promptsResponse?.emailUpselling && promptsResponse?.emailUpselling.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailUpselling}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailUpselling}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailTabooBreaker && promptsResponse?.emailTabooBreaker.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailTabooBreaker}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailTabooBreaker}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailFalseChoice && promptsResponse?.emailFalseChoice.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailFalseChoice}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailFalseChoice}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailPrincipleOfPolarization && promptsResponse?.emailPrincipleOfPolarization.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailPrincipleOfPolarization}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailPrincipleOfPolarization}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailForbiddenCuriosity && promptsResponse?.emailForbiddenCuriosity.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailForbiddenCuriosity}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailForbiddenCuriosity}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailParadoxicalTruth && promptsResponse?.emailParadoxicalTruth.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailParadoxicalTruthty}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailParadoxicalTruth}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailSingleSolutionFocusedResults && promptsResponse?.emailSingleSolutionFocusedResults.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailSingleSolutionFocusedResults}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailSingleSolutionFocusedResults}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailPASWhatsTheHell && promptsResponse?.emailPASWhatsTheHell.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailPASWhatsTheHell}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailPASWhatsTheHell}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailEliminatesCommonProblem && promptsResponse?.emailEliminatesCommonProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailEliminatesCommonProblem}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailEliminatesCommonProblem}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailKnowItAll && promptsResponse?.emailKnowItAll.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailKnowItAll}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailKnowItAll}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailIdealLife && promptsResponse?.emailIdealLife.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailIdealLife}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailIdealLife}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailUniqueMechanismProblem && promptsResponse?.emailUniqueMechanismProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailUniqueMechanismProblem}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailUniqueMechanismProblem}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailImpressedAuthority && promptsResponse?.emailImpressedAuthority.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailImpressedAuthority}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailImpressedAuthority}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailSurpriseUnifiedSource && promptsResponse?.emailSurpriseUnifiedSource.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailSurpriseUnifiedSource}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailSurpriseUnifiedSource}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailDisadvantageAscension && promptsResponse?.emailDisadvantageAscension.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailDisadvantageAscension}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailDisadvantageAscension}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.emailFuneralSolution && promptsResponse?.emailFuneralSolution.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleEmailFuneralSolution}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.emailFuneralSolution}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* Loader or ButtonsLayer */}
          {(!buttonsArea.show && isLoadingData)
            && (
              <LoaderContainer>
                <LoaderContainerMemo
                  count={countLoader}
                  message={translate.messageProccessInfoNoSec}
                  color="yellow"
                  className="progress_bar-area"
                />
              </LoaderContainer>
            )}

          {buttonsArea.show && (
            <ButtonsArea
              buttonsArea={buttonsArea}
              onGetDataSecondStage={getDataSecondStage}
              onGetDataThirdStage={getDataThirdStage}
              onGetDataFourthStage={getDataFourthStage}
              onGetDataFifthStage={getDataFifthStage}
              onGetDataSixthStage={getDataSixthStage}
              onGetDataSeventhStage={getDataSeventhStage}
            />
          )}
        </ResponseArea>
      </Container>
    </>
  );
}
