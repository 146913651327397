/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import CopyCutUnpaidPostsService from '../../../../services/CopyCutUnpaidPosts';

import { AuthContext } from '../../../../context/AuthContext';
import { RegionInputContext } from '../../../../components/RegionsInput/RegionInput.context';
import { Card as CardBtn } from '../components/Card';

import TitlePage from '../../../../components/TitlePage';
import { RegionsInput } from '../../../../components/RegionsInput';
import { Warning } from '../../../../components/Warnings';
import toast from '../../../../helpers/toast';

import IconClipboard from '../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../styles/logos/check_icon.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';
import IconAdd from '../../../../styles/logos/add.svg';
import IconClear from '../../../../styles/logos/icon_clear.svg';
import IconInfoGray from '../../../../styles/logos/icon_info_gray.svg';
import IconMic from '../../../../styles/logos/icon_mic.svg';
import IconSound from '../../../../styles/logos/icon_sound_animated.svg';

import { nicheList } from './lists/nicheList';
import { avatarList } from './lists/avatarList';
import { LoaderContainerMemo } from '../components/LoaderContainer';

import { Translate } from './translate';
import {
  Container,
  LoaderContainer,
  QuestionArea,
  CheckboxContainer,
  QuestionAreaTwoColumns,
  TextAreaContainer,
  QuestionContainer,
  ResponseArea,
  ResponseContainer,
  Card,
} from './style';
import { ButtonsArea } from './ButtonsArea';

export function CopyCutUnpaidPosts() {
  const translate = Translate();
  const [text, setText] = useState('');
  const { userInfo } = useContext(AuthContext);
  const { continentsSelected, countriesSelected, statesSelected } = useContext(RegionInputContext);
  const [infos, setInfos] = useState({
    id: null,
    infoSite: '',
    niche: '',
    avatar: '',
    continent: [],
    country: [],
    state: [],
    language: translate.language === 'pt-BR' ? 'pt-BR' : 'en-US',
    toneOfVoice: 'saleMode',
    checkboxSelected: false,
  });
  const [promptsResponse, setPromptsResponse] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [buttonsArea, setButtonsArea] = useState({
    show: true,
    buttons: {
      first: true,
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      sixth: true,
      seventh: true,
    },
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [colletedDataFirstStage, setCollectedDataFirstStage] = useState(false);
  const [colletedDataSecondStage, setCollectedDataSecondStage] = useState(false);
  const [colletedDataThirdStage, setCollectedDataThirdStage] = useState(false);
  const [colletedDataFourthStage, setCollectedDataFourthStage] = useState(false);
  const [colletedDataFifthStage, setCollectedDataFifthStage] = useState(false);
  const [countLoader, setCountLoader] = useState(0);
  const [showAvatarAndICPFields, setShowAvatarAndICPFields] = useState(false);
  const intervalLoader = useRef(null);

  const checkboxCustomizeRef = useRef(null);

  const [disabledTextArea, setDisabledTextArea] = useState(false);

  const [avatarListB2B, setAvatarListB2B] = useState(avatarList('B2B', translate.language));
  const [avatarListB2C, setAvatarListB2C] = useState(avatarList('B2C', translate.language));
  const [nicheListB2B, setNicheListB2B] = useState(nicheList('B2B', translate.language));
  const [nicheListB2C, setNicheListB2C] = useState(nicheList('B2C', translate.language));

  const [visibleOptions, setVisibleOptions] = useState({
    avatar: false,
    niche: false,
  });

  const divOptionsAvatar = useRef(null);
  const divOptionsNiche = useRef(null);

  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  // Clear all intervals when component is mounted, if there is any intervals running
  useEffect(() => () => {
    clearInterval(intervalLoader.current);
  }, []);

  // it monitors main button to enable or disable
  useEffect(() => {
    toogleMainBtn();
  }, [infos]);

  useEffect(() => {
    setInfos((prevState) => ({
      ...prevState,
      continent: continentsSelected.continent,
      country: countriesSelected.country,
      state: statesSelected.state,
    }));
  }, [continentsSelected, countriesSelected, statesSelected]);

  useEffect(() => {
    setInfos((prevState) => ({ ...prevState, infoSite: text }));
  }, [text]);

  async function getData(cbGetData, btnKey, idNameToScroll, cbCollectedData) {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // For users don't update texts
    setDisabledTextArea(true);

    // Init counter
    const interval = loaderCounter({ segToComplete: 60 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const id = await cbGetData();
      setInfos((prevState) => ({ ...prevState, id }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ ...prevState, buttons: { ...prevState.buttons, [btnKey]: false } }));

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById(idNameToScroll);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      cbCollectedData(true);
      setIsLoadingData(false);
      setCountLoader(0);
      setButtonsArea((prevState) => ({ ...prevState, show: true }));
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataLinkedIn() {
    const response = await CopyCutUnpaidPostsService.linkedInLayer({ infos });
    setPromptsResponse((prevState) => ({
      ...prevState,
      ...response.linkedin,
      ...response.linkedinAivav,
      id: response.id,
    }));

    return response.id;
  }

  async function getDataInstagram() {
    const response = await CopyCutUnpaidPostsService.instagramLayer({ infos });
    setPromptsResponse((prevState) => ({
      ...prevState,
      ...response.instagram,
      id: response.id,
    }));

    return response.id;
  }

  async function getDataFacebook() {
    const response = await CopyCutUnpaidPostsService.facebookLayer({ infos });
    setPromptsResponse((prevState) => ({
      ...prevState,
      ...response.facebook,
      id: response.id,
    }));

    return response.id;
  }

  async function getDataTiktok() {
    const response = await CopyCutUnpaidPostsService.tiktokLayer({ infos });
    setPromptsResponse((prevState) => ({
      ...prevState,
      ...response.tiktok,
      id: response.id,
    }));

    return response.id;
  }

  async function getDataEmail() {
    const response = await CopyCutUnpaidPostsService.emailLayer({ infos });
    setPromptsResponse((prevState) => ({
      ...prevState,
      ...response.emailMarketing,
      id: response.id,
    }));

    return response.id;
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function toogleMainBtn() {
    const contextIsFilled = infos.infoSite.length > 0;

    if (!contextIsFilled) {
      setIsBtnDisabled(true);
      return;
    }

    setIsBtnDisabled(false);
  }

  function editFormValues(event) {
    setInfos((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  function handleOptionsArea(containerId) {
    setVisibleOptions((prevState) => ({
      ...prevState,
      [containerId]: !prevState[containerId],
    }));
  }

  function toogleSelectedOptions(event, type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const checked = event.target.checked;
      const value = event.target.value;

      if (checked) {
        // ADD INTO STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        if (stringTurnedIntoArray?.includes(value)) return;
        stringTurnedIntoArray.push(value);
        setInfos((prev) => ({ ...prev, [key]: stringTurnedIntoArray.join(', ') }));
      } else {
        // REMOVE FROM STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        const filteredArray = stringTurnedIntoArray.filter((item) => item !== value);
        setInfos((prev) => ({ ...prev, [key]: filteredArray.join(', ') }));
      }
    }
  }

  function addAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const arrOfOptions = type === 'avatar' ? [...avatarListB2B, ...avatarListB2C] : [...nicheListB2B, ...nicheListB2C];
      setInfos((prev) => ({ ...prev, [key]: arrOfOptions.join(', ') }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = true;
      }
    }
  }

  function deleteAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      setInfos((prev) => ({ ...prev, [key]: '' }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = false;
      }
    }
  }

  function handleCheckBoxCustomize() {
    setShowAvatarAndICPFields(checkboxCustomizeRef.current.checked);
    setInfos((prevState) => ({ ...prevState, checkboxSelected: checkboxCustomizeRef.current.checked }));
  }

  function loaderCounter({ segToComplete }) {
    const incrementBySec = 100 / segToComplete;

    // Limpar qualquer intervalo existente
    if (intervalLoader.current) {
      clearInterval(intervalLoader.current);
    }

    // Iniciar o contador em 0
    setCountLoader(0);

    // Configurar o novo intervalo
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => {
        const newCount = Number(prevCount) + incrementBySec;
        return newCount > 100 ? 100 : newCount;
      });
    }, 1000);

    return intervalLoader.current;
  }

  const toggleListening = () => {
    if (isListening) {
      // Para o reconhecimento
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      // Limpa o texto anterior e inicia nova gravação
      setText('');
      // Inicia o reconhecimento
      recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognitionRef.current.lang = infos.language; // use a proper language app
      recognitionRef.current.continuous = true;
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        setText((prev) => `${prev} ${transcript}`);
      };
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <QuestionArea>
          <QuestionAreaTwoColumns>
            {/* Language Inputs */}
            <div className="container-selects">
              <div className="container-selects-border">
                <h4>{translate.borderTitleLanguage}</h4>
                <div className="container-selects-inputs">
                  <QuestionContainer>
                    <label htmlFor="language">{translate.questionLanguage}</label>
                    <select
                      name="language"
                      id="language"
                      value={infos.language}
                      onChange={(e) => editFormValues(e)}
                    >
                      {/* <!-- Portuguese --> */}
                      <option value="pt-BR">Português (Brazil)</option>
                      <option value="pt-PT">Português (Portugal)</option>
                      <option value="pt-AO">Português (Angola)</option>
                      <option value="pt-MZ">Português (Mozambique)</option>
                      <option value="pt">Português (Generic)</option>

                      {/* <!-- English --> */}
                      <option value="en-US">English (United States)</option>
                      <option value="en-GB">English (United Kingdom)</option>
                      <option value="en-AU">English (Australia)</option>
                      <option value="en-CA">English (Canada)</option>
                      <option value="en-NZ">English (New Zealand)</option>
                      <option value="en-IE">English (Ireland)</option>
                      <option value="en">English (Generic)</option>

                      {/* <!-- Spanish --> */}
                      <option value="es-ES">Español (Spain)</option>
                      <option value="es-MX">Español (Mexico)</option>
                      <option value="es-AR">Español (Argentina)</option>
                      <option value="es-CL">Español (Chile)</option>
                      <option value="es-CO">Español (Colombia)</option>
                      <option value="es-PE">Español (Peru)</option>
                      <option value="es-VE">Español (Venezuela)</option>
                      <option value="es">Español (Generic)</option>

                      {/* <!-- French --> */}
                      <option value="fr-FR">Français (France)</option>
                      <option value="fr-CA">Français (Canada)</option>
                      <option value="fr-BE">Français (Belgium)</option>
                      <option value="fr-CH">Français (Switzerland)</option>
                      <option value="fr">Français (Generic)</option>

                      {/* <!-- German --> */}
                      <option value="de-DE">Deutsch (Germany)</option>
                      <option value="de-AT">Deutsch (Austria)</option>
                      <option value="de-CH">Deutsch (Switzerland)</option>
                      <option value="de">Deutsch (Generic)</option>

                      {/* <!-- Chinese --> */}
                      <option value="zh-CN">中文 - 简体 (Simplified Chinese)</option>
                      <option value="zh-TW">中文 - 繁體 (Traditional Chinese)</option>
                      <option value="zh-HK">中文 - 香港 (Hong Kong)</option>
                      <option value="zh">中文 (Generic)</option>

                      {/* <!-- Hindi --> */}
                      <option value="hi-IN">हिंदी (India)</option>
                      <option value="hi">हिंदी (Generic)</option>

                      {/* <!-- Bengali --> */}
                      <option value="bn-BD">বাংলা (Bangladesh)</option>
                      <option value="bn-IN">বাংলা (India)</option>
                      <option value="bn">বাংলা (Generic)</option>

                      {/* <!-- Arabic --> */}
                      <option value="ar-SA">العربية (Saudi Arabia)</option>
                      <option value="ar-EG">العربية (Egypt)</option>
                      <option value="ar-DZ">العربية (Algeria)</option>
                      <option value="ar-MA">العربية (Morocco)</option>
                      <option value="ar-TN">العربية (Tunisia)</option>
                      <option value="ar-IQ">العربية (Iraq)</option>
                      <option value="ar-SY">العربية (Syria)</option>
                      <option value="ar-LB">العربية (Lebanon)</option>
                      <option value="ar-AE">العربية (United Arab Emirates)</option>
                      <option value="ar-KW">العربية (Kuwait)</option>
                      <option value="ar-QA">العربية (Qatar)</option>
                      <option value="ar-BH">العربية (Bahrain)</option>
                      <option value="ar-OM">العربية (Oman)</option>
                      <option value="ar-YE">العربية (Yemen)</option>
                      <option value="ar-JO">العربية (Jordan)</option>
                      <option value="ar">العربية (Generic)</option>
                    </select>
                  </QuestionContainer>
                  <QuestionContainer>
                    <label htmlFor="toneOfVoice">{translate.questionToneOfVoice}</label>
                    <select
                      name="toneOfVoice"
                      value={infos.toneOfVoice}
                      onChange={(e) => editFormValues(e)}
                    >
                      <option value="saleMode">{translate.optionSellingMode} ⭐</option>
                      <option value="businessAndTrust">{translate.optionBusinessAndTrust} ⭐</option>
                      <option value="profissional">{translate.optionProfessional} ⭐</option>
                      <option value="formal">Formal</option>
                      <option value="expert">{translate.optionExpert}</option>
                      <option value="casual">Casual</option>
                      <option value="socialMedia">{translate.optionSocialMedia}</option>
                      <option value="sensitiveCommunication">{translate.optionSensitiveCommunication}</option>
                      <option value="friendly">{translate.optionFriendly}</option>
                    </select>
                  </QuestionContainer>
                </div>
              </div>
            </div>

            {/* Geographic Inpus */}
            <div className="container-selects">
              <div className="container-selects-border">
                <h4>{translate.borderTitleGeo}</h4>
                <div className="container-selects-inputs">
                  <RegionsInput />
                </div>
              </div>
            </div>
          </QuestionAreaTwoColumns>

          {/* Context Input Area */}
          <QuestionContainer isListening={isListening} isDisabled={disabledTextArea}>
            <label htmlFor="infoSite">
              <span>{translate.labelContext}</span>
              <span className="context-tip">{' '}{translate.spanContext}</span>
            </label>
            <div className="text-area-container">
              <textarea
                name="infoSite"
                value={infos.infoSite}
                onChange={(e) => editFormValues(e)}
                placeholder={translate.placeholderContext}
                disabled={disabledTextArea}
              />
              <button
                type="button"
                onClick={toggleListening}
              >
                <img src={isListening ? IconSound : IconMic} alt="Microfone" />
              </button>
            </div>
            <Warning
              type="warning-dark"
              text={translate.warningMessage}
              size="small"
            />
          </QuestionContainer>

          {/* Avatar and Niche Input Area */}
          <CheckboxContainer>
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="checkBoxOptions"
                id="checkBoxOptions"
                ref={checkboxCustomizeRef}
                onChange={() => handleCheckBoxCustomize()}
              />
              <label htmlFor="checkBoxOptions">
                <span>{translate.labelCheckBox} </span>
              </label>
            </div>

            {(!infos.checkboxSelected) && (
              <div className="checkbox-message">
                <img src={IconInfoGray} alt="info" />
                <span>{translate.infoCheckBox}</span>
              </div>
            )}

            {(infos.checkboxSelected) && (
              <TextAreaContainer>
                <QuestionContainer $open={visibleOptions.avatar} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelAvatar}</span>
                    <span>{translate.spanAvatar}</span>
                  </label>
                  <textarea
                    name="avatar"
                    value={infos.avatar}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderAvatar}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('avatar')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsAvatar}>
                    <div>
                      <h4>B2B</h4>
                      {avatarListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {avatarListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
                <QuestionContainer $open={visibleOptions.niche} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelNiche}</span>
                    <span>{translate.spanNiche}</span>
                  </label>
                  <textarea
                    name="niche"
                    value={infos.niche}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderNiche}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('niche')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsNiche}>
                    <div>
                      <h4>B2B</h4>
                      {nicheListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {nicheListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
              </TextAreaContainer>
            )}
          </CheckboxContainer>
        </QuestionArea>

        <ResponseArea>
          {/* #1 Layer Response */}
          {(colletedDataFirstStage) && (
            <ResponseContainer id="first-stage-response">
              <h4>{translate.card1Title}</h4>
              <div className="response-area">
                {/* Visible to all users */}
                {(promptsResponse?.linkedinPost1 && promptsResponse?.linkedinPost1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPost1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPost2 && promptsResponse?.linkedinPost2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPost2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPost3 && promptsResponse?.linkedinPost3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPost3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPost4 && promptsResponse?.linkedinPost4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPost4}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPostAivav1 && promptsResponse?.linkedinPostAivav1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #5</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPostAivav1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPostAivav2 && promptsResponse?.linkedinPostAivav2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #6</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPostAivav2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPostAivav3 && promptsResponse?.linkedinPostAivav3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #7</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPostAivav3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinPostAivav4 && promptsResponse?.linkedinPostAivav4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn #8</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinPostAivav4}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #2 Layer Response */}
          {(colletedDataSecondStage) && (
            <ResponseContainer id="second-stage-response">
              <h4>{translate.card2Title}</h4>
              <div className="response-area">
                {/* Visible to all users */}
                {(promptsResponse?.instagramPost1 && promptsResponse?.instagramPost1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPost1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPost2 && promptsResponse?.instagramPost2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPost2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPost3 && promptsResponse?.instagramPost3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPost3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPost4 && promptsResponse?.instagramPost4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPost4}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #3 Layer Response */}
          {(colletedDataThirdStage) && (
            <ResponseContainer id="third-stage-response">
              <h4>{translate.card3Title}</h4>
              <div className="response-area">
                {/* Visible to all users */}
                {(promptsResponse?.facebookPost1 && promptsResponse?.facebookPost1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Facebook #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.facebookPost1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.facebookPost2 && promptsResponse?.facebookPost2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Facebook #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.facebookPost2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.facebookPost3 && promptsResponse?.facebookPost3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Facebook #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.facebookPost3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.facebookPost4 && promptsResponse?.facebookPost4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Facebook #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.facebookPost4}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #4 Layer Response */}
          {(colletedDataFourthStage) && (
            <ResponseContainer id="fourth-stage-response">
              <h4>{translate.card4Title}</h4>
              <div className="response-area">
                {/* Visible to all users */}
                {(promptsResponse?.tiktokPost1 && promptsResponse?.tiktokPost1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>TikTok #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.tiktokPost1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.tiktokPost2 && promptsResponse?.tiktokPost2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>TikTok #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.tiktokPost2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.tiktokPost3 && promptsResponse?.tiktokPost3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>TikTok #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.tiktokPost3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.tiktokPost4 && promptsResponse?.tiktokPost4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>TikTok #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.tiktokPost4}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #4 Layer Response */}
          {(colletedDataFifthStage) && (
            <ResponseContainer id="fifth-stage-response">
              <h4>{translate.card5Title}</h4>
              <div className="response-area">
                {/* Visible to all users */}
                {(promptsResponse?.emailMarketing1 && promptsResponse?.emailMarketing1.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Email Marketing #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailMarketing1}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailMarketing2 && promptsResponse?.emailMarketing2.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Email Marketing #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailMarketing2}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailMarketing3 && promptsResponse?.emailMarketing3.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Email Marketing #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailMarketing3}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailMarketing4 && promptsResponse?.emailMarketing4.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Email Marketing #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailMarketing4}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* Loader or ButtonsLayer */}
          {(!buttonsArea.show && isLoadingData)
            && (
              <LoaderContainer>
                <LoaderContainerMemo
                  count={countLoader}
                  message={translate.messageProccessInfoNoSec}
                  className="progress_bar-area"
                  color="yellow"
                />
              </LoaderContainer>
            )}

          {(buttonsArea.show && !isLoadingData)
            && (
            <ButtonsArea
              buttonsArea={buttonsArea}
              isDisabled={isBtnDisabled}
              onGetDataFirstStage={() => (
                getData(getDataLinkedIn, 'first', 'first-stage-response', setCollectedDataFirstStage)
              )}
              onGetDataSecondStage={() => (
                getData(getDataInstagram, 'second', 'second-stage-response', setCollectedDataSecondStage)
              )}
              onGetDataThirdStage={() => (
                getData(getDataFacebook, 'third', 'third-stage-response', setCollectedDataThirdStage)
              )}
              onGetDataFourthStage={() => (
                getData(getDataTiktok, 'fourth', 'fourth-stage-response', setCollectedDataFourthStage)
              )}
              onGetDataFifthStage={() => (
                getData(getDataEmail, 'fifth', 'fifth-stage-response', setCollectedDataFifthStage)
              )}
            />
            )}
        </ResponseArea>
      </Container>
    </>
  );
}
