import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ManageProjectContext } from '../../manageProjects.context';
import Loader from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import IconClipboard from '../../../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../../../styles/logos/check_icon.svg';
import IconTrash from '../../../../../../styles/logos/icon_trash_white.svg';
import IconEdit from '../../../../../../styles/logos/icon_edit_pencil.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, EntranceContainer, ResponseContainer, ResponseCardsContainer, Card,
} from './style';

export function InfosCopyCutUnpaidPosts() {
  const translate = Translate();
  const { id } = useParams();
  const {
    setProjectID, projectInfos, isLoadingProjectInfo, toogleModal, handleToogleModal, handleDeleteProject,
  } = useContext(ManageProjectContext);

  // CHANGE ID FROM Context function
  useEffect(() => {
    setProjectID(id);
  }, [id]);

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function translateToneOfVoice(toneOfVoice) {
    let toneOfVoiceTranslated = '';

    switch (toneOfVoice) {
      case 'saleMode':
        toneOfVoiceTranslated = translate.toneOfVoiceSaleMode;
        break;

      case 'profissional':
        toneOfVoiceTranslated = translate.toneOfVoiceProfissional;
        break;

      case 'expert':
        toneOfVoiceTranslated = translate.toneOfVoiceExpert;
        break;

      case 'businessAndTrust':
        toneOfVoiceTranslated = translate.toneOfVoiceBusinessAndTrust;
        break;

      case 'sensitiveCommunication':
        toneOfVoiceTranslated = translate.toneOfVoiceSensitiveCommunication;
        break;

      case 'friendly':
        toneOfVoiceTranslated = translate.toneOfVoiceFriendly;
        break;

      default:
        toneOfVoiceTranslated = toneOfVoice.charAt(0).toUpperCase() + toneOfVoice.slice(1);
        break;
    }

    return toneOfVoiceTranslated;
  }

  return (
    <>
      {isLoadingProjectInfo && <Loader loading={isLoadingProjectInfo} />}
      {!isLoadingProjectInfo && (
        <Container>
          <TitleContainer>
            <div className="title-infos">
              <div className="title-area">
                <h2>{projectInfos?.name}</h2>
                <Link to={`/client/ailab/manage-projects/edit-name/${projectInfos.id}?name=${projectInfos.name}`}>
                  <img src={IconEdit} alt="edit" />
                </Link>
              </div>
              <p>{translate.doneBy}<span>{projectInfos?.firstName} {projectInfos?.lastName} | {projectInfos?.email}</span></p>
            </div>
            <div className="title-actions">
              <Button
                color="red"
                onClick={() => handleToogleModal()}
              >
                <img
                  src={IconTrash}
                  alt="Delete Prompt"
                />
              </Button>
            </div>
          </TitleContainer>

          <EntranceContainer>
            <h3>Inputs</h3>
            <div className="info-group-d-flex">
              <div className="info-group">
                {/* <label>{translate.labelBusinessModel}</label> */}
                <label>Localização</label>
                <div>
                  {projectInfos?.continent}{' | '}
                  {projectInfos?.country}{' | '}
                  {projectInfos?.state}
                </div>
              </div>
              <div className="info-group">
                <label>{translate.labelLanguage}</label>
                <div>{projectInfos?.language ? projectInfos?.language : '---'}</div>
              </div>
              <div className="info-group">
                <label>{translate.labelToneOfVoice}</label>
                <div>{projectInfos?.toneOfVoice ? translateToneOfVoice(projectInfos?.toneOfVoice) : '---'}</div>
              </div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelSite} </label>
              <div>{projectInfos?.siteData}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelAvatar}</label>
              <div>{projectInfos?.avatar}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelNiche}</label>
              <div>{projectInfos?.niche}</div>
            </div>
          </EntranceContainer>

          <ResponseContainer>
            <h3>Outputs</h3>

            <div className="container-painels">
              <div className="container-layers">
                <h4>{translate.group1Title}</h4>

                {(!projectInfos?.linkedinPost1
                  && !projectInfos?.linkedinPost2
                  && !projectInfos?.linkedinPost3
                  && !projectInfos?.linkedinPost4
                  && !projectInfos?.linkedinAivavPost1
                  && !projectInfos?.linkedinAivavPost2
                  && !projectInfos?.linkedinAivavPost3
                  && !projectInfos?.linkedinAivavPost4
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.linkedinPost1 && projectInfos?.linkedinPost1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinPost1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinPost2 && projectInfos?.linkedinPost2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinPost2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinPost3 && projectInfos?.linkedinPost3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinPost3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinPost4 && projectInfos?.linkedinPost4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinPost4}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinAivavPost1 && projectInfos?.linkedinAivavPost1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #5</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinAivavPost1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinAivavPost2 && projectInfos?.linkedinAivavPost2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #6</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinAivavPost2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinAivavPost3 && projectInfos?.linkedinAivavPost3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #7</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinAivavPost3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinAivavPost4 && projectInfos?.linkedinAivavPost4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn #8</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinAivavPost4}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.group2Title}</h4>

                {(!projectInfos?.instagramPost1
                  && !projectInfos?.instagramPost2
                  && !projectInfos?.instagramPost3
                  && !projectInfos?.instagramPost4
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.instagramPost1 && projectInfos?.instagramPost1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPost1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPost2 && projectInfos?.instagramPost2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPost2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPost3 && projectInfos?.instagramPost3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPost3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.instagramPost4 && projectInfos?.instagramPost4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.instagramPost4}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.group3Title}</h4>

                {(!projectInfos?.facebookPost1
                  && !projectInfos?.facebookPost2
                  && !projectInfos?.facebookPost3
                  && !projectInfos?.facebookPost4
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.facebookPost1 && projectInfos?.facebookPost1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Facebook #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.facebookPost1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.facebookPost2 && projectInfos?.facebookPost2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Facebook #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.facebookPost2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.facebookPost3 && projectInfos?.facebookPost3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Facebook #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.facebookPost3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.facebookPost4 && projectInfos?.facebookPost4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Facebook #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.facebookPost4}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.group4Title}</h4>

                {(!projectInfos?.tiktokPost1
                  && !projectInfos?.tiktokPost2
                  && !projectInfos?.tiktokPost3
                  && !projectInfos?.tiktokPost4
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.tiktokPost1 && projectInfos?.tiktokPost1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>TikTok #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.tiktokPost1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.tiktokPost2 && projectInfos?.tiktokPost2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>TikTok #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.tiktokPost2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.tiktokPost3 && projectInfos?.tiktokPost3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>TikTok #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.tiktokPost3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.tiktokPost4 && projectInfos?.tiktokPost4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>TikTok #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.tiktokPost4}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.group5Title}</h4>

                {(!projectInfos?.emailMarketing1
                  && !projectInfos?.emailMarketing2
                  && !projectInfos?.emailMarketing3
                  && !projectInfos?.emailMarketing4
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.emailMarketing1 && projectInfos?.emailMarketing1.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Email Marketing #1</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailMarketing1}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.emailMarketing2 && projectInfos?.emailMarketing2.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Email Marketing #2</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailMarketing2}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.emailMarketing3 && projectInfos?.emailMarketing3.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Email Marketing #3</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailMarketing3}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.emailMarketing4 && projectInfos?.emailMarketing4.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Email Marketing #4</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailMarketing4}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>
            </div>
          </ResponseContainer>
        </Container>
      )}

      <Modal
        danger
        visible={toogleModal}
        title={translate.modalTitle}
        btnMessage={translate.modalBtn}
        onAction={() => handleDeleteProject()}
        onClose={() => handleToogleModal()}
      >
        {projectInfos?.name}
      </Modal>
    </>

  );
}
