import React, { useContext, useEffect, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import { SideBarVideosContext } from '../../context/SideBarVideosContext';
import { ProductsContext } from '../../context/ProductsContext';

import IconAcademy from '../../styles/logos/icon_academy.svg';
import IconConsultancy from '../../styles/logos/icon_consultancy1.svg';
import IconDataBase from '../../styles/logos/icon_database.svg';
import IconHouse from '../../styles/logos/icon_house.svg';
import IconMarketplace from '../../styles/logos/icon_marketplace.svg';
import IconRBI from '../../styles/logos/icon_rbi.svg';
import IconArrow from '../../styles/logos/icon_sidebararrow.svg';
import IconAdmin from '../../styles/logos/icon_admin.svg';
import IconBrain from '../../styles/logos/icon_brain.svg';
import IconLab from '../../styles/logos/icon_lab.svg';
import logoShiftersWhite from '../../styles/shifters-logos/shifters_white.png';
import logoShiftersRed from '../../styles/shifters-logos/shifters_red.png';
import logoShiftersYellow from '../../styles/shifters-logos/shifters_yellow.png';
import logoShiftersBlue from '../../styles/shifters-logos/shifters_blue.png';
import logoShiftersGreen from '../../styles/shifters-logos/shifters_green.png';


import { Translate } from './translate';
import {
  SideBar, SidebarWrapper, SidebarBackground, ListAcademy,
} from './styles';

const SideBarUser = forwardRef(({ toogle }, ref) => {
  const translate = Translate();
  const location = useLocation();
  const { products } = useContext(ProductsContext);
  const { userInfo } = useContext(AuthContext);
  const { product } = useContext(SideBarVideosContext);

  const productsAcademy = products
    .filter((product) => product.type === 'academy'
                      && product.active === 'Y');

  const productsConsulting = products
    .filter((product) => product.type === 'consulting'
                      && product.active === 'Y');

  const productsHome = products
    .filter((product) => product.type === 'home'
                      && product.active === 'Y');

  const productsIntelligence = products
    .filter((product) => product.type === 'intelligence'
                      && product.active === 'Y');

  function handleCollapse(e) {
    let submenuHTML = '';
    let arrowHTML = '';

    if (e.target.classList.contains('sidebar-logo') || e.target.classList.contains('sidebar-name') || e.target.classList.contains('sidebar-arrow')) {
      submenuHTML = e.target.parentElement.nextElementSibling;
    } else {
      submenuHTML = e.target.nextElementSibling;
    }

    if (e.target.classList.contains('sidebar-logo')) {
      arrowHTML = e.target.nextElementSibling.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-name')) {
      arrowHTML = e.target.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-arrow')) {
      arrowHTML = e.target;
    } else {
      arrowHTML = e.target.children[2];
    }

    if (submenuHTML.classList.contains('submenu')) {
      if (submenuHTML.className === 'submenu') {
        submenuHTML.classList.add('collapse');
        arrowHTML.classList.add('collapse');
      } else {
        submenuHTML.className = 'submenu';
        arrowHTML.className = 'sidebar-arrow';
      }
    }
  }

  useEffect(() => {
    if (product) {
      handleLogo();
    }
  }, [product, location]);

  function handleLogo() {
    if (Object.keys(product).length === 0 || !product || product.colorName === 'white' || !product.colorName) return logoShiftersWhite;
    if (product.colorName === 'red') return logoShiftersRed;
    if (product.colorName === 'yellow') return logoShiftersYellow;
    if (product.colorName === 'green') return logoShiftersGreen;
    if (product.colorName === 'blue') return logoShiftersBlue;
  }

  return (
    <SidebarWrapper>
      <SideBar $toogle={toogle} ref={ref}>
        <div className="container-logo">
          <Link to="/client">
            <img src={handleLogo()} alt="Logo Shifters" className="js-link" />
          </Link>
        </div>
        <ul>
          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconHouse} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name">Home</span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              <li>
                <Link to="/client/feed">
                  <span className="sidebar-name js-link">Feed</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/client/onboarding">
                  <span className="sidebar-name">Onboarding</span>
                </Link>
              </li> */}
              <li>
                <Link to="/client/favorites-videos">
                  <span className="sidebar-name js-link">{translate.favorite}</span>
                </Link>
              </li>
              <li>
                <Link to="/client/user-tracker">
                  <span className="sidebar-name js-link">{translate.userTracker}</span>
                </Link>
              </li>
              {productsHome.length > 0 && (
                productsHome.map((prod) => (
                  <ListAcademy
                    key={prod.id}
                    color={prod.colorName}
                    $productIsSelected={Number(prod.id) === Number(product?.id)}
                  >
                    <Link to={`/client/home/${prod.id}/${prod.first_video_id}`}>
                      <span className="sidebar-name js-link">
                        {translate.language === 'pt-BR' ? prod.namePt : prod.nameEn}
                      </span>
                    </Link>
                  </ListAcademy>
                ))
              )}
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconLab} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name"> AI Lab </span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              <li>
                <Link to="/client/ailab">
                  <span className="sidebar-name js-link">{translate.labelAILabsNewProject}</span>
                </Link>
              </li>
              <li>
                <Link to="/client/ailab/manage-projects">
                  <span className="sidebar-name js-link">{translate.labelAILabsManageProjects}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconAcademy} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name">Academy</span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              {productsAcademy.length > 0 && (
                productsAcademy.map((prod) => (
                  <ListAcademy
                    key={prod.id}
                    color={prod.colorName}
                    $productIsSelected={Number(prod.id) === Number(product?.id)}
                  >
                    <Link to={`/client/academy/${prod.id}/${prod.first_video_id}`}>
                      <span className="sidebar-name js-link">
                        {translate.language === 'pt-BR' ? prod.namePt : prod.nameEn}
                      </span>
                    </Link>
                  </ListAcademy>
                ))
              )}
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconConsultancy} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name">{translate.consulting}</span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              {productsConsulting.length > 0 && (
                productsConsulting.map((prod) => (
                  <ListAcademy
                    key={prod.id}
                    color={prod.colorName}
                    $productIsSelected={Number(prod.id) === Number(product?.id)}
                  >
                    <Link to={`/client/consulting/${prod.id}/${prod.first_video_id}`}>
                      <span className="sidebar-name js-link">
                        {translate.language === 'pt-BR' ? prod.namePt : prod.nameEn}
                      </span>
                    </Link>
                  </ListAcademy>
                ))
              )}
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconBrain} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name">{translate.intelligence}</span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              {productsIntelligence.length > 0 && (
                productsIntelligence.map((prod) => (
                  <ListAcademy
                    key={prod.id}
                    color={prod.colorName}
                    $productIsSelected={Number(prod.id) === Number(product?.id)}
                  >
                    <Link to={`/client/intelligence/${prod.id}/${prod.first_video_id}`}>
                      <span className="sidebar-name js-link">
                        {translate.language === 'pt-BR' ? prod.namePt : prod.nameEn}
                      </span>
                    </Link>
                  </ListAcademy>
                ))
              )}
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconRBI} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name"> RBI Software </span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              <li>
                <Link to="/client/dashboard/macro">
                  <span className="sidebar-name js-link">{translate.dashboardExecutivoMacro}</span>
                </Link>
              </li>
              {userInfo?.email === 'suporte+111menu@shifters.com.br' && (
                <>
                  <li>
                    <Link to="/client/dashboard/micro">
                      <span className="sidebar-name js-link">{translate.dashboardExecutivoMicro}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/client/dashboard/full-funnel">
                      <span className="sidebar-name js-link">Master Funnel</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </li>

          <li className="menu">
            <div onClick={handleCollapse}>
              <img src={IconDataBase} alt="Home" className="sidebar-logo" />
              <span className="sidebar-name">Data Services</span>
              <img src={IconArrow} alt="Home" className="sidebar-arrow" />
            </div>
            <ul className="submenu">
              <li>
                <Link to="/client/apis">
                  <span className="sidebar-name js-link"> API Connect</span>
                </Link>
              </li>
              <li>
                <Link to="/client/urlbuilder">
                  <span className="sidebar-name js-link"> URL Pro </span>
                </Link>
              </li>
              <li>
                <Link to="/client/b2b-databases">
                  <span className="sidebar-name js-link">Lead Intelligence</span>
                </Link>
              </li>
              <li>
                <Link to="/client/mass-keywords">
                  <span className="sidebar-name js-link">Keyword Master</span>
                </Link>
              </li>
            </ul>
          </li>

          {/* Marketplace Menu */}
          {userInfo?.email === 'suporte+111menu@shifters.com.br' && (
            <li className="menu">
              <div onClick={handleCollapse}>
                <img src={IconMarketplace} alt="Home" className="sidebar-logo" />
                <span className="sidebar-name">Marketplace</span>
                <img src={IconArrow} alt="Home" className="sidebar-arrow" />
              </div>
              <ul className="submenu">
                <li>
                  <Link to="/client/marketplace/search">
                    <span className="sidebar-name js-link">Search</span>
                  </Link>
                </li>
                <li>
                  <Link to="/client/marketplace/register">
                    <span className="sidebar-name js-link">Register</span>
                  </Link>
                </li>
                <li>
                  <Link to="/client/marketplace/cfo">
                    <span className="sidebar-name js-link">CFO As a Service</span>
                  </Link>
                </li>
                <li>
                  <Link to="/client/marketplace/cto">
                    <span className="sidebar-name js-link">CTO As a Service</span>
                  </Link>
                </li>
                <li>
                  <Link to="/client/marketplace/cmo">
                    <span className="sidebar-name js-link">CMO As a Service</span>
                  </Link>
                </li>
                <li>
                  <Link to="/client/marketplace/consultant">
                    <span className="sidebar-name js-link">{translate.digitalConsulting}</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}

          {userInfo?.admin === 'Y' && (
            <li className="menu">
              <div onClick={handleCollapse}>
                <img src={IconAdmin} alt="Home" className="sidebar-logo" />
                <span className="sidebar-name">Admin</span>
                <img src={IconArrow} alt="Home" className="sidebar-arrow" />
              </div>
              <ul className="submenu">
                <li>
                  <Link to="/client/company-tracker">
                    <span className="sidebar-name js-link">Talent Analytics</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </SideBar>
      <SidebarBackground $toogle={toogle} />
    </SidebarWrapper>
  );
});

export default SideBarUser;
