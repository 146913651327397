/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import CopyCutPaidAdsService from '../../../../services/CopyCutPaidAds';

import { AuthContext } from '../../../../context/AuthContext';
import { RegionInputContext } from '../../../../components/RegionsInput/RegionInput.context';
import { Card as CardBtn } from '../components/Card';

import TitlePage from '../../../../components/TitlePage';
import { RegionsInput } from '../../../../components/RegionsInput';
import { Warning } from '../../../../components/Warnings';
import toast from '../../../../helpers/toast';

import IconClipboard from '../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../styles/logos/check_icon.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';
import IconAdd from '../../../../styles/logos/add.svg';
import IconClear from '../../../../styles/logos/icon_clear.svg';
import IconInfoGray from '../../../../styles/logos/icon_info_gray.svg';
import IconMic from '../../../../styles/logos/icon_mic.svg';
import IconSound from '../../../../styles/logos/icon_sound_animated.svg';

import { nicheList } from './lists/nicheList';
import { avatarList } from './lists/avatarList';
import { LoaderContainerMemo } from '../components/LoaderContainer';

import { Translate } from './translate';
import {
  Container,
  LoaderContainer,
  QuestionArea,
  CheckboxContainer,
  QuestionAreaTwoColumns,
  TextAreaContainer,
  QuestionContainer,
  ResponseArea,
  ResponseContainer,
  Card,
} from './style';
import { ButtonsArea } from './ButtonsArea';

export function CopyCutPaidAds() {
  const translate = Translate();
  const [text, setText] = useState('');
  const { userInfo } = useContext(AuthContext);
  const { continentsSelected, countriesSelected, statesSelected } = useContext(RegionInputContext);
  const [infos, setInfos] = useState({
    id: null,
    businessModel: 'B2B',
    infoSite: '',
    niche: '',
    avatar: '',
    continent: [],
    country: [],
    state: [],
    language: translate.language === 'pt-BR' ? 'pt-BR' : 'en-US',
    toneOfVoice: 'saleMode',
    checkboxSelected: false,
  });
  const [promptsResponse, setPromptsResponse] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [buttonsArea, setButtonsArea] = useState({
    show: false,
    buttons: {
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      sixth: true,
      seventh: true,
    },
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [colletedDataFirstStage, setCollectedDataFirstStage] = useState(false);
  const [colletedDataSecondStage, setCollectedDataSecondStage] = useState(false);
  const [countLoader, setCountLoader] = useState(0);
  const [showAvatarAndICPFields, setShowAvatarAndICPFields] = useState(false);
  const intervalLoader = useRef(null);

  const checkboxCustomizeRef = useRef(null);

  const [disabledTextArea, setDisabledTextArea] = useState(false);

  const [avatarListB2B, setAvatarListB2B] = useState(avatarList('B2B', translate.language));
  const [avatarListB2C, setAvatarListB2C] = useState(avatarList('B2C', translate.language));
  const [nicheListB2B, setNicheListB2B] = useState(nicheList('B2B', translate.language));
  const [nicheListB2C, setNicheListB2C] = useState(nicheList('B2C', translate.language));

  const [visibleOptions, setVisibleOptions] = useState({
    avatar: false,
    niche: false,
  });

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy1: false,
    foundationalCopy2: false,
    foundationalCopy3: false,
    foundationalCopy4: false,
  });

  const divOptionsAvatar = useRef(null);
  const divOptionsNiche = useRef(null);

  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  // Clear all intervals when component is mounted, if there is any intervals running
  useEffect(() => () => {
    clearInterval(intervalLoader.current);
  }, []);

  // it monitors main button to enable or disable
  useEffect(() => {
    toogleMainBtn();
  }, [infos]);

  useEffect(() => {
    setInfos((prevState) => ({
      ...prevState,
      continent: continentsSelected.continent,
      country: countriesSelected.country,
      state: statesSelected.state,
    }));
  }, [continentsSelected, countriesSelected, statesSelected]);

  useEffect(() => {
    setInfos((prevState) => ({ ...prevState, infoSite: text }));
  }, [text]);

  async function getDataLayer1() {
    // Clear values
    setIsLoadingData(true);
    setPromptsResponse({});

    // For users don't update texts
    setDisabledTextArea(true);

    // Init counter
    const interval = loaderCounter({ segToComplete: 480 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const responsePromptsDb = await CopyCutPaidAdsService.postLayer1({ infos });
      setPromptsResponse((prevState) => ({
        ...prevState,
        ...responsePromptsDb,
        id: responsePromptsDb.id,
      }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ ...prevState, show: true }));
      setCollectedDataFirstStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('first-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataLayer2() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    const interval = loaderCounter({ segToComplete: 420 });
    intervalLoader.current = interval;

    // Get datas
    try {
      const responsePromptsDb = await CopyCutPaidAdsService.postLayer2({ id: promptsResponse.id });
      setPromptsResponse((prevState) => ({
        ...prevState,
        ...responsePromptsDb,
      }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, second: false } }));
      setCollectedDataSecondStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('second-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function toogleMainBtn() {
    const contextIsFilled = infos.infoSite.length > 0;

    if (!contextIsFilled) {
      setIsBtnDisabled(true);
      return;
    }

    setIsBtnDisabled(false);
  }

  function editFormValues(event) {
    setInfos((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  function handleOptionsArea(containerId) {
    setVisibleOptions((prevState) => ({
      ...prevState,
      [containerId]: !prevState[containerId],
    }));
  }

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prevState) => ({
      ...prevState,
      [containerId]: !prevState[containerId],
    }));
  }

  function toogleSelectedOptions(event, type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const checked = event.target.checked;
      const value = event.target.value;

      if (checked) {
        // ADD INTO STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        if (stringTurnedIntoArray?.includes(value)) return;
        stringTurnedIntoArray.push(value);
        setInfos((prev) => ({ ...prev, [key]: stringTurnedIntoArray.join(', ') }));
      } else {
        // REMOVE FROM STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        const filteredArray = stringTurnedIntoArray.filter((item) => item !== value);
        setInfos((prev) => ({ ...prev, [key]: filteredArray.join(', ') }));
      }
    }
  }

  function addAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const arrOfOptions = type === 'avatar' ? [...avatarListB2B, ...avatarListB2C] : [...nicheListB2B, ...nicheListB2C];
      setInfos((prev) => ({ ...prev, [key]: arrOfOptions.join(', ') }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = true;
      }
    }
  }

  function deleteAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      setInfos((prev) => ({ ...prev, [key]: '' }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = false;
      }
    }
  }

  function handleCheckBoxCustomize() {
    setShowAvatarAndICPFields(checkboxCustomizeRef.current.checked);
    setInfos((prevState) => ({ ...prevState, checkboxSelected: checkboxCustomizeRef.current.checked }));
  }

  function loaderCounter({ segToComplete }) {
    const incrementBySec = 100 / segToComplete;

    // Limpar qualquer intervalo existente
    if (intervalLoader.current) {
      clearInterval(intervalLoader.current);
    }

    // Iniciar o contador em 0
    setCountLoader(0);

    // Configurar o novo intervalo
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => {
        const newCount = Number(prevCount) + incrementBySec;
        return newCount > 100 ? 100 : newCount;
      });
    }, 1000);

    return intervalLoader.current;
  }

  const toggleListening = () => {
    if (isListening) {
      // Para o reconhecimento
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      // Limpa o texto anterior e inicia nova gravação
      setText('');
      // Inicia o reconhecimento
      recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognitionRef.current.lang = infos.language; // use a proper language app
      recognitionRef.current.continuous = true;
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        setText((prev) => `${prev} ${transcript}`);
      };
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <QuestionArea>
          <QuestionAreaTwoColumns>

            {/* Language Inputs */}
            <div className="container-selects">
              <div className="container-selects-border">
                <h4>{translate.borderTitleLanguage}</h4>
                <div className="container-selects-inputs">
                  <QuestionContainer>
                    <label htmlFor="language">{translate.questionLanguage}</label>
                    <select
                      name="language"
                      id="language"
                      value={infos.language}
                      onChange={(e) => editFormValues(e)}
                    >
                      {/* <!-- Portuguese --> */}
                      <option value="pt-BR">Português (Brazil)</option>
                      <option value="pt-PT">Português (Portugal)</option>
                      <option value="pt-AO">Português (Angola)</option>
                      <option value="pt-MZ">Português (Mozambique)</option>
                      <option value="pt">Português (Generic)</option>

                      {/* <!-- English --> */}
                      <option value="en-US">English (United States)</option>
                      <option value="en-GB">English (United Kingdom)</option>
                      <option value="en-AU">English (Australia)</option>
                      <option value="en-CA">English (Canada)</option>
                      <option value="en-NZ">English (New Zealand)</option>
                      <option value="en-IE">English (Ireland)</option>
                      <option value="en">English (Generic)</option>

                      {/* <!-- Spanish --> */}
                      <option value="es-ES">Español (Spain)</option>
                      <option value="es-MX">Español (Mexico)</option>
                      <option value="es-AR">Español (Argentina)</option>
                      <option value="es-CL">Español (Chile)</option>
                      <option value="es-CO">Español (Colombia)</option>
                      <option value="es-PE">Español (Peru)</option>
                      <option value="es-VE">Español (Venezuela)</option>
                      <option value="es">Español (Generic)</option>

                      {/* <!-- French --> */}
                      <option value="fr-FR">Français (France)</option>
                      <option value="fr-CA">Français (Canada)</option>
                      <option value="fr-BE">Français (Belgium)</option>
                      <option value="fr-CH">Français (Switzerland)</option>
                      <option value="fr">Français (Generic)</option>

                      {/* <!-- German --> */}
                      <option value="de-DE">Deutsch (Germany)</option>
                      <option value="de-AT">Deutsch (Austria)</option>
                      <option value="de-CH">Deutsch (Switzerland)</option>
                      <option value="de">Deutsch (Generic)</option>

                      {/* <!-- Chinese --> */}
                      <option value="zh-CN">中文 - 简体 (Simplified Chinese)</option>
                      <option value="zh-TW">中文 - 繁體 (Traditional Chinese)</option>
                      <option value="zh-HK">中文 - 香港 (Hong Kong)</option>
                      <option value="zh">中文 (Generic)</option>

                      {/* <!-- Hindi --> */}
                      <option value="hi-IN">हिंदी (India)</option>
                      <option value="hi">हिंदी (Generic)</option>

                      {/* <!-- Bengali --> */}
                      <option value="bn-BD">বাংলা (Bangladesh)</option>
                      <option value="bn-IN">বাংলা (India)</option>
                      <option value="bn">বাংলা (Generic)</option>

                      {/* <!-- Arabic --> */}
                      <option value="ar-SA">العربية (Saudi Arabia)</option>
                      <option value="ar-EG">العربية (Egypt)</option>
                      <option value="ar-DZ">العربية (Algeria)</option>
                      <option value="ar-MA">العربية (Morocco)</option>
                      <option value="ar-TN">العربية (Tunisia)</option>
                      <option value="ar-IQ">العربية (Iraq)</option>
                      <option value="ar-SY">العربية (Syria)</option>
                      <option value="ar-LB">العربية (Lebanon)</option>
                      <option value="ar-AE">العربية (United Arab Emirates)</option>
                      <option value="ar-KW">العربية (Kuwait)</option>
                      <option value="ar-QA">العربية (Qatar)</option>
                      <option value="ar-BH">العربية (Bahrain)</option>
                      <option value="ar-OM">العربية (Oman)</option>
                      <option value="ar-YE">العربية (Yemen)</option>
                      <option value="ar-JO">العربية (Jordan)</option>
                      <option value="ar">العربية (Generic)</option>
                    </select>
                  </QuestionContainer>
                  <QuestionContainer>
                    <label htmlFor="toneOfVoice">{translate.questionToneOfVoice}</label>
                    <select
                      name="toneOfVoice"
                      value={infos.toneOfVoice}
                      onChange={(e) => editFormValues(e)}
                    >
                      <option value="saleMode">{translate.optionSellingMode} ⭐</option>
                      <option value="businessAndTrust">{translate.optionBusinessAndTrust} ⭐</option>
                      <option value="profissional">{translate.optionProfessional} ⭐</option>
                      <option value="formal">Formal</option>
                      <option value="expert">{translate.optionExpert}</option>
                      <option value="casual">Casual</option>
                      <option value="socialMedia">{translate.optionSocialMedia}</option>
                      <option value="sensitiveCommunication">{translate.optionSensitiveCommunication}</option>
                      <option value="friendly">{translate.optionFriendly}</option>
                    </select>
                  </QuestionContainer>
                </div>
              </div>
            </div>

            {/* Geographic Inpus */}
            <div className="container-selects">
              <div className="container-selects-border">
                <h4>{translate.borderTitleGeo}</h4>
                <div className="container-selects-inputs">
                  <RegionsInput />
                </div>
              </div>
            </div>
          </QuestionAreaTwoColumns>

          {/* Context Input Area */}
          <QuestionContainer isListening={isListening} isDisabled={disabledTextArea}>
            <label htmlFor="infoSite">
              <span>{translate.labelContext}</span>
              <span className="context-tip">{' '}{translate.spanContext}</span>
            </label>
            <div className="text-area-container">
              <textarea
                name="infoSite"
                value={infos.infoSite}
                onChange={(e) => editFormValues(e)}
                placeholder={translate.placeholderContext}
                disabled={disabledTextArea}
              />
              <button
                type="button"
                onClick={toggleListening}
              >
                <img src={isListening ? IconSound : IconMic} alt="Microfone" />
              </button>
            </div>
            <Warning
              type="warning-dark"
              text={translate.warningMessage}
              size="small"
            />
          </QuestionContainer>

          {/* Avatar and Niche Input Area */}
          <CheckboxContainer>
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="checkBoxOptions"
                id="checkBoxOptions"
                ref={checkboxCustomizeRef}
                onChange={() => handleCheckBoxCustomize()}
              />
              <label htmlFor="checkBoxOptions">
                <span>{translate.labelCheckBox} </span>
              </label>
            </div>

            {(!infos.checkboxSelected) && (
              <div className="checkbox-message">
                <img src={IconInfoGray} alt="info" />
                <span>{translate.infoCheckBox}</span>
              </div>
            )}

            {(infos.checkboxSelected) && (
              <TextAreaContainer>
                <QuestionContainer $open={visibleOptions.avatar} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelAvatar}</span>
                    <span>{translate.spanAvatar}</span>
                  </label>
                  <textarea
                    name="avatar"
                    value={infos.avatar}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderAvatar}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('avatar')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('avatar')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsAvatar}>
                    <div>
                      <h4>B2B</h4>
                      {avatarListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {avatarListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
                <QuestionContainer $open={visibleOptions.niche} isDisabled={disabledTextArea}>
                  <label htmlFor="avatar">
                    <span>{translate.labelNiche}</span>
                    <span>{translate.spanNiche}</span>
                  </label>
                  <textarea
                    name="niche"
                    value={infos.niche}
                    onChange={(e) => editFormValues(e)}
                    placeholder={translate.placeholderNiche}
                    disabled={disabledTextArea}
                  />
                  <div className="btns-area">
                    <div
                      onClick={() => handleOptionsArea('niche')}
                      className="btns-action open-modal"
                    >
                      {translate.btnExplore}
                      <img src={IconArrow} alt="Arrow" />
                    </div>
                    <div
                      onClick={() => addAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnAddAll}
                      <img src={IconAdd} alt="Add" />
                    </div>
                    <div
                      onClick={() => deleteAllOptions('niche')}
                      className="btns-action"
                    >
                      {translate.btnDeleteAll}
                      <img src={IconClear} alt="Delete all" />
                    </div>
                  </div>
                  <div className="options-area" ref={divOptionsNiche}>
                    <div>
                      <h4>B2B</h4>
                      {nicheListB2B.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                    <div>
                      <h4>B2C</h4>
                      {nicheListB2C.map((item) => (
                        <label key={item}>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            onChange={(e) => toogleSelectedOptions(e, 'niche')}
                          /> {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </QuestionContainer>
              </TextAreaContainer>
            )}
          </CheckboxContainer>
        </QuestionArea>

        <ResponseArea>
          {/* Button #1 Layer */}
          {(!colletedDataFirstStage && !isLoadingData) && (
            <CardBtn
              buttonContent={translate.btn}
              colorButton="yellow"
              estimatedTimeDuration="8-9 m"
              estimatedTimeText={translate.estimatedTime}
              mainMessage={translate.card1Description}
              onHandleClick={getDataLayer1}
              title={translate.card1Title}
              isDisabled={isBtnDisabled}
            />
          )}

          {/* #1 Layer Response */}
          {(colletedDataFirstStage) && (
            <ResponseContainer id="first-stage-response">
              <h4>{translate.card1Title}</h4>
              <div className="response-area">
                {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                <>
                  {(promptsResponse?.foundationalCopy1 && promptsResponse?.foundationalCopy1.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy1}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy1', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy1}
                      </div>
                    </Card>
                  )}
                  {(promptsResponse?.foundationalCopy2 && promptsResponse?.foundationalCopy2.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy2}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy2', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy2}
                      </div>
                    </Card>
                  )}
                  {(promptsResponse?.foundationalCopy3 && promptsResponse?.foundationalCopy3.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy3}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy3', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy3}
                      </div>
                    </Card>
                  )}
                  {(promptsResponse?.foundationalCopy4 && promptsResponse?.foundationalCopy4.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy4}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy4', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy4}
                      </div>
                    </Card>
                  )}
                </>
                )}

                {/* Visible to all users */}
                {(promptsResponse?.vslDirectionToPleasure && promptsResponse?.vslDirectionToPleasure.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLDirectionToPleasure}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslDirectionToPleasure}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslWithdrawalFromPain && promptsResponse?.vslWithdrawalFromPain.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLWithdrawalFromPain}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslWithdrawalFromPain}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslImpactfulStatistics && promptsResponse?.vslImpactfulStatistics.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLImpactfulStatistics}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslImpactfulStatistics}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslPhysicalGuidance && promptsResponse?.vslPhysicalGuidance.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLPhysicalGuidance}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslPhysicalGuidance}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslValueStatement && promptsResponse?.vslValueStatement.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLValueStatement}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslValueStatement}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslTestimonial && promptsResponse?.vslTestimonial.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVSLTestimonial}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslTestimonial}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #2 Layer Response */}
          {colletedDataSecondStage && (
            <ResponseContainer id="second-stage-response">
              <h4>{translate.card2Title}</h4>
              <div className="response-area">
                {(promptsResponse?.instagramPrimaryGoal && promptsResponse?.instagramPrimaryGoal.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #1</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPrimaryGoal}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPrimaryComplaint && promptsResponse?.instagramPrimaryComplaint.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #2</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPrimaryComplaint}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramSecondGoal && promptsResponse?.instagramSecondGoal.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #3</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramSecondGoal}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramSecondComplaint && promptsResponse?.instagramSecondComplaint.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #4</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramSecondComplaint}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPromisses && promptsResponse?.instagramPromisses.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #5</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPromisses}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramBenefits && promptsResponse?.instagramBenefits.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #6</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBenefits}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramObjections && promptsResponse?.instagramObjections.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #7</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramObjections}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramConfusions && promptsResponse?.instagramConfusions.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #8</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramConfusions}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramUltimateFear && promptsResponse?.instagramUltimateFear.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #9</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramUltimateFear}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramFalseSolution && promptsResponse?.instagramFalseSolution.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #10</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramFalseSolution}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramMistakenBeliefs && promptsResponse?.instagramMistakenBeliefs.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #11</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramMistakenBeliefs}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramExpensiveAlternatives
                  && promptsResponse?.instagramExpensiveAlternatives.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #12</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramExpensiveAlternatives}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramBusinessPurpose && promptsResponse?.instagramBusinessPurpose.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #13</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBusinessPurpose}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramCaseStudies && promptsResponse?.instagramCaseStudies.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #14</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramCaseStudies}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPersonalValues && promptsResponse?.instagramPersonalValues.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #15</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPersonalValues}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramPersonalPrinciples && promptsResponse?.instagramPersonalPrinciples.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #16</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramPersonalPrinciples}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramBusinessBeliefs && promptsResponse?.instagramBusinessBeliefs.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #17</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBusinessBeliefs}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramBusinessThesis && promptsResponse?.instagramBusinessThesis.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #18</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBusinessThesis}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramBusinessMovement && promptsResponse?.instagramBusinessMovement.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #19</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBusinessMovement}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramBusinessFairCause && promptsResponse?.instagramBusinessFairCause.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #20</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBusinessFairCause}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramMarketingUniqueMechanism
                  && promptsResponse?.instagramMarketingUniqueMechanism.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #21</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramMarketingUniqueMechanism}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramFounderPositioning
                  && promptsResponse?.instagramFounderPositioning.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #22</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramFounderPositioning}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramBrandPositioning && promptsResponse?.instagramBrandPositioning.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #23</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramBrandPositioning}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramIrresistibleOffer && promptsResponse?.instagramIrresistibleOffer.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Instagram #24</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.instagramIrresistibleOffer}
                  </div>
                </Card>
                )}

                {(promptsResponse?.instagramProductPositioning
                  && promptsResponse?.instagramProductPositioning.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #25</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioning}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramProductPositioningName
                  && promptsResponse?.instagramProductPositioningName.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #26</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioningName}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramProductPositioningBenefits
                  && promptsResponse?.instagramProductPositioningBenefits.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #27</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioningBenefits}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramProductPositioningFeatures
                  && promptsResponse?.instagramProductPositioningFeatures.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #28</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioningFeatures}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramProductPositioningPrice
                  && promptsResponse?.instagramProductPositioningPrice.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #29</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioningPrice}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramProductPositioningDescription
                  && promptsResponse?.instagramProductPositioningDescription.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #30</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramProductPositioningDescription}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramMinimumViableProduct
                  && promptsResponse?.instagramMinimumViableProduct.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #31</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramMinimumViableProduct}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramMarketProblem
                  && promptsResponse?.instagramMarketProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #32</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramMarketProblem}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramMarketDesire
                  && promptsResponse?.instagramMarketDesire.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #33</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramMarketDesire}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.instagramMarketNeed
                  && promptsResponse?.instagramMarketNeed.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Instagram #34</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.instagramMarketNeed}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* Loader or ButtonsLayer */}
          {(!buttonsArea.show && isLoadingData)
            && (
              <LoaderContainer>
                <LoaderContainerMemo
                  count={countLoader}
                  message={translate.messageProccessInfoNoSec}
                  className="progress_bar-area"
                  color="yellow"
                />
              </LoaderContainer>
            )}

          {buttonsArea.show && (
            <ButtonsArea
              buttonsArea={buttonsArea}
              onGetDataSecondStage={getDataLayer2}
            />
          )}
        </ResponseArea>
      </Container>
    </>
  );
}
