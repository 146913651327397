/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../style';

export function TermsContentEN() {
  return (
    <Container>
      <h2>TERMS OF SERVICE - COPYCUT AI</h2>

      <p>(A product of SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP)</p>
      <p>Last Updated: March 12, 2025</p>

      <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR SERVICES. BY ACCESSING OR USING COPYCUT AI, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, YOU MUST NOT USE OUR SERVICES.
        WHILE WE STRIVE TO PROVIDE ACCURATE AND RELIABLE AI-GENERATED CONTENT, LANGUAGE MODELS CAN MAKE MISTAKES. USERS ARE SOLELY RESPONSIBLE FOR REVIEWING ALL GENERATED CONTENT FOR ACCURACY, APPROPRIATENESS, AND LEGAL COMPLIANCE BEFORE USE. COPYCUT AI ASSUMES NO LIABILITY FOR DECISIONS MADE OR ACTIONS TAKEN BASED ON AI-GENERATED CONTENT.
      </p>

      <h3>1. DEFINITIONS</h3>
      <p>"Agreement" means these Terms of Service.
        "Company", "we", "us", or "our" refers to SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP, registered under CNPJ/MF: 15.563.090/0001-04, with headquarters in Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011, São Paulo, Brazil operates CopyCut AI, an AI-powered marketing content generation platform. These terms and conditions apply to our services at https://app.shifters.ai or https://www.shifters.ai or https://shifters.ai, or interact with any of our applications, platforms, or related services (collectively, the "Services").
        "Content" means any information, data, text, software, music, sound, photographs, graphics, videos, messages, or other materials.
        "Generated Content" means all content created by the Services based on User Content or instructions.
        "Services" means the CopyCut AI platform and all related services, features, applications, and content.
        "Subscription" means the recurring payment plan for access to the Services.
        "User", "you", or "your" refers to any individual or entity that accesses or uses the Services.
        "User Content" means any Content that a User uploads, submits, or otherwise makes available to the Services.
      </p>

      <h3>2. SERVICE DESCRIPTION</h3>
      <p>CopyCut AI provides AI-powered marketing content generation services, including but not limited to:
        <ul>
          <li>VSL (Video Sales Letter) Generation</li>
          <li>Ad Campaign Creation</li>
          <li>Irresistible Offers</li>
          <li>Email Sequence Generation</li>
          <li>Marketing Mechanism Building</li>
          <li>Multi-language Optimization</li>
          <li>Custom LLM Development (Enterprise)</li>
          <li>Content Optimization and Enhancement</li>
          <li>Brand Voice Customization</li>
          <li>Marketing Strategy Recommendations</li>
          <li>The Services are provided on an "as is" and "as available" basis. We may update, modify, or enhance the Services at any time without prior notice.</li>
        </ul>
      </p>

      <h3>3. ELIGIBILITY AND REGISTRATION</h3>
      <p>
        <h4>3.1 Age Requirement</h4>
        <p>You must be at least 18 years of age to use the Services. By using the Services, you represent and warrant that you are at least 18 years old.</p>

        <h4>3.2 Registration Information</h4>
        <p>To access and use the Services, you must register and create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

        <h4>3.3 Account Verification</h4>
        <p>We reserve the right to verify your identity and eligibility at any time. We may request additional information or documentation to confirm your identity or authority to use the Services.</p>

        <h4>3.4 Corporate Users</h4>
        <p>If you register on behalf of a business or legal entity, you represent and warrant that you have the authority to bind that entity to this Agreement, and that entity accepts this Agreement.</p>
      </p>

      <h3>4. SERVICE TIERS AND PRICING</h3>
      <p>
        <h4>4.1 Service Tiers</h4>
        <p>CopyCut AI offers the following service tiers:
          <ul>
            <li>Start: $497/month</li>
            <li>Scale: $997/month</li>
            <li>Enterprise: $4,997/month</li>
          </ul>
        </p>

        <h4>4.2 Tier Features</h4>
        <p>Each tier includes specific features, usage limits, and support levels as described on our pricing page. We reserve the right to modify tier features at any time with notice to users.</p>

        <h4>4.3 Custom Solutions</h4>
        <p>Enterprise customers may request custom solutions with pricing determined on a case-by-case basis.</p>

        <h4>4.4 Price Changes</h4>
        <p>We reserve the right to change our prices at any time. Price changes will be communicated to existing subscribers at least 30 days before taking effect.</p>
      </p>

      <h3>5. PERFORMANCE SPECIFICATIONS</h3>
      <p>
        <h4>5.1 Generation Capabilities</h4>
        <p>
          <ul>
            <li>Generation Speed: 50+ assets in 30 minutes</li>
            <li>Language Support: 10 languages with 53 regional variations</li>
            <li>Brand Voice Options: 9 distinct tones</li>
            <li>Setup Time: 5 minutes</li>
            <li>Online Tutorial Videos: 5 videos</li>
          </ul>
        </p>

        <h4>5.2 Performance Limitations</h4>
        <p>Performance may vary based on system load, complexity of requests, and other factors. The specifications provided are estimates and not guarantees.</p>

        <h4>5.3 Usage Limits</h4>
        <p>Each subscription tier has specific usage limits. Exceeding these limits may result in additional charges or temporary service limitations.</p>
      </p>

      <h3>6. SERVICE LEVEL AGREEMENT</h3>
      <p>
        <h4>6.1 Uptime Guarantee</h4>
        <p>We guarantee 99.9% uptime for the Services, excluding scheduled maintenance.</p>

        <h4>6.2 Support Response Time</h4>
        <p>
          <ul>
            <li>Start Tier: Response within 24 hours on weekdays</li>
            <li>Scale Tier: Response within 12 hours on weekdays</li>
            <li>Enterprise Tier: Response within 4 hours on weekdays</li>
          </ul>
        </p>

        <h4>6.3 Scheduled Maintenance</h4>
        <p>We will provide advance notice of scheduled maintenance. Maintenance typically occurs during off-peak hours.</p>

        <h4>6.4 Data Backup</h4>
        <p>We perform daily backups of system data. However, Users are responsible for maintaining their own backups of important content.</p>

        <h4>6.5 Service Credits</h4>
        <p>If we fail to meet our uptime guarantee, eligible customers may request service credits according to the following schedule:
          <ul>
            <li>99.0% to 99.9% uptime: 10% credit of monthly fee</li>
            <li>95.0% to 98.9% uptime: 25% credit of monthly fee</li>
            <li>Below 95.0% uptime: 50% credit of monthly fee</li>
          </ul>
          Service credit requests must be submitted within 30 days of the incident and/or deducted from the invoice of the following month.
        </p>
      </p>

      <h3>7. INTELLECTUAL PROPERTY RIGHTS</h3>
      <p>
        <h4>7.1 Ownership of Generated Content</h4>
        <p>Subject to payment of applicable fees, you own all rights to the Generated Content created through your use of the Services. However, this ownership does not extend to any underlying technology, algorithms, or models used to create such content.</p>

        <h4>7.2 Ownership of Platform</h4>
        <p>The Services, including all software, algorithms, user interfaces, trademarks, logos, and other content provided by us, are owned by the Company and protected by intellectual property laws. No ownership rights to the platform or underlying technology are transferred to you.</p>

        <h4>7.3 Client Data Ownership</h4>
        <p>You retain all ownership rights to your User Content. By submitting User Content, you grant us a license as described in Section 8.</p>

        <h4>7.4 Feedback</h4>
        <p>If you provide suggestions, ideas, or feedback about the Services ("Feedback"), you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, create derivative works of, display, and perform the Feedback for any purpose without compensation to you.</p>
      </p>

      <h3>8. USER CONTENT AND LICENSES</h3>
      <p>
        <h4>8.1 License to User Content</h4>
        <p>By submitting User Content to the Services, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, process, adapt, modify, publish, transmit, and display such User Content solely for the purposes of:
          <ul>
            <li>Providing and maintaining the Services</li>
            <li>Improving and developing the Services</li>
            <li>Complying with legal obligations</li>
            <li>Enforcing our terms and policies</li>
          </ul>
        </p>

        <h4>8.2 AI Training License for Users in Free Trial, 7-day Paid Trial, 14-day Paid Trial, or 30-day Paid Trial</h4>
        <p>If you use our Services during a free trial, 7-day Paid Trial, 14-day Paid Trial, or 30-day Paid Trial, you additionally grant us a non-exclusive, worldwide, royalty-free license to use your User Content to train, test, and improve our artificial intelligence models and algorithms. This additional license is revocable upon upgrading to a paid subscription (Monthly Plan or Annual Plan) or after the trial period of 7 days, 14 days, or 30 days ends and you start a paid subscription (Monthly Plan or Annual Plan).</p>

        <h4>8.3 Paid Subscription Users</h4>
        <p>For paid subscription users, we will not use your User Content to train our AI models without your explicit consent.</p>

        <h4>8.4 User Content Representations</h4>
        <p>You represent and warrant that:
          <ul>
            <li>You own or have obtained all necessary rights to the User Content</li>
            <li>Your User Content does not violate any third-party rights</li>
            <li>Your User Content complies with all applicable laws and regulations</li>
            <li>You have the legal right to grant the licenses described in this Agreement</li>
          </ul>
        </p>

        <h4>8.5 Content Removal</h4>
        <p>We reserve the right to remove any User Content that violates this Agreement or that we determine is harmful, offensive, or otherwise objectionable.</p>
      </p>

      <h3>9. DATA PROTECTION AND SECURITY</h3>
      <p>
        <h4>9.1 Privacy Policy</h4>
        <p>Our collection and use of personal information is governed by our Privacy Policy, which is incorporated into this Agreement by reference.</p>

        <h4>9.2 LGPD Compliance</h4>
        <p>We comply with the Brazilian General Data Protection Law (LGPD) and other applicable data protection laws.</p>

        <h4>9.3 Security Measures</h4>
        <p>We implement and maintain reasonable technical, administrative, and physical safeguards designed to protect your data, including:
          <ul>
            <li>Enterprise-grade encryption for data in transit and at rest</li>
            <li>Regular security audits and vulnerability assessments</li>
            <li>Access controls and authentication requirements</li>
            <li>Secure data centers with physical security measures</li>
          </ul>
        </p>

        <h4>9.4 Data Breach Notification</h4>
        <p>In the event of a data breach affecting your personal information, we will notify you in accordance with applicable law.</p>

        <h4>9.5 Data Retention</h4>
        <p>We retain User Content and account information for as long as your account is active or as needed to provide the Services. After account termination, we may retain certain information as required by law or for legitimate business purposes.</p>

        <h4>9.6 Third-Party Data Processing</h4>
        <p>We may engage third-party service providers to process data on our behalf. These providers are bound by confidentiality obligations and are prohibited from using the data for any purpose other than providing services to us.</p>
      </p>

      <h3>10. ACCOUNT TERMS</h3>
      <p>
        <h4>10.1 Account Creation</h4>
        <p>You must create an account to use the Services. You are responsible for maintaining the security of your account credentials.</p>

        <h4>10.2 One Account Per User</h4>
        <p>Each user is permitted to create only one account. Multiple accounts created by the same user may be terminated.</p>

        <h4>10.3 Accurate Information</h4>
        <p>You must provide accurate and complete information when creating your account and keep this information updated.</p>

        <h4>10.4 Account Security</h4>
        <p>You are responsible for all activities that occur under your account. You must:
          <ul>
            <li>Create a strong, unique password</li>
            <li>Keep your password confidential</li>
            <li>Log out of your account when using shared devices</li>
            <li>Notify us immediately of any unauthorized access</li>
          </ul>
        </p>

        <h4>10.5 Account Sharing Prohibition</h4>
        <p>Your account is personal and may not be shared with or transferred to any other person or entity. Account sharing is strictly prohibited and may result in immediate termination.</p>

        <h4>10.6 Dormant Accounts</h4>
        <p>Accounts that remain inactive for more than 180 consecutive days may be deemed dormant. We reserve the right to terminate dormant accounts after providing notice.</p>
      </p>

      <h3>11. PAYMENT TERMS</h3>
      <p>
        <h4>11.1 Billing Cycle</h4>
        <p>Subscriptions are billed in advance on a monthly or annual basis, unless otherwise specified. All charges are non-refundable except as expressly provided in this Agreement.</p>

        <h4>11.2 Payment Methods</h4>
        <p>We accept payment via credit card, debit card, and other payment methods as specified on our website. You authorize us to charge your payment method for all fees incurred.</p>

        <h4>11.3 Annual Prepayment</h4>
        <p>Annual prepayment options are available with a discount as specified on our pricing page.</p>

        <h4>11.4 Taxes</h4>
        <p>All fees are exclusive of taxes. You are responsible for paying all applicable taxes, including sales tax, value-added tax, and other similar taxes.</p>

        <h4>11.5 Late Payments</h4>
        <p>Late payments may result in service suspension. A late fee of 2% per month may be applied to overdue balances.</p>

        <h4>11.6 Refund Policy</h4>
        <p>No refunds will be provided for partial months of service or for periods where the account was temporarily suspended due to non-compliance with this Agreement.</p>

        <h4>11.7 Billing Disputes</h4>
        <p>You must notify us of any billing disputes within 30 days of the charge date. Disputes submitted after this period may not be considered.</p>

        <h4>11.8 Cancellation</h4>
        <p>You may cancel your subscription at any time by providing 30 days' written notice. Cancellation will be effective at the end of your current billing cycle.</p>
      </p>

      <h3>12. FREE TRIAL OR PAID TRIAL</h3>
      <p>
        <h4>12.1 Trial Eligibility</h4>
        <p>We may offer a free trial of the Services or a paid trial of 1 BRL in Brazil or 1 USD outside Brazil for 7 days, 14 days, or 30 days. Eligibility for free trials or paid trials is determined at our sole discretion.</p>

        <h4>12.2 Trial Period</h4>
        <p>The duration of any free trial period will be specified at the time of sign-up.</p>

        <h4>12.3 Trial Limitations</h4>
        <p>Free trials or paid trials may have feature limitations or usage restrictions as compared to paid subscriptions (Monthly Plan or Annual Plan).</p>

        <h4>12.4 Credit Card Requirement</h4>
        <p>We may require you to provide a valid credit card to start a free trial. Your card will not be charged during the trial period, but will be automatically charged when the trial ends unless you cancel before that date.
          We may also require you to provide a valid credit card to start a 7-day trial, a 14-day trial or 30-day trial for 1 BRL in Brazil or 1 USD outside Brazil. Your card will be charged during the trial period for a minimum amount of 1 BRL in Brazil or 1 USD outside Brazil and will be automatically charged the normal amount when the trial ends unless you cancel before that date.
        </p>

        <h4>12.5 Automatic Conversion</h4>
        <p>At the end of the free trial period or 7-day trial, 14-day trial or 30-day trial, your account will automatically convert to a paid subscription (Monthly Plan or Annual Plan) unless you cancel before the trial ends.</p>

        <h4>12.6 One Trial Per User</h4>
        <p>Free trials or 7-day trials, 14-day trials or 30-day trials are limited to one per user. Attempts to create multiple accounts to obtain additional free trials are prohibited.</p>
      </p>

      <h3>13. SUBSCRIPTION TERMS</h3>
      <p>
        <h4>13.1 Subscription Period</h4>
        <p>Subscriptions begin on the date of initial payment and continue for the specified period (monthly or annual).</p>

        <h4>13.2 Automatic Renewal</h4>
        <p>Subscriptions automatically renew at the end of each subscription period unless canceled prior to the renewal date.</p>

        <h4>13.3 Cancellation Process</h4>
        <p>To cancel your subscription, you must provide written notice at least 30 days before the end of your current subscription period. Cancellation requests should be sent to suporte@shifters.com.br</p>

        <h4>13.4 Effect of Cancellation</h4>
        <p>Upon cancellation, your access to the Services will continue until the end of your current subscription period. No refunds will be provided for early cancellation.</p>

        <h4>13.5 Subscription Changes</h4>
        <p>You may upgrade your subscription at any time. Downgrades will take effect at the beginning of the next billing cycle.</p>

        <h4>13.6 Price Protection</h4>
        <p>If we increase the price of your subscription, we will notify you at least 30 days in advance. You may cancel your subscription before the price increase takes effect.</p>
      </p>

      <h3>14. PROHIBITED USES</h3>
      <p>
        <h4>14.1 General Prohibitions</h4>
        <p>You may not use the Services to:
          <ul>
            <li>Violate any applicable law or regulation</li>
            <li>Infringe the intellectual property rights of others</li>
            <li>Generate content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of privacy, or otherwise objectionable</li>
            <li>Distribute malware, viruses, or other harmful code</li>
            <li>Interfere with or disrupt the Services or servers</li>
            <li>Attempt to gain unauthorized access to the Services or other accounts</li>
            <li>Engage in any activity that could damage, disable, or impair the Services</li>
          </ul>
        </p>

        <h4>14.2 Content Restrictions</h4>
        <p>You may not use the Services to create:
          <ul>
            <li>Content that promotes illegal activities</li>
            <li>Content that promotes discrimination, bigotry, racism, or hatred</li>
            <li>Content that is sexually explicit or pornographic</li>
            <li>Content that is harmful to minors</li>
            <li>Content that is deceptive or fraudulent</li>
            <li>Content that impersonates any person or entity</li>
            <li>Content that contains personal information of third parties without consent</li>
          </ul>
        </p>

        <h4>14.3 Technical Restrictions</h4>
        <p>You may not:
          <ul>
            <li>Reverse engineer, decompile, or disassemble any part of the Services</li>
            <li>Use automated scripts, bots, or other software to access or interact with the Services</li>
            <li>Bypass or circumvent any security features of the Services</li>
            <li>Conduct performance or vulnerability tests without our express permission</li>
            <li>Use the Services for competitive analysis or to develop a competing product</li>
          </ul>
        </p>

        <h4>14.4 Usage Limitations</h4>
        <p>You may not exceed the usage limitations of your subscription tier. Excessive usage may result in additional charges or service limitations.</p>

        <h4>14.5 Fair Use Policy</h4>
        <p>We maintain a fair use policy to ensure equitable access to the Services for all users. If your usage significantly exceeds the average usage of other users with the same subscription tier, we may:
          <ul>
            <li>Contact you to discuss your usage patterns</li>
            <li>Recommend an upgraded subscription tier</li>
            <li>Limit your usage until the next billing cycle</li>
            <li>Terminate your account in cases of repeated excessive usage</li>
          </ul>
        </p>
      </p>

      <h3>15. THIRD-PARTY SERVICES</h3>
      <p>
        <h4>15.1 Third-Party Integrations</h4>
        <p>The Services may integrate with or provide access to third-party services, applications, or websites ("Third-Party Services"). These Third-Party Services are not under our control, and we are not responsible for their content, functionality, or privacy practices.</p>

        <h4>15.2 Third-Party Terms</h4>
        <p>Your use of Third-Party Services may be subject to additional terms and conditions provided by the third party. It is your responsibility to review and comply with these terms.</p>

        <h4>15.3 Data Sharing with Third Parties</h4>
        <p>When you connect to Third-Party Services through our platform, you may be sharing information with those third parties. We are not responsible for how third parties collect, use, or disclose your information.</p>

        <h4>15.4 Third-Party Liability</h4>
        <p>We disclaim all liability for any loss or damage arising from your use of Third-Party Services.</p>
      </p>

      <h3>16. LIMITATION OF LIABILITY</h3>
      <p>
        <h4>16.1 Liability Cap</h4>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR TOTAL LIABILITY TO YOU FOR ANY CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT OR THE SERVICES, REGARDLESS OF THE FORM OF ACTION, SHALL BE LIMITED TO THE AMOUNT PAID BY YOU TO US IN THE TWO (2) MONTHS PRECEDING THE CLAIM.</p>

        <h4>16.2 Exclusion of Damages</h4>
        <p>IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO YOUR USE OF OR INABILITY TO USE THE SERVICES.</p>

        <h4>16.3 Basis of the Bargain</h4>
        <p>THE LIMITATIONS OF LIABILITY IN THIS SECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE.</p>

        <h4>16.4 Essential Purpose</h4>
        <p>THE LIMITATIONS OF LIABILITY IN THIS SECTION APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>

        <h4>16.5 Exceptions</h4>
        <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above limitations may not apply to you.</p>
      </p>

      <h3>17. DISCLAIMERS AND WARRANTIES</h3>
      <p>
        <h4>17.1 Service Disclaimer</h4>
        <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</p>

        <h4>17.2 Content Disclaimer</h4>
        <p>WE DO NOT WARRANT THAT THE CONTENT GENERATED BY THE SERVICES WILL BE ERROR-FREE, ACCURATE, RELIABLE, OR MEET YOUR REQUIREMENTS. YOU ARE SOLELY RESPONSIBLE FOR EVALUATING AND VERIFYING ALL CONTENT GENERATED BY THE SERVICES.</p>

        <h4>17.3 AI Limitations</h4>
        <p>YOU ACKNOWLEDGE THAT AI TECHNOLOGY HAS INHERENT LIMITATIONS AND MAY PRODUCE CONTENT THAT IS INCORRECT, INAPPROPRIATE, OR MISLEADING. YOU ARE RESPONSIBLE FOR REVIEWING ALL GENERATED CONTENT BEFORE USE.</p>

        <h4>17.4 Service Interruptions</h4>
        <p>WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. YOU UNDERSTAND THAT THE SERVICES MAY BE UNAVAILABLE DUE TO SCHEDULED MAINTENANCE, SYSTEM UPGRADES, OR FACTORS BEYOND OUR CONTROL.</p>

        <h4>17.5 Security Disclaimer</h4>
        <p>WHILE WE IMPLEMENT REASONABLE SECURITY MEASURES, WE CANNOT GUARANTEE THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT OUR SECURITY MEASURES OR USE YOUR PERSONAL INFORMATION FOR IMPROPER PURPOSES.</p>
      </p>

      <h3>18. INDEMNIFICATION</h3>
      <p>
        <h4>18.1 User Indemnification</h4>
        <p>You agree to indemnify, defend, and hold harmless the Company, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that arise from or relate to:
          <ul>
            <li>Your use of the Services</li>
            <li>Your User Content</li>
            <li>Your violation of this Agreement</li>
            <li>Your violation of any rights of another person or entity</li>
            <li>Your violation of any applicable laws or regulations</li>
          </ul>
        </p>

        <h4>18.2 Defense Control</h4>
        <p>We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>

        <h4>18.3 Notification Requirement</h4>
        <p>You agree to promptly notify us of any third-party claims, cooperate with us in defending such claims, and pay all fees, costs, and expenses associated with defending such claims, including attorneys' fees.</p>
      </p>

      <h3>19. TERM AND TERMINATION</h3>
      <p>
        <h4>19.1 Term</h4>
        <p>This Agreement begins on the date you first use the Services and continues until terminated as described in this section.</p>

        <h4>19.2 Termination by User</h4>
        <p>You may terminate this Agreement at any time by:
          <ul>
            <li>Canceling your subscription as described in Section 13.3</li>
            <li>Deleting your account</li>
            <li>Ceasing all use of the Services</li>
          </ul>
        </p>

        <h4>19.3 Termination by Company</h4>
        <p>We may terminate this Agreement and your access to the Services at any time, with or without cause, and with or without notice. Reasons for termination may include, but are not limited to:
          <ul>
            <li>Violation of this Agreement</li>
            <li>Failure to pay fees when due</li>
            <li>Fraudulent, abusive, or illegal activity</li>
            <li>Extended periods of inactivity</li>
            <li>Upon request by law enforcement or government agencies</li>
            <li>If providing the Services to you becomes unlawful</li>
          </ul>
        </p>

        <h4>19.4 Effect of Termination</h4>
        <p>Upon termination:
          <ul>
            <li>Your right to access and use the Services will immediately cease</li>
            <li>We may delete your account and all associated data</li>
            <li>Any outstanding payment obligations will become immediately due</li>
            <li>Sections of this Agreement that by their nature should survive termination will survive</li>
          </ul>
        </p>

        <h4>19.5 Data Retention After Termination</h4>
        <p>After termination, we may retain your data for a limited period as required by law or for legitimate business purposes. We will securely delete your data when it is no longer needed.</p>
      </p>

      <h3>20. MODIFICATIONS TO TERMS OR SERVICES</h3>
      <p>
        <h4>20.1 Modification of Terms</h4>
        <p>We reserve the right to modify this Agreement at any time. We will notify you of material changes by posting the updated Agreement on our website or by sending you an email. Your continued use of the Services after such notification constitutes your acceptance of the modified Agreement.</p>

        <h4>20.2 Modification of Services</h4>
        <p>We reserve the right to modify, suspend, or discontinue any part of the Services at any time, with or without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the Services.</p>

        <h4>20.3 Price Changes</h4>
        <p>We may change our prices at any time. Price changes for existing subscribers will take effect at the start of the next subscription period after notice is provided.</p>

        <h4>20.4 Objection to Changes</h4>
        <p>If you do not agree with any modifications to this Agreement, you must stop using the Services and cancel your subscription.</p>
      </p>

      <h3>21. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
      <p>
        <h4>21.1 Governing Law</h4>
        <p>This Agreement shall be governed by and construed in accordance with the laws of São Paulo, Brazil, without regard to its conflict of law principles.</p>

        <h4>21.2 Dispute Resolution</h4>
        <p>Any dispute arising from or relating to this Agreement or the Services shall be resolved as follows:</p>

        <h4>21.2.1 Informal Resolution</h4>
        <p>Before filing a claim, you agree to try to resolve the dispute informally by contacting us at suporte@shifters.com.br. We will try to resolve the dispute by contacting you via email. If the dispute is not resolved within 30 days, either party may proceed with formal dispute resolution.</p>

        <h4>21.2.2 Formal Proceedings</h4>
        <p>Any dispute not resolved informally shall be resolved exclusively through binding arbitration in São Paulo, Brazil, in accordance with the arbitration rules of the Brazil-Canada Chamber of Commerce.</p>

        <h4>21.2.3 Class Action Waiver</h4>
        <p>ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER.</p>

        <h4>21.2.4 Exceptions</h4>
        <p>Notwithstanding the foregoing, either party may seek injunctive or other equitable relief in any court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party's intellectual property rights.</p>

        <h4>21.3 Time Limitation</h4>
        <p>Any claim arising out of or related to this Agreement must be filed within one (1) year after such claim arose, otherwise, the claim is permanently barred.</p>
      </p>

      <h3>22. GENERAL PROVISIONS</h3>
      <p>
        <h4>22.1 Entire Agreement</h4>
        <p>This Agreement constitutes the entire agreement between you and the Company regarding the Services and supersedes all prior agreements and understandings, whether written or oral.</p>

        <h4>22.2 Severability</h4>
        <p>If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect.</p>

        <h4>22.3 No Waiver</h4>
        <p>Our failure to enforce any right or provision of this Agreement will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of the Company.</p>

        <h4>22.4 Assignment</h4>
        <p>You may not assign or transfer this Agreement, by operation of law or otherwise, without our prior written consent. Any attempt to assign or transfer this Agreement without such consent will be null and void. We may assign or transfer this Agreement, at our sole discretion, without restriction.</p>

        <h4>22.5 Force Majeure</h4>
        <p>We will not be liable for any failure or delay in performance of our obligations under this Agreement that is due to causes beyond our reasonable control, including acts of God, natural disasters, pandemic, epidemic, war, terrorism, riots, civil unrest, government actions, power failures, or internet or telecommunications disruptions.</p>

        <h4>22.6 No Agency</h4>
        <p>No agency, partnership, joint venture, or employment is created as a result of this Agreement, and you do not have any authority of any kind to bind us in any respect whatsoever.</p>

        <h4>22.7 Notices</h4>
        <p>All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by email; and upon receipt, if sent by certified or registered mail, return receipt requested.</p>

        <h4>22.8 Export Control</h4>
        <p>You may not use, export, or re-export the Services in violation of any applicable laws or regulations, including without limitation United States and Brazilian export laws and regulations.</p>

        <h4>22.9 Language</h4>
        <p>This Agreement is in English and Portuguese. In case of conflict between the two versions, the Portuguese version shall prevail.</p>
      </p>

      <h3>23. CONTACT INFORMATION</h3>
      <p>If you have any questions about this Agreement or the Services, please contact us at:
        <ul>
          <li>Email: suporte@shifters.com.br</li>
          <li>Support:
            <Link to="https://www.shifters.com.br/AiGU75oYrw9c" target="_blank">
              https://www.shifters.com.br/AiGU75oYrw9c
            </Link>
          </li>
          <li>Address: Rua Mourato Coelho, 957, Pinheiros, CEP 05417-011 SP, São Paulo, Brazil</li>
          <li>SHIFTERS - CONSULTORIA E SOFTWARE LTDA. - EPP</li>
          <li>CNPJ/MF: 15.563.090/0001-04</li>
        </ul>
      </p>

      <p>By using CopyCut AI, you acknowledge that you have read, understood, and agree to be bound by this Agreement.</p>
    </Container>
  );
}
