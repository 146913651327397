import PropTypes from 'prop-types';
import { Card } from './style';

import IconExclamation from '../../styles/logos/warning_exclamation.svg';
import IconExclamationDark from '../../styles/logos/warning_exclamation_dark.svg';
import IconCheck from '../../styles/logos/warning_check.svg';
import IconCheckDark from '../../styles/logos/warning_check_dark.svg';
import IconError from '../../styles/logos/warning_error.svg';
import IconErrorDark from '../../styles/logos/warning_error_dark.svg';
import IconDefault from '../../styles/logos/warning_default.svg';
import IconDefaultDark from '../../styles/logos/warning_default_dark.svg';


function handleIcon(type) {
  switch (type) {
    case 'warning':
      return IconExclamation;

    case 'warning-dark':
      return IconExclamationDark;

    case 'success':
      return IconCheck;

    case 'success-dark':
      return IconCheckDark;

    case 'error':
      return IconError;

    case 'error-dark':
      return IconErrorDark;

    case 'default':
      return IconDefault;

    case 'default-dark':
      return IconDefaultDark;

    default:
      break;
  }
}
export function Warning({
  type, text, size,
}) {
  return (
    <Card type={type} size={size}>
      <img src={handleIcon(type)} alt="Icon" />
      <div className="text">{text}</div>
    </Card>
  );
}

Warning.defaultProps = {
  size: 'normal',
};

Warning.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['normal', 'small']),
  type: PropTypes.oneOf([
    'warning',
    'warning-dark',
    'success',
    'success-dark',
    'error',
    'error-dark',
    'default',
    'default-dark',
  ]).isRequired,
};
