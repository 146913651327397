import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';

import { MultiSelect } from '../MultiSelect';
import { RegionInputContext } from './RegionInput.context';

import { Translate } from './translate';


export function RegionsInput() {
  const translate = Translate();
  const {
    continentsList,
    countriesList,
    statesList,
    hasCleanFilter,
    setContinentsSelected,
    setCountriesSelected,
    setStatesSelected,
  } = useContext(RegionInputContext);

  return (
    <div className="regions-container">
      <MultiSelect
        placeholder="All"
        key="continent"
        name="continent"
        label={translate.continent}
        onSetFilter={setContinentsSelected}
        options={continentsList}
        hasCleanFilter={hasCleanFilter}
      />

      <MultiSelect
        placeholder="All"
        key="country"
        name="country"
        label={translate.country}
        onSetFilter={setCountriesSelected}
        options={countriesList}
        hasCleanFilter={hasCleanFilter}
      />

      <MultiSelect
        placeholder="All"
        key="state"
        name="state"
        label={translate.state}
        onSetFilter={setStatesSelected}
        options={statesList}
        hasCleanFilter={hasCleanFilter}
      />
    </div>
  );
}

RegionsInput.propTypes = {
};


