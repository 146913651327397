import PropTypes from 'prop-types';

import { CardArea } from './style';
import { Button } from '../../../../../components/Button';
import { Badge } from '../../../../../components/Badge';

import IconCPU from '../../../../../styles/logos/icon_cpu.svg';

export function Card({
  icon,
  title,
  estimatedTimeText,
  estimatedTimeDuration,
  mainMessage,
  onHandleClick,
  buttonContent,
  colorButton,
  isDisabled,
}) {
  function handleClick() {
    onHandleClick();
  }

  return (
    <CardArea>
      <div className="card-content">
        <div className="logo">
          <img src={icon} alt="Logo" />
        </div>
        <div className="content">
          <div className="content-title">
            <h3>{title}</h3>
            <Badge>
              {estimatedTimeText}: <strong>{estimatedTimeDuration}</strong>
            </Badge>
          </div>
          <p>{mainMessage}</p>
        </div>
      </div>
      <Button
        color={colorButton}
        onClick={() => handleClick()}
        disabled={isDisabled}
      >
        {buttonContent}
      </Button>
    </CardArea>
  );
}

Card.defaultProps = {
  icon: IconCPU,
  isDisabled: false,
};

Card.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  estimatedTimeText: PropTypes.string.isRequired,
  estimatedTimeDuration: PropTypes.string.isRequired,
  mainMessage: PropTypes.string.isRequired,
  onHandleClick: PropTypes.func.isRequired,
  buttonContent: PropTypes.string.isRequired,
  colorButton: PropTypes.oneOf(['blue', 'green', 'red', 'yellow', 'ghost', 'white']).isRequired,
  isDisabled: PropTypes.bool,
};
