import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '../../../../../components/ProgressBar';

export const LoaderContainerMemo = memo(({ count, message, color }) => (
  <div style={{
    textAlign: 'center',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  }}
  >
    <ProgressBar
      value={count}
      color={color}
      showPercentage={false}
    />
    <span>{message}</span>
  </div>
));


LoaderContainerMemo.default = {
  color: 'blue',
};

