export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    doneBy: 'Created by: ',

    labelSite: 'Context (Site Data / Landing Page Data / Competitors Data / Emails Data / Ecommerce / SKU)',
    labelAvatar: 'Target Audience (Ideal Customer / Persona / ICP / Audience / AVATAR)',
    labelNiche: 'Niche (Niche or mass market)',
    labelLanguage: 'Language',
    labelToneOfVoice: 'Tone of Voice',

    modalTitle: 'Are you sure you want to delete this item?',
    modalBtn: 'Delete',

    noData: 'No content in this layer.',

    toneOfVoiceSaleMode: 'Selling Mode',
    toneOfVoiceProfissional: 'Professional',
    toneOfVoiceExpert: 'Expert',
    toneOfVoiceBusinessAndTrust: 'Business and Trust',
    toneOfVoiceSensitiveCommunication: 'Sensitive Communication',
    toneOfVoiceFriendly: 'Friendly',

    group1Title: 'LinkedIn Posts',
    group2Title: 'Instagram Posts',
    group3Title: 'Facebook Posts',
    group4Title: 'TikTok Posts',
    group5Title: 'Email Marketing',

    titleCardVSLDirectionToPleasure: 'VSL Direction To Pleasure',
    titleCardVSLWithdrawalFromPain: 'VSL Withdrawal From Pain',
    titleCardVSLImpactfulStatistics: 'VSL Impactful Statistics',
    titleCardVSLPhysicalGuidance: 'VSL Physical Guidance',
    titleCardVSLValueStatement: 'VSL Value Statement',
    titleCardVSLTestimonial: 'VSL Testimonial',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelSite: 'Contexto (Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        labelAvatar: 'Público-Alvo (Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        labelNiche: 'Nicho (Nicho ou mercado de massas)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem certeza que deseja deletar esse item?',
        modalBtn: 'Deletar',

        noData: 'Sem conteúdo nesta camada.',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        titleCardVSLDirectionToPleasure: 'VSL Direção ao Prazer',
        titleCardVSLWithdrawalFromPain: 'VSL Afastamento da Dor',
        titleCardVSLImpactfulStatistics: 'VSL Estatísticas Impactantes',
        titleCardVSLPhysicalGuidance: 'VSL Orientação Física',
        titleCardVSLValueStatement: 'VSL Declaração de Valor',
        titleCardVSLTestimonial: 'VSL Depoimento',

        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelSite: 'Contexto (Dados do site / Landing Page / Concorrentes/ Dados de e-mail / Ecommerce / SKU)',
        labelAvatar: 'Público-Alvo (Cliente Ideal / Persona / ICP / Audiência / AVATAR)',
        labelNiche: 'Nicho (Nicho ou mercado de massas)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem a certeza que pretende eliminar este item?',
        modalBtn: 'Eliminar',

        noData: 'Sem conteúdo nesta camada.',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        titleCardVSLDirectionToPleasure: 'VSL Direção ao Prazer',
        titleCardVSLWithdrawalFromPain: 'VSL Afastamento da Dor',
        titleCardVSLImpactfulStatistics: 'VSL Estatísticas Impactantes',
        titleCardVSLPhysicalGuidance: 'VSL Orientação Física',
        titleCardVSLValueStatement: 'VSL Declaração de Valor',
        titleCardVSLTestimonial: 'VSL Testemunho',

        language,
      };

    case 'es-ES':
      return {
        ...terms,
        doneBy: 'Creado por: ',

        labelSite: 'Contexto (Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico / Ecommerce / SKU)',
        labelAvatar: 'Público Objetivo (Cliente Ideal / Persona / ICP / Audiencia / AVATAR)',
        labelNiche: 'Nicho (Nicho o mercado masivo)',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tono de voz',

        modalTitle: '¿Estás seguro de que quieres eliminar este elemento?',
        modalBtn: 'Eliminar',

        noData: 'Sin contenido en esta capa.',

        toneOfVoiceSaleMode: 'Modo de venta',
        toneOfVoiceProfissional: 'Profesional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negocios y Confianza',
        toneOfVoiceSensitiveCommunication: 'Comunicación Sensible',
        toneOfVoiceFriendly: 'Amigable',

        titleCardVSLDirectionToPleasure: 'VSL Dirección al Placer',
        titleCardVSLWithdrawalFromPain: 'VSL Alejamiento del Dolor',
        titleCardVSLImpactfulStatistics: 'VSL Estadísticas Impactantes',
        titleCardVSLPhysicalGuidance: 'VSL Orientación Física',
        titleCardVSLValueStatement: 'VSL Declaración de Valor',
        titleCardVSLTestimonial: 'VSL Testimonio',

        language,
      };

    default:
      return terms;
  }
}
