import React, { useContext } from 'react';
import {
  Route, Routes, useParams,
} from 'react-router-dom';
import { ModulesContext } from '../context/ModulesContext';
import { AuthContext } from '../context/AuthContext';
import Home from '../pages/IndexUser/Home';
import { TrackerCompany } from '../pages/IndexUser/Tracker/TrackerCompany';
import { TrackerCompanyClientInfo } from '../pages/IndexUser/Tracker/TrackerCompany/TrackerCompanyClientInfo';
import { TrackerUser } from '../pages/IndexUser/Tracker/TrackerUser';
import BusinessMacro from '../pages/IndexUser/BusinessMacro';
import URLBuilder from '../pages/IndexUser/URLBuilder';
import UrlBuilderAdd from '../pages/IndexUser/URLBuilder/URLBuilderAdd';
import UrlBuilderAddParams from '../pages/IndexUser/URLBuilder/URLBuilderAddParams';
import UrlBuilderAddParamsEdit from '../pages/IndexUser/URLBuilder/URLBuilderAddParamsEdit';
import UrlBuilderAddParamsNew from '../pages/IndexUser/URLBuilder/URLBuilderAddParamsNew';
import FavoritesVideos from '../pages/IndexUser/FavoritesVideos';
import { B2BDatabasesIndex } from '../pages/IndexUser/B2BDataBases';
import Settings from '../pages/Settings';
import EditPassword from '../pages/Settings/EditPassword';
import About from '../pages/About';
import { LegalPage } from '../pages/IndexUser/LegalPage';
import NotFound from '../pages/NotFound';
import { NotAllowed } from '../pages/NotAllowed';
import { UnderConstruction } from '../pages/UnderConstruction';
import { Academy } from '../pages/IndexUser/Academy';
import { RatingsVideos } from '../pages/IndexUser/RatingsVideos';
import { Feed } from '../pages/IndexUser/Feed';
import { Apis } from '../pages/IndexUser/APIs';
import { CopyCutPaid } from '../pages/IndexUser/AILabs/CopyCutPaid';
import { CopyCutPaidAds } from '../pages/IndexUser/AILabs/CopyCutPaidAds';
import { CopyCutUnpaidPosts } from '../pages/IndexUser/AILabs/CopyCutUnpaidPosts';
import { IndexAILab } from '../pages/IndexUser/AILabs/Index';
import { AIManageProjects } from '../pages/IndexUser/AILabs/ManageProjects';

export default function ClientRoutes() {
  const { accessHash, userInfo: { email } } = useContext(AuthContext);
  const { modules } = useContext(ModulesContext);
  const params = useParams();
  const idProduct = Number(params['*'].split('/')[1]);

  console.log(idProduct);

  return (
    <Routes>
      {/* Home - /index */}
      <Route path="/" element={<Home />} />

      {/* Home - /feed */}
      {accessHash.includes(modules.filter((item) => item.id === 19).map((item) => item.accessHash)[0])
        ? <Route path="feed" element={<Feed />} />
        : <Route path="feed" element={<NotAllowed />} />}

      {/* Home - /onboarding */}
      {accessHash.includes(modules.filter((item) => item.id === 20).map((item) => item.accessHash)[0])
        ? <Route path="onboarding" element={<UnderConstruction />} />
        : <Route path="onboarding" element={<UnderConstruction />} />}

      {/* Home - /favorites-videos */}
      {accessHash.includes(modules.filter((item) => item.id === 21).map((item) => item.accessHash)[0])
        ? <Route path="favorites-videos" element={<FavoritesVideos />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Home - /company-tracker */}
      {accessHash.includes(modules.filter((item) => item.id === 41).map((item) => item.accessHash)[0])
        ? (
          <>
            <Route path="company-tracker" element={<TrackerCompany />} />
            <Route path="company-tracker/user-info/:id" element={<TrackerCompanyClientInfo />} />
          </>
        )
        : <Route path="*" element={<NotAllowed />} />}

      {/* Home - /user-tracker */}
      {accessHash.includes(modules.filter((item) => item.id === 42).map((item) => item.accessHash)[0])
        ? <Route path="user-tracker" element={<TrackerUser />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Home - /:id/:video | get idProduct from param and compare with productId from accessHash */}
      {accessHash.includes(modules.filter((item) => item.productId === Number(idProduct)).map((item) => item.accessHash)[0])
        ? <Route path="home/:idProduct/:video" element={<Academy />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Academy - academy/:id/:video | get idProduct from param and compare with productId from accessHash */}
      {accessHash.includes(modules.filter((item) => item.productId === Number(idProduct)).map((item) => item.accessHash)[0])
        ? <Route path="academy/:idProduct/:video" element={<Academy />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Consulting - consulting/:id/:video | get idProduct from param and compare with productId from accessHash */}
      {accessHash.includes(modules.filter((item) => item.productId === Number(idProduct)).map((item) => item.accessHash)[0])
        ? <Route path="consulting/:idProduct/:video" element={<Academy />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Transformation - transformation/:id/:video | get idProduct from param and compare with productId from accessHash */}
      {accessHash.includes(modules.filter((item) => item.productId === Number(idProduct)).map((item) => item.accessHash)[0])
        ? <Route path="intelligence/:idProduct/:video" element={<Academy />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* RBI Software - /dashboard/macro */}
      {accessHash.includes(modules.filter((item) => item.id === 22).map((item) => item.accessHash)[0])
        ? <Route path="dashboard/macro" element={<BusinessMacro />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* RBI Software - /dashboard/micro */}
      {accessHash.includes(modules.filter((item) => item.id === 23).map((item) => item.accessHash)[0])
        ? <Route path="dashboard/micro" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* RBI Software - /dashboard/full-funnel */}
      {accessHash.includes(modules.filter((item) => item.id === 24).map((item) => item.accessHash)[0])
        ? <Route path="dashboard/full-funnel" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Data Services - /apis */}
      {accessHash.includes(modules.filter((item) => item.id === 25).map((item) => item.accessHash)[0])
        ? <Route path="apis" element={<Apis />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Data Services - /urlbuilder */}
      {accessHash.includes(modules.filter((item) => item.id === 26).map((item) => item.accessHash)[0])
        ? (
          <>
            <Route path="urlbuilder" element={<URLBuilder />} />
            <Route path="urlbuilder/add" element={<UrlBuilderAdd />} />
            <Route path="urlbuilder/addparams" element={<UrlBuilderAddParams />} />
            <Route path="urlbuilder/addparams/edit/:id" element={<UrlBuilderAddParamsEdit />} />
            <Route path="urlbuilder/addparams/new/:id" element={<UrlBuilderAddParamsNew />} />
          </>
        )
        : <Route path="*" element={<NotAllowed />} />}

      {/* Data Services - /b2b */}
      {accessHash.includes(modules.filter((item) => item.id === 27).map((item) => item.accessHash)[0])
        ? <Route path="b2b-databases" element={<B2BDatabasesIndex />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Data Services - /mass-keyword */}
      {accessHash.includes(modules.filter((item) => item.id === 28).map((item) => item.accessHash)[0])
        ? <Route path="mass-keyword" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/search */}
      {accessHash.includes(modules.filter((item) => item.id === 29).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/search" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/register */}
      {accessHash.includes(modules.filter((item) => item.id === 30).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/register" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/cfo */}
      {accessHash.includes(modules.filter((item) => item.id === 31).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/cfo" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/cto */}
      {accessHash.includes(modules.filter((item) => item.id === 32).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/cto" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/cmo */}
      {accessHash.includes(modules.filter((item) => item.id === 33).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/cmo" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* Marketplace - /marketplace/consultant */}
      {accessHash.includes(modules.filter((item) => item.id === 34).map((item) => item.accessHash)[0])
        ? <Route path="marketplace/consultant" element={<UnderConstruction />} />
        : <Route path="*" element={<NotAllowed />} />}

      {/* RatingsVideos */}
      <Route path="rating-video/:idVideo" element={<RatingsVideos />} />

      {/* User Settings Routes */}
      <Route path="settings" element={<Settings />} />

      {/* User Settings Password Routes */}
      <Route path="settings/edit-password" element={<EditPassword />} />

      {/* About Routes */}
      <Route path="about" element={<About />} />

      {/* Terms And Conditions Routes */}
      <Route path="legal" element={<LegalPage />} />

      {/* Ai Lab - Offer Builder */}
      {accessHash.includes(modules.filter((item) => item.id === 53).map((item) => item.accessHash)[0])
        ? (
          <>
            <Route path="/ailab" element={<IndexAILab />} />
            <Route path="/ailab/copycut-assets" element={<CopyCutPaid />} />

            {/* //TODO: Retirar essa trava ao terminar essa feature */}
            {email === 'suporte+111menu@shifters.com.br' && (
              <Route path="/ailab/copycut-paid-ads" element={<CopyCutPaidAds />} />
            )}

            <Route path="/ailab/copycut-unpaid-posts" element={<CopyCutUnpaidPosts />} />
            <Route path="/ailab/manage-projects/*" element={<AIManageProjects />} />
          </>
        )
        : <Route path="*" element={<NotAllowed />} />}

      <Route path="ailab/*" element={<NotFound />} />

      {/* Under Construction Routes */}
      <Route path="under-construction" element={<UnderConstruction />} />

      {/* Not Allowed Routes */}
      <Route path="not-allowed" element={<NotAllowed />} />

      {/* Not Found Routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
