import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Content } from './Content';
import { EditName } from './EditName';
import { InfosCopyCutPaid } from './InfosCopyCutPaid';
import { InfosCopyCutPaidAds } from './InfosCopyCutPaidAds';
import { InfosCopyCutUnpaidPosts } from './InfosCopyCutUnpaidPosts';

export function RouterManageProjects() {
  return (
    <Routes>
      <Route path="" element={<Content />} />
      <Route path="infos/copycut-assets/:id" element={<InfosCopyCutPaid />} />
      <Route path="infos/copycut-paid-ads/:id" element={<InfosCopyCutPaidAds />} />
      <Route path="infos/copycut-unpaid-posts/:id" element={<InfosCopyCutUnpaidPosts />} />
      <Route path="edit-name/:id" element={<EditName />} />
    </Routes>
  );
}
