import React, {
  useState, useRef, useEffect,
} from 'react';

import ClientRoutes from '../../routes/ClientRoutes';

import { ProductsProvider } from '../../context/ProductsContext';
import { ModulesProvider } from '../../context/ModulesContext';
import { SideBarVideosProvider } from '../../context/SideBarVideosContext';
import { FeedProvider } from '../../context/FeedContext';
import { RegionInputProvider } from '../../components/RegionsInput/RegionInput.context';

import Menu from '../../components/Menu';
import SideBarUser from '../../components/SidebarUser';
import { MainContainer } from '../../components/MainContainer';
// import SideBarUserMock from '../../components/SidebarUserMock';

import { PageContainer } from './style';

function IndexUser() {
  const [toogle, setToogle] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMinorThanTabletWidth = screenWidth <= 768;
  const sideBarRef = useRef(null);

  // Event to always look at screen resize
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close Menu sidebar
  useEffect(() => {
    document.addEventListener('mousedown', handleToogle);

    return () => {
      document.removeEventListener('mousedown', handleToogle);
    };
  }, [sideBarRef, toogle, isMinorThanTabletWidth]);

  function handleToogle(event) {
    const firstCondition = (
      isMinorThanTabletWidth
       && toogle
       && sideBarRef.current
       && !sideBarRef.current.contains(event.target)
    );

    const secondCondition = (
      isMinorThanTabletWidth
      && toogle
      && event.target.classList.contains('js-link')
    );

    if (firstCondition || secondCondition) {
      setToogle((prevState) => (prevState ? false : prevState));
    }
  }

  function handleToogleMenu() {
    setToogle((prevState) => !prevState);
  }

  return (
    <>
      <ModulesProvider>
        <ProductsProvider>
          <SideBarVideosProvider>
            <SideBarUser toogle={toogle} ref={sideBarRef} />
            <PageContainer $toogle={toogle}>
              <Menu onHandleToogle={handleToogleMenu} toogle={toogle} />
              <MainContainer>
                <FeedProvider>
                  <RegionInputProvider>
                    <ClientRoutes />
                  </RegionInputProvider>
                </FeedProvider>
              </MainContainer>
            </PageContainer>
          </SideBarVideosProvider>
        </ProductsProvider>
      </ModulesProvider>
    </>
  );
}

export default IndexUser;
