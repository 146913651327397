import React from 'react';
import { TermsContentPT } from './TermsContentPT';
import { TermsContentEN } from './TermsContentEN';
import BackButton from '../../../components/BackButton';
import { AreaContainer } from './style';

export function TermsContent() {
  const language = JSON.parse(localStorage.getItem('language'));
  const TermsContent = language === 'pt-BR' ? TermsContentPT : TermsContentEN;
  return (
    <AreaContainer>
      <BackButton />
      <TermsContent />
    </AreaContainer>
  );
}
