import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { TermsContent } from '../../LegalPageTemplate/TermsContent';
import { PrivacyPolicyContent } from '../../LegalPageTemplate/PrivacyPolicyContent';

export function LegalPage() {
  const [qs] = useSearchParams();
  const PageToShow = qs.get('assets') === 'terms-and-conditions' ? TermsContent : PrivacyPolicyContent;

  return (
    <PageToShow />
  );
}
